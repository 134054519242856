import React, { useEffect, useState } from 'react'
import { Accordion, Button, Container, Form, Table } from "react-bootstrap";
import { IoHomeOutline, IoTrashBin } from 'react-icons/io5';
import { DeleteLeadById, GetAllLeadStatus, GetAllLeads, GetLeadByUserId, GetLeadByUserIdCopy } from '../../../utils/proxy/LeadsProxy';
import DataTable from "react-data-table-component";
import { AiFillDelete, AiTwotoneEdit } from 'react-icons/ai';
import { MdEmail } from 'react-icons/md';
import { FaPhoneAlt } from 'react-icons/fa';
import Modal from 'react-bootstrap/Modal';
import LeadModal from './LeadModal';
import { AllUsers, GetAllManagerUsers } from '../../../utils/proxy/Authproxy';
import LoadingComponent from '../../Includes/LoadingComponent';
import Select from "react-select";
import { FaPlus } from 'react-icons/fa6';
import Swal from 'sweetalert2';
import { TbFilterStar } from 'react-icons/tb';
import { IoIosClose } from 'react-icons/io';
import DatePicker from 'react-date-picker';
import { Op_per } from '../../../utils/Permissions';

function Leads() {

  const [leads, setLeads] = useState([]);
  const [leadstatus, setLeadStatus] = useState([]);
  const [fullscreen, setFullscreen] = useState(true);
  const [show, setShow] = useState(false);
  const [leadid, setLeadId] = useState();
  const [reload, setReload] = useState(true);
  const [opttype, setOptType] = useState();

  const [users, setusers] = useState([])
  const [selectedUsers, setSelectedUsers] = useState(localStorage.getItem('role') === 'User' ? { value: localStorage.getItem('uuid'), label: localStorage.getItem('firstname') + " " + localStorage.getItem('lastname') + " (Self)" } : null);
  const [selectedUserId, setSelectedUserId] = useState(localStorage.getItem('role') === 'User' ? localStorage.getItem('uuid') : '');

  const [selectedleadstatus, setSelectedLeadStatus] = useState([]);
  const [selectedleadstatusids, setSelectedLeadStatusIds] = useState([]);

  const [page, setPage] = useState(0);
  const [size, setSize] = useState(10);
  const [totalPages, setTotalPages] = useState();
  const [totalRows, setTotalRows] = useState();
  const [currentPage, setCurrentPage] = useState();
  const [date, setDate] = useState('');

  function handleShow(id) {
    setOptType('update');
    setLeadId(id);
    setFullscreen(true);
    setShow(true);
  }

  function handleShowCreate() {
    setOptType('create');
    setFullscreen(true);
    setShow(true);
  }



  useEffect(() => {
    if (reload) {
      getLeads();
      getStatus();
      setReload(false);
    }

  }, [selectedUserId, reload, page, size, selectedleadstatusids, date]);

  async function getStatus() {
    let res = await GetAllLeadStatus();
    if (res) {
      setLeadStatus(res);

    }
  }


  useEffect(() => {

    const getAllUsersData = async () => {
      if (localStorage.getItem('role') === 'Admin') {
        let allusers = await AllUsers();
        allusers.push({ firstname: localStorage.getItem('firstname'), lastname: localStorage.getItem('lastname'), userid: localStorage.getItem('uuid'), username: localStorage.getItem('username') })
        setusers(allusers);
      } else if (localStorage.getItem('role') === 'Manager') {
        let allusers = await GetAllManagerUsers(localStorage.getItem('uuid'));
        setusers(allusers);
      }
    };
    getAllUsersData();
  }, []);

  async function getLeads() {
    let managerid = localStorage.getItem('role') === "Manager" ? localStorage.getItem('uuid') : ''
    let res = await GetLeadByUserIdCopy(selectedUserId, page, size, selectedleadstatusids, managerid, date);
    if (res) {
      setLeads(res.leads);
      setTotalPages(res.totalpages)
      setTotalRows(res.totalrows)
      setCurrentPage(res.currentpage)
    }
  }


  const handleSelectChange = (selected) => {
    if (selected == null) {
      setSelectedUsers(null);
      setSelectedUserId('')
      setReload(true)
      return;
    }
    setSelectedUsers(selected);
    setSelectedUserId(selected.value);
    setReload(true)
    setPage(0);
    setSize(10);
  };

  const handleSelectLeadStatusChange = (selected) => {
    const values = selected.map((option) => option.value);
    setSelectedLeadStatus(selected);
    setSelectedLeadStatusIds(values);
    setReload(true)
    setPage(0);
    setSize(10);
  };

  const handlePageChangeTable = (page) => {
    setPage(page - 1);
  }

  const handlePerRowChange = (newPerPage, page) => {
    setSize(newPerPage);
    setPage(page - 1);
  }

  function dateToIsoStringWithoutChange(istDate) {
    if (!(istDate instanceof Date)) {
      throw new Error("Input must be a Date object");
    }

    istDate.setMinutes(istDate.getMinutes() - istDate.getTimezoneOffset());

    const isoString = istDate.toISOString();

    return isoString;
  }

  const handleDateFilter = (value) => {
    if (value == null) {
      setDate('');
      setReload(true);
      return;
    }
    setDate(dateToIsoStringWithoutChange(value).split('T')[0]);
    setReload(true);
  };

  const clearDate = () => {
    setDate('');
    setReload(true);
  };

  const handleDelete = (id) => {
    Swal.fire({
      title: "Are You Sure You Want To Delete Lead?",
      text: "All task Assigned With This Lead Will Also be Deleted.",
      showDenyButton: true,
      // showCancelButton: true,
      confirmButtonText: "Yes, Delete Lead",
      confirmButtonColor: "#880808	",
      denyButtonColor: "#000"
      // denyButtonText: `Don't save`
    }).then(async (result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        let res = await DeleteLeadById(id)
        if (res) {
          Swal.fire("Lead has Been Deleted!", "", "success");
          setReload(true);
        }
      } else if (result.isDenied) {
        Swal.fire("You Have canceled The Delete Request", "", "info");
      }
    });
  };

  const columns = [
    // {
    //     name: "Id",
    //     selector: (row) => row.id,
    //     width:"50px"
    // },
    {
      name: "Contact Person",
      selector: (row) => <div onClick={() => handleShow(row.id)}><u style={{ color: 'blue', cursor: "pointer", fontSize: 10 }}>{row.firstname} {row.lastname}</u></div>,

    },
    {
      name: "Title",
      selector: (row) => <div style={{ fontSize: 10 }}>{row.title}</div>,

    },
    {
      name: "Account",
      selector: (row) => <div style={{ fontSize: 10 }}>{row.company}</div>,

    },
    {
      name: "Industry",
      selector: (row) => <div style={{ fontSize: 10 }}>{row.industry}</div>,
      // width: "10%"

    },
    {
      name: "Website",
      selector: (row) => <div style={{ fontSize: 10 }}>{row.website}</div>,
      // width: "15%"

    },
    {
      name: "Lead Status",
      selector: (row) => <div style={{ fontSize: 10, backgroundColor: row.leadcolor, padding: 3, borderRadius: 20, color: "#fff" }}>{row.status}</div>,
      // width: "7%"

    },
    {
      name: "Lead Source",
      selector: (row) => <div style={{ fontSize: 10 }}>{row.leadsource}</div>,
      // width: "10%"

    },
    {
      name: "isWon?",
      selector: (row) => <div style={{ fontSize: 10, color: '#fff', backgroundColor: row.leadachstatus == 'WON' ? 'green' : row.leadachstatus == 'LOST' ? 'red' : "blue", padding: 3, borderRadius: 10 }}>{row.leadachstatus == 'WON' ? 'WON' : row.leadachstatus == 'LOST' ? 'LOST' : "N/A"}</div>,
      // width: "10%"

    },
    {
      name: "Actions",
      selector: (row) => (
        <div key={row.id}>
          <MdEmail onClick={() => window.open("mailto:" + row.email, "_blank")} style={{ color: "#9889e6", fontSize: 20, }} />
          &nbsp;&nbsp;&nbsp;
          <FaPhoneAlt onClick={() => window.open("tel:" + row.phone, "_blank")} style={{ color: "#9889e6", fontSize: 15, }} />
          &nbsp;&nbsp;&nbsp;
          <IoTrashBin onClick={() => handleDelete(row.id)} style={{ color: "#f46e6f", fontSize: 15, }} />
        </div>
      ),
      // width: "10%"
    }
  ]


  const options = [];
  users.map((user) => {
    options.push({
      value: user.userid,
      label: user.username + "(" + user.firstname + " " + user.lastname + ")",
    });
  });

  const leadstatusoptions = [];
  leadstatus.map((status) => {
    leadstatusoptions.push({
      value: status.id,
      label: status.name
    })
  })


  const ExpandedComponent = ({ data }) => (
    <div
      style={{
        backgroundColor: data.disabled ? "red" : "",
        color: "#fff",
        padding: 10,
        fontSize: 12,
        margin: 20,
        width: "95%"

      }}
    >

      <Table className="mt-3 table table-bordered">
        <tr>
          <th style={{ fontWeight: "bold", color: "#000", padding: 10, width: "10%" }} >Company</th>
          <td style={{ width: "10%" }}>:</td>
          <td style={{ padding: 10, width: "30%" }}> {data.company} </td>
          <th style={{ fontWeight: "bold", color: "#000", padding: 10, width: "10%" }}>Contact Person </th>
          <td style={{ width: "10%" }}>:</td>
          <td style={{ padding: 10, width: "30%" }}>{data.salutation} {data.firstname} {data.lastname} </td>
          <th style={{ fontWeight: "bold", color: "#000", padding: 10, width: "10%" }} >Email</th>
          <td style={{ width: "10%" }}>:</td>
          <td style={{ padding: 10, width: "30%" }}> {data.email} </td>
        </tr>
        <tr>

          <th style={{ fontWeight: "bold", color: "#000", padding: 10, width: "10%" }}>Phone </th>
          <td style={{ width: "10%" }}>:</td>
          <td style={{ padding: 10, width: "30%" }}>{data.phone}</td>

          <th style={{ fontWeight: "bold", color: "#000", padding: 10, width: "10%" }}>Title </th>
          <td style={{ width: "10%" }}>:</td>
          <td style={{ padding: 10, width: "30%" }}>{data.title}</td>

          <th style={{ fontWeight: "bold", color: "#000", padding: 10, width: "10%" }}>Website </th>
          <td style={{ width: "10%" }}>:</td>
          <td style={{ padding: 10, width: "30%" }}>{data.website}</td>
        </tr>

        <tr>

          <th style={{ fontWeight: "bold", color: "#000", padding: 10, width: "10%" }}>Total Employes </th>
          <td style={{ width: "10%" }}>:</td>
          <td style={{ padding: 10, width: "30%" }}>{data.totalemployees}</td>

          <th style={{ fontWeight: "bold", color: "#000", padding: 10, width: "10%" }}>Worth </th>
          <td style={{ width: "10%" }}>:</td>
          <td style={{ padding: 10, width: "30%" }}>{data.annualrevenue}</td>

          <th style={{ fontWeight: "bold", color: "#000", padding: 10, width: "10%" }}>Industry </th>
          <td style={{ width: "10%" }}>:</td>
          <td style={{ padding: 10, width: "30%" }}>{data.industry}</td>
        </tr>


        <tr>

          <th style={{ fontWeight: "bold", color: "#000", padding: 10, width: "10%" }}>Lead Source </th>
          <td style={{ width: "10%" }}>:</td>
          <td style={{ padding: 10, width: "30%" }}>{data.leadsource}</td>

          <th style={{ fontWeight: "bold", color: "#000", padding: 10, width: "10%" }}>Lead Owner </th>
          <td style={{ width: "10%" }}>:</td>
          <td style={{ padding: 10, width: "30%" }}>{data.leadowner}</td>

          <th style={{ fontWeight: "bold", color: "#000", padding: 10, width: "10%" }}>Desc </th>
          <td style={{ width: "10%" }}>:</td>
          <td style={{ padding: 10, width: "30%" }}>{data.description}</td>
        </tr>

        <tr>

          <th style={{ fontWeight: "bold", color: "#000", padding: 10, width: "10%" }}>Products </th>
          <td style={{ width: "10%" }}>:</td>
          <td style={{ padding: 10, width: "30%" }}>
            {data.products.map((product) => (
              <div style={{ fontWeight: 'bold' }}>
                {product.productname}<br />
              </div>

            ))}
          </td>
          <th style={{ fontWeight: "bold", color: "#000", padding: 10, width: "10%" }}>Tax id </th>
          <td style={{ width: "10%" }}>:</td>
          <td style={{ padding: 10, width: "30%" }}>{data.taxid}</td>

        </tr>
      </Table>
    </div>
  );

  return (
    <Container fluid style={{ minHeight: "80vh" }}>
      <div
        className="glass-body"
        style={{
          margin: 10,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          padding: 0,
          backgroundColor: 'transparent'
        }}
      >

        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <ul id="breadcrumb">
            <li><a href="/"><IoHomeOutline
              style={{ color: "white", fontSize: 20 }}

            /></a></li>
            <li><a href="#"><span class="icon icon-beaker"> </span>Sales</a></li>
            <li><a href="/leads"><span class="icon icon-beaker"> </span>Leads</a></li>
            {/* <li><a href="#"><span class="icon icon-double-angle-right"></span>Doctor</a></li> */}

          </ul>

        </div>

      </div>

        <div className='glass-body' style={{margin:10}}>

        <div style={{ overflow: 'visible', display: 'flex', flexDirection: 'row', justifyContent: localStorage.getItem('role') === 'User' ? 'flex-end' : 'space-between', margin: '20px' }} className='flex-column-mobile'>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: 5
            }}
            className='flex-column-mobile'
          >
            <div style={{ width: '250px', display: Op_per.get().LEADS.USER_DROPDOWN ? '' : 'none' }}>
              <Form.Group
                className="mb-3 d-flex w-100"
                style={{ gap: "15px" }}
                controlId="formBasicPassword"
              >
                <Form.Label className="mt-2">Users</Form.Label>
                <div className="selectdoctor">
                  <Select

                    options={options}
                    value={selectedUsers}
                    onChange={handleSelectChange}
                    isClearable
                    styles={{

                      dropdownIndicator: (provided) => ({
                        ...provided,
                        color: '#ccc' // Change color of the arrow here
                      })
                    }}
                  />
                </div>
              </Form.Group>

            </div>

            <div style={{ width: "250px" }}>
              <Form.Group
                className="mb-3 d-flex w-100"
                style={{ gap: "15px" }}
                controlId="formBasicPassword"
              >
                <Form.Label className="mt-2">Date</Form.Label>
                <div>
                  <DatePicker
                    clearIcon={<IoIosClose onClick={() => clearDate()} />}
                    onChange={(date) => handleDateFilter(date)}
                    value={date}
                    format="dd-MM-yy"
                  />
                </div>
              </Form.Group>
            </div>
          </div>

          <div>
            <Button style={{ backgroundColor: "#2194f0" }} onClick={() => handleShowCreate()}>
              <FaPlus />
            </Button>
          </div>

        </div>

        <div className="glass-body" style={{ margin: 10 }}>
          <Accordion >
            <Accordion.Item eventKey="0">
              <Accordion.Header> <TbFilterStar /> &nbsp;
                Filter Leads</Accordion.Header>
              <Accordion.Body>
                <div>
                  <Form.Group
                    className="mb-3 d-flex w-100"
                    style={{ gap: "15px" }}
                    controlId="formBasicPassword"
                  >
                    <Form.Label className="mt-2">Status</Form.Label>
                    <div className="selectdoctor">
                      <Select
                        isMulti
                        options={leadstatusoptions}
                        value={selectedleadstatus}
                        onChange={handleSelectLeadStatusChange}
                        styles={{

                          dropdownIndicator: (provided) => ({
                            ...provided,
                            color: '#ccc' // Change color of the arrow here
                          })
                        }}
                      />
                    </div>
                  </Form.Group>

                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>



          {reload ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><div><LoadingComponent /></div></div> :
            <DataTable
              columns={columns}
              data={leads}
              // progressPending={pending}
              // selectableRows
              // onSelectedRowsChange={({ selectedRows }) =>
              // setSelectedRows(selectedRows)
              //   }
              expandableRows
              expandableRowsComponent={ExpandedComponent}
              pagination
              customStyles={{ headRow: { style: { backgroundColor: '#c0dfff', padding: 10, borderRadius: '10px 10px 0 0' }, } }}
              // CustomHeader={<CustomHeader/>}
              // actions={actionsMemo}
              style={{ marginTop: 10 }}
              paginationServer
              paginationTotalRows={totalRows}
              onChangeRowsPerPage={handlePerRowChange}
              onChangePage={handlePageChangeTable}
              // progressPending={pending}
              // progressComponent={<LoadingComponent/>}
              dense
            />}
          {show ? <LeadModal fullscreen={fullscreen} show={show} setShow={setShow} leadid={leadid} setReload={setReload} opttype={opttype} /> : ""}
        </div>
      </div>
    </Container>
  )
}

export default Leads