import { Button, Container, Form, OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import { IoHomeOutline } from "react-icons/io5";
import Select from "react-select";
import { useCallback, useEffect, useMemo, useState } from "react";
import Helper from "../../utils/helperFunctions";
import { GetAttendanceSheet } from "../../utils/proxy/AttendanceProxy";
import Swal from "sweetalert2";
import React from 'react';
import { AllUsers, GetAllManagerUsers } from "../../utils/proxy/Authproxy";
import { RiFileExcel2Fill } from "react-icons/ri";
import { FaFileDownload } from "react-icons/fa";
import PaySlipFormat from "../Common/PaySlipFormat";
import ReactDomServer from 'react-dom/server'
import jsPDF from "jspdf";
import { GetUserDeductionByUserid } from "../../utils/proxy/DeductionMasterProxy";
import { GetAllSettings } from "../../utils/proxy/SettingsProxy";

const AttendanceSheet = () => {

    const months = [
        { label: "January", value: "01" },
        { label: "February", value: "02" },
        { label: "March", value: "03" },
        { label: "April", value: "04" },
        { label: "May", value: "05" },
        { label: "June", value: "06" },
        { label: "July", value: "07" },
        { label: "August", value: "08" },
        { label: "September", value: "09" },
        { label: "October", value: "10" },
        { label: "November", value: "11" },
        { label: "December", value: "12" },
    ];

    const currentYear = new Date().getFullYear();
    const years = [];

    for (let i = currentYear; i >= currentYear - 100; i--) {
        years.push({ value: i.toString(), label: i.toString() });
    }

    const [selectedMonth, setSelectedMonth] = useState(months[new Date().getMonth()]);
    const [selectedYear, setSelectedYear] = useState(years[0]);
    const [data, setData] = useState([]);
    const [selectedRow, setSelectedRow] = useState();

    const [users, setusers] = useState([]);
    const [selectedOption, setSelectedOption] = useState(localStorage.getItem('role') === 'User' ? { value: localStorage.getItem('uuid'), label: localStorage.getItem('firstname') + " " + localStorage.getItem('lastname') + " (Self)" } : null);
    const [selectedUserID, setSelectedUserID] = useState(localStorage.getItem('role') === 'User' ? localStorage.getItem('uuid') : '');

    const [salary, setSalary] = useState(true);
    const [present, setPresent] = useState(true);
    const [absent, setAbsent] = useState(true);
    const [leaves, setLeaves] = useState(true);
    const [holiday, setHoliday] = useState(true);
    const [payable, setPayable] = useState(true);
    const [roi, setRoi] = useState(true);

    useEffect(() => {
        //fetchin all users
        const getAllUsersData = async () => {
            if (localStorage.getItem('role') === 'Admin') {
                let allusers = await AllUsers();
                allusers.push({ firstname: localStorage.getItem('firstname'), lastname: localStorage.getItem('lastname'), userid: localStorage.getItem('uuid'), username: localStorage.getItem('username') })
                setusers(allusers);
            } else if (localStorage.getItem('role') === 'Manager') {
                let allusers = await GetAllManagerUsers(localStorage.getItem('uuid'));
                setusers(allusers);
            }
        };
        getAllUsersData();
    }, [])

    useEffect(() => {
        const getAttendanceSheet = async () => {
            let dt = new Date(selectedYear.value + '-' + selectedMonth.value + '-01');
            dt.setMonth(dt.getMonth() + 1);

            let d = await GetAttendanceSheet(selectedYear.value + '-' + selectedMonth.value + '-01', dt.getFullYear() + '-' + (Helper.addLeadingZero(dt.getMonth() + 1)) + '-01', selectedUserID);
            if (d) {
                setData(d);

            } else {
                Swal.fire({
                    title: 'Error',
                    text: 'Something Went Wrong',
                    icon: 'error'
                })
            }
        }

        getAttendanceSheet();
    }, [selectedYear, selectedMonth, selectedUserID])

    const handleSelectChangeMOnth = (selected) => {
        setSelectedMonth(selected);
    };

    const handleSelectChangeYear = (selected) => {
        setSelectedYear(selected);
    };

    const handleChange = (selected) => {
        if (selected == null) {
            setSelectedOption(null)
            setSelectedUserID('');
        }
        else {
            setSelectedOption(selected);
            setSelectedUserID(selected.value);
        }

    };

    const countPresent = useCallback((attendance)=>{
        return Object.values(attendance).reduce((presentCount, val) => (val === 'P' ? presentCount + 1 : presentCount), 0);
    },[])

    const countAbsent = useCallback((attendance)=>{
        return Object.values(attendance).reduce((absentCount, val) => (val === 'A' ? absentCount + 1 : absentCount), 0);
    },[])

    const countLeave = useCallback((attendance)=>{
        return Object.values(attendance).reduce((leaveCount, val) => (val.split('-')[0] === 'L' ? leaveCount + 1 : leaveCount), 0);
    },[])

    const countHoliday = useCallback((attendance)=>{
        return Object.values(attendance).reduce((leaveCount, val) => ((val !== 'P' && val !== 'A' && val.split('-')[0] !== 'L' && val !== 'NA') ? leaveCount + 1 : leaveCount), 0);
    },[])

    const presentCounts = useMemo(() => {
        return data ? data.map(d => countPresent(d.attendance)) : [];
    }, [data]);
    
    const absentCounts = useMemo(() => {
        return data ? data.map(d => countAbsent(d.attendance)) : [];
    }, [data]);

    const leaveCounts = useMemo(() => {
        return data ? data.map(d => countLeave(d.attendance)) : [];
    }, [data]);

    const holidayCounts = useMemo(() => {
        return data ? data.map(d => countHoliday(d.attendance)) : [];
    }, [data]);

    const countPayableSalary = useMemo(() =>{
        let leaves;
        let holidays;
        let absents;
        let presents;
        const salary = data && data.map(d => {
            leaves = countLeave(d.attendance);
            holidays = countHoliday(d.attendance);
            absents = countAbsent(d.attendance);
            presents = countPresent(d.attendance);
            
            const temp = d?.salary - ((d?.salary / (leaves+holidays+absents+presents))*absents);
            return temp ? temp.toFixed(1) : 0;
        });

        return salary;
    },[data])

    const generatePaySlipPDF = async(username,paiddays,absentdays,leaves,holidays,usersalary,userid) =>{
        let company ;
        let userded = [];
        let totald = 0;
        let d = await GetAllSettings();
        if (d) {
            d.map(data=>{
                if (data.setting_name === 'COMAPNY_NAME') {
                    company = data.setting_value
                }
            })
        }

        let res = await GetUserDeductionByUserid(userid);
        if (res) {
            userded = res;

            let temp = res.reduce((sum,item)=>{
                return sum + item.amount;
            },0);
            totald = temp;
        } else {
            alert('Issue While Fetching Deductions')
        }

        const pdf = new jsPDF({
            orientation: 'portrait', // or 'landscape'
            unit: 'px', // units: 'mm', 'in', 'px'
            format: 'a4' // or a custom size [width, height]
          });
          
          pdf.html(ReactDomServer.renderToStaticMarkup(<PaySlipFormat month={months[selectedMonth.value-1].label} year={selectedYear.value} username={username} paid={paiddays} absent={absentdays} leaves={leaves} holidays={holidays} salary={usersalary} company={company} deductions={userded || []} totaldeduction={totald || 0} />), {
            callback: function (pdf) {
              pdf.save(`PaySlip_${username}_${months[selectedMonth.value-1].label}_${selectedYear.value}.pdf`);

              // Code to Create a URL for the blob and open it in a new tab
                // const pdfBlob = pdf.output("blob");
                // const pdfUrl = URL.createObjectURL(pdfBlob);
                // window.open(pdfUrl, "_blank");
                // setTimeout(() => URL.revokeObjectURL(pdfUrl), 1000);
            },
            margin: [10, 10, 10, 10],
            autoPaging: 'text',
            // x: 0,
            // y: 0,
            width: 430, //target width in the PDF document
            windowWidth: 1024
          });
    }

    const options = []

    users.map((user) => {
        options.push({
            value: user.userid,
            label: user.username + "(" + user.firstname + " " + user.lastname + ")",
        });
    });


    async function downloadCSV2() {
        // Collect all unique dates from the attendance data
        const datesSet = new Set();
        data.forEach(user => {
            Object.keys(user.attendance).forEach(date => datesSet.add(date));
        });
        const dates = Array.from(datesSet).sort(); // Convert to array and sort dates

        // Create CSV content
        const fields = ["username", ...dates, "Salary","Present","Absent","Leaves","Holiday","Payable","ROI"];
        let csv = fields.join(",") + "\n";

        // Iterate over each user to build CSV rows
        data.forEach((user,index) => {
            const { username, attendance, starttime, endtime, salary, roi } = user;
            const row = [username, ...dates.map(date => attendance[date] == 'P' ? attendance[date]+'-'+Helper.ConvertUtcToLocalTime(starttime[date]).split(" ")[1]+' '+Helper.ConvertUtcToLocalTime(starttime[date]).split(" ")[2]+'-'+(endtime[date] ? Helper.ConvertUtcToLocalTime(endtime[date]).split(" ")[1]+' '+Helper.ConvertUtcToLocalTime(endtime[date]).split(" ")[2] : 'None') : attendance[date].split('-')[0] == 'L' ? attendance[date].split('-')[0] : attendance[date]),salary,countPresent(attendance),countAbsent(attendance),countLeave(attendance),countHoliday(attendance),countPayableSalary[index],roi];
            csv += row.join(",") + "\n";
        });

        // Create a download link and trigger the download
        const link = document.createElement("a");
        const filename = `${selectedMonth.label}_${selectedYear.value}_attendance.csv`;

        // Create CSV data URI
        const csvData = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
        link.setAttribute("href", csvData);
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link); // Clean up
    }

    return (
        <Container fluid style={{ minHeight: "80vh" }}>
            <div
                className="glass-body"
                style={{
                    margin: 10,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    padding: 0,
                    backgroundColor: 'transparent'
                }}
            >

                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <ul id="breadcrumb">
                        <li><a href="/"><IoHomeOutline
                            style={{ color: "white", fontSize: 20 }}

                        /></a></li>
                        <li><a href="/attendance-sheet"><span class="icon icon-beaker"> </span>Attendance Sheet</a></li>

                    </ul>

                </div>

            </div>

            <div className="glass-body" style={{ margin: 10 }}>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "left",
                    }}

                    className="flex-column-mobile"
                >
                    <div style={{ width: "250px", zIndex: 3 }}>
                        <Form.Group
                            className="mb-3 d-flex w-100"
                            style={{ gap: "15px" }}
                            controlId="formBasicPassword"
                        >
                            <Form.Label className="mt-2">Users</Form.Label>

                            <div className="w-50">
                                <Select
                                    className="selectdoctor"
                                    options={options}
                                    value={selectedOption}
                                    onChange={handleChange}
                                    isClearable={localStorage.getItem('role') !== 'User'}

                                    styles={{

                                        dropdownIndicator: (provided) => ({
                                            ...provided,
                                            color: '#ccc' // Change color of the arrow here
                                        })
                                    }}
                                />
                            </div>
                        </Form.Group>
                    </div>
                    <div style={{ width: "250px", zIndex: 3 }}>
                        <Form.Group
                            className="mb-3 d-flex w-100"
                            style={{ gap: "15px" }}
                            controlId="formBasicPassword"
                        >
                            <Form.Label className="mt-2">Select Month</Form.Label>
                            <div className="w-50">
                                <Select
                                    className="selectdoctor"
                                    options={months}
                                    value={selectedMonth}
                                    onChange={handleSelectChangeMOnth}
                                    styles={{

                                        dropdownIndicator: (provided) => ({
                                            ...provided,
                                            color: '#ccc' // Change color of the arrow here
                                        })
                                    }}
                                />
                            </div>
                        </Form.Group>
                    </div>
                    <div style={{ width: "450px", zIndex: 3 }}>
                        <Form.Group
                            className="mb-3 d-flex w-100"
                            style={{ gap: "15px" }}
                            controlId="formBasicPassword"
                        >
                            <Form.Label className="mt-2">Select Year</Form.Label>
                            <div className="w-50">
                                <Select
                                    className="selectdoctor"
                                    options={years}
                                    value={selectedYear}
                                    onChange={handleSelectChangeYear}
                                    styles={{

                                        dropdownIndicator: (provided) => ({
                                            ...provided,
                                            color: '#ccc' // Change color of the arrow here
                                        })
                                    }}
                                />
                            </div>
                        </Form.Group>
                    </div>
                    <div>
                        <Button style={{ backgroundColor: "#2194f0" }} onClick={() => downloadCSV2()} >
                            <RiFileExcel2Fill />
                        </Button>
                    </div>
                </div>
                <div>
                    <Form.Check className="d-inline mr-2" type="checkbox" checked={salary} label="Salary" onChange={e => setSalary(e.target.checked)} />
                    <Form.Check className="d-inline mr-2" type="checkbox" checked={present} label="Present" onChange={e => setPresent(e.target.checked)} />
                    <Form.Check className="d-inline mr-2" type="checkbox" checked={absent} label="Absent" onChange={e => setAbsent(e.target.checked)} />
                    <Form.Check className="d-inline mr-2" type="checkbox" checked={leaves} label="Leaves" onChange={e => setLeaves(e.target.checked)} />
                    <Form.Check className="d-inline mr-2" type="checkbox" checked={holiday} label="Holiday" onChange={e => setHoliday(e.target.checked)} />
                    <Form.Check className="d-inline mr-2" type="checkbox" checked={payable} label="Payable" onChange={e => setPayable(e.target.checked)} />
                    <Form.Check className="d-inline mr-2" type="checkbox" checked={roi} label="Roi" onChange={e => setRoi(e.target.checked)} />
                </div>
                <div className="responsive-table-container">
                    <table bordered className="responsive-table">
                        <thead>
                            <tr>
                                <th style={{ backgroundColor: '#1ABC9C',color:'white', zIndex: 2 }}>Username</th>
                                {data && data.length > 0 && (
                                    <React.Fragment>
                                        {Object.keys(data[0].attendance).sort().map((key, index) => (
                                            <th style={{ backgroundColor: '#1ABC9C',color:'white' }} key={index}>{key.split('-')[2]}</th>
                                        ))}
                                        <th style={{ backgroundColor: '#1ABC9C',color:'white', display: salary ? '' : 'none' }}>Salary</th>
                                        <th style={{ backgroundColor: '#1ABC9C',color:'white', display: present ? '' : 'none' }}>Present</th>
                                        <th style={{ backgroundColor: '#1ABC9C',color:'white', display: absent ? '' : 'none' }}>Absent</th>
                                        <th style={{ backgroundColor: '#1ABC9C',color:'white', display: leaves ? '' : 'none' }}>Leaves</th>
                                        <th style={{ backgroundColor: '#1ABC9C',color:'white', display: holiday ? '' : 'none' }}>Holiday</th>
                                        <th style={{ backgroundColor: '#1ABC9C',color:'white', display: payable ? '' : 'none' }}>Payable</th>
                                        <th style={{ backgroundColor: '#1ABC9C',color:'white', display: roi ? '' : 'none' }}>Roi &#x28;task&#x29;</th>
                                        <th style={{ backgroundColor: '#1ABC9C',color:'white' }}>Action</th>
                                    </React.Fragment>
                                )}
                            </tr>
                        </thead>
                        <tbody>
                            {data && data.map((d, i) => (
                                <tr key={i} onClick={() => {if(selectedRow === i){setSelectedRow()}else{setSelectedRow(i)}}} style={{ border: selectedRow === i ? '2px solid' : '' }}>
                                    <td>{d.username}</td>
                                    {Object.keys(d.attendance)
                                        .sort()
                                        .map(key => (
                                            <OverlayTrigger key={key} overlay={<Tooltip id="tooltip-disabled">{d.attendance[key] == 'A' ? 'Absent' : d.attendance[key] == 'P' ? <div>Present <br/> <div>Start Time - {d.starttime[key] ? Helper.ConvertUtcToLocalTime(d.starttime[key]).split(" ")[1]+' '+Helper.ConvertUtcToLocalTime(d.starttime[key]).split(" ")[2] : 'None'}</div><div>End Time - {d.endtime[key] ? Helper.ConvertUtcToLocalTime(d.endtime[key]).split(" ")[1]+' '+Helper.ConvertUtcToLocalTime(d.endtime[key]).split(" ")[2] : 'None'}</div></div> : d.attendance[key].split('-')[0] == 'L' ? d.attendance[key].split('-')[1] : d.attendance[key]}</Tooltip>}>
                                            <td style={{ background: d.attendance[key] == 'A' ? '#FF4C4C' : d.attendance[key] == 'P' ? '#28A745' : d.attendance[key].split('-')[0] == 'L' ? '#FFC107' : '#5BC0DE' }} key={key}>{(d.attendance[key] == 'A' || d.attendance[key] == 'P' || d.attendance[key] == 'L' || d.attendance[key] == 'NA') ? d.attendance[key] : d.attendance[key].split('-')[0] == 'L' ? 'L' : 'H'}</td>
                                            </OverlayTrigger>
                                        ))}
                                    <td style={{ display: salary ? '' : 'none' }}>{d?.salary || 0}</td>
                                    <td style={{ display: present ? '' : 'none' }}>{presentCounts[i]}</td>
                                    <td style={{ display: absent ? '' : 'none' }}>{absentCounts[i]}</td>
                                    <td style={{ display: leaves ? '' : 'none' }}>{leaveCounts[i]}</td>
                                    <td style={{ display: holiday ? '' : 'none' }}>{holidayCounts[i]}</td>
                                    <td style={{ display: payable ? '' : 'none' }}>{countPayableSalary[i]}</td>
                                    <td style={{ display: roi ? '' : 'none' }}>{Math.round(d?.roi) || 0}</td>
                                    <td><OverlayTrigger overlay={<Tooltip id="tooltip-disabled">PaySlip</Tooltip>}><div><FaFileDownload onClick={()=>generatePaySlipPDF(d.firstname+' '+d.lastname,presentCounts[i],absentCounts[i],leaveCounts[i],holidayCounts[i],d?.salary || 0,d.userid)} title="PaySlip" /></div></OverlayTrigger></td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </Container>
    )
}

export default AttendanceSheet;