import { Button, Container, Form } from "react-bootstrap"
import { IoHomeOutline } from "react-icons/io5"
import { Op_per } from "../../utils/Permissions"
import Select from "react-select"
import React,{ useState, useEffect } from "react"
import DataTable from "react-data-table-component"
import LoadingComponent from "../Includes/LoadingComponent"
import { GetDCRCountStateWise } from "../../utils/proxy/ChartsProxy"
import { RiFileExcel2Fill } from "react-icons/ri"
import { Parser } from "@json2csv/plainjs"

export const DcrStateWise = () => {

    const months = [
        { label: "January", value: "01" },
        { label: "February", value: "02" },
        { label: "March", value: "03" },
        { label: "April", value: "04" },
        { label: "May", value: "05" },
        { label: "June", value: "06" },
        { label: "July", value: "07" },
        { label: "August", value: "08" },
        { label: "September", value: "09" },
        { label: "October", value: "10" },
        { label: "November", value: "11" },
        { label: "December", value: "12" },
    ];

    const currentYear = new Date().getFullYear();
    const years = [];

    for (let i = currentYear; i >= currentYear - 100; i--) {
        years.push({ value: i.toString(), label: i.toString() });
    }

    const [data, setData] = useState([]);
    const [selectedMonth, setSelectedMonth] = useState(months[new Date().getMonth()]); // Set the default month to '01'
    const [selectedYear, setSelectedYear] = useState(years[0]);
    const [reload, setReload] = useState(false);
    const [pending, setPending] = useState(false);

    useEffect(() => {
        // Fetch data from an API when the component mounts
        const getallteritories = async (selectedMonth, selectedYear) => {
            let userteritories = await GetDCRCountStateWise(
                selectedMonth,
                selectedYear
            );

            if (userteritories) {
                setData(userteritories);
                setPending(false);
            } else {
                alert("Error", "Issue While Fetching Tour Plan");
            }
        };
        if (selectedMonth.value && selectedYear.value) {
            setPending(true);
            getallteritories(selectedMonth.value, selectedYear.value);
        }
        setReload(false);
    }, [reload, selectedMonth, selectedYear]);

    const handleSelectChangeMOnth = (selected) => {
        setSelectedMonth(selected);
    };

    const handleSelectChangeYear = (selected) => {

        setSelectedYear(selected);
    };

    const columns = [
        {
            name: "State Name",
            selector: (row) => <div style={{ whiteSpace: 'normal', backgroundColor: row.statename === "" || row.statename === null ? "#f36e6f" : "", borderRadius: "10px", border: row.statename === "" || row.statename === null ? "2px solid transparent" : "2px solid transparent", padding: "3px", display: "flex", flexDirection: "row", color: row.statename === "" || row.statename === null ? "#fff" : "#000", margin: "3px", }}>{row.statename ? row.statename : "N/A"}</div>,
           
            
        },
        {
            name: <p>Doctors DCR Count</p>,
            selector: (row) => <span className="cursor">{row.doctor_count}</span>,
            // <div style={{ whiteSpace: 'normal',backgroundColor: row.unplanned_doctor=== "" || row.unplanned_doctor=== null ? "#f36e6f":"", borderRadius:"10px", border: row.unplanned_doctor=== "" || row.unplanned_doctor=== null ? "2px solid transparent": "2px solid transparent",padding:"3px", display:"flex", flexDirection:"row",color:row.unplanned_doctor=== "" || row.unplanned_doctor=== null ? "#fff": "#000", margin:"3px", }}>{row.unplanned_doctor? row.unplanned_doctor: "N/A"}</div> ,
   
        },
        {
            name: <p>Clinics DCR Count</p>,
            selector: (row) => <span className="cursor">{row.clinic_count}</span>,

            // <div style={{ whiteSpace: 'normal',backgroundColor: row.planned_clinic=== "" || row.planned_clinic=== null ? "#f36e6f":"", borderRadius:"10px", border: row.planned_clinic=== "" || row.planned_clinic=== null ? "2px solid transparent": "2px solid transparent",padding:"3px", display:"flex", flexDirection:"row",color:row.planned_clinic=== "" || row.planned_clinic=== null ? "#fff": "#000", margin:"3px", }}>{row.planned_clinic? row.planned_clinic: "N/A"}</div>,
         
        },
        {
            name: <p>Chemist DCR Count</p>,
            selector: (row) => <span className="cursor">{row.chemist_count}</span>,
            // <div style={{ whiteSpace: 'normal',backgroundColor: row.planned_chemist=== "" || row.planned_chemist=== null ? "#f36e6f":"", borderRadius:"10px", border: row.planned_chemist=== "" || row.planned_chemist=== null ? "2px solid transparent": "2px solid transparent",padding:"3px", display:"flex", flexDirection:"row",color:row.planned_chemist=== "" || row.planned_chemist=== null ? "#fff": "#000", margin:"3px", }}>{row.planned_chemist? row.planned_chemist: "N/A"}</div> ,
           
        },
        {
            name: <p>Stockist DCR Count</p>,
            selector: (row) => <span className="cursor">{row.stockist_count}</span>,
            //  <div style={{ whiteSpace: 'normal',backgroundColor: row.planned_stockist=== "" || row.planned_stockist=== null ? "#f36e6f":"", borderRadius:"10px", border: row.planned_stockist=== "" || row.planned_stockist=== null ? "2px solid transparent": "2px solid transparent",padding:"3px", display:"flex", flexDirection:"row",color:row.planned_stockist=== "" || row.planned_stockist=== null ? "#fff": "#000", margin:"3px", }}>{row.planned_stockist? row.planned_stockist: "N/A"}</div>  ,
    
        },
        {
            name: <p>Hospital DCR Count</p>,
            selector: (row) => <span className="cursor">{row.hospital_count}</span>,
            //  <div style={{ whiteSpace: 'normal',backgroundColor: row.planned_hospital=== "" || row.planned_hospital=== null ? "#f36e6f":"", borderRadius:"10px", border: row.planned_hospital=== "" || row.planned_hospital=== null ? "2px solid transparent": "2px solid transparent",padding:"3px", display:"flex", flexDirection:"row",color:row.planned_hospital=== "" || row.planned_hospital=== null ? "#fff": "#000", margin:"3px", }}>{row.planned_hospital? row.planned_hospital: "N/A"}</div> ,
       
        },
        {
            name: <p>Total DCR Count</p>,
            selector: (row) => <span className="cursor">{row.total}</span>,
            //  <div style={{ whiteSpace: 'normal',backgroundColor: row.planned_hospital=== "" || row.planned_hospital=== null ? "#f36e6f":"", borderRadius:"10px", border: row.planned_hospital=== "" || row.planned_hospital=== null ? "2px solid transparent": "2px solid transparent",padding:"3px", display:"flex", flexDirection:"row",color:row.planned_hospital=== "" || row.planned_hospital=== null ? "#fff": "#000", margin:"3px", }}>{row.planned_hospital? row.planned_hospital: "N/A"}</div> ,
          
        },
        {
            name: <p>FieldForce Count</p>,
            selector: (row) => <span className="cursor">{row.user_count}</span>,
            //  <div style={{ whiteSpace: 'normal',backgroundColor: row.planned_hospital=== "" || row.planned_hospital=== null ? "#f36e6f":"", borderRadius:"10px", border: row.planned_hospital=== "" || row.planned_hospital=== null ? "2px solid transparent": "2px solid transparent",padding:"3px", display:"flex", flexDirection:"row",color:row.planned_hospital=== "" || row.planned_hospital=== null ? "#fff": "#000", margin:"3px", }}>{row.planned_hospital? row.planned_hospital: "N/A"}</div> ,
          
        },
    ];

    async function downloadCSV(plans) {
        let csv;
        try {
            const opts = {
                fields: [
                    { label: "State", value: "statename" },
                    { label: "Doctors DCR Count", value: "doctor_count" },
                    { label: "Clinics DCR Count", value: "clinic_count" },
                    { label: "Chemists DCR Count", value: "chemist_count" },
                    { label: "Stockists DCR Count", value: "stockist_count" },
                    { label: "Hospitals DCR Count", value: "hospital_count" },
                    { label: "Total DCR", value: "total" },
                    { label: "FieldForce Count", value: "user_count" },
                ],
            };
            const parser = new Parser(opts);
            csv = parser.parse(plans);
        } catch (err) {
            console.error(err);
        }

        const link = document.createElement("a");

        if (csv == null) return;

        const month = months.find(month => month.value === selectedMonth.value);

        const filename = `DcrCountStateWise-${month.label}/${selectedYear.value}.csv`;

        csv = `DCR Count State Wise Report for ${month.label} ${selectedYear.value} \n\n\n${csv}`;

        if (!csv.match(/^data:text\/csv/i)) {
            csv = `data:text/csv;charset=utf-8,${csv}`;
        }

        link.setAttribute("href", encodeURI(csv));
        link.setAttribute("download", filename);
        link.click();
    }

    const Export = ({ onExport }) => (
        <Button style={{ position: 'absolute', right: 40, backgroundColor: "#2194f0",display:Op_per.get().REPORTS_TP_VS_ACHIEVEMENT.ALLOW_EXPORT_XLSX ? '' : 'none' }} onClick={(e) => onExport(e.target.value)} disabled={!(selectedMonth.value && selectedYear.value)}><RiFileExcel2Fill /></Button>
    );

    const actionsMemo = React.useMemo(
        () => <Export onExport={() => downloadCSV(data)} />,
        [data]
    );

    return (
        <Container fluid style={{ minHeight: "80vh" }}>
            <div
                className="glass-body"
                style={{
                    margin: 10,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    padding: 0,
                    backgroundColor: 'transparent'
                }}
            >

                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <ul id="breadcrumb">
                        <li><a href="/"><IoHomeOutline
                            style={{ color: "white", fontSize: 20 }}
                        /></a></li>
                        <li><a href="/dcr-statewise"><span class="icon icon-beaker"> </span>Reports</a></li>

                        <li><a href="#"><span class="icon icon-double-angle-right"></span>DCR Count State Wise</a></li>

                    </ul>

                </div>

            </div>
            <div className="glass-body" style={{ margin: 10 }}>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "left",
                    }}
                    className="flex-column-mobile"
                >
                    <div style={{ width: "250px" }}>
                        <Form.Group
                            className="mb-3 d-flex w-100"
                            style={{ gap: "15px" }}
                            controlId="formBasicPassword"
                        >
                            <Form.Label className="mt-2">Select Month</Form.Label>
                            <div className="w-50">
                                <Select
                                    className="selectdoctor"
                                    options={months}
                                    value={selectedMonth}
                                    onChange={handleSelectChangeMOnth}
                                    styles={{

                                        dropdownIndicator: (provided) => ({
                                            ...provided,
                                            color: '#ccc' // Change color of the arrow here
                                        })
                                    }}
                                />
                            </div>
                        </Form.Group>
                    </div>
                    <div style={{ width: "250px" }}>
                        <Form.Group
                            className="mb-3 d-flex w-100"
                            style={{ gap: "15px" }}
                            controlId="formBasicPassword"
                        >
                            <Form.Label className="mt-2">Select Year</Form.Label>
                            <div className="w-50">
                                <Select
                                    className="selectdoctor"
                                    options={years}
                                    value={selectedYear}
                                    onChange={handleSelectChangeYear}
                                    styles={{

                                        dropdownIndicator: (provided) => ({
                                            ...provided,
                                            color: '#ccc' // Change color of the arrow here
                                        })
                                    }}
                                />
                            </div>
                        </Form.Group>
                    </div>
                </div>
                <div className="pt-3">
                    <DataTable
                        columns={columns}
                        data={data}
                        actions={actionsMemo}
                        pagination
                        customStyles={{ headRow: { style: { backgroundColor: '#c0dfff', padding: 7 }, } }}
                        style={{ margin: '10px', padding: 10 }}
                        progressPending={pending}
                        progressComponent={<LoadingComponent />}
                        dense
                    />
                </div>
            </div>
        </Container>
    )
}