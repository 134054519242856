import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { GetTopPerformer, GetTopPerformerUnderManager } from '../../utils/proxy/DashboardProxy';
import { Row } from 'react-bootstrap';
import { MdOutlineAutoGraph } from "react-icons/md";

const PerfomanceChart = () => {

    const [users, setUsers] = useState([]);
    const [doctor, setDoctor] = useState([]);
    const [hospital, setHospital] = useState([]);
    const [clinic, setClinic] = useState([]);
    const [stockist, setStockist] = useState([]);
    const [chemist, setChemist] = useState([]);

    useEffect(() => {
        const getTopPerformer = async () => {
            let d;
            if (localStorage.getItem('role') == 'Admin') {

                d = await GetTopPerformer();
            } else if (localStorage.getItem('role') == 'Manager') {
                d = await GetTopPerformerUnderManager(localStorage.getItem('uuid'));
            }
            let username = [];
            let doctor = [];
            let hospital = [];
            let clinic = [];
            let stockist = [];
            let chemist = [];
            d && d.map((data) => {
                username.push(data.username);
                doctor.push(data.doctor)
                hospital.push(data.hospital)
                clinic.push(data.clinic)
                stockist.push(data.stockist)
                chemist.push(data.chemist)
            })
            setUsers(username);
            setDoctor(doctor)
            setHospital(hospital)
            setClinic(clinic)
            setStockist(stockist)
            setChemist(chemist)
        }
        getTopPerformer();
    }, [])

    const options = {
        chart: {
            id: 'bar-chart',
            stacked: true,
        },
        colors: ['#7BD3EA', '#4CB9E7', '#3887BE', '#38419D', '#200E3A'],
        xaxis: {
            categories: users,
            labels: {
                style: {
                    fontSize: '10px', // Change the font size here
                },
            },
        },
        plotOptions: {
            bar: {
                horizontal: false,
            },
            dataLabels: {
                position: 'top', // Set the position of the data labels to display above the bars
                style: {
                    fontSize: '12px', // Change the font size of the data labels
                    colors: ['#ff0000'], // Change the color of the labels if needed (for data points)
                },
            },
        },
        stroke: {
            show: true,
            width: 1,
            colors: ['#fff'],
        },
        // title: {
        //     text: 'Top #3 Performing Field Agents',
        //     style: {
        //         fontSize: '13px',
        //     }
        // },
    };

    const series = [
        {
            name: 'Doctor',
            data: doctor,
        }, {
            name: 'Hospital',
            data: hospital,
        }, {
            name: 'Clinic',
            data: clinic,
        }, {
            name: 'Chemist',
            data: chemist,
        }, {
            name: 'Stockist',
            data: stockist,
        },
    ];

    return (

       <>
        <Row>
        <div >
            <div md={4}>
                <div >
                    <MdOutlineAutoGraph color='#000' size={30} />
                </div>
            </div>
            <div md={4}>
            <h6 style={{textAlign:'center', marginBottom:0}}>Top #3 Performing Field Agents</h6>
            </div>
            <div md={4}>
          
            </div>
            
        </div>
    </Row> 
    <div  style={{ backgroundColor: 'white',height: 300, padding: 10 }}>
                    <Chart options={options} series={series} type="bar" height={300} />
                </div>
                </>
              
    );
};

export default PerfomanceChart;
