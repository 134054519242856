import React, { useEffect, useState } from "react";
import { Button, Container, Form } from "react-bootstrap"
import { IoHomeOutline } from "react-icons/io5"
import { AllUsers, GetAllManagerUsers } from "../../utils/proxy/Authproxy";
import { Op_per } from "../../utils/Permissions";
import Select from "react-select";
import DataTable from "react-data-table-component";
import LoadingComponent from "../Includes/LoadingComponent";
import { GetUserList } from "../../utils/proxy/ChartsProxy";
import { RiFileExcel2Fill } from "react-icons/ri";
import { Parser } from "@json2csv/plainjs";

export const UsersList = () =>{

    const [data, setData] = useState([]);
    const [userid, setUserId] = useState(localStorage.getItem('role') === 'User' ? localStorage.getItem('uuid') : null);
    const [selectedUser, setSelectedUser] = useState();
    const [users, setUsers] = useState([]);
    const [reload, setReload] = useState(false);
    const [pending, setPending] = useState(false);

    useEffect(() => {
        // Fetch data from an API when the component mounts
        const getallusers = async () => {
            if (localStorage.getItem('role') === 'Admin') {
                let allusers = await AllUsers();
                allusers.push({ firstname: localStorage.getItem('firstname'), lastname: localStorage.getItem('lastname'), userid: localStorage.getItem('uuid'), username: localStorage.getItem('username') })
                setUsers(allusers);
            } else if (localStorage.getItem('role') === 'Manager') {
                let allusers = await GetAllManagerUsers(localStorage.getItem('uuid'));
                setUsers(allusers);
            }
        };
        getallusers();
    }, []);

    useEffect(() => {
        // Fetch data from an API when the component mounts
        const getallteritories = async (userid) => {
            let userteritories = await GetUserList(
                userid
            );

            if (userteritories) {
                setData(userteritories);
                setPending(false);
            } else {
                alert("Error", "Issue While Fetching ");
            }
        };
        if (userid) {
            setPending(true);
            getallteritories(userid);
        }
        setReload(false);
    }, [reload, userid]);

    const handleSelectChange = (selected) => {

        setUserId(selected.value);
    };

    const options = [];

    users.map((user) => {
        options.push({
            value: user.userid,
            label: user.username + "(" + user.firstname + " " + user.lastname + ")",
        });
    });

    const columns = [
        {
            name: "Headquator",
            selector: (row) => <div style={{ whiteSpace: 'normal', backgroundColor: row.headquator === "" || row.headquator === null ? "#f36e6f" : "", borderRadius: "10px", border: row.headquator === "" || row.headquator === null ? "2px solid transparent" : "2px solid transparent", padding: "3px", display: "flex", flexDirection: "row", color: row.headquator === "" || row.headquator === null ? "#fff" : "#000", margin: "3px", }}>{row.headquator ? row.headquator : "N/A"}</div>,
            width: "15%",
            
        },
        {
            name: "State Name",
            selector: (row) => <div style={{ whiteSpace: 'normal', backgroundColor: row.statename === "" || row.statename === null ? "#f36e6f" : "", borderRadius: "10px", border: row.statename === "" || row.statename === null ? "2px solid transparent" : "2px solid transparent", padding: "3px", display: "flex", flexDirection: "row", color: row.statename === "" || row.statename === null ? "#fff" : "#000", margin: "3px", }}>{row.statename ? row.statename : "N/A"}</div>,
            width: "15%",
            
        },
        {
            name: "FieldForce Name",
            selector: (row) => <div style={{ whiteSpace: 'normal', backgroundColor: row.fieldforcename === "" || row.fieldforcename === null ? "#f36e6f" : "", borderRadius: "10px", border: row.fieldforcename === "" || row.fieldforcename === null ? "2px solid transparent" : "2px solid transparent", padding: "3px", display: "flex", flexDirection: "row", color: row.fieldforcename === "" || row.fieldforcename === null ? "#fff" : "#000", margin: "3px", }}>{row.fieldforcename ? row.fieldforcename : "N/A"}</div>,
            width: "15%",
            
        },
        {
            name: "Designation",
            selector: (row) => <div style={{ whiteSpace: 'normal', backgroundColor: row.designation === "" || row.designation === null ? "#f36e6f" : "", borderRadius: "10px", border: row.designation === "" || row.designation === null ? "2px solid transparent" : "2px solid transparent", padding: "3px", display: "flex", flexDirection: "row", color: row.designation === "" || row.designation === null ? "#fff" : "#000", margin: "3px", }}>{row.designation ? row.designation : "N/A"}</div>,
            width: "15%",
            
        },
        {
            name: "Reporting To",
            selector: (row) => <div style={{ whiteSpace: 'normal', backgroundColor: row.reportingto === "" || row.reportingto === null ? "#f36e6f" : "", borderRadius: "10px", border: row.reportingto === "" || row.reportingto === null ? "2px solid transparent" : "2px solid transparent", padding: "3px", display: "flex", flexDirection: "row", color: row.reportingto === "" || row.reportingto === null ? "#fff" : "#000", margin: "3px", }}>{row.reportingto ? row.reportingto : "N/A"}</div>,
            width: "15%",
            
        },
        {
            name: "UserName",
            selector: (row) => <div style={{ whiteSpace: 'normal', backgroundColor: row.username === "" || row.username === null ? "#f36e6f" : "", borderRadius: "10px", border: row.username === "" || row.username === null ? "2px solid transparent" : "2px solid transparent", padding: "3px", display: "flex", flexDirection: "row", color: row.username === "" || row.username === null ? "#fff" : "#000", margin: "3px", }}>{row.username ? row.username : "N/A"}</div>,
            width: "15%",
            
        },
        {
            name: "Status",
            selector: (row) => <div style={{ whiteSpace: 'normal', backgroundColor: row.status === "" || row.status === null ? "#f36e6f" : "", borderRadius: "10px", border: row.status === "" || row.status === null ? "2px solid transparent" : "2px solid transparent", padding: "3px", display: "flex", flexDirection: "row", color: row.status === "" || row.status === null ? "#fff" : "#000", margin: "3px", }}>{row.status ? row.status === 'Active' ? 'Approved' : row.status : "N/A"}</div>,
            width: "15%",
            
        },
    ]

    async function downloadCSV(plans) {
        let csv;
        try {
            const opts = {
                fields: [
                    { label: "Headquator", value: "headquator" },
                    { label: "State Name", value: "statename" },
                    { label: "FieldForce Name", value: "fieldforcename" },
                    { label: "Designation", value: "designation" },
                    { label: "Reporting To", value: "reportingto" },
                    { label: "User Name", value: "username" },
                    { label: "Status", value: "status" },
                ],
            };
            const parser = new Parser(opts);
            csv = parser.parse(plans);
        } catch (err) {
            console.error(err);
        }

        const link = document.createElement("a");

        if (csv == null) return;

        const filename = "UserList.csv";

        if (!csv.match(/^data:text\/csv/i)) {
            csv = `data:text/csv;charset=utf-8,${csv}`;
        }

        link.setAttribute("href", encodeURI(csv));
        link.setAttribute("download", filename);
        link.click();
    }

    const Export = ({ onExport }) => (
        <Button style={{ position: 'absolute', right: 40, backgroundColor: "#2194f0",display:Op_per.get().REPORTS_TP_VS_ACHIEVEMENT.ALLOW_EXPORT_XLSX ? '' : 'none' }} onClick={(e) => onExport(e.target.value)} disabled={!(userid)}><RiFileExcel2Fill /></Button>
    );

    const actionsMemo = React.useMemo(
        () => <Export onExport={() => downloadCSV(data)} />,
        [data]
    );

    return(
        <Container fluid style={{ minHeight: "80vh" }}>
            <div
                className="glass-body"
                style={{
                    margin: 10,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    padding: 0,
                    backgroundColor: 'transparent'
                }}
            >

                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <ul id="breadcrumb">
                        <li><a href="/"><IoHomeOutline
                            style={{ color: "white", fontSize: 20 }}
                        /></a></li>
                        <li><a href="/dcr-userlist"><span class="icon icon-beaker"> </span>Reports</a></li>

                        <li><a href="#"><span class="icon icon-double-angle-right"></span>User List</a></li>

                    </ul>

                </div>

            </div>
            <div className="glass-body" style={{ margin: 10 }}>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "left",
                    }}
                >
                    <div style={{ width: "250px", display: Op_per.get().REPORTS_TP_VS_ACHIEVEMENT.USER_DROPDOWN ? '' : 'none' }}>
                        <Form.Group
                            className="mb-3 d-flex w-100"
                            style={{ gap: "15px" }}
                            controlId="formBasicPassword"
                        >
                            <Form.Label className="mt-2">Users</Form.Label>
                            <div className="w-75">
                                <Select
                                    className="selectdoctor"
                                    options={options}
                                    value={selectedUser}
                                    onChange={handleSelectChange}
                                    styles={{

                                        dropdownIndicator: (provided) => ({
                                            ...provided,
                                            color: '#ccc' // Change color of the arrow here
                                        })
                                    }}
                                />
                            </div>
                        </Form.Group>
                    </div>
                </div>
                <div className="pt-3">
                    <DataTable
                        columns={columns}
                        data={data}
                        actions={actionsMemo}
                        pagination
                        customStyles={{ headRow: { style: { backgroundColor: '#c0dfff', padding: 7 }, } }}
                        style={{ margin: '10px', padding: 10 }}
                        progressPending={pending}
                        progressComponent={<LoadingComponent />}
                        dense
                    />
                </div>
            </div>
        </Container>
    )
}