import React, { useEffect,useState } from 'react'
import { Button, Container } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import { GetAllUsersDesignation, CheckUserDesignationAvailable, CreateUserDesignation, DeleteUserDesignation, UpdateUserDesignation } from '../../utils/proxy/UserDesignationProxy'
import ReactPaginate from 'react-paginate';
import Modal from 'react-bootstrap/Modal';
import Swal from 'sweetalert2';
import DataTable from "react-data-table-component";
import { AiFillDelete, AiTwotoneEdit } from "react-icons/ai";
import { Parser } from "@json2csv/plainjs";
import { HiDotsVertical } from 'react-icons/hi';
import { IoHomeOutline } from 'react-icons/io5';
import { FaPlus } from 'react-icons/fa6';
import { Op_per } from '../../utils/Permissions';

function Userdesignation() {

    const [cipdesignation, setCipDesignation] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [show, setShow] = useState(false);
    const [city_id, setCityId] = useState();
    const [docdesignation, setUserDesignation] = useState('');
    const [isdocdesignation, setIsUserDesignation] = useState();
    const [docdesc, setDocDesc] = useState('');
    const [reload, SetReload] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');


    const [currentId,setCurrentId] = useState();
    const [editShow, setEditShow] = useState(false);


    const filteredData = cipdesignation.filter((row) =>
    row.designation.toLowerCase().includes(searchQuery.toLowerCase())
  );


      useEffect(() => {
        // Fetch data from an API when the component mounts
        const getalldocdesignation = async () => {
            let cipdesignationdata = await GetAllUsersDesignation();
            if(cipdesignationdata){    
                setCipDesignation(cipdesignationdata);
            }else{
              alert('Error', 'Issue While Fetching Designations');
            }
          }
          getalldocdesignation();
          SetReload(false);
      }, [reload]); 

   
      
      useEffect(() => {
        const getallcountries = async (docdesignation) => {
            if (docdesignation.length > 0) {
                let statedata = await CheckUserDesignationAvailable(docdesignation);
                if (statedata) {
                    setIsUserDesignation(true);
                } else {
                    setIsUserDesignation(false);
                }
            }

        }
        getallcountries(docdesignation);
       

    }, [docdesignation]);

   


      // Function to handle page change
    const handlePageChange = (selectedPage) => {
        setCurrentPage(selectedPage.selected);
    };

    // Pagination configuration
  const itemsPerPage = 10; // Number of items per page
  const pageCount = Math.ceil(filteredData.length / itemsPerPage); // Total number of pages

  // Calculate the current page's items
  const startIndex = currentPage * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentPageItems = filteredData.slice(startIndex, endIndex);

  const handleClose = () => {setShow(false);setIsUserDesignation(false)}
    const handleShow = () => setShow(true);
    const handleEditShow = (row) => {
        setUserDesignation(row.designation);
        setDocDesc(row.description);
            setCurrentId(row.id);
            setEditShow(true)};
    
    const handleEditClose = () => {setEditShow(false);setIsUserDesignation(false)}

    const handleDesignationChange = (event) => {
        setUserDesignation(event.target.value);
    };

    const handleDescriptionChange = (event) => {
        setDocDesc(event.target.value);
    };

    let createUserDesignation = async (docdesignation, docdesc) => {

        let statedata = await CreateUserDesignation(docdesignation, docdesc);;
        if (statedata) {
            SetReload(true);
            setShow(false);
            

            Swal.fire({
                title: 'Success!',
                text: 'User Designation Added Successfully !!',
                icon: 'success',
                confirmButtonText: 'OK'
            });
        } else {
            alert('Error', 'Issue While Adding User Designation');
        }
    }

    let handleSave = (event) => {
        if ( isdocdesignation == false &&  docdesc.length > 0) {
            createUserDesignation(docdesignation, docdesc);
        }
    }

    let updateDegree = async (id,docdesignation,description) => {
        let degreedata = await UpdateUserDesignation(id,docdesignation,description);
        if (degreedata) {
            SetReload(true);
            setEditShow(false);
            

            Swal.fire({
                title: 'Success!',
                text: 'User Designation Update Successfully !!',
                icon: 'success',
                confirmButtonText: 'OK'
            });
        } else {
            alert('Error', 'Issue While Updating User Designation');
        }
    }


    let handleedit = (event) => {
        updateDegree(currentId,docdesignation,docdesc)
    }


    let deleteDegree = async (id) => {
        let degreedelete = await DeleteUserDesignation(id);

        if (degreedelete) {
            SetReload(true);

            Swal.fire({
                title: 'Success!',
                text: 'User Designation Deleted Successfully !!',
                icon: 'success',
                confirmButtonText: 'OK'
            });
        } else {
            alert('Error', 'Issue While Deleting User Designation');
        }
    }

    let handledelete = (id) => {
        deleteDegree(id);
    }

    const columns = [
      // {
      //   name: "Id",
      //   selector: (row) => row.id,
      // 
      //   width:"5%",
      // },
      {
        name: "Designation",
        selector: (row) =>
        <div style={{ whiteSpace: 'normal',backgroundColor:  row.designation === "" ||  row.designation === null ? "#f36e6f":"", borderRadius:"10px", border:  row.designation === "" ||  row.designation === null ? "2px solid transparent": "2px solid transparent",padding:"3px", display:"flex", flexDirection:"row",color: row.designation === "" ||  row.designation === null ? "#fff": "#000", margin:"3px", }}>{ row.designation ?  row.designation : "N/A"}</div> ,
      
        width:"10%",
        
      },
      {
        name: "Description",
        selector: (row) =><div style={{ whiteSpace: 'normal',backgroundColor:  row.description === "" ||  row.description === null ? "#f36e6f":"", borderRadius:"10px", border:  row.description === "" ||  row.description === null ? "2px solid transparent": "2px solid transparent",padding:"3px", display:"flex", flexDirection:"row",color: row.description === "" ||  row.description === null ? "#fff": "#000", margin:"3px", }}>{ row.description ?  row.description : "N/A"}</div> , 
        width:"25%",
      },
      // {
      //   name: "Actions",
      //   selector: (row) => (
      //     <div key={row.id}>
      //       <AiFillDelete
      //           onClick={() => handledelete(row.id)}
      //         style={{ color: "#f36e6f", fontSize: 20 }}
      //       />
      //       &nbsp;&nbsp;&nbsp;
      //       <AiTwotoneEdit
      //           onClick={() => handleEditShow(row)}
      //         style={{ color: "black", fontSize: 20 }}
      //       />
      //     </div>
      //   ),
      // },
    ];

    if(Op_per.get().OPTIONS_USER_DESIGNATION.ALLOW_DELETE || Op_per.get().OPTIONS_USER_DESIGNATION.ALLOW_EDIT){
      columns.push({
        name: "Actions",
        selector: (row) => (
          <div key={row.id}>
            <AiFillDelete
                onClick={() => handledelete(row.id)}
              style={{ color: "#f36e6f", fontSize: 20, display:Op_per.get().OPTIONS_USER_DESIGNATION.ALLOW_DELETE ? '' : 'none' }}
            />
            &nbsp;&nbsp;&nbsp;
            <AiTwotoneEdit
                onClick={() => handleEditShow(row)}
              style={{ color: "#9889e6", fontSize: 20, display:Op_per.get().OPTIONS_USER_DESIGNATION.ALLOW_EDIT ? '' : 'none' }}
            />
          </div>
        ),
      })
    }

    const customStyles = {   head: {     style: {       backgroundColor: 'blue',     },   }, };
    const CustomHeader = ({ children }) => (   <div style={{ backgroundColor: 'blue', padding: '10px', color: 'white' }}>    {children}  </div>);

    async function downloadCSV(cipdesignation) {
      let csv;
      try {
        const opts = {
          fields: ["id", "designation", "description"],
        };
        const parser = new Parser(opts);
        csv = parser.parse(cipdesignation);
      } catch (err) {
        console.error(err);
      }

      const link = document.createElement("a");

      if (csv == null) return;

      const filename = "export.csv";

      if (!csv.match(/^data:text\/csv/i)) {
        csv = `data:text/csv;charset=utf-8,${csv}`;
      }

      link.setAttribute("href", encodeURI(csv));
      link.setAttribute("download", filename);
      link.click();
    }

    const Export = ({ onExport }) => (
      <Button onClick={(e) => onExport(e.target.value)}>Export All</Button>
    );

    const actionsMemo = React.useMemo(
      () => <Export onExport={() => downloadCSV(cipdesignation)} />,
      [cipdesignation]
    );

  return (
    <Container fluid style={{ minHeight: "80vh" }}>
      <div
        className="glass-body"
        style={{
          margin: 10,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          padding:0,
          backgroundColor:'transparent'
        }}
      >

<div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
<ul id="breadcrumb">
  <li><a href="/"><IoHomeOutline
            style={{ color: "white", fontSize: 20 }}
            
          /></a></li>
  <li><a href="/user-designation"><span class="icon icon-beaker"> </span>Options</a></li>
  <li><a href="/user-designation"><span class="icon icon-beaker"> </span>User Designation</a></li>
  {/* <li><a href="#"><span class="icon icon-double-angle-right"></span>Doctor</a></li> */}
  
</ul>
	
</div>
        
      </div>

      <div className="glass-body" style={{ margin: 10 }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div>
            <Form.Control
             style={{height: 40,
            width:250,
            borderColor: '#ccc',
            borderWidth: 1,
            paddingHorizontal: 8,
            marginBottom: 16,
            borderTopLeftRadius:20,
            borderBottomRightRadius:20,}}
              className="glass"
              placeholder="Search Designation"
              type="text"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
          <div>
            <Button style={{backgroundColor: "#2194f0", display:Op_per.get().OPTIONS_USER_DESIGNATION.ALLOW_ADD ? '' : 'none' }} onClick={handleShow}>
            <FaPlus />
            </Button>
          </div>
        </div>
        <div style={{ marginTop: 10 }}>
          <DataTable
            columns={columns}
            data={filteredData}
            // progressPending={pending}
            pagination
            customStyles={{headRow: {style: {backgroundColor: '#c0dfff',  padding:10,borderRadius:'10px 10px 0 0' }, }  }}
                        CustomHeader={<CustomHeader/>}
            // actions={actionsMemo}
            style={{ marginTop: 10 }}
            dense
          />
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add User Designation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Designation <span style={{color:"red"}} >*</span></Form.Label>

              <Form.Control
                style={{
                  backgroundColor: isdocdesignation ? "#f36e6f" : "",
                  color: isdocdesignation ? "#fff" : "",
                  height: 40,
           
            borderColor: '#ccc',
            borderWidth: 1,
            paddingHorizontal: 8,
            marginBottom: 16,
            borderTopLeftRadius:20,
            borderBottomRightRadius:20,
                }}
                type="text"
                onChange={handleDesignationChange}
                placeholder="Enter Designation"
                required
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Description <span style={{color:"red"}} >*</span></Form.Label>
              <Form.Control
               style={{height: 80,
            
            borderColor: '#ccc',
            borderWidth: 1,
            paddingHorizontal: 8,
            marginBottom: 16,
            borderTopLeftRadius:20,
            borderBottomRightRadius:20,}}
                type="text"
                onChange={handleDescriptionChange}
                placeholder="Enter Description"
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={handleSave}
            disabled={isdocdesignation ? true : false}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={editShow} onHide={handleEditClose}>
        <Modal.Header closeButton>
          <Modal.Title>Update Cip Designation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Designation <span style={{color:"red"}} >*</span></Form.Label>

              <Form.Control
               style={{height: 40,
           
            borderColor: '#ccc',
            borderWidth: 1,
            paddingHorizontal: 8,
            marginBottom: 16,
            borderTopLeftRadius:20,
            borderBottomRightRadius:20,}}
                type="text"
                onChange={handleDesignationChange}
                value={docdesignation}
                placeholder="Enter Designation"
                required
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Description <span style={{color:"red"}} >*</span></Form.Label>
              <Form.Control
               style={{height: 80,
            
            borderColor: '#ccc',
            borderWidth: 1,
            paddingHorizontal: 8,
            marginBottom: 16,
            borderTopLeftRadius:20,
            borderBottomRightRadius:20,}}
                type="text"
                value={docdesc}
                onChange={handleDescriptionChange}
                placeholder="Enter Description"
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleEditClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleedit}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default Userdesignation;