import { useState } from "react";
import { useEffect } from "react";
import DataTable from "react-data-table-component";
import { AiFillDelete, AiTwotoneEdit } from "react-icons/ai";
import { FaPlus, FaRegRectangleXmark } from "react-icons/fa6";
import { IoMdCheckmarkCircle } from "react-icons/io";
import { DeleteTaskById, GetAllPendingTask, GetAllPendingTaskByuserid, GetAllTask, GetAllTaskByUser, GetAllTaskCategory, GetTaskUnderManagerUsers } from "../../utils/proxy/Taskproxy";
import { Button, Container, Modal, Row } from "react-bootstrap";
import { Form } from "react-router-dom";
import Select from "react-select";
import { AllUsers, GetAllManagerUsers } from "../../utils/proxy/Authproxy";
import { GetAllProjectsByUserid } from "../../utils/proxy/ProjectProxy";
import Swal from "sweetalert2";
import { GiMoneyStack } from "react-icons/gi";
import { FaTasks } from "react-icons/fa";

function TaskPendingChart({acdgd,re}) {

    const [users, setusers] = useState([]);
    const [task, setTask] = useState([]);
    const [reload, setReload] = useState(false);

    useEffect(() => {
        const getTask = async () => {
            if (localStorage.getItem('role') === 'Admin') {
                let allusers = await GetAllPendingTask();
                setTask(allusers);
            }
              else if(localStorage.getItem('role') === 'Manager'){
                let allusers = await GetTaskUnderManagerUsers(localStorage.getItem('uuid'));
                setTask(allusers);
              }
              else if(localStorage.getItem('role') === 'User'){
                let allusers = await GetAllPendingTaskByuserid(localStorage.getItem('uuid'));
                setTask(allusers);
               }
        }
        getTask();
        setReload(false);
    }, [re,reload])

    const options = [];

    users.map((user) => {
        options.push({
            value: user.userid,
            label: user.username + "(" + user.firstname + " " + user.lastname + ")",
        });
    });

    const setshoweditexpense = () => {

    }

    const edithandle = () => {

    }

    const handletaskdelete = async (id) => {
        let d = await DeleteTaskById(id);
        if (d.status === "success") {
          setReload(true)
          Swal.fire({
            title: "Deleted!",
            text: "Task Deleted Successfully !!",
            icon: "success",
            confirmButtonText: "OK",
          });
        }
      }

    const columns = [
        // {
        //     name: "Id",
        //     selector: (row) => row.id,
        //     sortable: true,
        //     width: '10%',
        // },
        {
            name: "Task",
            selector: (row) =>
                <div style={{ whiteSpace: 'normal', backgroundColor: row.task === "" || row.task === null ? "#f36e6f" : "", borderRadius: "10px", border: row.task === "" || row.task === null ? "2px solid transparent" : "2px solid transparent", padding: "3px", display: "flex", flexDirection: "row", color: row.task === "" || row.task === null ? "#fff" : "#000", margin: "3px", paddingLeft:20, paddingRight:20,}}>{row.task ? row.task : "N/A"}</div>,
            sortable: true,
            width: "35%",
            

        },
        {
            name: "Priority",
            selector: (row) =>
                <div style={{ whiteSpace: 'normal', backgroundColor: row.priority === "" || row.priority === null ? "#f36e6f" : "", borderRadius: "10px", border: row.priority === "" || row.priority === null ? "2px solid transparent" : "2px solid transparent", padding: "3px", display: "flex", flexDirection: "row", color: row.priority === "" || row.priority === null ? "#fff" : "#000", margin: "3px", paddingLeft:20, paddingRight:20,}}>{row.priority ? row.priority : "N/A"}</div>,
            sortable: true,
            width: "20%",
            

        },
        {
            name: "Username",
            selector: (row) =>
                <div style={{ whiteSpace: 'normal', backgroundColor: row.username === "" || row.username === null ? "#f36e6f" : "", borderRadius: "10px", border: row.username === "" || row.username === null ? "2px solid transparent" : "2px solid transparent", padding: "3px", display: "flex", flexDirection: "row", color: row.username === "" || row.username === null ? "#fff" : "#000", margin: "3px",paddingLeft:20, paddingRight:20}}>{row.username ? row.username : "N/A"}</div>,
            sortable: true,
            width: "30%",

        },
        {
            name: "Actions",
            selector: (row) => (
                <div  key={row.id}>
                    {/* <AiTwotoneEdit
                        style={{ color: "black", fontSize: 20 }}
                        onClick={() => { setshoweditexpense(true); edithandle(row) }}
                    /> */}
                    &nbsp;&nbsp;&nbsp;
                    <AiFillDelete
                        style={{ color: "#f36e6f", fontSize: 20 }}
                        onClick={() => handletaskdelete(row.id)}
                        title="Delete"
                    />
                </div>
            ),
        },
    ];

    return (
        <>
           
            
            {/* <Row >
        <div >
            <div md={4}>
                <div >
                    <FaTasks color='#000' size={30} />
                </div>
            </div>
            <div md={4}>
            <h6 style={{textAlign:'center', marginBottom:0}}>Task Pending</h6>
            </div>
            <div md={4} style={{paddingRight:10}}>
            <Button style={{ backgroundColor: "#FF8B13", marginLeft: 'auto', paddingRight: '10px', paddingTop: '2px', border:"none", }} onClick={acdgd} >
                        <FaPlus />
                    </Button>
            </div>
            
        </div>
    </Row>  */}
                
                <div className="" style={{ backgroundColor: 'white', padding: 10,overflowY: 'scroll' }}>
                    <div className="" style={{ backgroundColor: 'white' }}>
                    <h6 style={{ textAlign: 'center', marginBottom: 10 }}>Task Pending </h6>
                        <DataTable
                            columns={columns}
                            customStyles={{headRow: {style: {backgroundColor: '#c0dfff',  padding:10,borderRadius:'10px 10px 0 0' }, }  }}
                            data={task}
                            pagination
                            style={{ margin: 10, textAlign:"center" }}
                            dense
                        />
                    </div>
                </div>
           
        </>
    )
}

export default TaskPendingChart;