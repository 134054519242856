import React from 'react';
import { Button } from 'react-bootstrap';
import { RiFileExcel2Fill } from 'react-icons/ri';
import * as XLSX from 'xlsx';

class JSONToExcelConverter extends React.Component {
  handleConvertToExcel = () => {
    const data = this.props.data;

    const filename = this.props.filename;

    const wb = XLSX.utils.book_new();

    for (const dataType in data) {
      if (data[dataType].length > 0) {
        const ws = XLSX.utils.json_to_sheet(data[dataType]);
        XLSX.utils.book_append_sheet(wb, ws, dataType);
      }
    }

    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'buffer' });

    const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename+'.xlsx');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  render() {
    return (
      <div>
       <Button style={{position:'absolute',right:40,top:10,backgroundColor: "#2194f0" }} onClick={this.handleConvertToExcel}>
    <RiFileExcel2Fill />
    </Button>
        
      </div>
    );
  }
}

export default JSONToExcelConverter;
