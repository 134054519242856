import React, { useEffect, useState } from "react";
import { Button, Container } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import DataTable from "react-data-table-component";
import Dropdown from "react-bootstrap/Dropdown";
import { AiFillDelete, AiTwotoneEdit } from "react-icons/ai";
import { TiTick } from "react-icons/ti";
import Select from "react-select";
import { AllUsers, GetAllManagerUsers } from "../../utils/proxy/Authproxy";
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import Helper from "../../utils/helperFunctions";
import { GetAllDcr } from "../../utils/proxy/Dcrproxy";
import JSONToExcelConverter from "../../utils/JSONToExcelConverter";
import { IoHomeOutline } from "react-icons/io5";
import { RiFileExcel2Fill } from "react-icons/ri";
import { Parser } from "@json2csv/plainjs";
import { Op_per } from "../../utils/Permissions";
import LoadingComponent from "../Includes/LoadingComponent";

function Dcr() {
  const [dcr, setDcr] = useState([]);
  const [userid, setUserId] = useState(localStorage.getItem('role')==='User' ? localStorage.getItem('uuid') : "");
  const [reload, setReload] = useState(false);
  const [selectedUser, setSelectedUser] = useState();
  const [users, setUsers] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [date, setDate] = useState(new Date());
  const [loading, setLoading] = useState(false);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    // Fetch data from an API when the component mounts
    const getAllDcr = async (userid, date) => {
      setLoading(true);
      let response = await GetAllDcr(
        userid,
        Helper.ConvertLocalTimeToUtc(date)
      );
      if (response) {
        setDcr(response);
        setLoading(false);
        
      } else {
        alert("Error", "Issue While Fetching DCR");
      }
    };
    if (userid && date) {
      getAllDcr(userid, date);
    }
    setReload(false);
  }, [reload, userid, showModal, date]);

  useEffect(() => {
    // Fetch data from an API when the component mounts
    const getallusers = async () => {
      if (localStorage.getItem('role') === 'Admin') {
        let allusers = await AllUsers();
        setUsers(allusers);
      } else if (localStorage.getItem('role') === 'Manager') {
        let allusers = await GetAllManagerUsers(localStorage.getItem('uuid'));
        setUsers(allusers);
      }
    };
    getallusers();
  }, []);

  const options = [];

  users.map((user) => {
    options.push({
      value: user.userid,
      label: user.username + "(" + user.firstname + " " + user.lastname + ")",
    });
  });

  async function downloadCSV(data) {
    let csv = "";
    try {
        const link = document.createElement("a");
        const filename = "Reports.csv";

        if ( data.length === 0) {
            console.error("Data is not an array or is empty.");
            return;
        }

        csv += `Name: ${selectedUser.label}\n`;
        csv += `Date: ${date.toDateString()}\n`;

            const dateObj = data;

            // Process doctors
            if (dateObj.doctors && dateObj.doctors.length > 0) {
                const parser = new Parser();
                csv += "Doctors\n";
                const filteredArray = dateObj.doctors.map(subObj => {
                    const { id, userid,doctorid,createdat, ...filteredFields } = subObj;
                    const localCreatedAt = new Date(createdat).toLocaleString();
                    return {createdat:localCreatedAt, ...filteredFields};
                });
                const headerDoctors = Object.keys(filteredArray[0]);
                const optsDoctors = {
                    fields: headerDoctors,
                    transforms: [
                        // Add padding to each cell by adding extra spaces
                        (value) => {
                            return ` ${value} `.padStart(10).padEnd(10); // Add padding of 20 characters to each cell
                        }
                    ]
                };
                const csvDataDoctors = parser.parse(filteredArray, optsDoctors);
                csv += csvDataDoctors + "\n\n";
            }
            // Process hospital
            if (dateObj.hospitals && dateObj.hospitals.length > 0) {
                const parser = new Parser();
                csv += "Hospitals\n";
                const filteredArray = dateObj.hospitals.map(subObj => {
                    const { id, userid,hospitalid,cipid,createdat, ...filteredFields } = subObj;
                    const localCreatedAt = new Date(createdat).toLocaleString();
                    return {createdat:localCreatedAt, ...filteredFields};
                });
                const headerhospitals = Object.keys(filteredArray[0]);
                const optshospitals = {
                    fields: headerhospitals,
                    transforms: [
                        // Add padding to each cell by adding extra spaces
                        (value) => {
                            return ` ${value} `.padStart(10).padEnd(20); // Add padding of 20 characters to each cell
                        }
                    ]
                };
                const csvDatahospitals = parser.parse(filteredArray, optshospitals);
                csv += csvDatahospitals + "\n\n";
            }
            // Process clinic
            if (dateObj.clinics && dateObj.clinics.length > 0) {
                const parser = new Parser();
                csv += "Clinics\n";
                const filteredArray = dateObj.clinics.map(subObj => {
                    const { id, userid,clinicid,createdat, ...filteredFields } = subObj;
                    const localCreatedAt = new Date(createdat).toLocaleString();
                    return {createdat:localCreatedAt, ...filteredFields};
                });
                const headerClinics = Object.keys(filteredArray[0]);
                const optsClinics = {
                    fields: headerClinics,
                    transforms: [
                        // Add padding to each cell by adding extra spaces
                        (value) => {
                            return ` ${value} `.padStart(10).padEnd(20); // Add padding of 20 characters to each cell
                        }
                    ]
                };
                const csvDataClinics = parser.parse(filteredArray, optsClinics);
                csv += csvDataClinics + "\n\n";
            }
            // Process chemist
            if (dateObj.chemists && dateObj.chemists.length > 0) {
                const parser = new Parser();
                csv += "Chemists\n";
                const filteredArray = dateObj.chemists.map(subObj => {
                    const { id, userid,chemistid,createdat, ...filteredFields } = subObj;
                    const localCreatedAt = new Date(createdat).toLocaleString();
                    return {createdat:localCreatedAt, ...filteredFields};
                });
                const headerchemists = Object.keys(filteredArray[0]);
                const optschemists = {
                    fields: headerchemists,
                    transforms: [
                        // Add padding to each cell by adding extra spaces
                        (value) => {
                            return ` ${value} `.padStart(10).padEnd(20); // Add padding of 20 characters to each cell
                        }
                    ]
                };
                const csvDatachemists = parser.parse(filteredArray, optschemists);
                csv += csvDatachemists + "\n\n";
            }
            // Process stockist
            if (dateObj.stockists && dateObj.stockists.length > 0) {
                const parser = new Parser();
                csv += "Stockists\n";
                const filteredArray = dateObj.stockists.map(subObj => {
                    const { id, userid,stockistid,createdat, ...filteredFields } = subObj;
                    const localCreatedAt = new Date(createdat).toLocaleString();
                    return {createdat:localCreatedAt, ...filteredFields};
                });
                const headerstockists = Object.keys(filteredArray[0]);
                const optsstockists = {
                    fields: headerstockists,
                    transforms: [
                        // Add padding to each cell by adding extra spaces
                        (value) => {
                            return ` ${value} `.padStart(10).padEnd(20); // Add padding of 20 characters to each cell
                        }
                    ]
                };
                const csvDatastockists = parser.parse(filteredArray, optsstockists);
                csv += csvDatastockists + "\n\n";
            }

            // Add extra newline between dates
            csv += "\n\n\n\n";

        if (!csv) {
            console.error("No valid data to download.");
            return;
        }

        if (!csv.match(/^data:text\/csv/i)) {
            csv = `data:text/csv;charset=utf-8,${csv}`;
        }

        link.setAttribute("href", encodeURI(csv));
        link.setAttribute("download", filename);
        link.click();
    } catch (err) {
        console.error(err);
    }
}

  const doctorColumns = [
    // {
    //   name: "Id",
    //   selector: (row) => row.id,
    //   sortable: true,
    //   margin: 30,
    //   width: "5%",
    // },
    {
      name: "Location",
      selector: (row) => <a target="_blank" href={`https://maps.google.com/?q=${row.latitude},${row.longitutde}`}>View On Map</a>,
      width: "8%",
    },
    {
      name: "Doctor Name",
      selector: (row) => <div style={{ whiteSpace: 'normal', backgroundColor: row.doctorname === "" || row.doctorname === null ? "#f36e6f" : "", borderRadius: "10px", border: row.doctorname === "" || row.doctorname === null ? "2px solid transparent" : "2px solid transparent", padding: "3px", display: "flex", flexDirection: "row", color: row.doctorname === "" || row.doctorname === null ? "#fff" : "#000", margin: "3px", }}>{row.doctorname ? row.doctorname : "N/A"}</div>,
      width: "15%",
    },
    {
      name: "Planned",
      selector: (row) =>
        <div style={{ whiteSpace: 'normal', backgroundColor: row.notplanned ? "No" : "Yes" === "" || row.notplanned ? "No" : "Yes" === null ? "#f36e6f" : "", borderRadius: "10px", border: row.notplanned ? "No" : "Yes" === "" || row.notplanned ? "No" : "Yes" === null ? "2px solid transparent" : "2px solid transparent", padding: "3px", display: "flex", flexDirection: "row", color: row.notplanned ? "No" : "Yes" === "" || row.notplanned ? "No" : "Yes" === null ? "#fff" : "#000", margin: "3px", }}>{row.notplanned ? "No" : "Yes" ? row.notplanned ? "No" : "Yes" : "N/A"}</div>,
      width: "10%",
    },
    {
      name: "Join Work With",
      selector: (row) => <div style={{ whiteSpace: 'normal', backgroundColor: row.jointworkwithusername === "" || row.jointworkwithusername === null ? "#f36e6f" : "", borderRadius: "10px", border: row.jointworkwithusername === "" || row.jointworkwithusername === null ? "2px solid transparent" : "2px solid transparent", padding: "3px", display: "flex", flexDirection: "row", color: row.jointworkwithusername === "" || row.jointworkwithusername === null ? "#fff" : "#000", margin: "3px", }}>{row.jointworkwithusername ? row.jointworkwithusername : "N/A"}</div>,
      width: "10%",

    },
    {
      name: "Remark",
      selector: (row) => <div style={{ whiteSpace: 'normal', backgroundColor: row.remark === "" || row.remark === null ? "#f36e6f" : "", borderRadius: "10px", border: row.remark === "" || row.remark === null ? "2px solid transparent" : "2px solid transparent", padding: "3px", display: "flex", flexDirection: "row", color: row.remark === "" || row.remark === null ? "#fff" : "#000", margin: "3px", }}>{row.remark ? row.remark : "N/A"}</div>,
      width: "10%",
    },

    {
      name: "Created At",
      selector: (row) => <div style={{ whiteSpace: 'normal', backgroundColor: Helper.ConvertUtcToLocalTime(row.createdat) === "" || Helper.ConvertUtcToLocalTime(row.createdat) === null ? "#f36e6f" : "", borderRadius: "10px", border: Helper.ConvertUtcToLocalTime(row.createdat) === "" || Helper.ConvertUtcToLocalTime(row.createdat) === null ? "2px solid transparent" : "2px solid transparent", padding: "3px", display: "flex", flexDirection: "row", color: Helper.ConvertUtcToLocalTime(row.createdat) === "" || Helper.ConvertUtcToLocalTime(row.createdat) === null ? "#fff" : "#000", margin: "3px", }}>{Helper.ConvertUtcToLocalTime(row.createdat) ? Helper.ConvertUtcToLocalTime(row.createdat) : "N/A"}</div>,
      width: "10%",
    },
    {
      name: "Signature",
      selector: (row) => row.signature != '' ? <img src={row.signature} alt="signature" width={50} height={50} /> : '',
      width: "10%",
    },
    {
      name: "Image",
      selector: (row) => row.image != '' ? <img src={"data:image/png;base64,"+row.image} alt="image" width={50} height={50} /> : "",
      width: "10%",
    },
  ];
  const customStyles = {   head: {     style: {       backgroundColor: 'blue',     },   }, };
  const CustomHeader = ({ children }) => (   <div style={{ backgroundColor: 'blue', padding: '10px', color: 'white' }}>    {children}  </div>);

  const clinicColumns = [
    // {
    //   name: "Id",
    //   selector: (row) => row.id,
    //   sortable: true,
    //   margin: 30,
    //   width: "5%",
    // },
    {
      name: "Location",
      selector: (row) => <a target="_blank" href={`https://maps.google.com/?q=${row.latitude},${row.longitutde}`}>View On Map</a>,
      width: "10%",
    },
    {
      name: "Clinic Name",
      selector: (row) =>
        <div style={{ whiteSpace: 'normal', backgroundColor: row.clinicname === "" || row.clinicname === null ? "#f36e6f" : "", borderRadius: "10px", border: row.clinicname === "" || row.clinicname === null ? "2px solid transparent" : "2px solid transparent", padding: "3px", display: "flex", flexDirection: "row", color: row.clinicname === "" || row.clinicname === null ? "#fff" : "#000", margin: "3px", }}>{row.clinicname ? row.clinicname : "N/A"}</div>
      ,
      width: "15%",
    },
    {
      name: "Planned",
      selector: (row) => <div style={{ whiteSpace: 'normal', backgroundColor: row.notplanned ? "No" : "Yes" === "" || row.notplanned ? "No" : "Yes" === null ? "#f36e6f" : "", borderRadius: "10px", border: row.notplanned ? "No" : "Yes" === "" || row.notplanned ? "No" : "Yes" === null ? "2px solid transparent" : "2px solid transparent", padding: "3px", display: "flex", flexDirection: "row", color: row.notplanned ? "No" : "Yes" === "" || row.notplanned ? "No" : "Yes" === null ? "#fff" : "#000", margin: "3px", }}>{row.notplanned ? "No" : "Yes" ? row.notplanned ? "No" : "Yes" : "N/A"}</div>,
      width: "10%",
    },
    {
      name: "Join Work With",
      selector: (row) => <div style={{ whiteSpace: 'normal', backgroundColor: row.jointworkwithusername === "" || row.jointworkwithusername === null ? "#f36e6f" : "", borderRadius: "10px", border: row.jointworkwithusername === "" || row.jointworkwithusername === null ? "2px solid transparent" : "2px solid transparent", padding: "3px", display: "flex", flexDirection: "row", color: row.jointworkwithusername === "" || row.jointworkwithusername === null ? "#fff" : "#000", margin: "3px", }}>{row.jointworkwithusername ? row.jointworkwithusername : "N/A"}</div>,
      width: "10%",
    },
    {
      name: "Remark",
      selector: (row) => <div style={{ whiteSpace: 'normal', backgroundColor: row.remark === "" || row.remark === null ? "#f36e6f" : "", borderRadius: "10px", border: row.remark === "" || row.remark === null ? "2px solid transparent" : "2px solid transparent", padding: "3px", display: "flex", flexDirection: "row", color: row.remark === "" || row.remark === null ? "#fff" : "#000", margin: "3px", }}>{row.remark ? row.remark : "N/A"}</div>,
      width: "10%",
    },

    {
      name: "Created At",
      selector: (row) => <div style={{ whiteSpace: 'normal', backgroundColor: Helper.ConvertUtcToLocalTime(row.createdat) === "" || Helper.ConvertUtcToLocalTime(row.createdat) === null ? "#f36e6f" : "", borderRadius: "10px", border: Helper.ConvertUtcToLocalTime(row.createdat) === "" || Helper.ConvertUtcToLocalTime(row.createdat) === null ? "2px solid transparent" : "2px solid transparent", padding: "3px", display: "flex", flexDirection: "row", color: Helper.ConvertUtcToLocalTime(row.createdat) === "" || Helper.ConvertUtcToLocalTime(row.createdat) === null ? "#fff" : "#000", margin: "3px", }}>{Helper.ConvertUtcToLocalTime(row.createdat) ? Helper.ConvertUtcToLocalTime(row.createdat) : "N/A"}</div>,
      width: "10%",
    },
    {
      name: "Signature",
      selector: (row) => row.signature != '' ? <img src={row.signature} alt="signature" width={50} height={50} /> : '',
      width: "10%",
    },
    {
      name: "Image",
      selector: (row) => row.image != '' ? <img src={"data:image/png;base64,"+row.image} alt="image" width={50} height={50} /> : '',
      width: "10%",
    },
  ];
  
  
  const chemistColumns = [
    // {
    //   name: "Id",
    //   selector: (row) => row.id,
    //   sortable: true,
    //   margin: 30,
    //   width: "5%",
    // },
    {
      name: "Location",
      selector: (row) => <a target="_blank" href={`https://maps.google.com/?q=${row.latitude},${row.longitutde}`}>View On Map</a>,
      width: "10%",
    },
    {
      name: "Chemist Name",
      selector: (row) => <div style={{ whiteSpace: 'normal', backgroundColor: row.chemistname === "" || row.chemistname === null ? "#f36e6f" : "", borderRadius: "10px", border: row.chemistname === "" || row.chemistname === null ? "2px solid transparent" : "2px solid transparent", padding: "3px", display: "flex", flexDirection: "row", color: row.chemistname === "" || row.chemistname === null ? "#fff" : "#000", margin: "3px", }}>{row.chemistname ? row.chemistname : "N/A"}</div>,
      width: "15%",
    },
    {
      name: "Planned",
      selector: (row) => <div style={{ whiteSpace: 'normal', backgroundColor: row.notplanned ? "No" : "Yes" === "" || row.notplanned ? "No" : "Yes" === null ? "#f36e6f" : "", borderRadius: "10px", border: row.notplanned ? "No" : "Yes" === "" || row.notplanned ? "No" : "Yes" === null ? "2px solid transparent" : "2px solid transparent", padding: "3px", display: "flex", flexDirection: "row", color: row.notplanned ? "No" : "Yes" === "" || row.notplanned ? "No" : "Yes" === null ? "#fff" : "#000", margin: "3px", }}>{row.notplanned ? "No" : "Yes" ? row.notplanned ? "No" : "Yes" : "N/A"}</div>,
      width: "10%",
    },
    {
      name: "Join Work With",
      selector: (row) => <div style={{ whiteSpace: 'normal', backgroundColor: row.jointworkwithusername === "" || row.jointworkwithusername === null ? "#f36e6f" : "", borderRadius: "10px", border: row.jointworkwithusername === "" || row.jointworkwithusername === null ? "2px solid transparent" : "2px solid transparent", padding: "3px", display: "flex", flexDirection: "row", color: row.jointworkwithusername === "" || row.jointworkwithusername === null ? "#fff" : "#000", margin: "3px", }}>{row.jointworkwithusername ? row.jointworkwithusername : "N/A"}</div>,
      width: "10%",
    },
    {
      name: "Remark",
      selector: (row) => <div style={{ whiteSpace: 'normal', backgroundColor: row.remark === "" || row.remark === null ? "#f36e6f" : "", borderRadius: "10px", border: row.remark === "" || row.remark === null ? "2px solid transparent" : "2px solid transparent", padding: "3px", display: "flex", flexDirection: "row", color: row.remark === "" || row.remark === null ? "#fff" : "#000", margin: "3px", }}>{row.remark ? row.remark : "N/A"}</div>,
      width: "10%",
    },

    {
      name: "Created At",
      selector: (row) => <div style={{ whiteSpace: 'normal', backgroundColor: Helper.ConvertUtcToLocalTime(row.createdat) === "" || Helper.ConvertUtcToLocalTime(row.createdat) === null ? "#f36e6f" : "", borderRadius: "10px", border: Helper.ConvertUtcToLocalTime(row.createdat) === "" || Helper.ConvertUtcToLocalTime(row.createdat) === null ? "2px solid transparent" : "2px solid transparent", padding: "3px", display: "flex", flexDirection: "row", color: Helper.ConvertUtcToLocalTime(row.createdat) === "" || Helper.ConvertUtcToLocalTime(row.createdat) === null ? "#fff" : "#000", margin: "3px", }}>{Helper.ConvertUtcToLocalTime(row.createdat) ? Helper.ConvertUtcToLocalTime(row.createdat) : "N/A"}</div>,
      width: "10%",
    },
    {
      name: "Signature",
      selector: (row) => row.signature != '' ? <img src={row.signature} alt="signature" width={50} height={50} /> : '',
      width: "10%",
    },
    {
      name: "Image",
      selector: (row) => row.image != '' ? <img src={"data:image/png;base64,"+row.image} alt="image" width={50} height={50} /> : '',
      width: "10%",
    },
  ];
  const stockistColumns = [
    // {
    //   name: "Id",
    //   selector: (row) => row.id,
    //   sortable: true,
    //   margin: 30,
    //   width: "5%",
    // },
    {
      name: "Location",
      selector: (row) => <a target="_blank" href={`https://maps.google.com/?q=${row.latitude},${row.longitutde}`}>View On Map</a>,
      width: "10%",
    },
    {
      name: "Stockist Name",
      selector: (row) => <div style={{ whiteSpace: 'normal', backgroundColor: row.stockistname === "" || row.stockistname === null ? "#f36e6f" : "", borderRadius: "10px", border: row.stockistname === "" || row.stockistname === null ? "2px solid transparent" : "2px solid transparent", padding: "3px", display: "flex", flexDirection: "row", color: row.stockistname === "" || row.stockistname === null ? "#fff" : "#000", margin: "3px", }}>{row.stockistname ? row.stockistname : "N/A"}</div>,
      width: "15%",
    },
    {
      name: "Planned",
      selector: (row) => <div style={{ whiteSpace: 'normal', backgroundColor: row.notplanned ? "No" : "Yes" === "" || row.notplanned ? "No" : "Yes" === null ? "#f36e6f" : "", borderRadius: "10px", border: row.notplanned ? "No" : "Yes" === "" || row.notplanned ? "No" : "Yes" === null ? "2px solid transparent" : "2px solid transparent", padding: "3px", display: "flex", flexDirection: "row", color: row.notplanned ? "No" : "Yes" === "" || row.notplanned ? "No" : "Yes" === null ? "#fff" : "#000", margin: "3px", }}>{row.notplanned ? "No" : "Yes" ? row.notplanned ? "No" : "Yes" : "N/A"}</div>,
      width: "10%",
    },
    {
      name: "Join Work With",
      selector: (row) => <div style={{ whiteSpace: 'normal', backgroundColor: row.jointworkwithusername === "" || row.jointworkwithusername === null ? "#f36e6f" : "", borderRadius: "10px", border: row.jointworkwithusername === "" || row.jointworkwithusername === null ? "2px solid transparent" : "2px solid transparent", padding: "3px", display: "flex", flexDirection: "row", color: row.jointworkwithusername === "" || row.jointworkwithusername === null ? "#fff" : "#000", margin: "3px", }}>{row.jointworkwithusername ? row.jointworkwithusername : "N/A"}</div>,
      width: "10%",
    },
    {
      name: "Remark",
      selector: (row) => <div style={{ whiteSpace: 'normal', backgroundColor: row.remark === "" || row.remark === null ? "#f36e6f" : "", borderRadius: "10px", border: row.remark === "" || row.remark === null ? "2px solid transparent" : "2px solid transparent", padding: "3px", display: "flex", flexDirection: "row", color: row.remark === "" || row.remark === null ? "#fff" : "#000", margin: "3px", }}>{row.remark ? row.remark : "N/A"}</div>,
      width: "10%",
    },

    {
      name: "Created At",
      selector: (row) => <div style={{ whiteSpace: 'normal', backgroundColor: Helper.ConvertUtcToLocalTime(row.createdat) === "" || Helper.ConvertUtcToLocalTime(row.createdat) === null ? "#f36e6f" : "", borderRadius: "10px", border: Helper.ConvertUtcToLocalTime(row.createdat) === "" || Helper.ConvertUtcToLocalTime(row.createdat) === null ? "2px solid transparent" : "2px solid transparent", padding: "3px", display: "flex", flexDirection: "row", color: Helper.ConvertUtcToLocalTime(row.createdat) === "" || Helper.ConvertUtcToLocalTime(row.createdat) === null ? "#fff" : "#000", margin: "3px", }}>{Helper.ConvertUtcToLocalTime(row.createdat) ? Helper.ConvertUtcToLocalTime(row.createdat) : "N/A"}</div>,
      width: "10%",
    },
    {
      name: "Signature",
      selector: (row) => row.signature != '' ? <img src={row.signature} alt="signature" width={50} height={50} /> : '',
      width: "10%",
    },
    {
      name: "Image",
      selector: (row) => row.image != '' ? <img src={"data:image/png;base64,"+row.image} alt="image" width={50} height={50} /> : '',
      width: "10%",
    },
  ];
  const hospitalColumns = [
    // {
    //   name: "Id",
    //   selector: (row) => row.id,
    //   sortable: true,
    //   margin: 30,
    //   width: "5%",
    // },
    {
      name: "Location",
      selector: (row) => <a target="_blank" href={`https://maps.google.com/?q=${row.latitude},${row.longitutde}`}>View On Map</a>,
      width: "10%",
    },
    {
      name: "Hospital Name",
      selector: (row) => <div style={{ whiteSpace: 'normal', backgroundColor: row.hospitalname === "" || row.hospitalname === null ? "#f36e6f" : "", borderRadius: "10px", border: row.hospitalname === "" || row.hospitalname === null ? "2px solid transparent" : "2px solid transparent", padding: "3px", display: "flex", flexDirection: "row", color: row.hospitalname === "" || row.hospitalname === null ? "#fff" : "#000", margin: "3px", }}>{row.hospitalname ? row.hospitalname : "N/A"}</div>,
      width: "15%",
    },
    {
      name: "Stockist Name",
      selector: (row) => <div style={{ whiteSpace: 'normal', backgroundColor: row.stockistname === "" || row.stockistname === null ? "#f36e6f" : "", borderRadius: "10px", border: row.stockistname === "" || row.stockistname === null ? "2px solid transparent" : "2px solid transparent", padding: "3px", display: "flex", flexDirection: "row", color: row.stockistname === "" || row.stockistname === null ? "#fff" : "#000", margin: "3px", }}>{row.stockistname ? row.stockistname : "N/A"}</div>,
      width: "15%",
    },
    {
      name: "Planned",
      selector: (row) => <div style={{ whiteSpace: 'normal', backgroundColor: row.notplanned ? "No" : "Yes" === "" || row.notplanned ? "No" : "Yes" === null ? "#f36e6f" : "", borderRadius: "10px", border: row.notplanned ? "No" : "Yes" === "" || row.notplanned ? "No" : "Yes" === null ? "2px solid transparent" : "2px solid transparent", padding: "3px", display: "flex", flexDirection: "row", color: row.notplanned ? "No" : "Yes" === "" || row.notplanned ? "No" : "Yes" === null ? "#fff" : "#000", margin: "3px", }}>{row.notplanned ? "No" : "Yes" ? row.notplanned ? "No" : "Yes" : "N/A"}</div>,
      width: "10%",
    },
    {
      name: "Join Work With",
      selector: (row) => <div style={{ whiteSpace: 'normal', backgroundColor: row.jointworkwithusername === "" || row.jointworkwithusername === null ? "#f36e6f" : "", borderRadius: "10px", border: row.jointworkwithusername === "" || row.jointworkwithusername === null ? "2px solid transparent" : "2px solid transparent", padding: "3px", display: "flex", flexDirection: "row", color: row.jointworkwithusername === "" || row.jointworkwithusername === null ? "#fff" : "#000", margin: "3px", }}>{row.jointworkwithusername ? row.jointworkwithusername : "N/A"}</div>,
      width: "10%",
    },
    {
      name: "Cip Name",
      selector: (row) => <div style={{ whiteSpace: 'normal', backgroundColor: row.cipname === "" || row.cipname === null ? "#f36e6f" : "", borderRadius: "10px", border: row.cipname === "" || row.cipname === null ? "2px solid transparent" : "2px solid transparent", padding: "3px", display: "flex", flexDirection: "row", color: row.cipname === "" || row.cipname === null ? "#fff" : "#000", margin: "3px", }}>{row.cipname ? row.cipname : "N/A"}</div>,
      width: "10%",
    },
    {
      name: "Remark",
      selector: (row) => <div style={{ whiteSpace: 'normal', backgroundColor: row.remark === "" || row.remark === null ? "#f36e6f" : "", borderRadius: "10px", border: row.remark === "" || row.remark === null ? "2px solid transparent" : "2px solid transparent", padding: "3px", display: "flex", flexDirection: "row", color: row.remark === "" || row.remark === null ? "#fff" : "#000", margin: "3px", }}>{row.remark ? row.remark : "N/A"}</div>,
      width: "10%",
    },

    {
      name: "Created At",
      selector: (row) => <div style={{ whiteSpace: 'normal', backgroundColor: Helper.ConvertUtcToLocalTime(row.createdat) === "" || Helper.ConvertUtcToLocalTime(row.createdat) === null ? "#f36e6f" : "", borderRadius: "10px", border: Helper.ConvertUtcToLocalTime(row.createdat) === "" || Helper.ConvertUtcToLocalTime(row.createdat) === null ? "2px solid transparent" : "2px solid transparent", padding: "3px", display: "flex", flexDirection: "row", color: Helper.ConvertUtcToLocalTime(row.createdat) === "" || Helper.ConvertUtcToLocalTime(row.createdat) === null ? "#fff" : "#000", margin: "3px", }}>{Helper.ConvertUtcToLocalTime(row.createdat) ? Helper.ConvertUtcToLocalTime(row.createdat) : "N/A"}</div>,
      width: "10%",
    },
    {
      name: "Signature",
      selector: (row) => row.signature != '' ? <img src={row.signature} alt="signature" width={50} height={50} /> : '',
      width: "10%",
    },
    {
      name: "Image",
      selector: (row) => row.image != '' ? <img src={"data:image/png;base64,"+row.image} alt="image" width={50} height={50} /> : '',
      width: "10%",
    },
  ];

  const handleEditShow = (row) => { };

  const handleDelete = (row) => { };

  const handleSelectChange = (selected) => {
    
    setSelectedUser(selected);
    setUserId(selected.value);
  };
  
  return (
    <Container fluid style={{ minHeight: "80vh" }}>
      <div
        className="glass-body"
        style={{
          margin: 10,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          padding: 0,
          backgroundColor: 'transparent'
        }}
      >

        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <ul id="breadcrumb">
            <li><a href="/"><IoHomeOutline
              style={{ color: "white", fontSize: 20 }}
              
            /></a></li>
            <li><a href="/dailycallrecord"><span class="icon icon-beaker"> </span>Reports</a></li>

            <li><a href="#"><span class="icon icon-double-angle-right"></span>DCR (Daily)</a></li>

          </ul>

        </div>

      </div>


      <div className="glass-body" style={{ margin: 10 }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-evenly",
            }}
            className="flex-column-mobile"
          >
            <div style={{ width: "250px",display:Op_per.get().REPORTS_DCR_DAILY.USER_DROPDOWN ? '' : 'none' }}>
              <Form.Group
                className="mb-3 d-flex w-100"
                style={{ gap: "15px" }}
                controlId="formBasicPassword"
              >
                <Form.Label className="mt-2">Users </Form.Label>
                <div className="w-75">
                  <Select
                    className="selectdoctor"
                    options={options}
                    value={selectedUser}
                    onChange={handleSelectChange}
                    styles={{
   
   dropdownIndicator: (provided) => ({
     ...provided,
     color: '#ccc' // Change color of the arrow here
   })
 }}
                  />
                </div>
              </Form.Group>
            </div>
            <div style={{ width: "250px" }}>
              <Form.Group
                className="mb-3 d-flex w-100"
                style={{ gap: "15px" }}
                controlId="formBasicPassword"
              >
                <Form.Label className="mt-2">Date</Form.Label>
                <div>
                  <DatePicker
                    clearIcon={null}
                    onChange={(date) => setDate(Helper.AdjustTimezone(date))}
                    value={date}
                    format="dd-MM-yy"
                  />
                </div>
              </Form.Group>
            </div>
            <div>
            <Button style={{ position: 'absolute', right: 40, backgroundColor: "#2194f0", display:Op_per.get().REPORTS_DCR_DAILY.ALLOW_EXPORT_XLSX ? '' : 'none' }} onClick={() => downloadCSV(dcr)} disabled={!(userid && date)}>
              <RiFileExcel2Fill />
            </Button>
            </div>
          </div>
        </div>
        {loading ? <div style={{ display: 'grid', placeContent: 'center' }}><LoadingComponent /></div> :
        <>
        <div className="pt-3">
          <h>Doctors</h>
          <DataTable
            columns={doctorColumns}
            data={dcr.doctors}
            pagination
            customStyles={{headRow: {style: {backgroundColor: '#c0dfff',  padding:10,borderRadius:'10px 10px 0 0' }, }  }}
                        CustomHeader={<CustomHeader/>}
            style={{ marginTop: 10 }}
            dense
          />
        </div>
        <div className="pt-3">
          <h>Clinics</h>
          <DataTable
            columns={clinicColumns}
            data={dcr.clinics}
            pagination
            customStyles={{headRow: {style: {backgroundColor: '#c0dfff',  padding:10,borderRadius:'10px 10px 0 0' }, }  }}
                        CustomHeader={<CustomHeader/>}
            style={{ marginTop: 10 }}
            dense
          />
        </div>
        <div className="pt-3">
          <h>Chemists</h>
          <DataTable
            columns={chemistColumns}
            data={dcr.chemists}
            pagination
            customStyles={{headRow: {style: {backgroundColor: '#c0dfff',  padding:10,borderRadius:'10px 10px 0 0' }, }  }}
                        CustomHeader={<CustomHeader/>}
            style={{ marginTop: 10 }}
            dense
          />
        </div>
        <div className="pt-3">
          <h>Stockists</h>
          <DataTable
            columns={stockistColumns}
            data={dcr.stockists}
            pagination
            customStyles={{headRow: {style: {backgroundColor: '#c0dfff',  padding:10,borderRadius:'10px 10px 0 0' }, }  }}
                        CustomHeader={<CustomHeader/>}
            style={{ marginTop: 10 }}
            dense
          />
        </div>
        <div className="pt-3">
          <h>Hospitals</h>
          <DataTable
            so
            columns={hospitalColumns}
            data={dcr.hospitals}
            pagination
            customStyles={{headRow: {style: {backgroundColor: '#c0dfff',  padding:10,borderRadius:'10px 10px 0 0' }, }  }}
                        CustomHeader={<CustomHeader/>}
            style={{ marginTop: 10 }}
            dense
          />
        </div></>
        }
      </div>
    </Container>
  );
}

export default Dcr;
