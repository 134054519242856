import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { CreateLead, CreateLeadProduct, GetAllIndustry, GetAllLeadSource, GetAllLeadStatus, GetAllSalutation, GetLeadById, UpdateLead, UpdateLeadAchStatus, getLeadProductByLeadId } from '../../../utils/proxy/LeadsProxy';
import { FaPhoneAlt, FaPlusSquare } from 'react-icons/fa';
import { MdEmail } from 'react-icons/md';
import Form from 'react-bootstrap/Form';
import { GetAllCitiesByState, GetAllCountries, GetAllStatesByCountry } from '../../../utils/proxy/Countryproxy';
import { AllUsers, GetAllManagerUsers } from '../../../utils/proxy/Authproxy';
import Swal from 'sweetalert2';
import TaskModel from '../../Common/TaskModel';
import { GetAllTaskByLead } from '../../../utils/proxy/Taskproxy';
import { Badge, Table } from 'react-bootstrap';
import { taskColor } from '../../../utils/constant';
import { GetAllProducts } from '../../../utils/proxy/ProductsProxy';
import Select from 'react-select';
import { CreateLeadLog, GetAllLeadLog, GetAllLeadLogById } from '../../../utils/proxy/LeadlogProxy';
import DataTable from 'react-data-table-component';
import LoadingComponent from '../../Includes/LoadingComponent';
import { QuoteModal } from './QuoteModal';
import { useNavigate } from 'react-router-dom';
import { Op_per } from '../../../utils/Permissions';
import { CreateAccount, GetAllAccountType, GetAllAccounts } from '../../../utils/proxy/AccountsProxy';
import ReactDOMServer from 'react-dom/server';
import { CreateCompanyContact, GetAllCompanyContacts, GetAllCompanyContactsByAccountId } from '../../../utils/proxy/CompanyContactProxy';


function LeadModal({ fullscreen, show, setShow, leadid, setReload, opttype }) {

  const [lead, setLead] = useState({ leadownerid: localStorage.getItem('role') === 'User' ? localStorage.getItem('uuid') : null });
  const [leadstatus, setLeadStatus] = useState([]);
  const [saluations, setSaluations] = useState([]);
  const [leadsources, setLeadSources] = useState([]);
  const [industries, setIndustries] = useState([]);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([])
  const [users, setUsers] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [products, setProducts] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState(localStorage.getItem('role') === 'User' ? { value: localStorage.getItem('uuid'), label: localStorage.getItem('firstname') + " " + localStorage.getItem('lastname') + " (Self)" } : null);
  const [selectedUserId, setSelectedUserId] = useState(localStorage.getItem('role') === 'User' ? localStorage.getItem('uuid') : null);

  const [selectedProducts, setSelectedProducts] = useState([]);
  const [productIds, setProductIds] = useState([]);

  const [pending, setPending] = useState(false);

  const [leadactivity, setLeadActivity] = useState();

  const [logtype,setLogtype] = useState("");

  const [startlog,setStartLog] = useState();
  const [endlog,setEndLog] = useState();

  const [showPr,setShowPr] = useState(false);

  const [acccountTypes, setAccountsTypes] = useState([]);
  const [accounts ,setAccount] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState({});

  const [showQuoteModal, setShowQuoteModal] = useState(false);
  const navigate = useNavigate();

  const [radio, setRadio] = useState('new');
  const [acct, setAcct] = useState([]);
  const [selectedAcct, setSelectedAcct] = useState();
  const [contact, setContact] = useState([]);
  const [selectedContact, setSelectedContact] = useState();

  function handleClosePr() {
    setShowPr(false);
  }

  async function AddLog() {

    if (startlog != "" && endlog != "") {
      let res = await CreateLeadLog(leadid,logtype,new Date(startlog).toISOString(),new Date(endlog).toISOString(),logtype+" Log Added");
      if(res)
        {
          Swal.fire({
            title: "Good job!",
            text: logtype +" log Added!",
            icon: "success"
          });
        }

        setStartLog();
    setEndLog();
    setLogtype("");
    }else{
      Swal.fire({
        title: "Error While Adding!",
        text: logtype +" log Not Added!",
        icon: "error"
      });
    }
    
  }

  useEffect(() => {
    const fetchData = async () => {
      const fetchFunctions = [getSaluations, getSources, getIndustries, getCountries, getStatus, getTasks, getProducts,getActivty,getAccountTypes];
      if (opttype === 'update') {
        fetchFunctions.unshift(getLeads);
      }
      await Promise.all(fetchFunctions.map((func) => func()));
    };
  
    fetchData();
  }, [leadid]);

  // useEffect(() => {
  //   if (opttype == 'update') {
  //     getLeads();
  //   }
  //   getSaluations();
  //   getSources();
  //   getIndustries();
  //   getCountries();
  //   getStatus();
  //   getTasks();
  //   getProducts();
  //   getActivty();
  // }, [leadid]);

  useEffect(() => {
    getStates();
  }, [lead.countryid]);

  useEffect(() => {
    getCities();
  }, [lead.stateid]);

  useEffect(() => {
    // Fetch data from an API when the component mounts
    const getallusers = async () => {
      let allusers;
      if (localStorage.getItem('role') === 'Admin') {
        allusers = await AllUsers();
      } else if (localStorage.getItem('role') === 'Manager') {
        allusers = await GetAllManagerUsers(localStorage.getItem('uuid'));
      }
      setUsers(allusers);
    };
    getallusers();
  }, []);

  useEffect(()=>{
    const getAccAndCont = async() =>{
      if (radio === 'existing') {
        let account = await GetAllAccounts();

        if (account) {
          setAcct(account);
        }else{
          Swal.fire({
            title:'Error',
            text:'Failed To Fetch Accounts',
            icon:'error'
          })
        }
      }
    }

    getAccAndCont();
  },[radio])

  useEffect(() => {
    const getallCompanyContacts = async () => {
        let d = await GetAllCompanyContactsByAccountId(selectedAcct?.value);
        if (d) {
            setContact(d);
        } else {
            alert('Error', 'Issues while fetching data')
        }
    }

    selectedAcct?.value && getallCompanyContacts()
  }, [selectedAcct])

  async function getLeads() {
    let [res, res2] = await Promise.all([GetLeadById(leadid), getLeadProductByLeadId(leadid)]);
    if (res) {
      setLead(res);
      res2 && res2.forEach(d => {
        setSelectedProducts(prev => [...prev, { value: d.productid, label: d.productname }])
        setProductIds(prev => [...prev, d.productid])
      })
    }
  }

  async function getTasks() {
    let res = await GetAllTaskByLead(leadid);
    if (res) {
      setTasks(res);
    }
  }

  async function getSaluations() {
    let res = await GetAllSalutation();
    if (res) {
      setSaluations(res);
    }
  }

  useEffect(() => {
    getActivty();
  }, [leadid]);

  async function getActivty() {
    setPending(true);
    let res = await GetAllLeadLogById(parseInt(leadid));

    if (res) {
      setLeadActivity(res);
    }
    setPending(false);
  }

  async function getStatus() {
    let res = await GetAllLeadStatus();
    if (res) {
      setLeadStatus(res);

    }
  }

  async function getProducts() {
    let res = await GetAllProducts();
    if (res) {
      setProducts(res);

    }
  }

  async function getSources() {
    let res = await GetAllLeadSource();
    if (res) {
      setLeadSources(res);

    }
  }

  async function getIndustries() {
    let res = await GetAllIndustry();
    if (res) {
      setIndustries(res);

    }
  }


  async function getCountries() {
    let res = await GetAllCountries();
    if (res) {
      setCountries(res);

    }
  }

  async function getStates() {
    let res = await GetAllStatesByCountry(lead.countryid);
    console.log('states ', res);
    if (res) {
      setStates(res);

    }
  }


  async function getCities() {
    let res = await GetAllCitiesByState(lead.stateid);
    if (res) {
      setCities(res);

    }
  }


  function changesaluation(params) {
    setLead(prevLead => ({
      ...prevLead,
      salutationid: params
    }));
  }

  function changeleadsource(params) {
    setLead(prevLead => ({
      ...prevLead,
      leadsourceid: params
    }));
  }

  function changeIndustry(params) {
    setLead(prevLead => ({
      ...prevLead,
      industryid: params
    }));
  }

  function changeCountry(params) {
    setLead(prevLead => ({
      ...prevLead,
      countryid: params
    }));
  }

  function changeState(params) {
    setLead(prevLead => ({
      ...prevLead,
      stateid: params
    }));
  }

  function changeCity(params) {
    setLead(prevLead => ({
      ...prevLead,
      cityid: params
    }));
  }

  function changeOwner(params) {
    setLead(prevLead => ({
      ...prevLead,
      leadownerid: params
    }));
  }

  function changeStatus(params) {
    setLead(prevLead => ({
      ...prevLead,
      statusid: params
    }));
  }

  function changeProduct(params) {
    setLead(prevLead => ({
      ...prevLead,
      productid: params
    }));
  }
  async function updateleadStatus(status) {
    let res = await UpdateLeadAchStatus(lead.id, status);
    if (res.status === 'success') {
      const title = status === 'WON' ? "Congratulation 🥳" : "Lead Lost";
      const text = status === 'WON' ? "Congrats On Winning The Lead" : "Lead Has Set To be Lost";
      Swal.fire({
        title,
        text,
        icon: "success"
      });
      setReload(true);
    }
  }


  async function updateLead() {
    let res;
    let res2;
    if (opttype === "update") {
      [res, res2] = await Promise.all([UpdateLead(lead.id, lead), CreateLeadProduct(productIds, lead.id)]);
    } else {
      res = await CreateLead(radio === 'new' ? lead : {'salutationid':selectedContact.salutationid,'firstname':selectedContact.firstname,'lastname':selectedContact.lastname,'company':selectedAcct.accountname,'typeid':selectedAcct.typeid,'title':selectedContact.title,'website':selectedAcct.website,'description':selectedAcct.description,'leadownerid':localStorage.getItem('uuid'),'phone':selectedContact.phone,'email':selectedContact.email,'street':selectedContact.mailing_address_street,'zip':selectedContact.mailing_address_zip,'cityid':selectedContact.mailing_address_cityid,'stateid':selectedContact.mailing_address_stateid,'countryid':selectedContact.mailing_address_countryid,'totalemployees':selectedAcct.employees,'annualrevenue':selectedAcct.revenue,'industryid':selectedAcct.industryid,'taxid':selectedAcct.taxid});
    }

    if (res.status === 'success') {
      Swal.fire({
        title: opttype === "update" ? "Updated!" : "Created",
        text: opttype === "update" ? "Lead Has Been Updated Successfully" : "Lead Has Been Created Successfully",
        icon: "success"
      });
      setShow(false);
      setReload(true);
    }
  }

  const handleAccountchange = (selected) =>{
    setSelectedAcct(selected);
    setSelectedContact(null);
  }

  const handleContactchange = (selected) =>{
    setSelectedContact(selected);
  }

  const accountsOption = [];
  const contactsOption = [];

  acct.map((project) => {
    accountsOption.push({
        ...project,
        value: project.id,
        label: project.accountname
    })
  })

  contact.map((project) => {
    contactsOption.push({
        ...project,
        value: project.id,
        label: project.firstname + " " + project.lastname
    })
  })

  async function getAccountTypes() {
    let res = await GetAllAccountType();
    if (res) {
        setAccountsTypes(res);

    }
}

  function closeModal() {
    setLead({});
    setShow(false)
  }

  function handleNavigateQuote() {
    navigate('/quotecr',{ state: { selectedproductsProp: selectedProducts, leadDataProp : {'leadid':lead.id,'salutationid':lead.salutationid,'firstname':lead.firstname,'lastname':lead.lastname,
    'company':lead.company,'title':lead.title,'website':lead.website,'quoteownerid':lead.leadownerid,'phone':lead.phone,'email':lead.email,'street':lead.street,'cityid':lead.cityid,
    'stateid':lead.stateid,'countryid':lead.countryid,'totalemployees':lead.totalemployees,'annualrevenue':lead.annualrevenue,'leadsourceid':lead.leadsourceid,'industryid':lead.industryid},
   accountDataProp:{...selectedAccount}} })
  }

  const handleQuoteModal = () =>{
    Swal.fire({
      title: "Select Account Before Proceeding",
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: "Create Account With Current Data",
      denyButtonText: `Use Existing Account`
    }).then(async(result) => {
      if (result.isConfirmed) {
        let l = await CreateAccount({...lead,"accountname":lead.company,"accountownerid":lead.leadownerid,"employees":lead.totalemployees,"revenue":lead.annualrevenue,"billing_address_street":lead.street,"billing_address_cityid":lead.cityid,"billing_address_zip":lead.zip,"billing_address_stateid":lead.stateid,"billing_address_countryid":lead.countryid,"shipping_address_street":lead.street,"shipping_address_cityid":lead.cityid,"shipping_address_zip":lead.zip,"shipping_address_stateid":lead.stateid,"shipping_address_countryid":lead.countryid});
        let c = await CreateCompanyContact({"accountid":l.message.split('-')[1],"salutationid":lead.salutationid,"firstname":lead.firstname,"lastname":lead.lastname,"title":lead.title,"email":lead.email,"phone":lead.phone,"mailing_address_street":lead.street,"mailing_address_cityid":lead.cityid,"mailing_address_zip":lead.zip,"mailing_address_stateid":lead.stateid,"mailing_address_countryid":lead.countryid});
        if(l && c){
          navigate('/quotecr',{ state: { selectedproductsProp: selectedProducts, leadDataProp : {'leadid':lead.id,'salutationid':lead.salutationid,'firstname':lead.firstname,'lastname':lead.lastname,
    'company':lead.company,'title':lead.title,'website':lead.website,'quoteownerid':lead.leadownerid,'phone':lead.phone,'email':lead.email,'street':lead.street,'cityid':lead.cityid,
    'stateid':lead.stateid,'countryid':lead.countryid,'totalemployees':lead.totalemployees,'annualrevenue':lead.annualrevenue,'leadsourceid':lead.leadsourceid,'industryid':lead.industryid
  } } }) 
        }else{
          Swal.fire({
            title:'Warning',
            text:'Unable to Create Account',
            icon:'warning'
          })
        }
      } else if (result.isDenied) {
        let d = await GetAllAccounts();
        if (d){
          setAccount(d);
          setShowPr(true);
          
      //   Swal.fire({
      //     title: "Select Account",
      //     html: ReactDOMServer.renderToStaticMarkup(<Form.Group className="mb-3" controlId="formBasicEmail">
      //     <Form.Label>Account</Form.Label>
      //     <Form.Select className='selectdoctor' aria-label="Default select example">
      //         <option value={''}>--Select Account--</option>
      //         {d.map(saluation => (
      //             <option value={saluation.id}>{saluation.accountname}</option>
      //         ))}
      //     </Form.Select>
      // </Form.Group>)
      //     ,
      //     showCloseButton: true,
      //     showCancelButton: true,
      //     focusConfirm: false,
      //     didOpen: () => {
      //       // If needed, manipulate the DOM or re-focus on the form field
      //       const selectElement = document.querySelector('.selectdoctor');
      //       if (selectElement) {
      //         selectElement.addEventListener('click', () => {
      //           // Re-focus or re-initialize the dropdown if required
      //           selectElement.focus();
      //         });
      //       }
      //     },
      //   });
      }
    }
    });
  }

  const productOptions = [];

  products.map((area) => {
    productOptions.push({ value: area.id, label: area.name });
  });

  const handleProductChange = (selected) => {
    const values = selected.map((option) => option.value);
    
    setSelectedProducts(selected);
    setProductIds(values);
  }

  const columns = [
    {
      name: "Operation",
      selector: (row) => row.opttype,
      sortable: true
    },
    {
      name: "Start",
      selector: (row) => new Date(row.start).toLocaleString(),
      sortable: true
    },
    {
      name: "End",
      selector: (row) => new Date(row.end).toLocaleString(),
      sortable: true
    }
    ,
    {
      name: "Description",
      selector: (row) => row.description,
      sortable: true
    }
  ]

  return (
    <>
    <Modal show={show} fullscreen={fullscreen} onHide={() => closeModal()} >
      <Modal.Header closeButton>
        <Modal.Title><img src={process.env.PUBLIC_URL + "/images/nav2.png"} alt="Team Insights" width={200} /></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="flex-column-mobile">

          <Col md={opttype === "update" ? 8 : 12}>
            <Card style={{ height: "100%" }}>
              <div style={{ padding: 10 }}>
                <ul className="steps">
                  {leadstatus.map((st, index) => (
                    <>
                      <li className={"step" + (st.id == lead.statusid ? " active" : "")}>{st.name}</li>
                      {index !== leadstatus.length - 1 && <li className="line"></li>}
                    </>
                  ))}

                  {/* <li class="step">2</li>
    <li class="line"></li>
    <li class="step">3</li>
    <li class="line"></li>
    <li class="step active">4</li>
    <li class="line"></li>
    <li class="step">5</li> */}
                </ul>
              </div>
              <Card.Body >
                {opttype == "update" ? <>
                  <Card.Title><div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <div>
                      {lead.salutation} {lead.firstname} {lead.lastname} ({lead.company})
                    </div>
                    <div>
                      <Button onClick={() => window.open("tel:" + lead.phone, "_blank")} size="sm" style={{ marginLeft: "10px" }}><FaPhoneAlt /></Button >
                      <Button onClick={() => window.open("mailto:" + lead.email, "_blank")} size="sm" style={{ marginLeft: "10px" }}><MdEmail /></Button >
                      {Op_per.get().QUOTE_MASTER.CREATE_QUOTE && <Button onClick={() => handleQuoteModal()} size="sm" style={{ marginLeft: "10px" }}>Create Quote</Button >}
                    </div>
                  </div></Card.Title>
                  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>

                    <div><p>Company : {lead.company}<br />Title: {lead.title} <br />Email : {lead.email}<br />Phone : {lead.phone}</p></div>

                    <div>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Lead Status</Form.Label>
                        <Form.Select className='selectdoctor' value={lead.statusid} onChange={(e) => changeStatus(e.target.value)} aria-label="Default select example">
                          <option value={''}>--Select Status--</option>
                          {leadstatus.map(saluation => (
                            <option value={saluation.id}>{saluation.name}</option>
                          ))}


                        </Form.Select>
                      </Form.Group>
                    </div>
                    <div style={{ width: 'calc(100% - 445px)' }}>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Product</Form.Label>
                        <Select
                          className="selectdoctor"
                          closeMenuOnSelect={false}
                          options={productOptions}
                          value={selectedProducts}
                          onChange={handleProductChange}
                          isMulti
                          styles={{

                            dropdownIndicator: (provided) => ({
                              ...provided,
                              color: '#ccc' // Change color of the arrow here
                            })
                          }}
                        />
                      </Form.Group>
                    </div>
                  </div></> : ""}
                <h4>Details</h4>

                {opttype === 'create' && <div className='m-2 p-2'>
                    <Form.Check className="d-inline mr-5" name="group1" checked={radio === 'new'} id='inline-radio-1' type="radio" label="New" onChange={e => setRadio('new')} />
                    <Form.Check className="d-inline mr-5" name="group1" checked={radio === 'existing'} id='inline-radio-2' type="radio" label="Existing" onChange={e => setRadio('existing')} />
                </div>}
                
                <Form>
                  {radio === 'new' ? <><Row className="flex-column-mobile">
                    <Col>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Saluation</Form.Label>
                        <Form.Select className='selectdoctor' value={lead.salutationid} onChange={(e) => changesaluation(e.target.value)} aria-label="Default select example">
                          <option value={''}>--Select Saluation--</option>
                          {saluations.map(saluation => (
                            <option value={saluation.id}>{saluation.name}</option>
                          ))}


                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>First Name</Form.Label>
                        <Form.Control className='selectdoctor' onChange={(e) => setLead(prevLead => ({ ...prevLead, firstname: e.target.value }))} value={lead.firstname} type="text" placeholder="John" />
                      </Form.Group>
                    </Col>

                    <Col>
                      <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control className='selectdoctor' onChange={(e) => setLead(prevLead => ({ ...prevLead, lastname: e.target.value }))} value={lead.lastname} type="text" placeholder="Doe" />
                      </Form.Group>
                    </Col>

                    <Col>
                      <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label>Email</Form.Label>
                        <Form.Control className='selectdoctor' onChange={(e) => setLead(prevLead => ({ ...prevLead, email: e.target.value }))} value={lead.email} type="email" placeholder="john.doe@domain.com" />
                      </Form.Group>
                    </Col>



                  </Row>

                  <Row className="flex-column-mobile">
                    <Col>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Phone</Form.Label>
                        <Form.Control className='selectdoctor' onChange={(e) => setLead(prevLead => ({ ...prevLead, phone: e.target.value }))} value={lead.phone} type="text" placeholder="+91 0000000000" />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Title</Form.Label>
                        <Form.Control className='selectdoctor' onChange={(e) => setLead(prevLead => ({ ...prevLead, title: e.target.value }))} value={lead.title} type="text" placeholder="Senior Executive" />
                      </Form.Group>
                    </Col>

                    <Col>
                      <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label>Company</Form.Label>
                        <Form.Control className='selectdoctor' onChange={(e) => setLead(prevLead => ({ ...prevLead, company: e.target.value }))} value={lead.company} type="text" placeholder="Acme Pvt Ltd" />
                      </Form.Group>
                    </Col>

                    <Col>
                      <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label>Website</Form.Label>
                        <Form.Control className='selectdoctor' onChange={(e) => setLead(prevLead => ({ ...prevLead, website: e.target.value }))} value={lead.website} type="text" placeholder="www.example.com" />
                      </Form.Group>
                    </Col>



                  </Row>
                  <Row className="flex-column-mobile">
                  <Col>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Tax Id</Form.Label>
                        <Form.Control className='selectdoctor' onChange={(e) => setLead(prevLead => ({ ...prevLead, taxid: e.target.value }))} value={lead.taxid} type="text" placeholder="Enter Tax id" />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Total Employees</Form.Label>
                        <Form.Control className='selectdoctor' onChange={(e) => setLead(prevLead => ({ ...prevLead, totalemployees: e.target.value }))} value={lead.totalemployees} type="number" placeholder="10000" />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Lead Revenue</Form.Label>
                        <Form.Control className='selectdoctor' onChange={(e) => setLead(prevLead => ({ ...prevLead, annualrevenue: e.target.value }))} value={lead.annualrevenue} type="number" placeholder="Revenue Amount" />
                      </Form.Group>
                    </Col>

                    <Col>
                      <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label>Lead Source</Form.Label>
                        <Form.Select className='selectdoctor' value={lead.leadsourceid} onChange={(e) => changeleadsource(e.target.value)} aria-label="Default select example">
                          <option value={''}>--Select Source--</option>
                          {leadsources.map(saluation => (
                            <option value={saluation.id}>{saluation.name}</option>
                          ))}


                        </Form.Select>
                      </Form.Group>
                    </Col>

                    <Col>
                      <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label>Industry</Form.Label>
                        <Form.Select className='selectdoctor' value={lead.industryid} onChange={(e) => changeIndustry(e.target.value)} aria-label="Default select example">
                          <option value={''}>--Select Industry--</option>
                          {industries.map(saluation => (
                            <option value={saluation.id}>{saluation.name}</option>
                          ))}


                        </Form.Select>
                      </Form.Group>
                    </Col>



                  </Row>
                  <hr />
                  <h4>Location:</h4>
                  <Row className="flex-column-mobile">
                    <Col>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Country</Form.Label>
                        <Form.Select className='selectdoctor' value={lead.countryid} onChange={(e) => changeCountry(e.target.value)} aria-label="Default select example">
                          <option value={''}>--Select Country--</option>
                          {countries.map(saluation => (
                            <option value={saluation.id}>{saluation.name}</option>
                          ))}


                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>State</Form.Label>
                        <Form.Select className='selectdoctor' value={lead.stateid} onChange={(e) => changeState(e.target.value)} aria-label="Default select example">
                          <option value={''}>--Select State--</option>
                          {states.map(saluation => (
                            <option value={saluation.id}>{saluation.name}</option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                    </Col>

                    <Col>
                      <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label>City</Form.Label>
                        <Form.Select className='selectdoctor' value={lead.cityid} onChange={(e) => changeCity(e.target.value)} aria-label="Default select example">
                          <option value={''}>--Select City--</option>
                          {cities.map(saluation => (
                            <option value={saluation.id}>{saluation.city}</option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="flex-column-mobile">
                    <Col>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Street</Form.Label>
                        <Form.Control className='selectdoctor' onChange={(e) => setLead(prevLead => ({ ...prevLead, street: e.target.value }))} value={lead.street} type="text" placeholder="ABC Banquite Street, Opp Benjiman Hall" />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Zip</Form.Label>
                        <Form.Control className='selectdoctor' onChange={(e) => setLead(prevLead => ({ ...prevLead, zip: e.target.value }))} value={lead.zip} type="text" placeholder="000000" />
                      </Form.Group>
                    </Col>

                  </Row>

                  <hr />
                  <Row className="flex-column-mobile">
                  <Col>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Account Type</Form.Label>
                                                <Form.Select className='selectdoctor' value={lead.typeid} onChange={(e) => setLead(prevLead => ({ ...prevLead, typeid: e.target.value }))} aria-label="Default select example">
                                                    <option value={''}>--Select Type--</option>
                                                    {acccountTypes.map(saluation => (
                                                        <option value={saluation.id}>{saluation.name}</option>
                                                    ))}
                                                </Form.Select>
                                            </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Description</Form.Label>
                        <Form.Control className='selectdoctor' onChange={(e) => setLead(prevLead => ({ ...prevLead, description: e.target.value }))} value={lead.description} type="text" placeholder="" />
                      </Form.Group>
                    </Col>

                  </Row>
                  {!(localStorage.getItem('role') === 'User') && <Row className="flex-column-mobile">
                    <Col>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Lead Owner</Form.Label>
                        <Form.Select className='selectdoctor' value={lead.leadownerid} onChange={(e) => changeOwner(e.target.value)} aria-label="Default select example">
                          <option value={''}>--Select Owner--</option>
                          {users.map(saluation => (
                            <option value={saluation.userid}>{saluation.firstname} {saluation.lastname} ({saluation.username})</option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                    </Col>

                  </Row>}</> : 
                  <Row className="flex-column-mobile">
                    <Col>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Account</Form.Label>
                        <Select
                                    className='selectdoctor'
                                    options={accountsOption}
                                    value={selectedAcct}
                                    onChange={handleAccountchange}
                                    placeholder='Select...'
                                />
                      </Form.Group>
                    </Col>

                    <Col>
                      <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label>Contact Name</Form.Label>
                        <Select
                                    className='selectdoctor'
                                    options={contactsOption}
                                    value={selectedContact}
                                    onChange={handleContactchange}
                                    placeholder='Select...'
                                />
                      </Form.Group>
                    </Col>
                  </Row>
                  }



                  <Button onClick={() => updateLead()} variant="primary" type="button">
                    {opttype === "update" ? "Update Lead" : "Create Lead"}
                  </Button>
                </Form>
              </Card.Body>
            </Card>
          </Col>
          {opttype === "update" ? <Col md={4} >
            <Card >
              <Card.Body style={{ overflowX: 'auto' }}>
                <Card.Title><div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                  <div>
                    Quick Actions
                  </div>
                  <div>

                  </div>
                </div></Card.Title>
                <Card.Text>
                  <div>
                    <div className="d-grid gap-2">
                      <Button onClick={() => updateleadStatus("WON")} variant="success" size="lg">
                        Lead Won 🥳
                      </Button>
                      <Button onClick={() => updateleadStatus("LOST")} variant="danger" size="lg">
                        Lead Lost 🥺
                      </Button>
                    </div>
                    <div className="mt-2" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                      <Button variant='outline-primary' onClick={() => setLogtype("Call")}>Log Call 📞</Button>
                      <Button variant='outline-primary' onClick={() => setLogtype("Email")}>Log Email 📨</Button>
                      <Button variant='outline-primary' onClick={() => setLogtype("Meeting")}>Log Meeting 🤝</Button>

                    </div>
                   {logtype && <div>
                      <Form className='mt-1'>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                          <Form.Label> {logtype} Start Time</Form.Label>
                          <Form.Control onChange={(e) => setStartLog(e.target.value)} type="datetime-local" />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                          <Form.Label>{logtype} End Time</Form.Label>
                          <Form.Control onChange={(e) => setEndLog(e.target.value)} type="datetime-local" />
                        </Form.Group>
                        <div className='d-flex justify-content-between'>
                        <Button variant="primary" onClick={() => AddLog()}>Add Log</Button>
                        <Button variant="danger" onClick={() => setLogtype("")}>Cancel</Button>
                        </div>
                      </Form>
                    </div>}

                  </div>
                </Card.Text>
              </Card.Body>
            </Card>

            <Card>
              <Card.Body style={{ overflowX: 'auto' }}>
                <Card.Title><div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                  <div>
                    Tasks For Lead
                  </div>
                  <div>
                    {/* <Button onClick={() => window.open("tel:" + lead.phone, "_blank")} size="sm" style={{ marginLeft: "10px" }}></Button > */}
                    {lead ? <TaskModel buttontext={<FaPlusSquare />} buttonclass={""} leadid={leadid} /> : ""}

                  </div>
                </div></Card.Title>
                <Card.Text>
                  <div>

                    <Table hover className="mt-3 table table-bordered">
                      <thead>
                        <tr>
                          <th>Created On</th>
                          <th>Task</th>
                          <th>Status</th>

                        </tr>
                      </thead>
                      <tbody>
                        {tasks.map((task) => (
                          <tr>
                            <td>{task.createdat.split("T")[0]}</td>
                            <td>{task.task}</td>
                            <td><badge className="badge" style={{ color: "#000", backgroundColor: task.status === 'new' ? taskColor.newbackground : task.status === 'inprogress' ? taskColor.inprogressbackground : task.status === 'completed' ? taskColor.completedbackground : "" }}>{task.status}</badge></td>
                          </tr>
                        ))}



                      </tbody>
                    </Table>
                  </div>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col> : ""}
        </Row>
        {opttype === "update" && <Row className='mt-3'>
          <Col>
            <Card >
              <Card.Body style={{ overflowX: 'auto' }}>
                <Card.Title><div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                  <div>
                    Lead Activity 📈
                  </div>
                  <div>

                  </div>
                </div></Card.Title>
                <Card.Text>
                  <div>
                    <DataTable
                      columns={columns}
                      data={leadactivity}
                      pagination
                      customStyles={{ headRow: { style: { backgroundColor: '#c0dfff', padding: 7 }, } }}
                      style={{ margin: '10px', padding: 10 }}
                      progressPending={pending}
                      progressComponent={<LoadingComponent />}
                      dense
                    />

                  </div>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>}
      </Modal.Body>
      {/* <ConvertLeadModal show={false}/> */}
      {/* <QuoteModal 
        show={showQuoteModal} 
        setShow={setShowQuoteModal} 
        operation='Create' 
      /> */}
    </Modal>
    <Modal show={showPr} onHide={handleClosePr} animation={false} style={{backgroundColor:'#0000008f'}}>
        {/* <Modal.Header closeButton>
          <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header> */}
        <Modal.Body>
          <h4>Select Account To Proceed</h4>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Account</Form.Label>
          <Form.Select className='selectdoctor' aria-label="Default select example" onChange={(e) => setSelectedAccount(JSON.parse(e.target.value))}>
              <option value={''}>--Select Account--</option>
              {accounts.map(saluation => (
                  <option value={JSON.stringify(saluation)}>{saluation.accountname}</option>
              ))}
          </Form.Select>
      </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleNavigateQuote}>
            Continue
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default LeadModal