import React from 'react';
import { useEffect } from 'react';
import Chart from 'react-apexcharts';
import { GetUserTypes } from '../../utils/proxy/DashboardProxy';
import { useState } from 'react';
import { Row } from 'react-bootstrap';
import { VscGraph } from "react-icons/vsc";

const InternalExternalChart = () => {

    const [userCount, setUserCount] = useState([]);
    const [types, setTypes] = useState([]);

    useEffect(()=>{
        const getUserTypes = async() =>{
            let d = await GetUserTypes();
            let usercount = [];
            let types = [];
            d && d.map((data)=>{
                usercount.push(data.usercount);
                types.push(data.type);
            })
            setUserCount(usercount);
            setTypes(types);
        }
        getUserTypes();
    },[])

  const chartOptions = {
    chart: {
        id: 'bar-chart',
        stacked: true,
        // marginTop:10,
        offsetY:4,
    },
    colors: ['#38419D', '#200E3A'],
    xaxis: {
        categories: types,
        labels: {
            style: {
                fontSize: '10px', // Change the font size here
            },
        },
    },
    plotOptions: {
        bar: {
            horizontal: true,
        },
        dataLabels: {
            position: 'top', // Set the position of the data labels to display above the bars
            style: {
                fontSize: '12px', // Change the font size of the data labels
                colors: ['#ff0000'], // Change the color of the labels if needed (for data points)
            },
        },
    },
    stroke: {
        show: true,
        width: 1,
        colors: ['#fff'],
    },
    // title: {
    //     text: 'Internal/External User Summary',
    //     style:{
    //         fontSize:'13px',
    //     }
    // },
    legend:{
        show:true,
        
        position:'bottom', // to set series position above chart or below chart
    }
  };
  
  const series = [
    {
        name: 'External',
        data: [userCount[0],0],
    },
    {
        name: 'Internal',
        data:[0,userCount[1]],
    },
];

  return (

    <>

      <div style={{backgroundColor:'white',padding:10}}>
      <h6 style={{textAlign:'center', marginBottom:10}}>Internal / External User</h6>
      <Chart options={chartOptions} series={series} type="bar" width='100%' height={201} />
    </div>
    </>


  
   
  );
};

export default InternalExternalChart;
