import React, { useEffect, useState } from "react";
import { Button, Container, OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import {
  GetAllCountries,
  GetAllCitiesDetail,
  GetAllAreasDetail,
  GetAllStatesDetail,
} from "../../utils/proxy/Countryproxy";
import {
  GetAllHospitalss,
  CreateHospitals,
  UpdateHospitals,
  CheckHospitalsAvailable,
  CreateExcelHospitals,
  DeleteHospitals,
  HospitalBulkUpdateArea,
  BulkDeleteHospitals,
  GetAllHospitalssPagination,
  UpdateHospitalsLat,
} from "../../utils/proxy/Hospitalsproxy";
import Badge from "react-bootstrap/Badge";
import Modal from "react-bootstrap/Modal";
import Swal from "sweetalert2";
import DataTable from "react-data-table-component";
import Select from "react-select";
import * as XLSX from "xlsx";
import { AiFillDelete, AiTwotoneEdit } from "react-icons/ai";
import Dropdown from "react-bootstrap/Dropdown";
import { Parser } from "@json2csv/plainjs";
import { unwind, flatten } from "@json2csv/transforms";
import Helper from "../../utils/helperFunctions";
import { AllUsers } from "../../utils/proxy/Authproxy";
import { GetAllCips } from "../../utils/proxy/Cipproxy";
import LoadingComponent from "../Includes/LoadingComponent";
import { HiDotsVertical } from "react-icons/hi";
import { SiDatabricks } from "react-icons/si";
import { IoHomeOutline } from "react-icons/io5";
import { Op_per } from "../../utils/Permissions";
import { FaMapMarked } from "react-icons/fa";
import { LuMapPinOff } from "react-icons/lu";


function HospitalsMaster() {
  const [hospitalss, setHospitalss] = useState([]);
  const [areas, setAreas] = useState([]);
  const [citys, setCitys] = useState([]);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [hospitalsname, setHospitalsName] = useState([]);
  const [selectedAreas, setSelectedAreas] = useState([]);
  const [areasid, setAreasid] = useState([]);
  const [hospitalsaddress, setHospitalsAddress] = useState("");
  const [country, setCountry] = useState();
  const [state, setState] = useState();
  const [city, setCity] = useState();
  const [pincode, setPincode] = useState();
  const [number, setNumber] = useState();
  const [email, setEmail] = useState();
  const [hospitalsid, setHospitalsId] = useState();
  const [users, setUsers] = useState([]);

  const [pending, setPending] = React.useState(true);

  const [show, setShow] = useState(false);
  const [showuploader, setShowUploader] = useState(false);
  const [showedit, setShowEdit] = useState(false);
  const [ishospitalsname, setIsHospitalsName] = useState();
  const [reload, SetReload] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [showbulkareaedit, setShowBulkAreaEdit] = useState(false);
  const [bulkSourceData, setBulkSourceData] = useState([]);
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [toggledClearRows, setToggleClearRows] = React.useState(false);

  const [editdata, setEditdata] = useState();

  const [cips, setCips] = useState([]);
  const [selectedcips, setSelectedCips] = useState([]);
  const [cipid, setCipId] = useState([]);

  const [perPage, setPerage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [totalPages, setTotalPages] = useState();
  const [currentPage, setCurrentPage] = useState();

  const [excelData, setExcelData] = useState([]);
  const [columnTitles, setColumnTitles] = useState({
    title1: excelData.length > 0 ? Object.keys(excelData[0])[0] : "",
    title2: excelData.length > 0 ? Object.keys(excelData[0])[1] : "",
    title3: excelData.length > 0 ? Object.keys(excelData[0])[2] : "",
    title4: excelData.length > 0 ? Object.keys(excelData[0])[3] : "",
    title5: excelData.length > 0 ? Object.keys(excelData[0])[4] : "",
  });
  const [fileUploaded, setFileUploaded] = useState(false);

  // const filteredData = hospitalss.filter((row) => {
  //   if (row.hospitalsname.toLowerCase().includes(searchQuery.toLowerCase())) {
  //     return true;
  //   }

  //   // If there are users associated with the doctor, loop through them.
  //   if (row.users && row.users.length > 0) {
  //     for (let user of row.users) {
  //       if (user.username.toLowerCase().includes(searchQuery.toLowerCase())) {
  //         return true;
  //       }
  //     }
  //   }

  //   return false;
  // }
  // );

  const handleClearRows = () => {
    setToggleClearRows(!toggledClearRows);
  }

  const options = [];

  areas.map((area) => {
    options.push({ value: area.id, label: area.areaname });
  });

  const getallhospitalss = async (page) => {
    let hospitalssdata = await GetAllHospitalssPagination(page, perPage, searchQuery);
    if (hospitalssdata) {
      setHospitalss(hospitalssdata[0].hospitals);
      setTotalRows(hospitalssdata[0].totalrows);
      setCurrentPage(hospitalssdata[0].currentpage);
      setTotalPages(hospitalssdata[0].totalpages);
      setPending(false);
    } else {
      alert("Error", "Issue While Fetching Hospitals");
    }
  };

  useEffect(() => {
    // Fetch data from an API when the component mounts
    getallhospitalss(currentPage == undefined ? 0 : currentPage);

    SetReload(false);

  }, [reload, searchQuery]);

  const handlePageChange = page => {

    getallhospitalss(page - 1);

  }

  const handlePerRowChange = async (newPerPage, page) => {
    setPerage(newPerPage);
    const areasdata = await GetAllHospitalssPagination(page - 1, newPerPage, searchQuery);
    if (areasdata) {
      setHospitalss(areasdata[0].hospitals);
      setTotalRows(areasdata[0].totalrows)
      setTotalPages(areasdata[0].totalpages);
      setPending(false);
    } else {
      alert("Error", "Issue While Fetching Doctors");
    };
  }

  useEffect(() => {
    // Fetch data from an API when the component mounts
    const getallusers = async () => {
      let allusers = await AllUsers();
      if (allusers) {
        setUsers(allusers);
      } else {
        alert("Error", "Issue While Fetching Users");
      }
    };
    getallusers();
    const getallcips = async () => {
      let cipsdata = await GetAllCips();
      if (cipsdata) {
        setCips(cipsdata);
      } else {
        alert("Error", "Issue While Fetching CIP's");
      }
    };
    getallcips();
  }, []);

  const userOptions = [];

  users.map((user) => {
    userOptions.push({
      value: user.userid,
      label: user.username + "(" + user.firstname + " " + user.lastname + ")",
    });
  });

  const cipoptions = [];
  cips.map((cip) => {
    cipoptions.push({ value: cip.id, label: cip.cipname });
  });

  useEffect(() => {
    // Fetch data from an API when the component mounts
    const getallcitys = async () => {
      let citysdata = await GetAllCitiesDetail();
      if (citysdata) {
        setCitys(citysdata);
      } else {
        alert("Error", "Issue While Fetching City");
      }
    };
    getallcitys();

    const getallareas = async () => {
      let areas = await GetAllAreasDetail();
      if (areas) {
        setAreas(areas);
      } else {
        alert("Error", "Issue While Fetching Teritories");
      }
    };
    getallareas();

    const getallcountries = async () => {
      let countriesdata = await GetAllCountries();
      if (countriesdata) {
        setCountries(countriesdata);
      } else {
        alert("Error", "Issue While Fetching Countries");
      }
    };
    getallcountries();

    const getallstates = async () => {
      let statesdata = await GetAllStatesDetail();
      if (statesdata) {
        setStates(statesdata);
      } else {
        alert("Error", "Issue While Fetching State");
      }
    };
    getallstates();
  }, [show]);

  useEffect(() => {
    const checkHospitalsNameAvailable = async (hospitalsname) => {
      if (hospitalsname.length > 0) {
        let statedata = await CheckHospitalsAvailable(hospitalsname);
        if (statedata) {
          setIsHospitalsName(true);
        } else {
          setIsHospitalsName(false);
        }
      }
    };
    checkHospitalsNameAvailable(hospitalsname);
  }, [hospitalsname]);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setIsHospitalsName(false);
    setShow(true);
  };

  const handleUploaderShow = () => setShowUploader(true);
  const handleUploaderClose = () => {
    setExcelData([]);
    setColumnTitles({
      title1: "",
      title2: "",
      title3: "",
    });
    setFileUploaded(false);
    setShowUploader(false);
  };

  const handleEditShow = (row) => {
    setHospitalsName(row.hospitalsname);
    let ar = [];
    let arid = [];
    row.areas.map((area) => {
      ar.push({ value: area.id, label: area.areaname });
      arid.push(area.id);
      return true;
    });
    setSelectedAreas(ar);
    setAreasid(arid);
    setHospitalsAddress(row.address);
    setState(row.stateid);
    setCity(row.cityid);
    setPincode(row.pincode);
    setCountry(row.countryid);
    setNumber(row.phone);
    setEmail(row.email);
    setHospitalsId(row.id);
    setShowEdit(true);
  };
  const handleEditClose = () => {
    setHospitalsName("");
    setSelectedAreas([]);
    setAreasid([]);
    setHospitalsAddress("");
    setState("");
    setCity("");
    setPincode("");
    setCountry("");
    setNumber("");
    setEmail("");
    setShowEdit(false);
  };

  const handleCountryCodeChange = (event) => {
    setCountry(event.target.value);
  };

  const handleHospitalsNameChange = (event) => {
    setHospitalsName(event.target.value);
  };

  const handleSelectChange = (selected) => {
    const values = selected.map((option) => option.value);
    
    setSelectedAreas(selected);
    setAreasid(values);
  };

  const handleHospitalsAddressChange = (event) => {
    setHospitalsAddress(event.target.value);
  };

  const handleStateChange = (event) => {
    setState(event.target.value);
  };

  const handleCityChange = (event) => {
    setCity(event.target.value);
  };

  const handlePincodeChange = (event) => {
    setPincode(event.target.value);
  };

  const handleNumberChange = (event) => {
    setNumber(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  let createHospitals = async (
    hospitalsname,
    areasid,
    hospitalsaddress,
    country,
    state,
    city,
    pincode,
    number,
    email
  ) => {
    let statedata = await CreateHospitals(
      hospitalsname,
      areasid,
      hospitalsaddress,
      country,
      state,
      city,
      pincode,
      number,
      email
    );
    if (statedata) {
      SetReload(true);
      setShow(false);

      Swal.fire({
        title: "Success!",
        text: "Hospital Added Successfully !!",
        icon: "success",
        confirmButtonText: "OK",
      });
    } else {
      alert("Error", "Issue While Fetching Hospital");
    }
  };

  let updateHospitals = async (
    hospitalsid,
    hospitalsname,
    areasid,
    hospitalsaddress,
    country,
    state,
    city,
    pincode,
    number,
    email
  ) => {
    let statedata = await UpdateHospitals(
      hospitalsid,
      hospitalsname,
      areasid,
      hospitalsaddress,
      country,
      state,
      city,
      pincode,
      number,
      email
    );
    if (statedata) {
      SetReload(true);
      setShow(false);

      Swal.fire({
        title: "Success!",
        text: "Hospitals Updated Successfully !!",
        icon: "success",
        confirmButtonText: "OK",
      });
    } else {
      alert("Error", "Issue While Updating Hospital");
    }
  };

  let deleteHospitals = async (hospitalsid) => {
    let statedata = await DeleteHospitals(hospitalsid);
    if (statedata) {
      SetReload(true);
      setShow(false);

      Swal.fire({
        title: "Success!",
        text: "Hospitals Deleted !!",
        icon: "success",
        confirmButtonText: "OK",
      });
    } else {
      alert("Error", "Issue While Delete Hospital");
    }
  };

  let handleSave = (event) => {
    if (ishospitalsname === false) {
      createHospitals(
        hospitalsname,
        areasid,
        hospitalsaddress,
        country,
        state,
        city,
        pincode,
        number,
        email
      );
    }
  };

  let handleUpdate = (event) => {
    updateHospitals(
      hospitalsid,
      hospitalsname,
      areasid,
      hospitalsaddress,
      country,
      state,
      city,
      pincode,
      number,
      email
    );
  };

  let handleDelete = (id) => {
    Swal.fire({
      title: "Do you want to delete the Hospitals?",

      showCancelButton: true,
      confirmButtonText: "Delete",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        deleteHospitals(id);
      }
    });
  };

  let handleLocDelete = (id) => {
    Swal.fire({
      title: "Do you want to delete the Location?",

      showCancelButton: true,
      confirmButtonText: "Delete",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
       // deleteLocation(id);
       UpdateHospitalsLat(id, "", "");
       
      }
    });
  };

  const columns = [
    // {
    //   name: "Id",
    //   selector: (row) => row.id,
    //   sortable: true,
    //   width:"3%",
    //   fontSize: 8,
      
    // },
    {
      name: "Hospitals",
      selector: (row) => 
      <div style={{ whiteSpace: 'normal',backgroundColor: row.hospitalsname=== "" || row.hospitalsname=== null ? "#f36e6f":"", borderRadius:"10px", border: row.hospitalsname=== "" || row.hospitalsname=== null ? "2px solid transparent": "2px solid transparent",padding:"3px", display:"flex", flexDirection:"row",color:row.hospitalsname=== "" || row.hospitalsname=== null ? "#fff": "#000", margin:"3px", }}>{row.hospitalsname? row.hospitalsname: "N/A"}</div>
      ,
      sortable: true,
    },
    {
      name: "Address",
      selector: (row) => <div style={{ whiteSpace: 'normal' }}>{row.address}</div>,
      sortable: true,
    },
    {
      name: "Location",
      selector: (row) => <div style={{ whiteSpace: 'normal',textAlign:'center' }}>{row.latitude && <a target="_blank" href={`http://maps.google.com/maps?q=${row.latitude},${row.longitude}`}><FaMapMarked size={20} />
      </a>}</div>,
      sortable: true,
    },
    {
      name: "Phone No.",
      selector: (row) => 
      <div style={{ whiteSpace: 'normal',backgroundColor: row.phone === "" || row.phone === null ? "#f36e6f":"", borderRadius:"10px", border: row.phone === "" || row.phone === null ? "2px solid transparent": "2px solid transparent",padding:"3px", display:"flex", flexDirection:"row",color:row.phone === "" || row.phone === null ? "#fff": "#000", margin:"3px", }}>{row.phone ? row.phone : "N/A"}</div>,
      sortable: true,
    },
    {
      name: "Users",
      selector: (row) =>
        row.users &&
        row.users.map((hobby) => (
          <OverlayTrigger
            key={hobby.id}
            placement="right"
            overlay={<Tooltip style={{ maxWidth: '300px', fontSize: 12, }}><p>Username : {hobby.username} </p><p>Email : {hobby.email} </p><p>Firstname : {hobby.firstname} </p><p>Lastname : {hobby.lastname} </p><p>Address1 : {hobby.address1} </p><p>Address2 : {hobby.address2} </p><p>Role : {hobby.role}</p></Tooltip>}
          >
         <div style={{padding:1,fontSize:'11px',fontWeight:'bold',margin:3,padding:2,backgroundColor:"",borderRadius:"10px",border: "2px solid transparent",color:'#000'}} >{hobby.username}
        
        </div>
        
        
          {/* <div style={{backgroundColor:"", borderRadius:"10px", border: "2px solid transparent",padding:"3px", display:"flex", flexDirection:"row",color:"#000", margin:"3px" }} > 
          <div >
         
          {hobby.username}
          </div> 
        </div> */}
           
          </OverlayTrigger>
        )),
      sortable: true,
    },
    // {
    //   name: "Created By",
    //   selector: (row) =>
    //   <div style={{ whiteSpace: 'normal',backgroundColor: row.createdbyuser === "" || row.createdbyuser === null ? "#f36e6f":"", borderRadius:"10px", border: row.createdbyuser === "" || row.createdbyuser === null ? "2px solid transparent": "2px solid transparent",padding:"3px", display:"flex", flexDirection:"row",color:row.createdbyuser === "" || row.createdbyuser === null ? "#fff": "#000", margin:"3px", }}>{row.createdbyuser ? row.createdbyuser : "N/A"}</div>,
      
    //   width:"8%",
    //   sortable: true,
    // },
    // {
    //   name: "Country",
    //   selector: (row) => row.countryname,
    // },
    {
      name: "Territories",
      selector: (row) =>
<div style={{ whiteSpace: 'normal' }}>{row.areas.map((hobby) => (
        <div style={{padding:1,fontSize:'11px',fontWeight:'bold',margin:3,padding:2,backgroundColor:"",borderRadius:"10px",border: "2px solid transparent",color:'#000'}} key={hobby.id}>
          <Badge style={{color:"#000", borderRadius:"5px",backgroundColor:"",padding:"3px",}} >{hobby.id}</Badge> {hobby.areaname}
        </div>
     ))}</div>,

        // row.areas.map((hobby) => (
        //   <div style={{backgroundColor:"", borderRadius:"10px", border: "2px solid transparent",padding:"3px", display:"flex", flexDirection:"row",color:"#000",margin:"3px" }}  > 
        //   <div style={{color:"#000", borderRadius:"5px",backgroundColor:"",padding:"1px",}}>
         
        //   {hobby.id}
        //   </div> &nbsp; {hobby.areaname}
        // </div>
        // )),
      sortable: true,
    },
    {
      name: "CIP's",
      selector: (row) =>

      <div style={{ whiteSpace: 'normal' }}>{row.cips.map((hobby) => (
        <div style={{padding:1,fontSize:'11px',fontWeight:'bold',margin:3,padding:2,backgroundColor:"",borderRadius:"10px",border: "2px solid transparent",color:'#000'}} key={hobby.id}>
          <Badge style={{color:"#000", borderRadius:"5px",backgroundColor:"",padding:"3px",}} >{hobby.id}</Badge> {hobby.cipname}
        </div>
     ))}</div>,

        // <div style={{ whiteSpace: 'normal' }}>{row.cips.map((hobby) => (
        //   <div style={{backgroundColor:"", borderRadius:"10px", border: "2px solid transparent",padding:"3px", display:"flex", flexDirection:"row",color:"#000", margin:"3px"}}  > 
        //   <div style={{color:"#000", borderRadius:"5px",backgroundColor:"",padding:"1px",}}>
         
        //   {hobby.id}
        //   </div> &nbsp; {hobby.cipname}
        // </div>
         
        // ))}</div>,
      sortable: true,
    },

  ];

  const customStyles = {   head: {     style: {       backgroundColor: 'blue',     },   }, };
  const CustomHeader = ({ children }) => (   <div style={{ backgroundColor: 'blue', padding: '10px', color: 'white' }}>    {children}  </div>);

  if (Op_per.get().MASTERS_HOSPITALS.ALLOW_DELETE || Op_per.get().MASTERS_HOSPITALS.ALLOW_EDIT) {
    columns.push(
      {
        name: "Actions",

        selector: (row) => (
          <div key={row.id}>
            <AiFillDelete
              onClick={() => handleDelete(row.id)}
              style={{ color: "#f36e6f", fontSize: 20,display:Op_per.get().MASTERS_HOSPITALS.ALLOW_DELETE ? '' : 'none' }}
            />
            &nbsp;&nbsp;&nbsp;
            <LuMapPinOff 
            onClick={() => handleLocDelete(row.id)}
            style={{ color: "#f36e6f", fontSize: 20,display:Op_per.get().MASTERS_HOSPITALS.ALLOW_DELETE ? '' : 'none' }}
            />
            &nbsp;&nbsp;&nbsp;
            <AiTwotoneEdit
              style={{ color: "#9889e6", fontSize: 20,display:Op_per.get().MASTERS_HOSPITALS.ALLOW_DELETE ? '' : 'none' }}
              onClick={() => handleEditShow(row)}
            />
          </div>
        ),
      }
    )
  }

  async function downloadCSV(hospitalss) {
    let csv;
    try {
      const opts = {
        fields: [
          "id",
          "hospitalsname",
          "areas_areaname",
          "countryname",
          "statename",
          "cityname",
          "latitude",
          "longitude",
          "address",
          "pincode",
          "phone",
          "email",
          "deleted",
          "disabled",
          "createdby",
          "createdat",
          "updatedat",
        ],
        transforms: [
          unwind({
            paths: ["areas"],
          }),
          flatten({ object: false, array: true, separator: "_" }),
        ],
      };
      const parser = new Parser(opts);
      csv = parser.parse(hospitalss);
    } catch (err) {
      console.error(err);
    }

    const link = document.createElement("a");

    if (csv == null) return;

    const filename = "export.csv";

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute("href", encodeURI(csv));
    link.setAttribute("download", filename);
    link.click();
  }

  const Export = ({ onExport }) => (
    <Button onClick={(e) => onExport(e.target.value)}>Export All</Button>
  );

  const actionsMemo = React.useMemo(
    () => <Export onExport={() => downloadCSV(hospitalss)} />,
    [hospitalss]
  );

  // A super simple expandable component.
  const ExpandedComponent = ({ data }) => (
    <div
      className="container p-3"
      style={{
        backgroundColor: data.disabled ? "#f36e6f" : "",
      color: "#fff",
      padding: 10,
      fontSize: 9,
      margin:20,
      borderRadius:20,
      border:"2px solid transparent",
      width:"95%"
      }}
    >
    <Table className="mt-3 table table-bordered blazetable">
      <tr>
      <th style={{fontWeight:"bold", color:"#000", padding:10, width:"10%"}} >Hospital</th>
      <td style={{ width:"10%"}}>:</td>
      <td style={{ padding:10, width:"30%" }}> <span  style={{
                        backgroundColor:"", borderRadius:"10px", border: "2px solid transparent",padding:"5px",color:"#000", fontWeight:"bold"
                       }}>{data.hospitalsname}</span> </td>
                       <th  style={{fontWeight:"bold", color:"#000" , padding:10, width:"10%"}}>Areas </th>
                       <td style={{ width:"10%"}}>:</td>
        <td style={{ padding:10,width:"30%" }}>{data.areas != null ? (
                  data.areas.map((are) => (
                    <span
                       style={{
                        backgroundColor:"", borderRadius:"10px", border: "2px solid transparent",padding:"5px",color:"#000", fontWeight:"bold",margin:"3px"
                       }}
                    >
                      {are.areaname}
                    </span>
                  ))
                ) : (
                  <span
                   style={{
                        backgroundColor:"", borderRadius:"10px", border: "2px solid transparent",padding:"5px",color:"#000", fontWeight:"bold",margin:"3px"
                       }}
                  >
                    N/A
                  </span>
                )}</td>
      </tr>
      <tr>
      <th style={{fontWeight:"bold", color:"#000", padding:10,width:"10%"}} >Address  </th>
      <td style={{ width:"10%"}}>:</td>
      <td style={{ padding:10,width:"30%" }}> <span  style={{
                        backgroundColor:"", borderRadius:"10px", border: "2px solid transparent",padding:"5px",color:"#000", fontWeight:"bold"
                       }}>{data.address}</span> </td>
                        <th style={{fontWeight:"bold", color:"#000", padding:10}} >Country  </th>
                        <td style={{ width:"10%"}}>:</td>
      <td style={{ padding:10 }}> <span  style={{
                        backgroundColor:"", borderRadius:"10px", border: "2px solid transparent",padding:"5px",color:"#000", fontWeight:"bold"
                       }}>{data.countryname === "" || data.countryname === null
                ? "N/A"
                : data.countryname}</span> </td>
                       
      </tr>
      <tr>
      <th style={{fontWeight:"bold", color:"#000", padding:10,width:"10%"}} >State  </th>
      <td style={{ width:"10%"}}>:</td>
      <td style={{ padding:10,width:"30%" }}> <span  style={{
                        backgroundColor:"", borderRadius:"10px", border: "2px solid transparent",padding:"5px",color:"#000", fontWeight:"bold"
                       }}>{data.statename}</span> </td>

<th style={{fontWeight:"bold", color:"#000", padding:10,width:"10%"}} >City  </th>
<td style={{ width:"10%"}}>:</td>
      <td style={{ padding:10,width:"30%" }}> <span  style={{
                        backgroundColor:"", borderRadius:"10px", border: "2px solid transparent",padding:"5px",color:"#000", fontWeight:"bold"
                       }}>{data.cityname}</span> </td>
      </tr>
      <tr>
      <th style={{fontWeight:"bold", color:"#000", padding:10,width:"10%"}} >Pincode  </th>
      <td style={{ width:"10%"}}>:</td>
      <td style={{ padding:10,width:"30%" }}> <span  style={{
                        backgroundColor:"", borderRadius:"10px", border: "2px solid transparent",padding:"5px",color:"#000", fontWeight:"bold"
                       }}>{data.pincode === "" || data.pincode === null
                ? "N/A"
                : data.pincode}</span> </td>

<th style={{fontWeight:"bold", color:"#000", padding:10,width:"10%"}} >Phone  </th>
<td style={{ width:"10%"}}>:</td>
<td style={{ padding:10,width:"30%" }}><span
                     style={{
                     backgroundColor: data.phone === "" || data.phone === null ? "red":"#000", borderRadius:"10px", border: data.phone === "" || data.phone === null ? "2px solid transparent": "2px solid transparent",padding:"5px",margin:"3px",color:"#000", fontWeight:"bold"
                       }}
                    > {data.phone === "" || data.phone === null ? "N/A" : data.phone}</span></td>
      </tr>
      <tr>
      <th style={{fontWeight:"bold", color:"#000", padding:10,width:"10%"}} >Email  </th>
      <td style={{ width:"10%"}}>:</td>
     <td style={{ padding:10,width:"30%" }}> <span
                     style={{
                     backgroundColor: data.email === "" || data.email === null || data.email === "N/A" ? "red":"#000", borderRadius:"10px", border: data.email === "" || data.email === null || data.email === "N/A" ? "2px solid transparent": "2px solid transparent",padding:"5px",margin:"3px",color:"#000", fontWeight:"bold"
                       }}
                    >{data.email === "" || data.email === null ? "N/A" : data.email}</span></td>

<th style={{fontWeight:"bold", color:"#000", padding:10,width:"10%"}} >Created By  </th>
<td style={{ width:"10%"}}>:</td>
      <td style={{ padding:10,width:"30%" }}> <span  style={{
                        backgroundColor:"", borderRadius:"10px", border: "2px solid transparent",padding:"5px",color:"#000", fontWeight:"bold"
                       }}>{data.createdbyuser === "" || data.createdbyuser === null
                ? "N/A"
                : data.createdbyuser}</span> </td>
      </tr>
     
    </Table>
      {/* <div className="row">
        <div className="col-3">
          <table>
            <tr>
              <td>
                <b>Hospitals Name</b>
              </td>
              <td style={{ paddingRight: 10, paddingLeft: 10 }}> : </td>
              <td>{data.hospitalsname}</td>
            </tr>
            <tr>
              <td>
                <b>Areas</b>
              </td>
              <td style={{ paddingRight: 10, paddingLeft: 10 }}> : </td>
              <td>
                {data.areas.map((are) => (
                  <span>{are.areaname}</span>
                ))}
              </td>
            </tr>
          </table>
        </div>
        <div className="col-3">
          <table>
            <tr>
              <td>
                <b>Address</b>
              </td>
              <td style={{ paddingRight: 10, paddingLeft: 10 }}> : </td>
              <td>{data.address}</td>
            </tr>
            <tr>
              <td>
                <b>Country</b>
              </td>
              <td style={{ paddingRight: 10, paddingLeft: 10 }}> : </td>
              <td>{data.countryname}</td>
            </tr>
          </table>
        </div>
        <div className="col-3">
          <table>
            <tr>
              <td>
                <b>State</b>
              </td>
              <td style={{ paddingRight: 10, paddingLeft: 10 }}> : </td>
              <td>{data.statename}</td>
            </tr>
            <tr>
              <td>
                <b>City</b>
              </td>
              <td style={{ paddingRight: 10, paddingLeft: 10 }}> : </td>
              <td>{data.cityname}</td>
            </tr>
          </table>
        </div>
        <div className="col-3">
          <table>
            <tr>
              <td>
                <b>Pincode</b>
              </td>
              <td style={{ paddingRight: 10, paddingLeft: 10 }}> : </td>
              <td>{data.pincode}</td>
            </tr>
            <tr>
              <td>
                <b>Phone</b>
              </td>
              <td style={{ paddingRight: 10, paddingLeft: 10 }}> : </td>
              <td>{data.phone}</td>
            </tr>
          </table>
        </div>
        <div className="col-3">
          <table>
            <tr>
              <td>
                <b>Email</b>
              </td>
              <td style={{ paddingRight: 10, paddingLeft: 10 }}> : </td>
              <td>{data.email}</td>
            </tr>
            <tr>
              <td>
                <b>Created By</b>
              </td>
              <td style={{ paddingRight: 10, paddingLeft: 10 }}> : </td>
              <td>{data.createdby}</td>
            </tr>
          </table>
        </div>
      </div> */}
    </div>
  );

  //upload excels
  let createExcelHospitals = async (jsonData) => {

    let statedata = await CreateExcelHospitals(jsonData);
    if (statedata) {
      SetReload(true);
      setShowUploader(false);

      Swal.fire({
        title: "Success!",
        text: "Hospitals Added Successfully !!",
        icon: "success",
        confirmButtonText: "OK",
      });
    } else {
      alert("Error", "Issue While Fetching Hospital");
    }
  };

  // Upload excel
  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (event) => {
      const data = new Uint8Array(event.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const sheetData = XLSX.utils.sheet_to_json(sheet);
      setExcelData(sheetData);
      setFileUploaded(true);
    };

    reader.readAsArrayBuffer(file);
  };

  // export excel
  const handleExcelExport = () => {
    Swal.fire({
      title: "Exporting!",
      text: "Exporting Excel !!",
      showConfirmButton: false,
      showCancelButton: false,
      allowOutsideClick: false,
      didOpen: async () => {
        Swal.showLoading()
        let d = await GetAllHospitalss();
        if (d) {
          Swal.hideLoading()
          Swal.close()
          downloadCSV(d);
        }

      }
    });
  }

  const handleTitleChange = (event) => {
    const { name, value } = event.target;
    setColumnTitles({ ...columnTitles, [name]: value });
  };

  const handleAddData = () => {
    const newData = [];
    excelData.forEach((row) => {
      const newRow = {
        hospitalsname: row[columnTitles.title1],
        address: row[columnTitles.title2],
        pincode: row[columnTitles.title3],
        phone: row[columnTitles.title4],
        email: row[columnTitles.title5],
      };
      newData.push(newRow);
    });
    createExcelHospitals(newData);
  };

  const handleBulkUpdateSourceData = () => {
    let newArr = [];

    selectedRows.map((obj) => {
      let option = {

        // ...obj,
        id: obj.id,
        hospitalsname: obj.hospitalsname,
        areas: Helper.SetTableDataInDropdown(obj.areas, "id", "areaname"),
        users: Helper.SetTableDataInDropdown(obj.users, "id", "username"),
        address: obj.address,
        country: { id: obj.countryid, name: obj.countryname },
        state: { id: obj.stateid, statename: obj.statename },
        city: { id: obj.cityid, cityname: obj.cityname },
        cips: Helper.SetTableDataInDropdown(obj.cips, "id", "cipname"), //TODO CIP LABEL
        pincode: obj.pincode,
        phone: obj.phone,
        email: obj.email,
      };
      newArr.push(option);
      return true;
    });

    setBulkSourceData(newArr);
    setShowBulkAreaEdit(true);
  };
  let handleBulkAreaUpdate = async () => {
    let newData = bulkSourceData.map((data) => {
      return {
        id: data.id,
        areas: Helper.GetIdsFromObj(data.areas),
        hospitalsname: data.hospitalsname,
        users: Helper.GetIdsFromObj(data.users),
        address: data.address,
        countryid: data.country.id,
        stateid: data.state.id,
        cityid: data.city.id,
        cips: Helper.GetIdsFromObj(data.cips),
        pincode: data.pincode,
        phone: data.phone,
        email: data.email,
      };
    });

    let response = await HospitalBulkUpdateArea(newData);
    if (response) {
      SetReload(true);
      setPending(true);
      setShowBulkAreaEdit(false);
      handleClearRows();

      Swal.fire({
        title: "Success!",
        text: "Hospitals Bulk Updated !!",
        icon: "success",
        confirmButtonText: "OK",
      });
    } else {
      alert("Error", "Issue While Updating Hospital");
    }
  };

  const handleBulkDataDelete = async () => {

    let newIds = [];

    selectedRows.map((obj) => {
      newIds.push(obj.id)
    })

    let response = await BulkDeleteHospitals(newIds);
    if (response) {
      SetReload(true);

      Swal.fire({
        title: "Success!",
        text: "Hospitals Deleted !!",
        icon: "success",
        confirmButtonText: "OK",
      });
    } else {
      alert("Error", "Issue While Deleting Hospital");
    }
  }


  return (
    <Container fluid style={{ minHeight: "80vh" }}>
      <div
        className="glass-body"
        style={{
          margin: 10,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          padding:0,
          backgroundColor:'transparent'
        }}
      >
        <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
<ul id="breadcrumb">
  <li><a href="/"><IoHomeOutline
            style={{ color: "white", fontSize: 20 }}
            
          /></a></li>
  <li><a href="/hospital-master"><span class="icon icon-beaker"> </span>Masters</a></li>
  <li><a href="/hospital-master"><span class="icon icon-beaker"> </span> Hospitals</a></li>
  {/* <li><a href="#"><span class="icon icon-double-angle-right"></span>Doctor</a></li> */}
  
</ul>
	
</div>
      </div>

      <div className="glass-body" style={{ margin: 10 }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
          className="flex-column-mobile"
        >
          <div>
            <Form.Control
            style={{height: 40,
            width:250,
            borderColor: '#ccc',
            borderWidth: 1,
            paddingHorizontal: 8,
            marginBottom: 16,
            borderRadius:10}}
              className="glass"
              placeholder="Search"
              type="text"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>

          <div style={{ display: "flex", flexDirection: "row" }}>
            {selectedRows.length > 0 && (Op_per.get().MASTERS_HOSPITALS.ALLOW_EDIT || Op_per.get().MASTERS_HOSPITALS.ALLOW_DELETE) ? (
              <div class="dropdown" style={{ marginRight: 10 }}>
                <button
                  class="btn btn-secondary"
                  type="button"
                  variant="warning"
                  title="Bulk Menu"
                  id="dropdown-basic"
                  style={{ backgroundColor: " #FFCA2C" }}
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                <SiDatabricks color="#000" />
                </button>
                <ul class="dropdown-menu">
                  <li onClick={handleBulkUpdateSourceData} style={{display:Op_per.get().MASTERS_HOSPITALS.ALLOW_EDIT ? '' : 'none'}}>
                    <a class="dropdown-item"> Update</a>
                  </li>
                  <li onClick={handleBulkDataDelete} style={{display:Op_per.get().MASTERS_HOSPITALS.ALLOW_DELETE ? '' : 'none'}}>
                    <a class="dropdown-item"> Delete</a>
                  </li>
                 
                </ul>
              </div>
            ) : (
              ""
            )}
            {Op_per.get().MASTERS_HOSPITALS.ALLOW_ADD || Op_per.get().MASTERS_HOSPITALS.ALLOW_IMPORT_XLSX || Op_per.get().MASTERS_HOSPITALS.ALLOW_EXPORT_XLSX ? (
              <div class="dropdown">
                <button
                  class="btn btn-secondary"
                  type="button"
                  style={{ backgroundColor: "#2194f0" }}
                  title="Options"
                  id="dropdown-basic"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <HiDotsVertical />
                </button>
                <ul class="dropdown-menu">
                  <li onClick={handleShow} style={{display:Op_per.get().MASTERS_HOSPITALS.ALLOW_ADD ? '' : 'none'}}>
                    <a class="dropdown-item">Add</a>
                  </li>
                  <li onClick={handleUploaderShow} style={{display:Op_per.get().MASTERS_HOSPITALS.ALLOW_IMPORT_XLSX ? '' : 'none'}}>
                    <a class="dropdown-item">Import XLSX</a>
                  </li>
                  <li onClick={handleExcelExport} style={{display:Op_per.get().MASTERS_HOSPITALS.ALLOW_EXPORT_XLSX ? '' : 'none'}}>
                    <a class="dropdown-item">Export XLSX</a>
                  </li>
                </ul>
              </div>
            ) : ("")}

            {/* <Export onExport={() => downloadCSV(hospitalss)} /> */}
          </div>
        </div>
        <div className="pt-3">
          <DataTable
            columns={columns}
            data={hospitalss}
            selectableRows
            onSelectedRowsChange={({ selectedRows }) =>
              setSelectedRows(selectedRows)
            }
            highlightOnHover
            expandableRows
            expandableRowsComponent={ExpandedComponent}
            progressPending={pending}
            progressComponent={<LoadingComponent />}
            pagination
            customStyles={{headRow: {style: {backgroundColor: '#c0dfff',  padding:10,borderRadius:'10px 10px 0 0' }, }  }}
                        CustomHeader={<CustomHeader/>}
            paginationServer
            paginationTotalRows={totalRows}
            onChangeRowsPerPage={handlePerRowChange}
            onChangePage={handlePageChange}
            // actions={actionsMemo}
            style={{ marginTop: 10 }}
            dense
          />
        </div>
      </div>
      <Modal show={show} backdrop="static" size="md" onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Hospital</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Hospital <span style={{color:"red"}} >*</span></Form.Label>
              <Form.Control
                style={{
                  backgroundColor: ishospitalsname ? "#f36e6f" : "",
                  color: ishospitalsname ? "#fff" : "",
                  height: 40,
           
            borderColor: '#ccc',
            borderWidth: 1,
            paddingHorizontal: 8,
            marginBottom: 16,
            borderTopLeftRadius:20,
            borderBottomRightRadius:20,
                }}
                type="text"
                onChange={handleHospitalsNameChange}
                placeholder="Enter Hospital Name"
                required
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Territories <span style={{color:"red"}} >*</span></Form.Label>
              <Select
               className="selectdoctor"
                closeMenuOnSelect={false}
                options={options}
                value={selectedAreas}
                onChange={handleSelectChange}
                isMulti
                styles={{
   
   dropdownIndicator: (provided) => ({
     ...provided,
     color: '#ccc' // Change color of the arrow here
   })
 }}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Address <span style={{color:"red"}} >*</span></Form.Label>
              <Form.Control
              style={{height: 40,
           
           borderColor: '#ccc',
           borderWidth: 1,
           paddingHorizontal: 8,
           marginBottom: 16,
           borderRadius:10,}}
                type="text"
                onChange={handleHospitalsAddressChange}
                placeholder="Enter Address"
                required
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Country <span style={{color:"red"}} >*</span></Form.Label>
              <Form.Select  className="selectdoctor" onChange={handleCountryCodeChange} required  style={{
    backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='%232457C5'%3E%3Cpath d='M14.293 7.293a1 1 0 0 0-1.414 1.414L10 11.414l-2.879-2.88a1 1 0 1 0-1.414 1.414l3.5 3.5a1 1 0 0 0 1.414 0l3.5-3.5a1 1 0 0 0 0-1.414z'/%3E%3C/svg%3E")`, // Arrow SVG with blue color (fill='%232457C5')
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right .75rem center',
    backgroundSize: '25px 25px' // Adjust as needed
  }}>
                <option>---Select Country---</option>
                {countries.map((city) => (
                  <option key={city.id} value={city.id}>
                    {city.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>State <span style={{color:"red"}} >*</span></Form.Label>
              <Form.Select   className="selectdoctor" onChange={handleStateChange} required  style={{
    backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='%232457C5'%3E%3Cpath d='M14.293 7.293a1 1 0 0 0-1.414 1.414L10 11.414l-2.879-2.88a1 1 0 1 0-1.414 1.414l3.5 3.5a1 1 0 0 0 1.414 0l3.5-3.5a1 1 0 0 0 0-1.414z'/%3E%3C/svg%3E")`, // Arrow SVG with blue color (fill='%232457C5')
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right .75rem center',
    backgroundSize: '25px 25px' // Adjust as needed
  }}>
                <option>---Select State---</option>
                {states.map((city) => (
                  <option key={city.id} value={city.id}>
                    {city.statename}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>City <span style={{color:"red"}} >*</span></Form.Label>
              <Form.Select  className="selectdoctor" onChange={handleCityChange} required  style={{
    backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='%232457C5'%3E%3Cpath d='M14.293 7.293a1 1 0 0 0-1.414 1.414L10 11.414l-2.879-2.88a1 1 0 1 0-1.414 1.414l3.5 3.5a1 1 0 0 0 1.414 0l3.5-3.5a1 1 0 0 0 0-1.414z'/%3E%3C/svg%3E")`, // Arrow SVG with blue color (fill='%232457C5')
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right .75rem center',
    backgroundSize: '25px 25px' // Adjust as needed
  }}>
                <option>---Select City---</option>
                {citys.map((city) => (
                  <option key={city.id} value={city.id}>
                    {city.cityname}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Pincode <span style={{color:"red"}} >*</span></Form.Label>
              <Form.Control
              style={{height: 40,
           
           borderColor: '#ccc',
           borderWidth: 1,
           paddingHorizontal: 8,
           marginBottom: 16,
           borderRadius:10,}}
                type="text"
                onChange={handlePincodeChange}
                placeholder="Enter Pincode"
                required
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Phone <span style={{color:"red"}} >*</span></Form.Label>
              <Form.Control
              style={{height: 40,
           
           borderColor: '#ccc',
           borderWidth: 1,
           paddingHorizontal: 8,
           marginBottom: 16,
           borderRadius:10,}}
                type="number"
                onChange={handleNumberChange}
                placeholder="Enter Phone"
                required
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Email <span style={{color:"red"}} >*</span></Form.Label>
              <Form.Control
              style={{height: 40,
           
           borderColor: '#ccc',
           borderWidth: 1,
           paddingHorizontal: 8,
           marginBottom: 16,
           borderRadius:10,}}
                type="email"
                onChange={handleEmailChange}
                placeholder="Enter Email"
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={handleSave}
            disabled={ishospitalsname ? true : false}
          >
            Add Hospital
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showuploader} onHide={handleUploaderClose}>
        <Modal.Header closeButton>
          <Modal.Title>Upload Excel</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <input
              type="file"
              accept=".xlsx, .xls"
              onChange={handleFileUpload}
            />
          </div>

          {fileUploaded && (
            <div>
              <hr />
              <div>
                <label style={{paddingTop:10}} htmlFor="title1">Hospital Name <span style={{color:"red"}} >*</span></label>
                <Form.Select
                 className="selectdoctor" 
                  id="title1"
                  name="title1"
                  value={columnTitles.title1}
                  onChange={handleTitleChange}
                >
                  <option>--Select Data ---</option>
                  {Object.keys(excelData[0] || {}).map((key) => (
                    <option key={key} value={key}>
                      {key}
                    </option>
                  ))}
                </Form.Select>
              </div>
              <div>
                <label style={{paddingTop:10}} htmlFor="title2">Address <span style={{color:"red"}} >*</span></label>
                <Form.Select
                 className="selectdoctor" 
                  id="title2"
                  name="title2"
                  value={columnTitles.title2}
                  onChange={handleTitleChange}
                  style={{
    backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='%232457C5'%3E%3Cpath d='M14.293 7.293a1 1 0 0 0-1.414 1.414L10 11.414l-2.879-2.88a1 1 0 1 0-1.414 1.414l3.5 3.5a1 1 0 0 0 1.414 0l3.5-3.5a1 1 0 0 0 0-1.414z'/%3E%3C/svg%3E")`, // Arrow SVG with blue color (fill='%232457C5')
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right .75rem center',
    backgroundSize: '25px 25px' // Adjust as needed
  }}
                >
                  <option>--Select Data ---</option>
                  {Object.keys(excelData[0] || {}).map((key) => (
                    <option key={key} value={key}>
                      {key}
                    </option>
                  ))}
                </Form.Select>
              </div>
              <div>
                <label style={{paddingTop:10}} htmlFor="title3">Pincode <span style={{color:"red"}} >*</span></label>
                <Form.Select
                 className="selectdoctor" 
                  id="title3"
                  name="title3"
                  value={columnTitles.title3}
                  onChange={handleTitleChange}
                  style={{
    backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='%232457C5'%3E%3Cpath d='M14.293 7.293a1 1 0 0 0-1.414 1.414L10 11.414l-2.879-2.88a1 1 0 1 0-1.414 1.414l3.5 3.5a1 1 0 0 0 1.414 0l3.5-3.5a1 1 0 0 0 0-1.414z'/%3E%3C/svg%3E")`, // Arrow SVG with blue color (fill='%232457C5')
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right .75rem center',
    backgroundSize: '25px 25px' // Adjust as needed
  }}
                >
                  <option>--Select Data ---</option>
                  {Object.keys(excelData[0] || {}).map((key) => (
                    <option key={key} value={key}>
                      {key}
                    </option>
                  ))}
                </Form.Select>
              </div>
              <div>
                <label style={{paddingTop:10}} htmlFor="title4">Phone <span style={{color:"red"}} >*</span></label>
                <Form.Select
                 className="selectdoctor" 
                  id="title4"
                  name="title4"
                  value={columnTitles.title4}
                  onChange={handleTitleChange}
                  style={{
    backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='%232457C5'%3E%3Cpath d='M14.293 7.293a1 1 0 0 0-1.414 1.414L10 11.414l-2.879-2.88a1 1 0 1 0-1.414 1.414l3.5 3.5a1 1 0 0 0 1.414 0l3.5-3.5a1 1 0 0 0 0-1.414z'/%3E%3C/svg%3E")`, // Arrow SVG with blue color (fill='%232457C5')
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right .75rem center',
    backgroundSize: '25px 25px' // Adjust as needed
  }}
                >
                  <option>--Select Data ---</option>
                  {Object.keys(excelData[0] || {}).map((key) => (
                    <option key={key} value={key}>
                      {key}
                    </option>
                  ))}
                </Form.Select>
              </div>
              <div>
                <label style={{paddingTop:10}} htmlFor="title5">Email <span style={{color:"red"}} >*</span></label>
                <Form.Select
                 className="selectdoctor" 
                  id="title5"
                  name="title5"
                  value={columnTitles.title5}
                  onChange={handleTitleChange}
                  style={{
    backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='%232457C5'%3E%3Cpath d='M14.293 7.293a1 1 0 0 0-1.414 1.414L10 11.414l-2.879-2.88a1 1 0 1 0-1.414 1.414l3.5 3.5a1 1 0 0 0 1.414 0l3.5-3.5a1 1 0 0 0 0-1.414z'/%3E%3C/svg%3E")`, // Arrow SVG with blue color (fill='%232457C5')
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right .75rem center',
    backgroundSize: '25px 25px' // Adjust as needed
  }}
                >
                  <option>--Select Data ---</option>
                  {Object.keys(excelData[0] || {}).map((key) => (
                    <option key={key} value={key}>
                      {key}
                    </option>
                  ))}
                </Form.Select>
              </div>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleUploaderClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleAddData}>
            Add Data
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showedit} onHide={handleEditClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Hospitals</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Hospital <span style={{color:"red"}} >*</span></Form.Label>
              <Form.Control
               style={{height: 40,
           
           borderColor: '#ccc',
           borderWidth: 1,
           paddingHorizontal: 8,
           marginBottom: 16,
           borderRadius:10,}}
                type="text"
                onChange={handleHospitalsNameChange}
                placeholder="Enter Hospital Name"
                value={hospitalsname}
                required
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Areas <span style={{color:"red"}} >*</span></Form.Label>
              <Select
               className="selectdoctor"
                closeMenuOnSelect={false}
                options={options}
                value={selectedAreas}
                onChange={handleSelectChange}
                isMulti
                styles={{
   
   dropdownIndicator: (provided) => ({
     ...provided,
     color: '#ccc' // Change color of the arrow here
   })
 }}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Address <span style={{color:"red"}} >*</span></Form.Label>
              <Form.Control
               style={{height: 40,
           
           borderColor: '#ccc',
           borderWidth: 1,
           paddingHorizontal: 8,
           marginBottom: 16,
           borderRadius:10,}}
                type="text"
                onChange={handleHospitalsAddressChange}
                placeholder="Enter Address"
                required
                value={hospitalsaddress}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Country <span style={{color:"red"}} >*</span></Form.Label>
              <Form.Select
               className="selectdoctor"
                onChange={handleCountryCodeChange}
                required
                value={country}
                style={{
    backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='%232457C5'%3E%3Cpath d='M14.293 7.293a1 1 0 0 0-1.414 1.414L10 11.414l-2.879-2.88a1 1 0 1 0-1.414 1.414l3.5 3.5a1 1 0 0 0 1.414 0l3.5-3.5a1 1 0 0 0 0-1.414z'/%3E%3C/svg%3E")`, // Arrow SVG with blue color (fill='%232457C5')
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right .75rem center',
    backgroundSize: '25px 25px' // Adjust as needed
  }}
              >
                <option>---Select Country---</option>
                {countries.map((country) => (
                  <option key={country.id} value={country.id}>
                    {country.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>State <span style={{color:"red"}} >*</span></Form.Label>
              <Form.Select  className="selectdoctor" onChange={handleStateChange} required value={state}
               style={{
    backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='%232457C5'%3E%3Cpath d='M14.293 7.293a1 1 0 0 0-1.414 1.414L10 11.414l-2.879-2.88a1 1 0 1 0-1.414 1.414l3.5 3.5a1 1 0 0 0 1.414 0l3.5-3.5a1 1 0 0 0 0-1.414z'/%3E%3C/svg%3E")`, // Arrow SVG with blue color (fill='%232457C5')
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right .75rem center',
    backgroundSize: '25px 25px' // Adjust as needed
  }}>
                <option>---Select State---</option>
                {states.map((state) => (
                  <option key={state.id} value={state.id}>
                    {state.statename}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>City <span style={{color:"red"}} >*</span></Form.Label>
              <Form.Select  className="selectdoctor" onChange={handleCityChange} required value={city}  style={{
    backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='%232457C5'%3E%3Cpath d='M14.293 7.293a1 1 0 0 0-1.414 1.414L10 11.414l-2.879-2.88a1 1 0 1 0-1.414 1.414l3.5 3.5a1 1 0 0 0 1.414 0l3.5-3.5a1 1 0 0 0 0-1.414z'/%3E%3C/svg%3E")`, // Arrow SVG with blue color (fill='%232457C5')
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right .75rem center',
    backgroundSize: '25px 25px' // Adjust as needed
  }}>
                <option>---Select City---</option>
                {citys.map((city) => (
                  <option key={city.id} value={city.id}>
                    {city.cityname}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Pincode <span style={{color:"red"}} >*</span></Form.Label>
              <Form.Control
               style={{height: 40,
           
           borderColor: '#ccc',
           borderWidth: 1,
           paddingHorizontal: 8,
           marginBottom: 16,
           borderRadius:10,}}
                type="text"
                onChange={handlePincodeChange}
                placeholder="Enter Pincode"
                required
                value={pincode}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Phone <span style={{color:"red"}} >*</span></Form.Label>
              <Form.Control
               style={{height: 40,
           
           borderColor: '#ccc',
           borderWidth: 1,
           paddingHorizontal: 8,
           marginBottom: 16,
           borderRadius:10,}}
                type="number"
                onChange={handleNumberChange}
                placeholder="Enter Phone"
                required
                value={number}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Email <span style={{color:"red"}} >*</span></Form.Label>
              <Form.Control
               style={{height: 40,
           
           borderColor: '#ccc',
           borderWidth: 1,
           paddingHorizontal: 8,
           marginBottom: 16,
           borderRadius:10,}}
                type="email"
                onChange={handleEmailChange}
                placeholder="Enter Email"
                value={email}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleEditClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleUpdate}>
            Update Hospitals
          </Button>
        </Modal.Footer>
      </Modal>
      {showbulkareaedit && (
        <Modal
          fullscreen={true}
          size="lg"
          show={true}
          onHide={() => setShowBulkAreaEdit(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Bulk Update Hospitals</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="scrollable-div">
              <Table
                style={{ tableLayout: "auto", width: "100%" }}
                className="mt-3 table table-bordered blazetable"
                striped
                bordered
                hover
                size="sm"
              >
                <thead className="sticky">
                  <tr style={{ width: "auto", whiteSpace: "nowrap" }}>
                    {Object.keys(bulkSourceData[0]).map((row, index) => {
                      return <th className={index === 0 ? "sticky-col1" : index === 1 ? "sticky-col2" : ""}>{row}</th>;
                    })}
                  </tr>
                </thead>
                <tbody>
                  {bulkSourceData.map((row, rowIndex) => {
                    return (
                      <tr key={rowIndex}>
                        {Object.keys(row).map((cell, cellIndex) => {
                          return (
                            <td
                              style={{ width: "auto", whiteSpace: "nowrap" }}
                              key={cellIndex}
                              className={cellIndex === 0 ? "sticky-col1" : cellIndex === 1 ? "sticky-col2" : ""}
                            >
                              {cell === "areas" || cell === "cips" || cell === "users" || cell === "country" || cell == "state" || cell == "city" ? (
                                <div style={{ minWidth: "200px" }}>
                                  <Select
                                    closeMenuOnSelect={false}
                                    options={
                                      cell === "areas" ? options : cell === "country" ? countries : cell == "state" ? states : cell === "city" ? citys : cell === "cips" ? cipoptions : userOptions
                                    }
                                    value={bulkSourceData[rowIndex][cell]}
                                    getOptionLabel={(option) => cell === "country" ? option.name : cell === "state" ? option.statename : cell === "city" ? option.cityname : option.label}
                                    getOptionValue={(option) => cell === "country" || cell == "state" || cell == "city" ? option.id : option.value}
                                    onChange={(selectedOption) => {
                                      const newValue = selectedOption;
                                      setBulkSourceData((prevData) => {
                                        const newData = [...prevData];
                                        newData[rowIndex][cell] = newValue;
                                        return newData;
                                      });
                                    }}
                                    isMulti={cell !== 'country' && cell !== 'state' && cell !== 'city'}
                                  />
                                </div>
                              ) : (
                                <input
                                  type="text"
                                  name={cell}
                                  value={
                                    bulkSourceData[rowIndex][cell] !== null &&
                                      bulkSourceData[rowIndex][cell] !== undefined
                                      ? bulkSourceData[rowIndex][cell]
                                      : ""
                                  }
                                  disabled={cell === "id"}
                                  onChange={(e) => {
                                    const newValue = e.target.value;
                                    setBulkSourceData((prevData) => {
                                      const newData = [...prevData];
                                      newData[rowIndex][cell] = newValue;
                                      return newData;
                                    });
                                  }}
                                />
                              )}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setShowBulkAreaEdit(false)}
            >
              Close
            </Button>
            <Button variant="primary" onClick={handleBulkAreaUpdate}>
              Update Hospitals
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </Container>
  );
}

export default HospitalsMaster;
