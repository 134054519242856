import React, { useEffect, useState } from 'react'
import { Col, Container, Form, Row } from 'react-bootstrap'
import { IoHomeOutline } from 'react-icons/io5'
import Select from "react-select";
import { AllUsers, GetAllManagerUsers } from '../../utils/proxy/Authproxy';
import UserDetail from './UserDetail';
import UserHrDetails from './UserHrDetails';
import PayoutDetails from './PayoutDetails';
import UserProjects from './UserProjects';
import UserTaskChart from '../Includes/UserTaskChart';
import UserRoi from './UserRoi';
import UserRoiGraph from './UserRoiGraph';
import { Op_per } from '../../utils/Permissions';
import BarChart from '../Includes/BarChart';

function UserPerformancePage() {

  const months = [
    { label: "January", value: "01" },
    { label: "February", value: "02" },
    { label: "March", value: "03" },
    { label: "April", value: "04" },
    { label: "May", value: "05" },
    { label: "June", value: "06" },
    { label: "July", value: "07" },
    { label: "August", value: "08" },
    { label: "September", value: "09" },
    { label: "October", value: "10" },
    { label: "November", value: "11" },
    { label: "December", value: "12" },
  ];

  const currentYear = new Date().getFullYear();
  const years = [];

  for (let i = currentYear; i >= currentYear - 100; i--) {
    years.push({ value: i.toString(), label: i.toString() });
  }

  const [users, setUsers] = useState([]);
  const [userid, setUserId] = useState(localStorage.getItem('role') === 'User' ? localStorage.getItem('uuid') : null);
  const [selectedUser, setSelectedUser] = useState(localStorage.getItem('role') === 'User' ? localStorage.getItem('uuid') : null);
  const [selectedMonth, setSelectedMonth] = useState(months[new Date().getMonth()]); // Set the default month to '01'
  const [selectedYear, setSelectedYear] = useState(years[0]); // Set the default year to the current year

  const [presentdays, setPresentDays] = useState();





  useEffect(() => {
    // Fetch data from an API when the component mounts
    const getallusers = async () => {

      if (localStorage.getItem('role') === 'Admin') {
        let allusers = await AllUsers();
        setUsers(allusers);
      } else if (localStorage.getItem('role') === 'Manager') {
        let allusers = await GetAllManagerUsers(localStorage.getItem('uuid'));
        setUsers(allusers);
      }

    };
    getallusers();
  }, []);

  const options = [];

  users.map((user) => {
    options.push({
      value: user.userid,
      label: user.username + "(" + user.firstname + " " + user.lastname + ")",
    });
  });

  const handleSelectChange = (selected) => {
    setSelectedUser(selected);
    setUserId(selected.value);
  };

  const handleSelectChangeMOnth = (selected) => {
    setSelectedMonth(selected);
  };

  const handleSelectChangeYear = (selected) => {

    setSelectedYear(selected);
  };



  return (
    <div>
      <div
        className="glass-body"
        style={{
          margin: 10,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          padding: 0,
          backgroundColor: 'transparent'
        }}
      >

        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <ul id="breadcrumb">
            <li><a href="/"><IoHomeOutline
              style={{ color: "white", fontSize: 20 }}

            /></a></li>
            <li><a href="/user-perfomance"><span class="icon icon-beaker"> </span>Performance</a></li>
            {/* <li><a href="/user-teritory"><span class="icon icon-beaker"> </span> User Teritory Master</a></li> */}
            {/* <li><a href="#"><span class="icon icon-double-angle-right"></span>Doctor</a></li> */}

          </ul>

        </div>
      </div>


      <Row style={{ margin: 12 }}>
        <div className="glass-body" style={{position:'relative',zIndex:1}}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
            className='flex-column-mobile'
          >
            <div style={{ width: "250px", display: Op_per.get().HR_PERFOMANCE.USER_DROPDOWN ? '' : 'none' }}>
              <Form.Group
                className="mb-3 d-flex w-100"
                style={{ gap: "15px" }}
                controlId="formBasicPassword"
              >
                <Form.Label className="mt-2">Users</Form.Label>
                <div className="w-75">
                  <Select
                    className="selectdoctor"
                    options={options}
                    value={selectedUser}
                    onChange={handleSelectChange}
                    styles={{

                      dropdownIndicator: (provided) => ({
                        ...provided,
                        color: '#ccc' // Change color of the arrow here
                      })
                    }}
                  />
                </div>
              </Form.Group>
            </div>
            <div style={{ width: "250px" }}>
              <Form.Group
                className="mb-3 d-flex w-100"
                style={{ gap: "15px" }}
                controlId="formBasicPassword"
              >
                <Form.Label className="mt-2">Select Month</Form.Label>
                <div className="w-50">
                  <Select
                    className="selectdoctor"
                    options={months}
                    value={selectedMonth}
                    onChange={handleSelectChangeMOnth}
                    styles={{

                      dropdownIndicator: (provided) => ({
                        ...provided,
                        color: '#ccc' // Change color of the arrow here
                      })
                    }}
                  />
                </div>
              </Form.Group>
            </div>
            <div style={{ width: "250px" }}>
              <Form.Group
                className="mb-3 d-flex w-100"
                style={{ gap: "15px" }}
                controlId="formBasicPassword"
              >
                <Form.Label className="mt-2">Select Year</Form.Label>
                <div className="w-50">
                  <Select
                    className="selectdoctor"
                    options={years}
                    value={selectedYear}
                    onChange={handleSelectChangeYear}
                    styles={{

                      dropdownIndicator: (provided) => ({
                        ...provided,
                        color: '#ccc' // Change color of the arrow here
                      })
                    }}
                  />
                </div>
              </Form.Group>
            </div>

          </div>
        </div>
      </Row>

     
        <Container fluid style={{minHeight:"80vh"}}>
        <div className='glass-body'>
          {
            userid != null && selectedMonth && selectedYear ?
              (<> <Row className='mr-2 mb-2'>

                <Col md={4}  >
                  <div className='pr-card-sg'>
                    <UserDetail selectedUserId={userid} />
                  </div>
                </Col>
                <Col md={4}  >
                  <div className='pr-card-sg'>
                    <UserHrDetails selectedUserId={userid} selectedMonth={selectedMonth} selectedYear={selectedYear} />
                  </div>
                </Col>
                <Col md={4}  >
                  <div className='pr-card-sg'>
                    <PayoutDetails selectedUserId={userid} selectedMonth={selectedMonth} selectedYear={selectedYear} />
                  </div>
                </Col>
              </Row>

                <Row className='mr-2'>
                  <Col md={4} >
                    <div className='pr-card-sg'>
                      <UserRoiGraph selectedUserId={userid} selectedMonth={selectedMonth} selectedYear={selectedYear} />
                    </div>
                  </Col>
                  <Col md={8} >
                    <div className='pr-card-sg'>
                      <BarChart selectedUserId={userid} selectedMonth={selectedMonth} selectedYear={selectedYear} />
                    </div>
                  </Col>
                </Row>

                <Row className='mr-2'>

                  <Col className='pr-card-sg'>
                    <UserRoi selectedUserId={userid} selectedMonth={selectedMonth} selectedYear={selectedYear} />
                  </Col>


                </Row></>) : <Row>
                <Col md={12} className='text-center'>
                  <p>Select option To View Data</p>
                </Col>
              </Row>
          }
               </div>
        </Container>
 

    </div>
  )
}

export default UserPerformancePage