import React from 'react'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { useState } from 'react';
import Swal from 'sweetalert2';
import { ForgotPassword, LoginProxy, getAuthServer } from '../../utils/proxy/Authproxy';
import 'bootstrap/dist/css/bootstrap.min.css'
import { Button, Container, Image, InputGroup } from 'react-bootstrap';
import { FaEye, FaEyeSlash } from 'react-icons/fa';




function Login2(props) {
  const [companyid, setCompanyid] = useState();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(true);

  const getLogin = async (email, password) => {

    let login = await LoginProxy(email, password);

    if (login) {
      if (login.message) {
        Swal.fire({
          title:'Info',
          text:login.message,
          icon:'info'
        })
      }else{

      props.setIsloggedin();
      localStorage.setItem("accessToken", login.accessToken);
      localStorage.setItem("tokenType", login.tokenType);
      localStorage.setItem("username", login.username);
      localStorage.setItem("firstname", login.firstname);
      localStorage.setItem("lastname", login.lastname);
      localStorage.setItem("role", login.role);
      localStorage.setItem("uuid", login.uuid);
      localStorage.setItem("type", login.type);
      localStorage.setItem("adminid", login.adminid);
      localStorage.setItem("managers", login.managerid);
      localStorage.setItem("image", login.image);
    }

    } else {
      Swal.fire({
        title: "Invalid Login credentials..!",
        text: "Check The Login Details You Have Entered.",
        icon: "error"
      });
    }
  }

  const handleSubmit = async (e) => {

    e.preventDefault();

    if (username === '' || password === '' || companyid === '') {
      alert('All Fields Are required');
    } else {
      let srvd = await getAuthServer(companyid);

      if (srvd) {
        localStorage.setItem("AUTH_URL", srvd.authurl)
        localStorage.setItem("REPORTING_URL", srvd.reportingurl)
        getLogin(username, password, props);
      } else {
        Swal.fire({
          title: "Invalid !",
          text: "Invalid Company Id",
          icon: "error"
        });
      }


    }




  };

  const handleForgotPassword = async () => {
    if ((companyid == '' || companyid == null) || (username == '' || username == null)) {
      Swal.fire({
        title: 'Info',
        text: 'Enter Company Id And Username then Click on Forget Password Link',
        icon: 'info'
      })
    } else {
      let d = await getAuthServer(companyid);
      if (d.id) {
        Swal.showLoading();
        let k = await ForgotPassword(d.authurl, username);
        if (k.status === 'success') {
          Swal.hideLoading();
          Swal.fire({
            title: 'Success',
            text: k.message,
            icon: 'success'
          })
          setCompanyid(null);
          setUsername('');
        } else if (k.status === 'error') {
          Swal.hideLoading();
          Swal.fire({
            title: 'Error',
            text: k.message,
            icon: 'error'
          })
        } else {
          Swal.hideLoading();
          Swal.fire({
            title: 'Error',
            text: 'Something Went Wrong',
            icon: 'error'
          })
        }
      } else {
        Swal.hideLoading();
        Swal.fire({
          title: 'Error',
          text: 'Invalid Company Id',
          icon: 'error'
        })
      }
    }
  }

  function handleShowPassword() {
    setShowPassword(!showPassword)
  }


  return (
    <>
      <div style={{ backgroundImage: `url(${process.env.PUBLIC_URL + '/images/bg.png'})`, backgroundPoistion: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover", height: "100vh", alignItems: "center", display: "flex", justifyContent: "center", position: "relative" }}>
        <div style={{ backgroundColor: "white", position: "absolute", borderRadius: "20px" }}>
          <form onSubmit={(e) => handleSubmit(e)}>
            <Container style={{ height: "90vh" }}>
              <Row style={{ height: "100%" }}>
                <Col md={6} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                  <div style={{ padding: "20px 60px" }}>
                    
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                      <img src={process.env.PUBLIC_URL + '/images/nav3.png'} style={{ width: "50%" }} alt='team insights'></img>
                    </div>

                 
                      <h3 style={{ display: "flex", marginTop: "20px", fontFamily: "helvetica" }}>Login</h3>
                      <div >
                        <Form.Group>

                          <Form.Label htmlFor="inputPassword5">Company Id</Form.Label>
                          <Form.Control
                            type="number"

                            style={{ backgroundColor: "white" }}
                            placeholder='Enter your Company Id'
                            value={companyid}
                            onChange={(e) => setCompanyid(e.target.value)}
                          />
                        </Form.Group>
                      </div>

                      <div >



                        <Form.Group>

                          <Form.Label htmlFor="inputPassword5">Username</Form.Label>
                          <Form.Control
                            type="text"

                            style={{ backgroundColor: "white" }}
                            placeholder='Enter your username'
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                          />
                        </Form.Group>
                      </div>


                      <div  >

                        <Form.Group>

                          <Form.Label htmlFor="inputPassword5">Password</Form.Label>


                          <InputGroup className="mb-3">
                            <Form.Control
                              placeholder='Enter your password'
                              type={showPassword ? "password" : "text"}
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                              style={{ backgroundColor: "white" }}
                            />
                            <Button variant="outline-secondary" id="button-addon2" onClick={() => handleShowPassword()}>
                              {showPassword ? <FaEyeSlash />
                                : <FaEye />}

                            </Button>
                          </InputGroup>
                        </Form.Group>

                      </div>

                      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <button className='btn' style={{ color: "white", border: "none", fontSize: "20px", padding: "20px", borderRadius: "30px", backgroundColor: "#2AB9D4", display: "flex", marginTop: "20px", height: "40px", width: "180px", alignItems: "center", justifyContent: "center" }} type="submit" >Login</button>

                        <p style={{ color: "#7D7D7D", paddingTop: "20px" }}><span onClick={handleForgotPassword} >Forgot password?</span></p>
                      </div>
                  
                  </div>



                </Col>

                <Col md={6} style={{ backgroundImage: `url(${process.env.PUBLIC_URL + '/images/team.jpg'})`, backgroundRepeat: 'no-repeat', backgroundSize: "cover", borderTopRightRadius: "20px", borderBottomRightRadius: "20px" }} className='d-none d-md-block'>
                </Col>
              </Row>
            </Container>

          </form>
        </div>
      </div>
    </>
  )
}

export default Login2;