import React, { useEffect, useState } from 'react'
import { Badge, Button, Card, Col, Row, Table } from 'react-bootstrap'
import { FaUser } from "react-icons/fa";
import { GetUserDetails } from '../../utils/proxy/ReportsProxy';
import { GetAllLeaveRequestByMonth, GetLeaveCount } from '../../utils/proxy/LeaveRequestProxy';
import { GetAttendance } from '../../utils/proxy/AttendanceProxy';
import { GetUserDeductionByUserid } from '../../utils/proxy/DeductionMasterProxy';


function UserHrDetails(selectedUserId,selectedMonth,selectedYear) {

    const [userinfo,setUserInfo] = useState({});
    const [userid,setUserId] = useState(selectedUserId.selectedUserId);

    const [present, setPresent] = useState();
    const [absent, setAbsent] = useState();
    const [holiday, setHoliday] = useState();
    const [days,setDays] = useState();

    const [leaves, setLeaves] = useState([]);
    const [leaveCount, setLeaveCount] = useState([]);
    const [casualLeave, setCasualLeave] = useState();
    const [sickLeave, setSickLeave] = useState()

    const [GetAllDeduction,setAllDeduction] = useState([]);
    const [userDeduction,setUserDeduction] = useState([]);

    useEffect(() => {


        const getUserDetail = async () => {

            let res = await GetUserDetails(selectedUserId.selectedUserId);
            if (res) {
                setUserInfo(res);
            }
        }
        if (selectedUserId.selectedUserId !=null || selectedUserId.selectedUserId != undefined) {
            getUserDetail(selectedUserId.selectedUserId)
          }

        async function getLeaves() {
            let data = await GetAllLeaveRequestByMonth(selectedUserId.selectedMonth.value,selectedUserId.selectedYear.value,selectedUserId.selectedUserId);
            let data2 = await GetLeaveCount(selectedUserId.selectedMonth.value,selectedUserId.selectedYear.value,selectedUserId.selectedUserId);
            if (data) {
                setLeaves(data);
                setLeaveCount(data2);
                setCasualLeave(0);
                setSickLeave(0);
                data2.map(item=>{
                    if(item.name == 'casual leave'){
                        setCasualLeave(le => le + 1)
                    }else if(item.name == 'sick leave'){
                        setSickLeave(le => le + 1);
                    }
                })
 
            } else {
                alert("Unable To Fetch");
            }
        }


        const getAttendance = async()=>{
            let start = selectedUserId.selectedYear.value+'-'+selectedUserId.selectedMonth.value+'-01';
            let a,b ;
            if(selectedUserId.selectedMonth.value == 12){
                a = (parseInt(selectedUserId.selectedYear.value) + 1).toString();
                b = '01';
            }else{
                a = selectedUserId.selectedYear.value;
                let resultString = (parseInt(selectedUserId.selectedMonth.value) + 1).toString();
                b = resultString.length === 1 ? '0' + resultString : resultString;
            }
            let end = a + '-'+b+'-01';

            let d = await GetAttendance(selectedUserId.selectedUserId,start, end);


            if(d){
                setPresent(0);
                setAbsent(0);
                setHoliday(0);
                setDays(d.length);
                d.map(item=>{
                    if(item.working == 'Present'){
                        setPresent(present=>present+1);
                    }else if(item.working == 'Absent'){
                        setAbsent(absent=>absent+1);
                    }else if(item.working == 'Holiday'){
                        setHoliday(holiday=>holiday+1);
                    }
                })
            }
        }


        const getDeduction = async () => {
            let res = await GetUserDeductionByUserid(selectedUserId.selectedUserId);
            if (res) {
                setUserDeduction(res);
            } else {
                alert('Issue While Fetching Deductions')
            }
        }

        if ((selectedUserId.selectedUserId !=null || selectedUserId.selectedUserId != undefined) && selectedUserId.selectedMonth.value && selectedUserId.selectedYear.value) {
            getLeaves();
            getDeduction();
            getAttendance();
          }
       
    },[selectedUserId,selectedMonth,selectedYear])


    return (
        
<>
              

                <Row className='mt-3'>
                    <Col md={12}>
                    <h6 style={{textAlign:'center', marginBottom:10}}>HR Details</h6>
                        <Table className="mt-3 table table-bordered jmn-table" size='xs' responsive>
                            <tr>
                                <td className='pr-table-heads'><b>Salary</b></td>
                                <td className='pr-table-seprator'>:</td>
                                <td>{userinfo.salary ? userinfo.salary : "N/A"}</td>
                            </tr>
                            <tr>
                                <td className='pr-table-heads'><b>DOJ</b></td>
                                <td className='pr-table-seprator'>:</td>
                                <td>{userinfo.dateofjoining ? userinfo.dateofjoining.split("T")[0] : 'N/A'}</td>
                            </tr>
                            <tr>
                                <td className='pr-table-heads'><b>Deductions</b></td>
                                <td className='pr-table-seprator'>:</td>
                                <td>{userDeduction.length > 0 ? userDeduction.map(item => {
                                    return <Badge className='m-1' pill bg="primary">{item.name} {item.amount}</Badge>;
                                }) : "N/A"}</td>
                            </tr>
                            <tr>
                                <td className='pr-table-heads'><b>Present Days</b></td>
                                <td className='pr-table-seprator'>:</td>
                                <td>{present ? present :'N/A'}</td>
                            </tr>
                            <tr>
                                <td className='pr-table-heads'><b>Absent Days</b></td>
                                <td className='pr-table-seprator'>:</td>
                                <td>{absent ? absent : "N/A"}</td>
                            </tr>
                            <tr>
                                <td className='pr-table-heads'><b>Holidays</b></td>
                                <td className='pr-table-seprator'>:</td>
                                <td>{holiday ? holiday : "N/A"}</td>
                            </tr>
                            <tr>
                                <td className='pr-table-heads'><b>Leaves</b></td>
                                <td className='pr-table-seprator'>:</td>
                                <td><Badge className='m-1' pill bg="primary">SICK LEAVE {sickLeave != null || undefined ? sickLeave : 0}</Badge>
                                </td>
                            </tr>
                        </Table>
                      
                    </Col>
                </Row>
                </>
    )
}

export default UserHrDetails