import { useEffect } from "react";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import { DeleteNotification, GetAllNotificationByUserid } from "../../utils/proxy/NotificationProxy";
import { Link, useNavigate } from "react-router-dom";
import { data } from "jquery";

export const NotificationModal = ({ notificationModal, setNotificationModal, setNewNotification }) => {

    const [notification, setNotification] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const getNotifications = async () => {
            let d = await GetAllNotificationByUserid(localStorage.getItem('uuid'), 'unread','');
            if (d) {
                setNotification(d);
            }
        }
        getNotifications();
    }, [])

    const handleNavigate = async(id,link) =>{
        setNotificationModal(false);
        navigate(link);
        await DeleteNotification(id);
        setNewNotification(true)
    }

    return (
        <Modal show={notificationModal} onHide={() => setNotificationModal(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Notification</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ height: '300px', overflow:'auto' }}>
                {notification.length > 0 ? <div className="list-inline-item dropdown notification-list dropdown-arrow dropdown-menu-lg">
                    {notification.map(data => (
                        <div key={data.id} onClick={()=>handleNavigate(data.id,data.link)} className="dropdown-item notify-item arrow-none waves-effect">
                            <div className="notify-icon bg-primary">
                                <i className="mdi mdi-message-text-outline" />
                            </div>
                            <p className="notify-details">
                                <b>{data.title}</b>
                                <small className="text-muted" dangerouslySetInnerHTML={{__html:data.content}}>
                                    
                                </small>
                            </p>
                        </div>
                    ))}
                </div> : <div>No Notification</div>}
            </Modal.Body>
        </Modal>
    )
}