import React, { useState } from 'react'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import {ForgotPassword, LoginProxy, getAuthServer} from '../../utils/proxy/Authproxy'
import { Alert } from 'bootstrap';
import {GrLogin} from 'react-icons/gr'
import { GetPermissionsByUserid } from '../../utils/proxy/UserPermissionsProxy';
import { Permissions } from '../../utils/Permissions';
import { AUTH_URL, REPORTING_URL } from '../../utils/constant';
import Swal from 'sweetalert2';

function Login(props) {

    const [companyid,setCompanyid] = useState();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const handleUsernameChange = (event) => {
        setUsername(event.target.value);
    };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };


    const getLogin = async (email,password) => {
        let login = await LoginProxy(email,password);
        if(login){
            
           props.setIsloggedin();
           localStorage.setItem("accessToken",login.accessToken);
           localStorage.setItem("tokenType",login.tokenType);
           localStorage.setItem("username",login.username);
           localStorage.setItem("firstname",login.firstname);
           localStorage.setItem("lastname",login.lastname);
           localStorage.setItem("role",login.role);
           localStorage.setItem("uuid",login.uuid);
           localStorage.setItem("type",login.type);
           localStorage.setItem("adminid",login.adminid);
           localStorage.setItem("managers",login.managerid);
           localStorage.setItem("image",login.image);
      
        }else{
          Swal.fire({
            title: "Invalid Login credentials..!",
            text: "Check The Login Details You Have Entered.",
            icon: "error"
          });
        }
      }

    const handleSubmit = async (e) => {

      e.preventDefault();

        if (username === '' || password === '' || companyid === '') {
            alert('All Fields Are requuired');
          } else {
            let srvd = await getAuthServer(companyid);

            if (srvd) {
              localStorage.setItem("AUTH_URL",srvd.authurl)
              localStorage.setItem("REPORTING_URL",srvd.reportingurl)
              getLogin(username,password,props);
            }else{
              Swal.fire({
                title: "Invalid !",
                text: "Invalid Company Id",
                icon: "error"
              });
            }

      
          }

        


    };
    
    const handleForgotPassword = async() =>{
      if((companyid == '' || companyid == null) || (username == '' || username == null)){
        Swal.fire({
          title:'Info',
          text:'Enter Company Id And Username then Click on Forget Password Link',
          icon:'info'
        })
      }else{
        let d = await getAuthServer(companyid);
        if(d.id){
          Swal.showLoading();
          let k = await ForgotPassword(d.authurl,username);
          if (k.status === 'success') {
            Swal.hideLoading();
            Swal.fire({
              title:'Success',
              text:k.message,
              icon:'success'
            })
            setCompanyid(null);
            setUsername('');
          }else if(k.status === 'error'){
            Swal.hideLoading();
            Swal.fire({
              title:'Error',
              text:k.message,
              icon:'error'
            })
          }else{
            Swal.hideLoading();
            Swal.fire({
              title:'Error',
              text:'Something Went Wrong',
              icon:'error'
            })
          }
        }else{
          Swal.hideLoading();
          Swal.fire({
            title:'Error',
            text:'Invalid Company Id',
            icon:'error'
          })
        }
      }
    }

    return (
        <div className='d-flex align-items-center justify-content-center ' style={{ height: "100vh",backgroundImage:"url('/images/ti.png')",backgroundPosition:'center',backgroundRepeat:'no-repeat',backgroundSize:'cover'}}>
            <div className='glass p-4' style={{backgroundColor:"rgb(255 255 255 / 60%)"}} >
                <div className='d-flex align-items-center justify-content-center '>
                <img src={process.env.PUBLIC_URL + '/images/loginicon.png'} alt="My Image" width={100} />
                </div>
             <h4 style={{ color: '#357631',
    textAlign:'center',
    marginBottom: 5,
   paddingTop:5}} >Log In</h4>
   <Form onSubmit={(e) => handleSubmit(e)}>
                    <Form.Group className="mb-1" controlId="formBasicEmail">
                            <Form.Label>Company Id</Form.Label>
                            <Form.Control className='glass' style={{height: 40,
    width:250,
    borderColor: '#ccc',
    borderWidth: 1,
    paddingHorizontal: 8,
    marginBottom: 16,
    borderTopLeftRadius:20,
    borderBottomRightRadius:20,
    backgroundColor:"rgb(255 255 255 / 20%) !important"}} type="number" value={companyid} onChange={(e) => setCompanyid(e.target.value)} placeholder="Company Id" 
    />
                        
                        </Form.Group>
                        <Form.Group className="mb-1" controlId="formBasicEmail">
                            <Form.Label>Username</Form.Label>
                            <Form.Control className='glass' style={{height: 40,
    width:250,
    borderColor: '#ccc',
    borderWidth: 1,
    paddingHorizontal: 8,
    marginBottom: 16,
    borderTopLeftRadius:20,
    borderBottomRightRadius:20,
    backgroundColor:"rgb(255 255 255 / 20%) !important"}} type="text" value={username} onChange={handleUsernameChange} placeholder="Username" />
                        
                        </Form.Group>

                        <Form.Group className="mb-1" controlId="formBasicPassword">
                            <Form.Label>Password</Form.Label>
                            <Form.Control  className='glass' style={{height: 40,
    width:250,
    borderColor: '#ccc',
    borderWidth: 1,
    paddingHorizontal: 8,
    marginBottom: 16,
    borderTopLeftRadius:20,
    borderBottomRightRadius:20,
    backgroundColor:"rgb(255 255 255 / 20%) !important"
    }} type="password" value={password} onChange={handlePasswordChange} placeholder="Password" />
                        </Form.Group>
                        <Form.Group className="mb-1" controlId="formBasicCheckbox">
                            <Form.Check  type="checkbox" label="Remember Me" />
                        </Form.Group>
                       
                        
      <button style={{ width:230,
    height:43,
    backgroundColor:"#28B3D2",
    padding:10,
    border:0,
    color:"#fff",
    borderTopLeftRadius:20,
    borderTopRightRadius:10,
    borderBottomRightRadius:20,
    borderBottomLeftRadius:10,
    margin:10,
    justifyContent:'center',
    alignItems:'center'}}  size="lg" type="submit">
       Log In  
      </button >
      </Form>

      <div >
      <div className='d-flex align-items-center justify-content-center text-primary' ><span className='waves-effect' onClick={handleForgotPassword}>Forgot Password ?</span></div>

    </div>
    
              
                    </div>
        </div>
    )
}

export default Login

