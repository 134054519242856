import { Button, Card, Col, Container, Row } from "react-bootstrap"
import { FaTasks } from "react-icons/fa"
import { AiOutlineUserAdd } from "react-icons/ai";
import { MdOutlineLeaderboard } from "react-icons/md";
import CardDash from "./Common/CardDash";
import { DemoChart1 } from "./Includes/DemoChart1";
import { DonutDemo } from "./Includes/DonutDemo";
import { LineBarDemochart } from "./Includes/LineBarDemochart";
import { HorizontalBarChart } from "./Includes/HorizontalBarChart";
import Calendar from 'react-calendar';
import { useNavigate } from "react-router-dom";
import { ExpenseChart } from "./Includes/ExpenseChart";
import TaskPendingChart from "./Includes/TaskPendingChart";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { CardDash2 } from "./Common/CardDash2";
import { GetDataForCardDash } from "../utils/proxy/DashboardProxy";
import UserNotes from "./Common/UserNotes";
import MoodChart from "./Common/MoodChart";
import Select from "react-select";
import { AllUsers, GetAllManagerUsers } from "../utils/proxy/Authproxy";
import { GetLiveNotice } from "../utils/proxy/NoticeProxy";

export const UserDashboard2 = () => {

  const navigate = useNavigate();
  const [cardData, setCardData] = useState([]);

  const [expensedur, setExpensedur] = useState();
  const [taskof, setTaskof] = useState();
  const [moodMonth, setMoodMonth] = useState();

  const [totalExpense, setTotalExpense] = useState(0);
  const [users, setusers] = useState([])
  const [selectedUsers, setSelectedUsers] = useState(localStorage.getItem('role') === 'User' ? { value: localStorage.getItem('uuid'), label: localStorage.getItem('firstname') + " " + localStorage.getItem('lastname') + " (Self)" } : null);
  const [selectedUserId, setSelectedUserId] = useState(localStorage.getItem('role') === 'User' ? localStorage.getItem('uuid') : '');

  const [notices, setNotices] = useState([]);

  useEffect(() => {
    const getCardData = async () => {
      let d = await GetDataForCardDash(localStorage.getItem('uuid'), localStorage.getItem("role"));
      if (d) {
        setCardData(d);
      } else {
        Swal.fire({
          title: 'Error',
          text: 'Something Went Wrong',
          icon: 'error'
        })
      }
    }

    getCardData();
  }, [])

  useEffect(() => {

    const getAllUsersData = async () => {
      if (localStorage.getItem('role') === 'Admin') {
        let allusers = await AllUsers();
        allusers.push({ firstname: localStorage.getItem('firstname'), lastname: localStorage.getItem('lastname'), userid: localStorage.getItem('uuid'), username: localStorage.getItem('username') })
        setusers(allusers);
      } else if (localStorage.getItem('role') === 'Manager') {
        let allusers = await GetAllManagerUsers(localStorage.getItem('uuid'));
        setusers(allusers);
      }
    };
    getAllUsersData();
  }, []);

  useEffect(() => {
    const getNotices = async () => {
      let d = await GetLiveNotice();
      if (d) {
        setNotices(d);
      }
    }

    getNotices();
  }, []);

  const options = [];

  users.map((user) => {
    options.push({
      value: user.userid,
      label: user.username + "(" + user.firstname + " " + user.lastname + ")",
    });
  });

  const handleSelectChange = (selected) => {
    if (selected == null) {
      setSelectedUsers(null);
      setSelectedUserId('')
      return;
    }
    setSelectedUsers(selected);
    setSelectedUserId(selected.value);
  };

  const iconComponents = {
    FaTasks: FaTasks, // Map the icon names to the imported components
    AiOutlineUserAdd: AiOutlineUserAdd,
    MdOutlineLeaderboard: MdOutlineLeaderboard
    // Add other icon components here if needed
  };

  const cardArray = [
    { title: 'Create Task', icon: 'FaTasks' },
    { title: 'Create Lead', icon: 'MdOutlineLeaderboard' },
    { title: 'Add User', icon: 'AiOutlineUserAdd' },
  ]

  const chartData = [
    { count: 21, name: 'Completed Tasks', icon: 'mdi mdi-checkbox-marked-circle' },
    { count: 17, name: 'In-Progress Tasks', icon: 'mdi mdi-account' },
    { count: 9, name: 'Leads', icon: 'mdi mdi-account' },
    { count: 10, name: 'Products', icon: 'mdi mdi-account' },
  ]

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      minHeight: '24px',  // Adjust height here
      borderRadius: '5px',
      color:'#000'
      //borderColor: state.isFocused ? '#2684FF' : '#ccc',
      //boxShadow: state.isFocused ? '0 0 0 1px #2684FF' : null,
    }),
    valueContainer: (provided) => ({
      ...provided,
      height: '24px',  // Match the control height here
      padding: '0 8px',
      color:'#000'
    }),
    input: (provided) => ({
      ...provided,
      margin: '0px',
      color:'#000'
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      //height: '24px',  // Adjust height for the indicator (dropdown arrow)
    }),
  };

  const tileContent = ({ date, view }) => {
    // Check if it's the desired view (for example, 'month')
    if (view === 'month') {
      // Check if it's the desired date(s) to display dot(s)
      if (date.getDate() === 15 || date.getDate() === 20) {
        return <div className="d-flex"><div style={{ width: 6, height: 6, backgroundColor: 'red', borderRadius: '50%' }} onClick={(event) => { event.stopPropagation(); alert("Dot Clicked") }}></div><div style={{ width: 6, height: 6, backgroundColor: 'green', borderRadius: '50%' }}></div></div>;
      }
    }
    return null;
  };

  const handleClick = (date) => {
    const d = new Date(date).toDateString();
    navigate('/attendance', { state: { dateProp: date } })
  }

  return (

    <>
      {
        notices.length > 0 ? <div className='notice__block'>
          <marquee>{notices.map(d => <>
            <i class="fa fa-star" aria-hidden="true"></i>
            <span style={{ marginRight: 10, marginLeft: 10 }}>{d.notice}</span>
            </>)}
          </marquee>
        </div> : ''
      }

      <div className='container-fluid dashboard__section' style={{ minHeight: "80vh" }}>

        <div className='glass-body m-2 p-4 main__container'>

          {/* <div class="row">
            <div class="col-sm-12">
              <div class="page-title-box">
                <h4 class="page-title">Dashboard</h4>
              </div>
            </div>
            <div class="clearfix"></div>
          </div> */}

          <div className="row">
            <div className="col-xl-8">
              <div className="mb-4 counter__section row">
                {cardData.map((item) => (
                  <CardDash2 count={item.count} title={item.title} />
                ))}
              </div>

              <div className="card" style={{height:'80%'}}>
                <Card.Header>Tasks Of {taskof} </Card.Header>
                <div className="card-body" >
                  <LineBarDemochart setTaskof={setTaskof} />
                </div>
              </div>

            </div>


            <div className="col-xl-4">
              
              <div className="card">
                <Card.Header>
                  <div>Daily Mood of {moodMonth} </div>

                  <div style={{ width:'100%',zIndex:2,background:'#fff',marginTop:10,marginBottom:10}}>
                    <Select
                    options={options}
                    value={selectedUsers}
                    styles={customStyles}
                    onChange={handleSelectChange} />
                  </div>

                  </Card.Header>
                <div className="card-body">
                  <MoodChart setMoodMonth={setMoodMonth} userid={selectedUserId} />
                </div>
              </div>

              <div className="card" style={{ height: "302px" }} >
                <Card.Header>My Notes</Card.Header>
                <div className="card-body" style={{ overflow: 'scroll' }}>
                  <UserNotes />
                </div>
              </div>

            </div>
          </div>

          <div className="row">
            <div className="col-xl-4 ">
              <div className="card">
                <Card.Header>Lead Sources</Card.Header>
                <div className="card-body">
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <div>
                      <DonutDemo />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4">
              <div className="card">
                <Card.Header> 
                Expense {expensedur} <span style={{color:'#3F51B5'}}> (Total Expenses {totalExpense} /-) </span>
                 </Card.Header>
                <div className="card-body">
                  <ExpenseChart setExpensedur={setExpensedur} setTotalExpense={setTotalExpense} />
                </div>
              </div>
            </div>
            <div className="col-xl-4">
              <div className="card">
                <Card.Header>Calendar</Card.Header>
                <div className="card-body">
                  <Calendar
                    className='react-calendar-dashboard react-calendar__navigation__label__labelText_bold'
                    style={{ width: '100%', height: '100%' }}
                    value={new Date()}
                    //  tileContent={tileContent}
                    onClickDay={handleClick} />
                </div>
              </div>
            </div>
          </div>

        </div>

      </div>
    </>

  )
}