import { Button, Container, Form } from "react-bootstrap"
import { IoHomeOutline } from "react-icons/io5"
import { Op_per } from "../../utils/Permissions"
import Select from "react-select"
import React,{ useEffect, useState } from "react"
import { AllUsers, GetAllManagerUsers } from "../../utils/proxy/Authproxy"
import LoadingComponent from "../Includes/LoadingComponent"
import DataTable from "react-data-table-component"
import { GetFieldForceStatusReport } from "../../utils/proxy/ChartsProxy"
import { RiFileExcel2Fill } from "react-icons/ri"
import { Parser } from "@json2csv/plainjs"

export const FieldForceStatus = () =>{

    const [data, setData] = useState([]);
    const [userid, setUserId] = useState(localStorage.getItem('role') === 'User' ? localStorage.getItem('uuid') : null);
    const [selectedUser, setSelectedUser] = useState();
    const [users, setUsers] = useState([]);
    const [reload, setReload] = useState(false);
    const [pending, setPending] = useState(false);

    useEffect(() => {
        // Fetch data from an API when the component mounts
        const getallusers = async () => {
            if (localStorage.getItem('role') === 'Admin') {
                let allusers = await AllUsers();
                allusers.push({ firstname: localStorage.getItem('firstname'), lastname: localStorage.getItem('lastname'), userid: localStorage.getItem('uuid'), username: localStorage.getItem('username') })
                setUsers(allusers);
            } else if (localStorage.getItem('role') === 'Manager') {
                let allusers = await GetAllManagerUsers(localStorage.getItem('uuid'));
                setUsers(allusers);
            }
        };
        getallusers();
    }, []);

    useEffect(() => {
        // Fetch data from an API when the component mounts
        const getallteritories = async (userid) => {
            let userteritories = await GetFieldForceStatusReport(
                userid
            );

            if (userteritories) {
                setData(userteritories);
                setPending(false);
            } else {
                alert("Error", "Issue While Fetching Tour Plan");
            }
        };
        if (userid) {
            setPending(true);
            getallteritories(userid);
        }
        setReload(false);
    }, [reload, userid]);

    const handleSelectChange = (selected) => {

        setUserId(selected.value);
    };

    const options = [];

    users.map((user) => {
        options.push({
            value: user.userid,
            label: user.username + "(" + user.firstname + " " + user.lastname + ")",
        });
    });

    const columns = [
        {
            name: "FieldForce Name",
            selector: (row) => <div style={{ whiteSpace: 'normal', backgroundColor: row.username === "" || row.username === null ? "#f36e6f" : "", borderRadius: "10px", border: row.username === "" || row.username === null ? "2px solid transparent" : "2px solid transparent", padding: "3px", display: "flex", flexDirection: "row", color: row.username === "" || row.username === null ? "#fff" : "#000", margin: "3px", }}>{row.username ? row.username : "N/A"}</div>,
            // width: "9%",
            
        },
        {
            name: "Headquator",
            selector: (row) => <div style={{ whiteSpace: 'normal', backgroundColor: row.headquator === "" || row.headquator === null ? "#f36e6f" : "", borderRadius: "10px", border: row.headquator === "" || row.headquator === null ? "2px solid transparent" : "2px solid transparent", padding: "3px", display: "flex", flexDirection: "row", color: row.headquator === "" || row.headquator === null ? "#fff" : "#000", margin: "3px", }}>{row.headquator ? row.headquator : "N/A"}</div>,
            // width: "9%",
            
        },
        {
            name: "Designation",
            selector: (row) => <div style={{ whiteSpace: 'normal', backgroundColor: row.designation == "" ? "N/A" : row.designation + " " + row.designation === "" || row.designation == "" ? "N/A" : row.designation + " " + row.designation === null ? "#f36e6f" : "", borderRadius: "10px", border: row.designation == "" ? "N/A" : row.designation + " " + row.designation === "" || row.designation == "" ? "N/A" : row.designation + " " + row.designation === null ? "2px solid transparent" : "2px solid transparent", padding: "3px", display: "flex", flexDirection: "row", color: row.designation == "" ? "N/A" : row.designation + " " + row.designation === "" || row.designation == "" ? "N/A" : row.designation + " " + row.designation === null ? "#fff" : "#000", margin: "3px", }}>{row.designation == null ? "N/A" : row.designation}</div>,
            // width: "9%",
        },
        {
            name: "State Name",
            selector: (row) => <div style={{ whiteSpace: 'normal', backgroundColor: row.statename == "" ? "N/A" : row.statename + " " + row.statename === "" || row.statename == "" ? "N/A" : row.statename + " " + row.statename === null ? "#f36e6f" : "", borderRadius: "10px", border: row.statename == "" ? "N/A" : row.statename + " " + row.statename === "" || row.statename == "" ? "N/A" : row.statename + " " + row.statename === null ? "2px solid transparent" : "2px solid transparent", padding: "3px", display: "flex", flexDirection: "row", color: row.statename == "" ? "N/A" : row.statename + " " + row.statename === "" || row.statename == "" ? "N/A" : row.statename + " " + row.statename === null ? "#fff" : "#000", margin: "3px", }}>{row.statename == null ? "N/A" : row.statename}</div>,
            // width: "9%",
        },
        {
            name: "Reporting To",
            selector: (row) => <div style={{ whiteSpace: 'normal', backgroundColor: row.reportingto == "" ? "N/A" : row.reportingto + " " + row.reportingto === "" || row.reportingto == "" ? "N/A" : row.reportingto + " " + row.reportingto === null ? "#f36e6f" : "", borderRadius: "10px", border: row.reportingto == "" ? "N/A" : row.reportingto + " " + row.reportingto === "" || row.reportingto == "" ? "N/A" : row.reportingto + " " + row.reportingto === null ? "2px solid transparent" : "2px solid transparent", padding: "3px", display: "flex", flexDirection: "row", color: row.reportingto == "" ? "N/A" : row.reportingto + " " + row.reportingto === "" || row.reportingto == "" ? "N/A" : row.reportingto + " " + row.reportingto === null ? "#fff" : "#000", margin: "3px", }}>{row.reportingto == null ? "N/A" : row.reportingto}</div>,
            // width: "12%",
        },
        {
            name: <p>Active Territory</p>,
            selector: (row) => <span className="cursor">{row.active_teritory}</span>,
            // <div style={{ whiteSpace: 'normal',backgroundColor: row.unplanned_doctor=== "" || row.unplanned_doctor=== null ? "#f36e6f":"", borderRadius:"10px", border: row.unplanned_doctor=== "" || row.unplanned_doctor=== null ? "2px solid transparent": "2px solid transparent",padding:"3px", display:"flex", flexDirection:"row",color:row.unplanned_doctor=== "" || row.unplanned_doctor=== null ? "#fff": "#000", margin:"3px", }}>{row.unplanned_doctor? row.unplanned_doctor: "N/A"}</div> ,
            // width: "5%",
        },
        {
            name: <p>Pending Territory</p>,
            selector: (row) => <span className="cursor">{row.pending_teritory}</span>,
            // <div style={{ whiteSpace: 'normal',backgroundColor: row.unplanned_doctor=== "" || row.unplanned_doctor=== null ? "#f36e6f":"", borderRadius:"10px", border: row.unplanned_doctor=== "" || row.unplanned_doctor=== null ? "2px solid transparent": "2px solid transparent",padding:"3px", display:"flex", flexDirection:"row",color:row.unplanned_doctor=== "" || row.unplanned_doctor=== null ? "#fff": "#000", margin:"3px", }}>{row.unplanned_doctor? row.unplanned_doctor: "N/A"}</div> ,
            // width: "5%",
        },
        {
            name: <p>Active Doctor</p>,
            selector: (row) => <span className="cursor">{row.active_doctor}</span>,

            // <div style={{ whiteSpace: 'normal',backgroundColor: row.planned_clinic=== "" || row.planned_clinic=== null ? "#f36e6f":"", borderRadius:"10px", border: row.planned_clinic=== "" || row.planned_clinic=== null ? "2px solid transparent": "2px solid transparent",padding:"3px", display:"flex", flexDirection:"row",color:row.planned_clinic=== "" || row.planned_clinic=== null ? "#fff": "#000", margin:"3px", }}>{row.planned_clinic? row.planned_clinic: "N/A"}</div>,
            // width: "5%",
        },
        {
            name: <p>Pending Doctor</p>,
            selector: (row) => <span className="cursor">{row.pending_doctor}</span>,

            // <div style={{ whiteSpace: 'normal',backgroundColor: row.planned_clinic=== "" || row.planned_clinic=== null ? "#f36e6f":"", borderRadius:"10px", border: row.planned_clinic=== "" || row.planned_clinic=== null ? "2px solid transparent": "2px solid transparent",padding:"3px", display:"flex", flexDirection:"row",color:row.planned_clinic=== "" || row.planned_clinic=== null ? "#fff": "#000", margin:"3px", }}>{row.planned_clinic? row.planned_clinic: "N/A"}</div>,
            // width: "5%",
        },
        {
            name: <p>Active Hospital</p>,
            selector: (row) => <span className="cursor">{row.active_hospital}</span>,
            // <div style={{ whiteSpace: 'normal',backgroundColor: row.planned_chemist=== "" || row.planned_chemist=== null ? "#f36e6f":"", borderRadius:"10px", border: row.planned_chemist=== "" || row.planned_chemist=== null ? "2px solid transparent": "2px solid transparent",padding:"3px", display:"flex", flexDirection:"row",color:row.planned_chemist=== "" || row.planned_chemist=== null ? "#fff": "#000", margin:"3px", }}>{row.planned_chemist? row.planned_chemist: "N/A"}</div> ,
            // width: "5%",
        },
        {
            name: <p>Pending Hospital</p>,
            selector: (row) => <span className="cursor">{row.pending_hospital}</span>,
            // <div style={{ whiteSpace: 'normal',backgroundColor: row.planned_chemist=== "" || row.planned_chemist=== null ? "#f36e6f":"", borderRadius:"10px", border: row.planned_chemist=== "" || row.planned_chemist=== null ? "2px solid transparent": "2px solid transparent",padding:"3px", display:"flex", flexDirection:"row",color:row.planned_chemist=== "" || row.planned_chemist=== null ? "#fff": "#000", margin:"3px", }}>{row.planned_chemist? row.planned_chemist: "N/A"}</div> ,
            // width: "5%",
        },
        {
            name: <p>Active Clinic</p>,
            selector: (row) => <span className="cursor">{row.active_clinic}</span>,
            //  <div style={{ whiteSpace: 'normal',backgroundColor: row.planned_stockist=== "" || row.planned_stockist=== null ? "#f36e6f":"", borderRadius:"10px", border: row.planned_stockist=== "" || row.planned_stockist=== null ? "2px solid transparent": "2px solid transparent",padding:"3px", display:"flex", flexDirection:"row",color:row.planned_stockist=== "" || row.planned_stockist=== null ? "#fff": "#000", margin:"3px", }}>{row.planned_stockist? row.planned_stockist: "N/A"}</div>  ,
            // width: "5%",
        },
        {
            name: <p>Pending Clinic</p>,
            selector: (row) => <span className="cursor">{row.pending_clinic}</span>,
            //  <div style={{ whiteSpace: 'normal',backgroundColor: row.planned_stockist=== "" || row.planned_stockist=== null ? "#f36e6f":"", borderRadius:"10px", border: row.planned_stockist=== "" || row.planned_stockist=== null ? "2px solid transparent": "2px solid transparent",padding:"3px", display:"flex", flexDirection:"row",color:row.planned_stockist=== "" || row.planned_stockist=== null ? "#fff": "#000", margin:"3px", }}>{row.planned_stockist? row.planned_stockist: "N/A"}</div>  ,
            // width: "5%",
        },
        {
            name: <p>Active Chemist</p>,
            selector: (row) => <span className="cursor">{row.active_chemist}</span>,
            //  <div style={{ whiteSpace: 'normal',backgroundColor: row.planned_hospital=== "" || row.planned_hospital=== null ? "#f36e6f":"", borderRadius:"10px", border: row.planned_hospital=== "" || row.planned_hospital=== null ? "2px solid transparent": "2px solid transparent",padding:"3px", display:"flex", flexDirection:"row",color:row.planned_hospital=== "" || row.planned_hospital=== null ? "#fff": "#000", margin:"3px", }}>{row.planned_hospital? row.planned_hospital: "N/A"}</div> ,
            // width: "5%",
        },
        {
            name: <p>Pending Chemist</p>,
            selector: (row) => <span className="cursor">{row.pending_chemist}</span>,
            //  <div style={{ whiteSpace: 'normal',backgroundColor: row.planned_hospital=== "" || row.planned_hospital=== null ? "#f36e6f":"", borderRadius:"10px", border: row.planned_hospital=== "" || row.planned_hospital=== null ? "2px solid transparent": "2px solid transparent",padding:"3px", display:"flex", flexDirection:"row",color:row.planned_hospital=== "" || row.planned_hospital=== null ? "#fff": "#000", margin:"3px", }}>{row.planned_hospital? row.planned_hospital: "N/A"}</div> ,
            // width: "5%",
        },
        {
            name: <p>Active Stockist</p>,
            selector: (row) => <span className="cursor">{row.active_stockist}</span>,
            //  <div style={{ whiteSpace: 'normal',backgroundColor: row.planned_hospital=== "" || row.planned_hospital=== null ? "#f36e6f":"", borderRadius:"10px", border: row.planned_hospital=== "" || row.planned_hospital=== null ? "2px solid transparent": "2px solid transparent",padding:"3px", display:"flex", flexDirection:"row",color:row.planned_hospital=== "" || row.planned_hospital=== null ? "#fff": "#000", margin:"3px", }}>{row.planned_hospital? row.planned_hospital: "N/A"}</div> ,
            // width: "5%",
        },
        {
            name: <p>Pending Stockist</p>,
            selector: (row) => <span className="cursor">{row.pending_stockist}</span>,
            //  <div style={{ whiteSpace: 'normal',backgroundColor: row.planned_hospital=== "" || row.planned_hospital=== null ? "#f36e6f":"", borderRadius:"10px", border: row.planned_hospital=== "" || row.planned_hospital=== null ? "2px solid transparent": "2px solid transparent",padding:"3px", display:"flex", flexDirection:"row",color:row.planned_hospital=== "" || row.planned_hospital=== null ? "#fff": "#000", margin:"3px", }}>{row.planned_hospital? row.planned_hospital: "N/A"}</div> ,
            // width: "5%",
        },
        {
            name: <p>Active Cip</p>,
            selector: (row) => <span className="cursor">{row.active_cip}</span>,
            //  <div style={{ whiteSpace: 'normal',backgroundColor: row.planned_hospital=== "" || row.planned_hospital=== null ? "#f36e6f":"", borderRadius:"10px", border: row.planned_hospital=== "" || row.planned_hospital=== null ? "2px solid transparent": "2px solid transparent",padding:"3px", display:"flex", flexDirection:"row",color:row.planned_hospital=== "" || row.planned_hospital=== null ? "#fff": "#000", margin:"3px", }}>{row.planned_hospital? row.planned_hospital: "N/A"}</div> ,
            // width: "5%",
        },
        {
            name: <p>Pending Cip</p>,
            selector: (row) => <span className="cursor">{row.pending_cip}</span>,
            //  <div style={{ whiteSpace: 'normal',backgroundColor: row.planned_hospital=== "" || row.planned_hospital=== null ? "#f36e6f":"", borderRadius:"10px", border: row.planned_hospital=== "" || row.planned_hospital=== null ? "2px solid transparent": "2px solid transparent",padding:"3px", display:"flex", flexDirection:"row",color:row.planned_hospital=== "" || row.planned_hospital=== null ? "#fff": "#000", margin:"3px", }}>{row.planned_hospital? row.planned_hospital: "N/A"}</div> ,
            // width: "5%",
        },
    ];

    async function downloadCSV(plans) {
        let csv;
        try {
            const opts = {
                fields: [
                    { label: "FieldForce Name", value: "username" },
                    { label: "Headquator", value: "headquator" },
                    { label: "Designation", value: "designation" },
                    { label: "State Name", value: "statename" },
                    { label: "Reporting To", value: "reportingto" },
                    { label: "Active Territory", value: "active_teritory" },
                    { label: "Pending Territory", value: "pending_teritory" },
                    { label: "Active Doctor", value: "active_doctor" },
                    { label: "Pending Doctor", value: "pending_doctor" },
                    { label: "Active Hospital", value: "active_hospital" },
                    { label: "Pending Hospital", value: "pending_hospital" },
                    { label: "Active Clinic", value: "active_clinic" },
                    { label: "Pending Clinic", value: "pending_clinic" },
                    { label: "Active Chemist", value: "active_chemist" },
                    { label: "Pending Chemist", value: "pending_chemist" },
                    { label: "Active Stockist", value: "active_stockist" },
                    { label: "Pending Stockist", value: "pending_stockist" },
                    { label: "Active Cip", value: "active_cip" },
                    { label: "Pending Cip", value: "pending_cip" },
                ],
            };
            const parser = new Parser(opts);
            csv = parser.parse(plans);
        } catch (err) {
            console.error(err);
        }

        const link = document.createElement("a");

        if (csv == null) return;

        const filename = "FieldForceStatus.csv";

        csv = `FieldForce Status Report \n\n\n${csv}`;

        if (!csv.match(/^data:text\/csv/i)) {
            csv = `data:text/csv;charset=utf-8,${csv}`;
        }

        link.setAttribute("href", encodeURI(csv));
        link.setAttribute("download", filename);
        link.click();
    }

    const Export = ({ onExport }) => (
        <Button style={{ position: 'absolute', right: 40, backgroundColor: "#2194f0",display:Op_per.get().REPORTS_TP_VS_ACHIEVEMENT.ALLOW_EXPORT_XLSX ? '' : 'none' }} onClick={(e) => onExport(e.target.value)} disabled={!(userid)}><RiFileExcel2Fill /></Button>
    );

    const actionsMemo = React.useMemo(
        () => <Export onExport={() => downloadCSV(data)} />,
        [data]
    );

    return(
        <Container fluid style={{ minHeight: "80vh" }}>
            <div
                className="glass-body"
                style={{
                    margin: 10,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    padding: 0,
                    backgroundColor: 'transparent'
                }}
            >

                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <ul id="breadcrumb">
                        <li><a href="/"><IoHomeOutline
                            style={{ color: "white", fontSize: 20 }}
                        /></a></li>
                        <li><a href="/fieldforcestatus"><span class="icon icon-beaker"> </span>Reports</a></li>

                        <li><a href="#"><span class="icon icon-double-angle-right"></span>Field Force Status</a></li>

                    </ul>

                </div>

            </div>
            <div className="glass-body" style={{ margin: 10 }}>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "left",
                    }}
                    className="flex-column-mobile"
                >
                    <div style={{ width: "250px", display: Op_per.get().REPORTS_TP_VS_ACHIEVEMENT.USER_DROPDOWN ? '' : 'none' }}>
                        <Form.Group
                            className="mb-3 d-flex w-100"
                            style={{ gap: "15px" }}
                            controlId="formBasicPassword"
                        >
                            <Form.Label className="mt-2">Users</Form.Label>
                            <div className="w-75">
                                <Select
                                    className="selectdoctor"
                                    options={options}
                                    value={selectedUser}
                                    onChange={handleSelectChange}
                                    styles={{

                                        dropdownIndicator: (provided) => ({
                                            ...provided,
                                            color: '#ccc' // Change color of the arrow here
                                        })
                                    }}
                                />
                            </div>
                        </Form.Group>
                    </div>
                </div>
                <div className="pt-3">
                    <DataTable
                        columns={columns}
                        data={data}
                        actions={actionsMemo}
                        pagination
                        customStyles={{ headRow: { style: { backgroundColor: '#c0dfff', padding: 7 }, } }}
                        style={{ margin: '10px', padding: 10 }}
                        progressPending={pending}
                        progressComponent={<LoadingComponent />}
                        dense
                    />
                </div>
            </div>
        </Container>
    )
}