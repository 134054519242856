import React from 'react';
import { useEffect } from 'react';
import Chart from 'react-apexcharts';
import { GetDisabledUsers, GetWorkingUsers } from '../../utils/proxy/DashboardProxy';
import { useState } from 'react';
import { Row } from 'react-bootstrap';
import { BiSolidReport } from "react-icons/bi";

const WorkingUsers = () => {

  const [workings, setWorkings] = useState({});

  useEffect(() => {
    const getdisabled = async () => {
      let d = await GetWorkingUsers();
      setWorkings(d);
    }
    getdisabled();
  }, [])

  const chartOptions = {
    chart: {
      type: 'donut',
      toolbar: {
        show: true,
      }
    },
    labels: ['Started', 'Ended', 'Not Started'],
    dataLabels: {
      enabled: false
    },
    pie: {
      donut: {
        labels: {
          show: true,
        },
      },
    },
    colors: ['#3887BE', '#38419D', '#ff0000'],
    legend: {
      show: true,
      position: 'bottom'
    },
    // title: {
    //   text: "Today's Visit Summary",
    //   style: {
    //     fontSize: '13px',
    //   }
    // },
    legend: {
      show: true,
      position: 'bottom',
      markers: {
        width: 12,
        height: 12,
        radius: 2 // To make the legend marker square, set radius to 0
      }
    }
  };

  let series = [];
  if(workings){
    series = [workings.daystarted, workings.dayended, workings.notstarted];
  }

  return (

   <>
       <div style={{ backgroundColor: 'white', padding: 10 }}>
          <h6 style={{textAlign:'center', marginBottom:10}}>Today's Visit</h6>
          <Chart options={chartOptions} series={series} type="pie" width='100%' height={238} />
        </div>
        </>
   
       
  );
};

export default WorkingUsers;
