import React, { useEffect, useState } from "react";
import { Button, Container, Modal } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import DataTable from "react-data-table-component";
import Dropdown from "react-bootstrap/Dropdown";
import {
  DeleteUserChemistStatus,
  GetAllUserChemist,
  GetAllUserChemistByFilter,
  UpdateUserChemistStatus,
} from "../../../utils/proxy/Userchemitsproxy";
import { AiFillDelete, AiTwotoneEdit } from "react-icons/ai";
import { TiTick } from "react-icons/ti";
import Select from "react-select";
import { AllUsers, GetAllManagerUsers } from "../../../utils/proxy/Authproxy";
import Addarea from "./Addchemist";
import { GetAllChemistbyUserStatus } from "../../../utils/proxy/Userchemitsproxy";
import { Parser } from "@json2csv/plainjs";
import { HiDotsVertical } from "react-icons/hi";
import { IoHomeOutline } from "react-icons/io5";
import { Op_per } from "../../../utils/Permissions";
import LoadingComponent from "../../Includes/LoadingComponent";

function Userchemistmaster() {
  const [userchemist, setUserChemist] = useState([]);
  const [userid, setUserId] = useState(localStorage.getItem('role') === 'User' ? localStorage.getItem('uuid') : "all");
  const [areatype, setAreaType] = useState("all");
  const [status, setStatus] = useState("PENDING_FOR_ADDITION,ACTIVE");
  const [reload, setReload] = useState(false);
  const [selectedUser, setSelectedUser] = useState(localStorage.getItem('role')==='User' ? {value:localStorage.getItem('uuid'),label:localStorage.getItem('firstname')+" "+localStorage.getItem('lastname')+" (Self)"} : null);
  const [users, setUsers] = useState([]);

  const [editRow,setEditRow] = useState([]);
  const [showEdit,setShowEdit] = useState(false);
  const [selectedStatus,setSelectedStatus] = useState([]);

  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const [searchQuery, setSearchQuery] = useState('');

  const filteredData = userchemist.filter((row) =>{
    if(row.chemistname?.toLowerCase().includes(searchQuery.toLowerCase())){
      return true;
    }
  })

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const editStatusChange = (selected) =>
  {

    setSelectedStatus(selected);
  }

  useEffect(() => {
    // Fetch data from an API when the component mounts
    const getallteritories = async (userid, areatype, status) => {
      setLoading(true);
      let userteritories = await GetAllChemistbyUserStatus(userid, status);
      if (userteritories) {
        setUserChemist(userteritories);
        setLoading(false);
      } else {
        alert("Error", "Issue While Fetching Chemists");
      }
    };
    getallteritories(userid, areatype, status);
    setReload(false);
  }, [reload, userid]);

  useEffect(() => {
    // Fetch data from an API when the component mounts
    const getallusers = async () => {
      var allusers;
      if(localStorage.getItem('role') === 'Admin'){
        allusers = await AllUsers();
      }else if(localStorage.getItem('role') === 'Manager'){
        allusers = await GetAllManagerUsers(localStorage.getItem('uuid'));
      }
      if (allusers) {
        setUsers(allusers);
      } else {
        alert("Error", "Issue While Fetching Users");
      }
    };
    if(localStorage.getItem('role') != 'User'){
      getallusers();
    }  
  }, []);

  const options = [];

  const statusoptions = [{
    value: "ACTIVE",
    label: "APPROVE"
  },
  {
    value: "INACTIVE",
    label: "INACTIVE"
  },
  {
    value: "REJECTED",
    label: "REJECT"
  },
  {
    value: "PENDING_FOR_ADDITION",
    label: "PENDING FOR ADDITION"
  },
  {
    value: "PENDING_FOR_DELETION",
    label: "PENDING FOR DELETION"
  }];

  users.map((user) => {
    options.push({
      value: user.userid,
      label: user.username + "(" + user.firstname + " " + user.lastname + ")",
    });
  });

  const columns = [
    // {
    //   name: "Id",
    //   selector: (row) => row.id,
    //   sortable: true,
    //   width:"5%",
    // },
    {
      name: "Chemists",
      selector: (row) => <div style={{ whiteSpace: 'normal',backgroundColor:  row.chemistname === "" ||  row.chemistname === null ? "#f36e6f":"", borderRadius:"10px", border:  row.chemistname === "" ||  row.chemistname === null ? "2px solid transparent": "2px solid transparent",padding:"3px", display:"flex", flexDirection:"row",color:  row.chemistname === "" ||  row.chemistname === null ? "#fff": "#000", margin:"3px", }}>{ row.chemistname ?  row.chemistname : "N/A"}</div>,
      width:"10%",
    },
    {
      name: "Address",
      selector: (row) => <div style={{ whiteSpace: 'normal',backgroundColor: row.address === "" || row.address === null ? "#f36e6f":"", borderRadius:"10px", border: row.address === "" || row.address === null ? "2px solid transparent": "2px solid transparent",padding:"3px", display:"flex", flexDirection:"row",color:row.address === "" || row.address === null ? "#fff": "#000", margin:"3px", }}>{row.address ? row.address : "N/A"}</div>,
      width:"15%",
    },
    {
      name: "City",
      selector: (row) => <div style={{ whiteSpace: 'normal',backgroundColor:  row.cityname === "" ||  row.cityname === null ? "#f36e6f":"", borderRadius:"10px", border:  row.cityname === "" ||  row.cityname === null ? "2px solid transparent": "2px solid transparent",padding:"3px", display:"flex", flexDirection:"row",color: row.cityname === "" ||  row.cityname === null ? "#fff": "#000", margin:"3px", }}>{ row.cityname ?  row.cityname : "N/A"}</div>,
      width:"10%",
      style: {
        marginLeft:10
      }
    },
    {
      name: "Pincode",
      selector: (row) => <div style={{ whiteSpace: 'normal',backgroundColor:  row.pincode === "" ||  row.pincode === null ? "#f36e6f":"", borderRadius:"10px", border:  row.pincode === "" ||  row.pincode === null ? "2px solid transparent": "2px solid transparent",padding:"3px", display:"flex", flexDirection:"row",color: row.pincode === "" ||  row.pincode === null ? "#fff": "#000", margin:"3px", }}>{ row.pincode ?  row.pincode : "N/A"}</div>,
      width:"10%",
    },
    {
      name: "Chemist Code",
      selector: (row) => 
      <div style={{ whiteSpace: 'normal',backgroundColor:  row.chemistcode === "" ||  row.chemistcode === null ? "#f36e6f":"", borderRadius:"10px", border:  row.chemistcode === "" ||  row.chemistcode === null ? "2px solid transparent": "2px solid transparent",padding:"3px", display:"flex", flexDirection:"row",color: row.chemistcode === "" ||  row.chemistcode === null ? "#fff": "#000", margin:"3px", }}>{ row.chemistcode ?  row.chemistcode : "N/A"}</div>,
      width:"10%",
    },
      
    
    {
      name: "Phone",
      selector: (row) => <div style={{ whiteSpace: 'normal',backgroundColor:  row.phone === "" ||  row.phone === null ? "#f36e6f":"", borderRadius:"10px", border:  row.phone === "" ||  row.phone === null ? "2px solid transparent": "2px solid transparent",padding:"3px", display:"flex", flexDirection:"row",color: row.phone === "" ||  row.phone === null ? "#fff": "#000", margin:"3px", }}>{ row.phone ?  row.phone : "N/A"}</div>,
      width:"10%",
    },
    {
      name: "Status",
      selector: (row) => <div style={{ whiteSpace: 'normal',backgroundColor:  row.status === "" ||  row.status === null ? "#f36e6f":"", borderRadius:"10px", border:  row.status === "" ||  row.status === null ? "2px solid transparent": "2px solid transparent",padding:"3px", display:"flex", flexDirection:"row",color: row.status === "" ||  row.status === null ? "#fff": "#000", margin:"3px", }}>{ row.status ?  row.status === 'ACTIVE' ? 'Approved' : row.status : "N/A"}</div>,
      width:"10%",
    },
    // {
    //   name: "Actions",
    //   selector: (row) => (
    //     <div key={row.id}>
    //       <AiTwotoneEdit
    //         style={{ color: "black", fontSize: 20 }}
    //         onClick={() => handleEditShow(row)}
    //       />
    //       &nbsp;&nbsp;&nbsp;
    //       <AiFillDelete
    //         onClick={() => handleDelete(row.id)}
    //         style={{ color: "#f36e6f", fontSize: 20 }}
    //       />
    //     </div>
    //   ),
    // },
  ];

  if(Op_per.get().USERS_USER_CHEMISTS.ALLOW_DELETE || Op_per.get().USERS_USER_CHEMISTS.ALLOW_EDIT){
    columns.push({
      name: "Actions",
      selector: (row) => (
        <div key={row.id}>
          <AiTwotoneEdit
            style={{ color: "#9889e6", fontSize: 20, display:Op_per.get().USERS_USER_CHEMISTS.ALLOW_EDIT ? '' : 'none' }}
            onClick={() => handleEditShow(row)}
          />
          &nbsp;&nbsp;&nbsp;
          <AiFillDelete
            onClick={() => handleDelete(row.id)}
            style={{ color: "#f36e6f", fontSize: 20, display:Op_per.get().USERS_USER_CHEMISTS.ALLOW_DELETE ? '' : 'none' }}
          />
        </div>
      ),
    })
  }

  const customStyles = {   head: {     style: {       backgroundColor: 'blue',     },   }, };
  const CustomHeader = ({ children }) => (   <div style={{ backgroundColor: 'blue', padding: '10px', color: 'white' }}>    {children}  </div>);

  const handleEditShow = (row) => {
    setEditRow(row);
    setSelectedStatus(statusoptions.find(item => item.value === row.status));
    setShowEdit(true);
  };

  const handleEditClose = () => {
    setEditRow([]);
    setShowEdit(false);
  }

  const handleEditSave = async () => {
    setShowEdit(false);
    let res = await UpdateUserChemistStatus(editRow.id,selectedStatus.value)
    if (res) {
      
      setReload(true)
      alert("Status Updated")
      
    } else {
      alert("Error While Updating Status")
      
    }
  }

  const handleDelete = async(row) => {
    let res = await DeleteUserChemistStatus(row)
    if (res) {
      
      setReload(true)
      alert("Deleted successfully")
      
    } else {
      alert("Error While Updating Status")
      
    }
  };

  const handleSelectChange = (selected) => {
    setSelectedUser(selected);
    setUserId(selected.value);
  };

  async function downloadCSV(userchemist) {
    let csv;
    try {
      const opts = {
        fields: [
          "id",
          "chemistname",
          "address",
          "cityname",
          "pincode",
          "chemistcode",
          "phone",
          "status",
        ],
      };
      const parser = new Parser(opts);
      csv = parser.parse(userchemist);
    } catch (err) {
      console.error(err);
    }

    const link = document.createElement("a");

    if (csv == null) return;

    const filename = "export.csv";

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute("href", encodeURI(csv));
    link.setAttribute("download", filename);
    link.click();
  }

  const Export = ({ onExport }) => (
    <Button onClick={(e) => onExport(e.target.value)}>Export All</Button>
  );

  const actionsMemo = React.useMemo(
    () => <Export onExport={() => downloadCSV(userchemist)} />,
    [userchemist]
  );

  return (
    <Container fluid style={{ minHeight: "80vh" }}>
      <div
        className="glass-body"
        style={{
          margin: 10,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          padding:0,
          backgroundColor:'transparent'
        }}
      >

<div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
<ul id="breadcrumb">
  <li><a href="/"><IoHomeOutline
            style={{ color: "white", fontSize: 20 }}
            
          /></a></li>
  <li><a href="/user-chemist-master"><span class="icon icon-beaker"> </span>Users</a></li>
  <li><a href="/user-chemist-master"><span class="icon icon-beaker"> </span> User Chemists</a></li>
  {/* <li><a href="#"><span class="icon icon-double-angle-right"></span>Doctor</a></li> */}
  
</ul>
	
</div>
      </div>

      <div className="glass-body" style={{ margin: 10 }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
          className="flex-column-mobile"
        >
          <div style={{ width: "250px" }}>
          {Op_per.get().USERS_USER_CHEMISTS.USER_DROPDOWN ? <Form.Group
              className="mb-3 d-flex w-100"
              style={{ gap: "15px" }}
              controlId="formBasicPassword"
            >
              <Form.Label className="mt-2">Users</Form.Label>
              <div className="w-75">
                <Select
                
                 className="selectdoctor"
                  options={options}
                  value={selectedUser}
                  onChange={handleSelectChange}
                  isDisabled={loading}
                  styles={{
   
   dropdownIndicator: (provided) => ({
     ...provided,
     color: '#ccc' // Change color of the arrow here
   })
 }}
                />
              </div>
            </Form.Group> : "" }
          </div>

          <div>
            <Form.Control
              style={{
                height: 40,
                width: 250,
                borderColor: "#ccc",
                borderWidth: 1,
                paddingHorizontal: 8,
                marginBottom: 16,
                borderRadius:10,
              }}
              placeholder="Search"
              type="text"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>

          <div>
          <div class="dropdown">
                <button
                  class="btn btn-secondary"
                  type="button"
                  style={{ backgroundColor: "#2194f0", display:Op_per.get().USERS_USER_CHEMISTS.ALLOW_ADD ? '' : 'none' }}
                  title="Options"
                  id="dropdown-basic"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <HiDotsVertical />
                </button>
                <ul class="dropdown-menu">
                  <li onClick={openModal} style={{display:Op_per.get().USERS_USER_CHEMISTS.ALLOW_ADD ? '' : 'none'}}>
                    <a class="dropdown-item">Add</a>
                  </li>
                 
                </ul>
              </div>

            {/* <Export onExport={() => downloadCSV(userchemist)} /> */}
          </div>
        </div>
        <div className="pt-3">
          <DataTable
            columns={columns}
            data={filteredData}
            // selectableRows
            // onSelectedRowsChange={handleChange}
            // clearSelectedRows={toggledClearRows}
            //  expandableRows
            //  expandableRowsComponent={ExpandedComponent}
            pagination
            customStyles={{headRow: {style: {backgroundColor: '#c0dfff',  padding:10,borderRadius:'10px 10px 0 0' }, }  }}
                        CustomHeader={<CustomHeader/>}
            // actions={actionsMemo}
            style={{ marginTop: 10 }}
            progressPending={loading}
            progressComponent={<LoadingComponent />}
            dense
          />
        </div>
      </div>

      <Addarea showModal={showModal} closeModal={closeModal} />

      <Modal show={showEdit} onHide={handleEditClose}>
        <Modal.Header closeButton>
          <Modal.Title>Update Status</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Form>

      <Form.Group className="mb-3" controlId="formBasicPassword">
        <Form.Label>Status <span style={{color:"red"}}>*</span></Form.Label>
        <Select
        className="selectdoctor"
                  options={statusoptions}
                  value={selectedStatus}
                  onChange={editStatusChange}
                  styles={{
   
   dropdownIndicator: (provided) => ({
     ...provided,
     color: '#ccc' // Change color of the arrow here
   })
 }}
                />
      </Form.Group>
     
    
    </Form>
    
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleEditClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleEditSave}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>

    </Container>
  );
}

export default Userchemistmaster;
