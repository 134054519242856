import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { GetTaskProgression, GetUserTaskProgression } from '../../utils/proxy/DashboardProxy';
import { Row } from 'react-bootstrap';
import { BiTask } from "react-icons/bi";

const TaskStatusChart = () => {

    const [months, setmonths] = useState();
    const [newCount, setNewCount] = useState();
    const [inprogress, setInprogress] = useState();
    const [completed, setCompleted] = useState();

    useEffect(() => {
        const taskProgression = async () => {
            let t;
            if (localStorage.getItem('role') === 'Admin') {
                t = await GetTaskProgression();
            } else if (localStorage.getItem('role') === 'User' || localStorage.getItem('role') === 'Manager') {
                t = await GetUserTaskProgression(localStorage.getItem('uuid'));
            }

            let months = [];
            let newcount = [];
            let inprogress = [];
            let completed = [];
            t && t.map((data) => {
                months.push(data.month)
                newcount.push(data.newcount)
                inprogress.push(data.inprogresscount)
                completed.push(data.completedcount)
            })
            setmonths(months);
            setNewCount(newcount);
            setInprogress(inprogress);
            setCompleted(completed);
        }
        taskProgression();
    }, [])

    const options = {
        chart: {
            id: 'bar-chart',
            stacked: true,       //set true to set bar on top of eachother
        },
        colors: ['#007bff', '#f5b225', '#29b348',],
        xaxis: {
            categories: months,
            labels: {
                style: {
                    fontSize: '10px', // Change the font size here
                },
            },
        },
        plotOptions: {
            bar: {
                horizontal: false,
            },
            dataLabels: {
                position: 'top', // Set the position of the data labels to display above the bars
                style: {
                    fontSize: '12px', // Change the font size of the data labels
                    colors: ['#ff0000'], // Change the color of the labels if needed (for data points)
                },
            },
        },
        stroke: {
            show: true,
            width: 1,
            colors: ['#fff'],
        },
        // title: {
        //     text: 'Task Status',
        //     style: {
        //         fontSize: '13px',

        //     }
        // },
        // legend: {                  // to set position categories name
        //     position: 'top'
        // }
    };

    const series = [
        {
            name: 'New',
            data: newCount,
        }, {
            name: 'In-Progress',
            data: inprogress,
        }, {
            name: 'Completed',
            data: completed,
        },
    ];

    return (
        <>
            {/* <Row>
    <div >
        <div md={4}>
            <div >
                <BiTask color='#000' size={30} />
            </div>
        </div>
        <div md={4}>
        <h6 style={{textAlign:'center', marginBottom:0}}>Task Status</h6>
        </div>
        <div md={4}>
      
        </div>
        
    </div>
</Row>  */}
            <div className="bar-chart" style={{ backgroundColor: 'white', padding: 10 }}>
                <h6 style={{ textAlign: 'center', marginBottom: 0 }}>Task Status</h6>
                <Chart options={options} series={series} type="bar" height={390} />
            </div>

        </>


    );
};

export default TaskStatusChart;
