import { Container } from "react-bootstrap"
import Chart from 'react-apexcharts';

export const DemoChart1 = () => {

    const options = {
        chart: {
            id: 'line-chart'
        },
        xaxis: {
            categories: ['jan', 'feb', 'mar', 'apr']
        },
        title: {
            text: "Tasks Progression",
            style: {
                fontSize: '13px',
            }
        },
        legend: {
            show: true,
            position: 'bottom',
            markers: {
                width: 12,
                height: 12,
                radius: 2 // To make the legend marker square, set radius to 0
            }
        }
    };

    const series = [
        {
            name: 'New',
            data: [12, 54, 63],
        },
        {
            name: 'In-Progress',
            data: [23, 55, 32],
        },
        {
            name: 'Completed',
            data: [32, 64, 6],
        },
    ];

    return (
        <Container fluid>
            <Chart options={options} series={series} type="line" />
        </Container>
    )
}