import React, { useEffect, useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap';
import Select from "react-select";
import { GetAllCountries, GetAllStatesByCountry } from '../../utils/proxy/Countryproxy';
import Swal from 'sweetalert2';
import { CreateTaxRules, UpdateTaxRules } from '../../utils/proxy/TaxProxy';

function TaxRulesModal({action,data,show,setShow,setReload}) {

    const [countries,setCountries] = useState([]);
    const [states,setStates] = useState([]);

    const [selectedcountries,setSelectedCountries] = useState([]);
    const [selectedcountriesIids,setSelectedCountriesIds] = useState([]);

    const [selectedstates,setSelectedStates] = useState([]);
    const [selectedstatesids,setSelectedStatesIds] = useState([]);

    const [name,setName] = useState(data ? data.name : "");
    const [taxRate,setTaxrate] = useState("");
    const [level,setLevel] = useState("");
    const [id,setId] = useState("");
   


    useEffect(() => {if (action === "edit") {
      setId(data.id)
      setName(data.name)
      setTaxrate(data.taxrate)
      setLevel(data.level)
      data.countries.map((ed) => {
        setSelectedCountries(val => [...val,{value:ed.id,label:ed.name}])
        setSelectedCountriesIds(val => [...val,ed.id]);
      })
      data.states.map((e) => {
        setSelectedStates(val => [...val,{value:e.id, label:e.name}])
        setSelectedStatesIds(val => [...val,e.id])
      })
    }}, [])

    


    function handleselectCountriesChange(selected) {
        setSelectedCountries(selected);
        setSelectedCountriesIds(val => [...val,selected.value]);
    }

    function handleselectStatesChange(selected) {
        const values = selected.map((option) => option.value);
        setSelectedStates(selected);
        setSelectedStatesIds(values);
    }


    const countriesOption = [];
    countries.map((country) => {
        countriesOption.push({
        value: country.id,
        label: country.name,
      });
    });
    const statesOption = [];
    states.map((state) => {
        statesOption.push({
        value: state.id,
        label: state.name,
      });
    });


    useEffect(() => {
        AllCountries();
    },[])

    useEffect(() => {
        if (selectedcountriesIids) {
            AllStates();
        }
    },[selectedcountriesIids])



   async function AllCountries() {
        let res =  await GetAllCountries();
        if (res) {
            setCountries(res);
        }
    }

    async function AllStates() {
        let res = await GetAllStatesByCountry(selectedcountriesIids);
        if(res) {
            setStates(res);
        }
    }

   

  const handleClose = () => {
    setShow(false);
    setName("");
    setTaxrate("");
    setLevel("");
    setSelectedCountries([]);
    setSelectedCountriesIds([]);
    setSelectedStates([]);
    setSelectedStatesIds([]);
  }

  const handleSave = async() => {
    if(name == null || name == ""){
      Swal.fire({
        title: "Warning!",
        text: "Enter your name",
        icon: "warning",
        confirmButtonText: "OK",
      });
    } else if(level == null || level == ""){
      Swal.fire({
        title: "Warning!",
        text: "Enter level",
        icon: "warning",
        confirmButtonText: "OK",
      });
    } else if(taxRate == null || taxRate == "") {
      Swal.fire({
        title: "Warning!",
        text: "Enter the tax rate",
        icon: "warning",
        confirmButtonText: "OK",
      });
    } else if(selectedcountries == null || selectedcountries == ""){
      Swal.fire({
        title: "Warning!",
        text: "Select a country",
        icon: "warning",
        confirmButtonText: "OK",
      });
    } else if( selectedstates == null || selectedstates == ""){
      Swal.fire({
        title: "Warning!",
        text: "Select a state",
        icon: "warning",
        confirmButtonText: "OK",
      });
    }  
    
    else{
      let d;
      if(action === "create"){
         d = await CreateTaxRules(name,level,taxRate,selectedcountriesIids,selectedstatesids)
      }  else{
         d = await UpdateTaxRules(id,name,level,taxRate,selectedcountriesIids,selectedstatesids)
      }   
      

      if(d.status == "success"){
        Swal.fire({
          title: "Success",
          text: d.message,
          icon: "success",
          confirmButtonText: "OK",
          });
        setReload(true);
        setShow(false);
      } else{
        Swal.fire({
          title: "error",
          text: "Something went wrong",
          icon: "error",
          confirmButtonText: "OK",
        });
      }
      }
   
    
  }
  
  return (
    <>
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{action === 'create' ? 'Add Rule' : 'Edit Rule'}</Modal.Title>
      </Modal.Header>
      <div style={{margin:10}}>
      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
        <Form.Label>Name</Form.Label>
        <Form.Control type="text" value={name} onChange={(e) => setName(e.target.value)} />
      </Form.Group>
      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
        <Form.Label >Tax Rate</Form.Label>
        <Form.Control type="number" value={taxRate} onChange={(e) => setTaxrate(parseFloat(e.target.value))} />
      </Form.Group>
      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
        <Form.Label >Level</Form.Label>
        <Form.Control type="number" value={level} onChange={(e) => setLevel(e.target.value)} />
      </Form.Group>
      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
        <Form.Label>Country</Form.Label>
        <Select
                className="selectdoctor"
                closeMenuOnSelect={true}
                options={countriesOption}
                value={selectedcountries}
                onChange={handleselectCountriesChange}
                styles={{
   
   dropdownIndicator: (provided) => ({
     ...provided,
     color: '#ccc' // Change color of the arrow here
   })
 }}
              />
      </Form.Group>
      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
        <Form.Label>States</Form.Label>
        <Select
                className="selectdoctor"
                closeMenuOnSelect={true}
                options={statesOption}
                value={selectedstates}
                onChange={handleselectStatesChange}
                required
                isMulti
                styles={{
   
   dropdownIndicator: (provided) => ({
     ...provided,
     color: '#ccc' // Change color of the arrow here
   })
 }}
              />
      </Form.Group>
      </div>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSave}>
        {action === 'create' ? 'Save' : 'Update'} Changes
        </Button>
      </Modal.Footer>
    </Modal>
  </>
  )
}

export default TaxRulesModal;