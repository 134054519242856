import { useEffect } from "react";
import { Button, Container, Form, Modal } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { FaPlus } from "react-icons/fa6";
import { CreateNotice, DeleteNotice, EditNotice, GetAllNotice } from "../../utils/proxy/NoticeProxy";
import { useState } from "react";
import { AiFillDelete, AiTwotoneEdit } from "react-icons/ai";
import Swal from "sweetalert2";
import { IoHomeOutline } from "react-icons/io5";
import { Op_per } from "../../utils/Permissions";

function Notice() {

    const [id, setId] = useState();
    const [notice, setNotice] = useState();
    const [start, setStart] = useState('');
    const [end, setEnd] = useState('');

    const [data, setData] = useState();
    const [reload, setReload] = useState(false);
    const [show, setShow] = useState(false);
    const [showedit, setShowEdit] = useState(false);

    const [disabled, setDisabled] = useState();

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        const getAllNotice = async () => {
            let d = await GetAllNotice();
            if (d) {
                setData(d);
            } else {
                alert('Error', 'Issue while fetching data');
            }
        }
        getAllNotice();
        setReload(false);
    }, [reload])

    const handledelete = async (id) => {
        let d = await DeleteNotice(id);
        if (d.status === 'success') {
            setReload(true);
            Swal.fire({
                title: "Success!",
                text: "Notice Deleted !!",
                icon: "success",
                confirmButtonText: "OK",
            });
        }
    }

    const handleEditShow = (row) => {
        setId(row.id);
        setNotice(row.notice);
        setStart(row.start);
        setEnd(row.end);
        setDisabled(row.disabled);
        setShowEdit(true);
    }

    const handleSave = async () => {
        if (notice == null || notice == '') {
            Swal.fire({
                title: "Warning!",
                text: "Enter Notice !!",
                icon: "warning",
                confirmButtonText: "OK",
            });
        } else if (start == null || start == '') {
            Swal.fire({
                title: "Warning!",
                text: "Enter Start Date !!",
                icon: "warning",
                confirmButtonText: "OK",
            });
        } else if (end == null || end == '') {
            Swal.fire({
                title: "Warning!",
                text: "Enter End Date !!",
                icon: "warning",
                confirmButtonText: "OK",
            });
        } else {
            let d = await CreateNotice(localStorage.getItem('uuid'), notice, start, end);
            if (d.status === 'success') {
                setShow(false);
                setReload(true);
                setNotice(null);
                setStart('');
                setEnd('');
                Swal.fire({
                    title: "Success!",
                    text: "New Notice Added !!",
                    icon: "success",
                    confirmButtonText: "OK",
                });
            }
        }
    }

    const handleEditSave = async () => {
        if (notice == null || notice == '') {
            Swal.fire({
                title: "Warning!",
                text: "Enter Notice !!",
                icon: "warning",
                confirmButtonText: "OK",
            });
        } else if (start == null || start == '') {
            Swal.fire({
                title: "Warning!",
                text: "Enter Start Date !!",
                icon: "warning",
                confirmButtonText: "OK",
            });
        } else if (end == null || end == '') {
            Swal.fire({
                title: "Warning!",
                text: "Enter End Date !!",
                icon: "warning",
                confirmButtonText: "OK",
            });
        } else {
            let d = await EditNotice(id, localStorage.getItem('uuid'), notice, start, end, disabled);
            if (d.status === 'success') {
                setShowEdit(false);
                setReload(true);
                setId(null);
                setNotice(null);
                setStart('');
                setEnd('');
                setDisabled(null);
                Swal.fire({
                    title: "Success!",
                    text: "Notice Edited !!",
                    icon: "success",
                    confirmButtonText: "OK",
                });
            }
        }
    }

    const columns = [
        // {
        //     name: "Id",
        //     selector: (row) => row.id,
        //  
        //     width:"5%",
        // },
        {
            name: "Created By",
            selector: (row) =>
                <div style={{ whiteSpace: 'normal', backgroundColor: row.username === "" || row.username === null ? "#f36e6f" : "", borderRadius: "10px", border: row.username === "" || row.username === null ? "2px solid transparent" : "2px solid transparent", padding: "3px", display: "flex", flexDirection: "row", color: row.username === "" || row.username === null ? "#fff" : "#000", margin: "3px", }}>{row.username ? row.username : "N/A"}</div>,
            // width:"8%",


        },
        {
            name: "Start Date",
            selector: (row) =>
                <div style={{ whiteSpace: 'normal', backgroundColor: row.start.split('T')[0] === "" || row.start.split('T')[0] === null ? "#f36e6f" : "", borderRadius: "10px", border: row.start.split('T')[0] === "" || row.start.split('T')[0] === null ? "2px solid transparent" : "2px solid transparent", padding: "3px", display: "flex", flexDirection: "row", color: row.start.split('T')[0] === "" || row.start.split('T')[0] === null ? "#fff" : "#000", margin: "3px", }}>{row.start.split('T')[0] ? row.start.split('T')[0] : "N/A"}</div>,
            // width:"10%",

        },
        {
            name: "End Date",
            selector: (row) =>
                <div style={{ whiteSpace: 'normal', backgroundColor: row.end.split('T')[0] === "" || row.end.split('T')[0] === null ? "#f36e6f" : "", borderRadius: "10px", border: row.end.split('T')[0] === "" || row.end.split('T')[0] === null ? "2px solid transparent" : "2px solid transparent", padding: "3px", display: "flex", flexDirection: "row", color: row.end.split('T')[0] === "" || row.end.split('T')[0] === null ? "#fff" : "#000", margin: "3px", }}>{row.end.split('T')[0] ? row.end.split('T')[0] : "N/A"}</div>,
            // width:"10%",

        },
        {
            name: "Notice",
            selector: (row) =>
                <div style={{ whiteSpace: 'normal', backgroundColor: row.notice === "" || row.notice === null ? "#f36e6f" : "", borderRadius: "10px", border: row.notice === "" || row.notice === null ? "2px solid transparent" : "2px solid transparent", padding: "3px", display: "flex", flexDirection: "row", color: row.notice === "" || row.notice === null ? "#fff" : "#000", margin: "3px", }}>{row.notice ? row.notice : "N/A"}</div>,
            // width:"10%",

        },
        {
            name: "Disabled",
            selector: (row) =>
                <div style={{ whiteSpace: 'normal', backgroundColor: row.disabled ? "Yes" : "No" === "" || row.disabled ? "Yes" : "No" === null ? "#f36e6f" : "", borderRadius: "10px", border: row.disabled ? "Yes" : "No" === "" || row.disabled ? "Yes" : "No" === null ? "2px solid transparent" : "2px solid transparent", padding: "3px", display: "flex", flexDirection: "row", color: row.disabled ? "Yes" : "No" === "" || row.disabled ? "Yes" : "No" === null ? "#fff" : "#000", margin: "3px", }}>{row.disabled ? "Yes" : "No" ? row.disabled ? "Yes" : "No" : "N/A"}</div>,
            // width:"10%",

        },
    ];

    const customStyles = { head: { style: { backgroundColor: 'blue', }, }, };
    const CustomHeader = ({ children }) => (<div style={{ backgroundColor: 'blue', padding: '10px', color: 'white' }}>    {children}  </div>);

    if (Op_per.get().NOTICE.ALLOW_DELETE || Op_per.get().NOTICE.ALLOW_EDIT) {
        columns.push({
            name: "Actions",
            selector: (row) => (
                <div key={row.id}>
                    <AiFillDelete
                        onClick={() => handledelete(row.id)}
                        style={{ color: "#f36e6f", fontSize: 20, display: Op_per.get().NOTICE.ALLOW_DELETE ? '' : 'none' }}
                    />
                    &nbsp;&nbsp;&nbsp;
                    <AiTwotoneEdit
                        onClick={() => handleEditShow(row)}
                        style={{ color: "#9889e6", fontSize: 20, display: Op_per.get().NOTICE.ALLOW_EDIT ? '' : 'none' }}
                    />
                </div>
            ),
        })
    }

    return (
        <Container fluid style={{ minHeight: "80vh" }}>
            <div
                className="glass-body"
                style={{
                    margin: 10,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    padding: 0,
                    backgroundColor: 'transparent'
                }}
            >

                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <ul id="breadcrumb">
                        <li><a href="/"><IoHomeOutline
                            style={{ color: "white", fontSize: 20 }}

                        /></a></li>
                        <li><a href="/user-doctor-master"><span class="icon icon-beaker"> </span>Notices</a></li>
                        {/* <li><a href="/user-doctor-master"><span class="icon icon-beaker"> </span> User Doctor Master</a></li> */}
                        {/* <li><a href="#"><span class="icon icon-double-angle-right"></span>Doctor</a></li> */}

                    </ul>

                </div>

            </div>
            <div className="glass-body" style={{ margin: 10 }}>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                    }}
                    className="flex-column-mobile"
                >
                    <div>
                        <Form.Control
                            className="glass"
                            placeholder="Search"
                            type="text"
                            style={{ display: 'none' }}
                        //   value={searchQuery}
                        //   onChange={(e) => setSearchQuery(e.target.value)}
                        />
                    </div>
                    {Op_per.get().NOTICE.ALLOW_ADD ? (
                        <div>
                            <Button style={{ backgroundColor: "#2194f0" }} onClick={handleShow}>
                                <FaPlus />
                            </Button>
                        </div>
                    ) : ("")}
                </div>
                <div style={{ marginTop: 10 }}>
                    <DataTable
                        columns={columns}
                        data={data}
                        pagination
                        customStyles={{ headRow: { style: { backgroundColor: '#c0dfff', padding: 10, borderRadius: '10px 10px 0 0' }, } }}
                        CustomHeader={<CustomHeader />}
                        // actions={actionsMemo}
                        style={{ marginTop: 10 }}
                        // progressPending={pending}
                        // progressComponent={<LoadingComponent/>}
                        dense
                    />
                </div>
            </div>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Notice</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Notice <span style={{ color: "red" }} >*</span></Form.Label>
                            <Form.Control
                                style={{
                                    height: 40,

                                    borderColor: '#ccc',
                                    borderWidth: 1,
                                    paddingHorizontal: 8,
                                    marginBottom: 16,
                                    
                                    borderRadius:10,
                                }}
                                type="text"
                                onChange={(e) => setNotice(e.target.value)}
                                placeholder="Enter Notice"
                                required
                            />
                        </Form.Group>
                        <Form.Label>Start <span style={{ color: "red" }} >*</span></Form.Label>
                        <Form.Control
                            style={{
                                height: 40,

                                borderColor: '#ccc',
                                borderWidth: 1,
                                paddingHorizontal: 8,
                                marginBottom: 16,
                                
                                borderRadius:10,
                            }}
                            type="datetime-local"
                            onChange={(e) => setStart(new Date(e.target.value).toISOString())}
                            placeholder="Enter start date "
                        />
                        <Form.Label>End <span style={{ color: "red" }} >*</span></Form.Label>
                        <Form.Control
                            style={{
                                height: 40,

                                borderColor: '#ccc',
                                borderWidth: 1,
                                paddingHorizontal: 8,
                                marginBottom: 16,
                                
                                borderRadius:10,
                            }}
                            type="datetime-local"
                            onChange={(e) => setEnd(new Date(e.target.value).toISOString())}
                            placeholder="Enter end date"
                        />
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button
                        variant="primary"
                        onClick={handleSave}
                    >
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showedit} onHide={() => setShowEdit(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Notice</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Notice <span style={{ color: "red" }} >*</span></Form.Label>
                            <Form.Control
                                style={{
                                    height: 40,

                                    borderColor: '#ccc',
                                    borderWidth: 1,
                                    paddingHorizontal: 8,
                                    marginBottom: 16,
                                    
                                    borderRadius:10,
                                }}
                                type="text"
                                value={notice}
                                onChange={(e) => setNotice(e.target.value)}
                                placeholder="Enter Notice"
                                required
                            />
                        </Form.Group>
                        <Form.Label>Start <span style={{ color: "red" }} >*</span></Form.Label>
                        <Form.Control
                            style={{
                                height: 40,

                                borderColor: '#ccc',
                                borderWidth: 1,
                                paddingHorizontal: 8,
                                marginBottom: 16,
                                
                                borderRadius:10,
                            }}
                            type="datetime-local"
                            value={start.split(':')[0] + ":" + start.split(':')[1]}
                            onChange={(e) => setStart(new Date(e.target.value).toISOString())}
                            placeholder="Enter Symbol "
                        />
                        <Form.Label >End <span style={{ color: "red" }} >*</span></Form.Label>
                        <Form.Control
                            style={{
                                height: 40,
                                borderColor: '#ccc',
                                borderWidth: 1,
                                paddingHorizontal: 8,
                                marginBottom: 16,
                                
                                borderRadius:10,
                            }}
                            type="datetime-local"
                            value={end.split(':')[0] + ":" + end.split(':')[1]}
                            onChange={(e) => setEnd(new Date(e.target.value).toISOString())}
                            placeholder="Enter Value"
                        />
                        <Form.Label></Form.Label>
                        <Form.Check
                            type="switch"
                            label="Disabled"
                            defaultChecked={disabled}
                            onChange={(e) => { setDisabled(!disabled) }}
                        />

                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowEdit(false)}>
                        Close
                    </Button>
                    <Button
                        variant="primary"
                        onClick={handleEditSave}
                    >
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>

        </Container>
    )
}

export default Notice;