import React, { useEffect, useState } from "react";
import { Button, Container } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import DataTable from "react-data-table-component";
import Dropdown from "react-bootstrap/Dropdown";
import { AiFillDelete, AiTwotoneEdit } from "react-icons/ai";
import Select from "react-select";
import Modal from "react-bootstrap/Modal";
import Swal from "sweetalert2";
import { HiDotsVertical } from "react-icons/hi";
import {
  AddRole,
  DeleteRole,
  EditRole,
  GetAllRole,
} from "../../utils/proxy/UserRoleProxy";
import { GetAllUser } from "../../utils/proxy/Usermasterproxy";
import { userRoles } from "../../utils/constant";
import { Parser } from "@json2csv/plainjs";
import { unwind, flatten } from "@json2csv/transforms";
import { IoHomeOutline } from "react-icons/io5";
import { Op_per } from "../../utils/Permissions";

function UserRole() {
  const [user, setUser] = useState([]);
  const [data, setData] = useState([]);
  const [userid, setUserId] = useState("all");
  const [reload, setReload] = useState(false);
  const [selectedUser, setSelectedUser] = useState();
  const [searchQuery, setSearchQuery] = useState("");
  const [show, setShow] = useState(false);
  const [role, setRole] = useState("");
  const [editOperation, setEditOperation] = useState(true);

  const roleOptions = [];
  userRoles.map((role) => {
    let option = { value: role, label: role };
    roleOptions.push(option);
    return true;
  });

  useEffect(() => {
    // Fetch data from an API when the component mounts
    if (show) {
      const getallteritories = async () => {
        let users = await GetAllUser();
        if (users) {
          setUser(users);
        } else {
          alert("Error", "Issue While Fetching Users");
        }
      };
      getallteritories();
    }
  }, [show]);

  useEffect(() => {
    // Fetch data from an API when the component mounts
    const getAllRoles = async () => {
      let response = await GetAllRole();
      if (response) {
        setData(response);
      } else {
        alert("Error", "Issue While Fetching Roles");
      }
    };
    getAllRoles();
    setReload(false);
  }, [reload]);

  const handleSelectChange = (selected) => {
    setUserId(selected.value);
  };

  const handleClose = () => {
    setShow(false);
    setRole("");
    setUserId("");
    setEditOperation(false);
  };

  const handleShow = () => {
    // setIsClinicName(false);
    setShow(true);
  };

  const options = [];

  user.map((user) => {
    options.push({
      value: user.userid,
      label: user.username + "(" + user.firstname + " " + user.lastname + ")",
    });
  });

  const columns = [
    // {
    //   name: "Id",
    //   selector: (row) => row.userid,
    //  
    //   width: '250px'
    // },
    {
      name: "Username",
      selector: (row) =>
      <div style={{ whiteSpace: 'normal',backgroundColor: row.username === "" || row.username === null ? "#f36e6f":"", borderRadius:"10px", border: row.username === "" || row.username === null ? "2px solid transparent": "2px solid transparent",padding:"3px", display:"flex", flexDirection:"row",color:row.username === "" || row.username === null ? "#fff": "#000", margin:"3px", }}>{row.username ? row.username : "N/A"}</div>,
      
      width:"15%",
     
      fontSize: 10,
      fontWeight:'bold'
   },
    {
      name: "Firstname",
      selector: (row) =>  <div style={{ whiteSpace: 'normal',backgroundColor: row.firstname === "" || row.firstname === null ? "#f36e6f":"", borderRadius:"10px", border: row.firstname === "" || row.firstname === null ? "2px solid transparent": "2px solid transparent",padding:"3px", display:"flex", flexDirection:"row",color:row.firstname === "" || row.firstname === null ? "#fff": "#000", margin:"3px", }}>{row.firstname ? row.firstname : "N/A"}</div>,
      width:"15%",
    },
    {
      name: "Lastname",
      selector: (row) => <div style={{ whiteSpace: 'normal',backgroundColor: row.lastname === "" || row.lastname === null ? "#f36e6f":"", borderRadius:"10px", border: row.lastname === "" || row.lastname === null ? "2px solid transparent": "2px solid transparent",padding:"3px", display:"flex", flexDirection:"row",color:row.lastname === "" || row.lastname === null ? "#fff": "#000", margin:"3px", }}>{row.lastname ? row.lastname : "N/A"}</div>,
      width:"15%",
    },
    {
      name: "Role",
      selector: (row) => <div style={{ whiteSpace: 'normal',backgroundColor: row.role === "" || row.role === null ? "#f36e6f":"", borderRadius:"10px", border: row.role === "" || row.role === null ? "2px solid transparent": "2px solid transparent",padding:"3px", display:"flex", flexDirection:"row",color:row.role === "" || row.role === null ? "#fff": "#000", margin:"3px", }}>{row.role ? row.role : "N/A"}</div>,
      width:"15%",
    },
    // {
    //   name: "Actions",
    //   selector: (row) => (
    //     <div key={row.id}>
    //       <AiTwotoneEdit
    //         style={{ color: "black", fontSize: 20 }}
    //         onClick={() => handleEditShow(row)}
    //       />
    //       &nbsp;&nbsp;&nbsp;
    //       <AiFillDelete
    //         onClick={() => {
    //           if (!row.role) {
    //             Swal.fire({
    //               title: "Warning!",
    //               text: "You dont have any role assigned to this user !!",
    //               icon: "warning",
    //               confirmButtonText: "OK",
    //             });
    //           } else {
    //             handleDelete(row.userid);
    //           }
    //         }}
    //         style={{ color: "#f36e6f", fontSize: 20 }}
    //       />
    //     </div>
    //   ),
    // },
  ];

  if(Op_per.get().USERS_USER_ROLES.ALLOW_DELETE || Op_per.get().USERS_USER_ROLES.ALLOW_EDIT){
    columns.push({
      name: "Actions",
      selector: (row) => (
        <div key={row.id}>
          <AiTwotoneEdit
            style={{ color: "#9889e6", fontSize: 20,display:Op_per.get().USERS_USER_ROLES.ALLOW_EDIT ? '' : 'none' }}
            onClick={() => handleEditShow(row)}
          />
          &nbsp;&nbsp;&nbsp;
          <AiFillDelete
            onClick={() => {
              if (!row.role) {
                Swal.fire({
                  title: "Warning!",
                  text: "You dont have any role assigned to this user !!",
                  icon: "warning",
                  confirmButtonText: "OK",
                });
              } else {
                handleDelete(row.userid);
              }
            }}
            style={{ color: "#f36e6f", fontSize: 20,display:Op_per.get().USERS_USER_ROLES.ALLOW_DELETE ? '' : 'none' }}
          />
        </div>
      ),
    },)
  }

  const customStyles = {   head: {     style: {       backgroundColor: 'blue',     },   }, };
  const CustomHeader = ({ children }) => (   <div style={{ backgroundColor: 'blue', padding: '10px', color: 'white' }}>    {children}  </div>);

  let deleteRole = async (roleId) => {
    let statedata = await DeleteRole(roleId);
    if (statedata) {
      setReload(true);

      Swal.fire({
        title: "Success!",
        text: "Role Deleted !!",
        icon: "success",
        confirmButtonText: "OK",
      });
    } else {
      alert("Error", "Issue While Updating Role");
    }
  };

  let handleDelete = (id) => {
    Swal.fire({
      title: "Do you want to delete the Role?",

      showCancelButton: true,
      confirmButtonText: "Delete",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        deleteRole(id);
      }
    });
  };

  const handleEditShow = (row) => {
    setEditOperation(true);
    setShow(true);
    setRole({ value: row.role, label: row.role });
    setUserId(row.userid);
  };

  let prepareData = () => {
    return {
      userid,
      role: role.value,
    };
  };

  const handleSave = async () => {
    let response = editOperation
      ? await EditRole(prepareData())
      : await AddRole(prepareData());
    if (response) {
      
      setReload(true);
      setShow(false);

      Swal.fire({
        title: "Success!",
        text: `User Role ${
          editOperation ? "Updated" : "Added"
        } Successfully !!`,
        icon: "success",
        confirmButtonText: "OK",
      });
    } else {
      alert("Error", "Issue While Fetching Data");
    }
  };

  async function downloadCSV(data) {
    let csv;
    try {
      const opts = {
        fields: [
          "userid",
          "username",
          "firstname",
          "lastname",
          "role",
        ],
      };
      const parser = new Parser(opts);
      csv = parser.parse(data);
    } catch (err) {
      console.error(err);
    }

    const link = document.createElement("a");

    if (csv == null) return;

    const filename = "export.csv";

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute("href", encodeURI(csv));
    link.setAttribute("download", filename);
    link.click();
  }

  const Export = ({ onExport }) => (
    <Button onClick={(e) => onExport(e.target.value)}>Export All</Button>
  );

  const actionsMemo = React.useMemo(
    () => <Export onExport={() => downloadCSV(data)} />,
    [data]
  );

  return (
    <Container fluid style={{ minHeight: "80vh" }}>
      <div
        className="glass-body"
        style={{
          margin: 10,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          padding:0,
          backgroundColor:'transparent'
        }}
      >

<div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
<ul id="breadcrumb">
  <li><a href="/"><IoHomeOutline
            style={{ color: "white", fontSize: 20 }}
            
          /></a></li>
  <li><a href="/user-role"><span class="icon icon-beaker"> </span>Users</a></li>
  <li><a href="/user-role"><span class="icon icon-beaker"> </span> User Roles</a></li>
  {/* <li><a href="#"><span class="icon icon-double-angle-right"></span>Doctor</a></li> */}
  
</ul>
	
</div>
      </div>

      <div className="glass-body" style={{ margin: 10 }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
          className="flex-column-mobile"
        >
          <div>
            <Form.Control
             style={{height: 40,
            width:250,
            borderColor: '#ccc',
            borderWidth: 1,
            paddingHorizontal: 8,
            marginBottom: 16,
            borderRadius:10}}
              className="glass"
              placeholder="Search"
              type="text"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
          <div>
          <div class="dropdown">
                <button
                  class="btn btn-secondary"
                  type="button"
                  style={{ backgroundColor: "#2194f0",display:Op_per.get().USERS_USER_ROLES.ALLOW_ADD ? '' : 'none' }}
                  title="Options"
                  id="dropdown-basic"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <HiDotsVertical />
                </button>
                <ul class="dropdown-menu">
                  <li onClick={setShow} style={{display:Op_per.get().USERS_USER_ROLES.ALLOW_ADD ? '' : 'none'}}>
                    <a class="dropdown-item">Add</a>
                  </li>
                 
                </ul>
              </div>

            {/* <Export onExport={() => downloadCSV(user)} /> */}
          </div>
        </div>
        <div className="pt-3">
          <DataTable
          
            columns={columns}
            data={data}
            pagination
            customStyles={{headRow: {style: {backgroundColor: '#c0dfff',  padding:10,borderRadius:'10px 10px 0 0' }, }  }}
                        CustomHeader={<CustomHeader/>}
            style={{ marginTop: 10 }}
            // actions={actionsMemo}
            dense
          />
        </div>
      </div>
      <Modal show={show} backdrop="static" size="md" onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{editOperation ? "Add" : "Edit"} Role</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label >User <span style={{color:"red"}} >*</span></Form.Label>
              {editOperation ? (
                <Form.Label  className="selectuser">{userid}</Form.Label>
              ) : (
                <Select
                  className="selectdoctor"
                  options={options}
                  value={selectedUser}
                  onChange={handleSelectChange}
                  styles={{
   
   dropdownIndicator: (provided) => ({
     ...provided,
     color: '#ccc' // Change color of the arrow here
   })
 }}
                />
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Role <span style={{color:"red"}} >*</span></Form.Label>
              <Select
               className="selectdoctor"
                options={roleOptions}
                value={role}
                onChange={(selOp) => setRole(selOp)}
                styles={{
   
   dropdownIndicator: (provided) => ({
     ...provided,
     color: '#ccc' // Change color of the arrow here
   })
 }}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={handleSave}
            // disabled={isclinicname ? true : false}
          >
            Add Role
          </Button>
        </Modal.Footer>
      </Modal>
      {/* <Modal show={showedit} onHide={handleEditClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Clinic</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" >
              <Form.Label>Clinic Name</Form.Label>
              <Form.Control
                type="text"
                onChange={handleClinicNameChange}
                placeholder="Enter Clinic Name"
                value={clinicname}
                required
              />
            </Form.Group>

            <Form.Group className="mb-3" >
              <Form.Label>Areas</Form.Label>
                            <Select
                closeMenuOnSelect={false}
                options={options}
                value={selectedAreas}
                onChange={handleAreaChange}
                isMulti
              />
            </Form.Group>
            <Form.Group className="mb-3" >
              <Form.Label>Address</Form.Label>
              <Form.Control
                type="text"
                onChange={handleClinicAddressChange}
                placeholder="Enter Address"
                required
                value={clinicaddress}
              />
            </Form.Group>

            <Form.Group className="mb-3" >
              <Form.Label>Country</Form.Label>
              <Form.Select
                onChange={handleCountryCodeChange}
                required
                value={country}
              >
                <option>---Select Country---</option>
                {countries.map((country) => (
                  <option key={country.id} value={country.id}>
                    {country.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3" >
              <Form.Label>State</Form.Label>
              <Form.Select onChange={handleStateChange} required value={state}>
                <option>---Select State---</option>
                {states.map((state) => (
                  <option key={state.id} value={state.id}>
                    {state.statename}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>City</Form.Label>
              <Form.Select onChange={handleCityChange} required value={city}>
                <option>---Select City---</option>
                {citys.map((city) => (
                  <option key={city.id} value={city.id}>
                    {city.cityname}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3" >
              <Form.Label>Pincode</Form.Label>
              <Form.Control
                type="text"
                onChange={handlePincodeChange}
                placeholder="Enter Pincode"
                required
                value={pincode}
              />
            </Form.Group>

            <Form.Group className="mb-3" >
              <Form.Label>Phone</Form.Label>
              <Form.Control
                type="number"
                onChange={handleNumberChange}
                placeholder="Enter Phone"
                required
                value={number}
              />
            </Form.Group>

            <Form.Group className="mb-3" >
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                onChange={handleEmailChange}
                placeholder="Enter Email"
                value={email}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleEditClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleUpdate}>
            Update Clinic
          </Button>
        </Modal.Footer>
      </Modal> */}
    </Container>
  );
}

export default UserRole;
