import React, { useEffect, useState } from "react";
import { Badge, Button, Container } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import DataTable from "react-data-table-component";
import Dropdown from "react-bootstrap/Dropdown";
import { AiFillDelete, AiFillEye, AiTwotoneEdit } from "react-icons/ai";
import Select from "react-select";
import Modal from "react-bootstrap/Modal";
import Swal from "sweetalert2";
import { HiDotsVertical } from "react-icons/hi";
import {
  AddRole,
  DeleteRole,
  EditRole,
  GetAllRole,
} from "../../utils/proxy/UserRoleProxy";
import { GetAllUser } from "../../utils/proxy/Usermasterproxy";
import { userRoles } from "../../utils/constant";
import { Parser } from "@json2csv/plainjs";
import { unwind, flatten } from "@json2csv/transforms";
import { CreateManagersProxy, GetAllManagersProxy, UpdateManagersProxy } from "../../utils/proxy/Manageruserproxy";
import { GetAllAreasDetail, GetAllCitiesDetail } from "../../utils/proxy/Countryproxy";
import { GetAllDoctorByCitiesMini } from "../../utils/proxy/Userdoctorproxy";
import { GetAllChemistByCities } from "../../utils/proxy/Userchemitsproxy";
import { GetAllStockistByCities } from "../../utils/proxy/Userstockistproxy";
import { GetAllClinicByCities } from "../../utils/proxy/Userclinicproxy";
import { GetAllHospitalByCities } from "../../utils/proxy/Userhospitalsproxy";
import { IoHomeOutline } from "react-icons/io5";
import { Op_per } from "../../utils/Permissions";

function Usermanager() {

const [user, setUser] = useState([]);
const [selectedUser, setSelectedUser] = useState([]);
const [selectedManager, setSelectedManager] = useState();
const [managers,setManagers] = useState([]);
const [reload, setReload] = useState(false);
const [editOperation, setEditOperation] = useState(false);
const [show, setShow] = useState(false);
const [userid, setUserId] = useState([]);
const [managerid, setManagerId] = useState();
const [username, setUserName] = useState();

const [citys, setCitys] = useState([]);
const [selectedCities, setSelectedCities] = useState([]);
const [Citiesid, setCitiesid] = useState([]);

const [Doctors, setDoctors] = useState([]);
const [selectedDoctors, setSelectedDoctors] = useState([]);
const [Doctorsid, setDoctorsid] = useState([]);

const [Chemists, setChemists] = useState([]);
const [selectedChemists, setSelectedChemists] = useState([]);
const [Chemistsid, setChemistsid] = useState([]);

const [Stockists, setStockists] = useState([]);
const [selectedStockists, setSelectedStockists] = useState([]);
const [Stockistsid, setStockistsid] = useState([]);

const [Clinics, setClinics] = useState([]);
const [selectedClinics, setSelectedClinics] = useState([]);
const [Clinicsid, setClinicsid] = useState([]);


const [Hospitals, setHospitals] = useState([]);
const [selectedHospitals, setSelectedHospitals] = useState([]);
const [Hospitalsid, setHospitalsid] = useState([]);



const [areas, setAreas] = useState([]);
const [selectedAreas, setSelectedAreas] = useState([]);
const [areasid, setAreasid] = useState([]);

useEffect(() => {
    // Fetch data from an API when the component mounts
    const getAllManagers = async () => {
      let response = await GetAllManagersProxy();
      if (response) {
        setManagers(response);
      } else {
        alert("Error", "Issue While Fetching Managers");
      }
    };
    getAllManagers();
    setReload(false);
  }, [reload]);

   useEffect(() => {
    // Fetch data from an API when the component mounts
    if (show) {
      const getallteritories = async () => {
        let users = await GetAllUser();
        if (users) {
          users.push({ firstname: localStorage.getItem('firstname'), lastname: localStorage.getItem('lastname'), userid: localStorage.getItem('uuid'), username: localStorage.getItem('username') })
          setUser(users);
        } else {
          alert("Error", "Issue While Fetching Users");
        }
      };
      getallteritories();

      const getallcitys = async () => {
        let citysdata = await GetAllCitiesDetail();
        if (citysdata) {
          setCitys(citysdata);
        } else {
          alert("Error", "Issue While Fetching City");
        }
      };
      getallcitys();


      const getallareas = async () => {
        let areas = await GetAllAreasDetail();
        if (areas) {
          setAreas(areas);
        } else {
          alert("Error", "Issue While Fetching Territories");
        }
      };
      getallareas();

      

    }

    

  }, [show]);

  useEffect(() => {
    // Fetch data from an API when the component mounts
    if (show) {
      

      const getalldoctors = async (Citiesid) => {
        let areas = await GetAllDoctorByCitiesMini(Citiesid);
        if (areas) {
            setDoctors(areas);
        } else {
          alert("Error", "Issue While Fetching Doctors");
        }
      };
      getalldoctors(Citiesid);

      const getAllChemistByCities = async (Citiesid) => {
        let areas = await GetAllChemistByCities(Citiesid);
        if (areas) {
            setChemists(areas);
        } else {
          alert("Error", "Issue While Fetching Chemists");
        }
      };
      getAllChemistByCities(Citiesid);

      const getAllStockistCity = async (Citiesid) => {
        let areas = await GetAllStockistByCities(Citiesid);
        if (areas) {
            setStockists(areas);
        } else {
          alert("Error", "Issue While Fetching Stockits");
        }
      };
      getAllStockistCity(Citiesid);

      const getAllClinicCity = async (Citiesid) => {
        let areas = await GetAllClinicByCities(Citiesid);
        if (areas) {
            setClinics(areas);
        } else {
          alert("Error", "Issue While Fetching Clinics");
        }
      };
      getAllClinicCity(Citiesid);


      const getAllHospitalsCity = async (Citiesid) => {
        let areas = await GetAllHospitalByCities(Citiesid);
        if (areas) {
            setHospitals(areas);
        } else {
          alert("Error", "Issue While Fetching Hospitals");
        }
      };
      getAllHospitalsCity(Citiesid);
    }

    

  }, [show,Citiesid]);


  const handleEditShow = (row) => {
    if(row.areas != null){
      row.areas.map(d =>{
        setSelectedAreas(prevSelectedAreas => [...prevSelectedAreas, {value: d.id, label: d.area}])
        setAreasid(prevAreasid => [...prevAreasid, d.id])
      })
    }
    row.cities.map(d=>{
      setSelectedCities(prevSelectedAreas => [...prevSelectedAreas, {value: d.cityid, label: d.cityname}])
      setCitiesid(k => [...k, d.cityid])
    })
    row.doctors.map(d=>{
      setSelectedDoctors(prevSelectedAreas => [...prevSelectedAreas, {value: d.id, label: d.doctorname}])
      setDoctorsid(k => [...k, d.id])
    })
    row.chemists.map(d=>{
      setSelectedChemists(prevSelectedAreas => [...prevSelectedAreas, {value: d.id, label: d.chemistname}])
      setChemistsid(k => [...k, d.id])
    })
    row.stockists.map(d=>{
      setSelectedStockists(prevSelectedAreas => [...prevSelectedAreas, {value: d.id, label: d.chemistname}])
      setStockistsid(k => [...k, d.id])
    })
    row.clinics.map(d=>{
      setSelectedClinics(prevSelectedAreas => [...prevSelectedAreas, {value: d.id, label: d.clinicname}])
      setClinicsid(k => [...k, d.id])
    })
    row.hospitals.map(d=>{
      setSelectedHospitals(prevSelectedAreas => [...prevSelectedAreas, {value: d.id, label: d.hospitalname}])
      setHospitalsid(k => [...k, d.id])
    })
    row.users.map(d=>{
      setSelectedUser(prevSelectedAreas => [...prevSelectedAreas, {value: d.userid, label: d.username}])
      setUserId(k => [...k, d.userid])
    })
    setEditOperation(true);
    setShow(true);
    setManagerId(row.managerid);
    setUserName(row.managername)
  };

  const handleAddShow = () => {
    setEditOperation(false);
    setShow(true);
  };

    const handleClose = () => {
    setShow(false);
    setUserId("");
    setEditOperation(false);
    setSelectedAreas([])
    setAreasid([]);
    setSelectedDoctors([])
    setDoctorsid([]);
    setSelectedChemists([])
    setChemistsid([]);
    setSelectedStockists([])
    setStockistsid([]);
    setSelectedClinics([])
    setClinicsid([]);
    setSelectedHospitals([])
    setHospitalsid([]);
    setSelectedUser([])
    setUserId([]);
    setSelectedCities([])
    setCitiesid([])
  };

    const handleSave = async () => {
    let response = editOperation
      ? await UpdateManagersProxy(managerid,Citiesid,userid)
      : await CreateManagersProxy(managerid,Citiesid,userid);
    if (response) {
      
      setReload(true);
      setShow(false);

      Swal.fire({
        title: "Success!",
        text: `User Role ${
          editOperation ? "Updated" : "Added"
        } Successfully !!`,
        icon: "success",
        confirmButtonText: "OK",
      });
      setSelectedAreas([])
      setAreasid([]);
      setSelectedDoctors([])
      setDoctorsid([]);
      setSelectedChemists([])
      setChemistsid([]);
      setSelectedStockists([])
      setStockistsid([]);
      setSelectedClinics([])
      setClinicsid([]);
      setSelectedHospitals([])
      setHospitalsid([]);
      setSelectedUser([])
      setUserId([]);
    } else {
      alert("Error", "Issue While Fetching Data");
    }
  };

    const handleSelectChange = (selected) => {
    const values = selected.map((option) => option.value);
    
    setSelectedUser(selected);
    setUserId(values);
  };

  const handleManagerSelectChange = (selected) => {
    setManagerId(selected.value);
  };

  const handleAreaSelectChange = (selected) => {
    const values = selected.map((option) => option.value);
    
    setSelectedAreas(selected);
    setAreasid(values);
  };

  const handleCitySelectChange = (selected) => {
    const values = selected.map((option) => option.value);
    
    setSelectedCities(selected);
    setCitiesid(values);
  };

  const handleDoctorSelectChange = (selected) => {
    const values = selected.map((option) => option.value);
    
    setSelectedDoctors(selected);
    setDoctorsid(values);
  };

  const handleChemistSelectChange  = (selected) => {
    const values = selected.map((option) => option.value);
    
    setSelectedChemists(selected);
    setChemistsid(values);
  };

  const handleStockistSelectChange = (selected) => {
    const values = selected.map((option) => option.value);
    
    setSelectedStockists(selected);
    setStockistsid(values);
  };

  const handleClinicSelectChange = (selected) => {
    const values = selected.map((option) => option.value);
    
    setSelectedClinics(selected);
    setClinicsid(values);
  };

  const handleHospitalsSelectChange = (selected) => {
    const values = selected.map((option) => option.value);
    
    setSelectedHospitals(selected);
    setHospitalsid(values);
  };

    const options = [];

  user.map((user) => {
    options.push({
      value: user.userid,
      label: user.username + "(" + user.firstname + " " + user.lastname + ")",
    });
  });

  const areaoptions = [];

  areas.map((area) => {
    areaoptions.push({ value: area.id, label: area.areaname });
  });

  const citiesoptions = [];

  citys.map((area) => {
    citiesoptions.push({ value: area.id, label: area.cityname });
  });

  const doctorsoption = [];

  Doctors.map((area) => {
    doctorsoption.push({ value: area.id, label: area.doctorname });
  });

  const chemistoption = [];

  Chemists.map((area) => {
    chemistoption.push({ value: area.id, label: area.chemistname });
  });


  const stockistoption = [];

  Stockists.map((area) => {
    stockistoption.push({ value: area.id, label: area.stockistname });
  });

  const clinicsoption = [];

  Clinics.map((area) => {
    clinicsoption.push({ value: area.id, label: area.clinicname });
  });


  const hospitalsoption = [];

  Hospitals.map((area) => {
    hospitalsoption.push({ value: area.id, label: area.hospitalname });
  });

  const columns = [
    {
      name: "Manager",
      selector: (row) => 
      <div style={{ whiteSpace: 'normal',backgroundColor: row.managername === "" || row.managername === null ? "#f36e6f":"", borderRadius:"10px", border: row.managername === "" || row.managername === null ? "2px solid transparent": "2px solid transparent",padding:"3px", display:"flex", flexDirection:"row",color:row.managername === "" || row.managername === null ? "#fff": "#000", margin:"3px", }}>{row.managername ? <div><span style={{fontWeight:"bold", color:"#000"}}>{row.managername}</span><br/><span>{row.managerfirstname}{row.managerlastname}</span></div> : "N/A"}</div>, 
      width:"13%",
    },
    // {
    //     name: "Areas",
    //     selector: (row) => <div style={{ whiteSpace: 'normal' }}>{row.areas && row.areas.map((doc) => ( <Badge bg="danger" key={doc.id}>{doc.area}</Badge>))}</div>,
    //   },
    // {
    //   name: "Doctors",
    //   selector: (row) => <div style={{ whiteSpace: 'normal' }}>{row.doctors && row.doctors.map((doc) => ( <Badge bg="danger" key={doc.id}>{doc.doctorname}</Badge>))}</div>,
    // },
    // {
    //     name: "Chemists",
    //     selector: (row) => <div style={{ whiteSpace: 'normal' }}>{row.chemists && row.chemists.map((doc) => ( <Badge bg="danger" key={doc.id}>{doc.chemistname}</Badge>))}</div>,
    //   },
    //   {
    //     name: "Stockist",
    //     selector: (row) => <div style={{ whiteSpace: 'normal' }}>{row.stockists && row.stockists.map((doc) => ( <Badge bg="danger" key={doc.id}>{doc.chemistname}</Badge>))}</div>,width:"160px",
    //   },
    //   {
    //     name: "Clinics",
    //     selector: (row) => <div style={{ whiteSpace: 'normal' }}>{row.clinics && row.clinics.map((doc) => ( <Badge bg="danger" key={doc.id}>{doc.clinicname}</Badge>))}</div>,
    //   },
    //   {
    //     name: "Hospitals",
    //     selector: (row) => <div style={{ whiteSpace: 'normal' }}>{row.hospitals && row.hospitals.map((doc) => ( <Badge bg="danger" key={doc.id}>{doc.hospitalname}</Badge>))}</div>,width: '200px',
    //   },
    {
      name: "Designation",
      selector: (row) =>
      <div style={{ whiteSpace: 'normal',backgroundColor: row.designation === "" || row.designation === null ? "#f36e6f":"", borderRadius:"10px", border: row.designation === "" || row.designation === null ? "2px solid transparent": "2px solid transparent",padding:"3px", display:"flex", flexDirection:"row",color:row.designation === "" || row.designation === null ? "#fff": "#000", margin:"3px", }}>{row.designation ? row.designation : "N/A"}</div>,
      width:"12%",
    },
    {
      name: "HQ",
      selector: (row) => <div style={{ whiteSpace: 'normal',backgroundColor: row.hq === "" || row.hq === null ? "#f36e6f":"", borderRadius:"10px", border: row.hq === "" || row.hq === null ? "2px solid transparent": "2px solid transparent",padding:"3px", display:"flex", flexDirection:"row",color:row.hq === "" || row.hq === null ? "#fff": "#000", margin:"3px", }}>{row.hq ? row.hq : "N/A"}</div>,
      width:"11%",
    },
      {
        name: "Users",
        selector: (row) => <div style={{ whiteSpace: 'normal' }}>{row.users && row.users.map((doc) => ( <Badge bg="danger" key={doc.userid}>{doc.username}</Badge>))}</div>,
        width:"12%",
      },
      {
        name: "Reporting To",
        selector: (row) => <div style={{ whiteSpace: 'normal',backgroundColor: row.reportingto === "" || row.reportingto === null ? "#f36e6f":"", borderRadius:"10px", border: row.reportingto === "" || row.reportingto === null ? "2px solid transparent": "2px solid transparent",padding:"3px", display:"flex", flexDirection:"row",color:row.reportingto === "" || row.reportingto === null ? "#fff": "#000", margin:"3px", }}>{row.reportingto ? row.reportingto : "N/A"}</div>,
        width:"15%",
      },
      {
        name: "Cities",
        selector: (row) => <div style={{ whiteSpace: 'normal' }}>{row.cities && row.cities.map((doc) => ( <Badge bg="danger" key={doc.cityid}>{doc.cityname}</Badge>))}</div>,
        width:"15%",
      },
    // {
    //   name: "Actions",
    //   selector: (row) => (
    //     <div key={row.id}>
    //        {/* <AiFillEye
    //        style={{ color: "dodgerblue", fontSize: 20 }} />
    //        &nbsp;&nbsp;&nbsp; */}
    //     <AiTwotoneEdit
    //       style={{ color: "black", fontSize: 20 }}
    //       onClick={() => handleEditShow(row)}
    //     />
    //     &nbsp;&nbsp;&nbsp;
    //     <AiFillDelete
    //     //   onClick={() => {
    //     //     if (!row.role) {
    //     //       Swal.fire({
    //     //         title: "Warning!",
    //     //         text: "You dont have any role assigned to this user !!",
    //     //         icon: "warning",
    //     //         confirmButtonText: "OK",
    //     //       });
    //     //     } else {
    //     //     //   handleDelete(row.userid);
    //     //     }
    //     //   }}
    //       style={{ color: "#f36e6f", fontSize: 20 }}
    //     />
    //   </div>
    //   ),
    // },
  ];

  if(Op_per.get().USERS_MANAGERS.ALLOW_DELETE || Op_per.get().USERS_MANAGERS.ALLOW_EDIT){
    columns.push({
      name: "Actions",
      selector: (row) => (
        <div key={row.id}>
           {/* <AiFillEye
           style={{ color: "dodgerblue", fontSize: 20 }} />
           &nbsp;&nbsp;&nbsp; */}
        <AiTwotoneEdit
          style={{ color: "#9889e6", fontSize: 20, display: Op_per.get().USERS_MANAGERS.ALLOW_EDIT ? '' : 'none'}}
          onClick={() => handleEditShow(row)}
        />
        &nbsp;&nbsp;&nbsp;
        <AiFillDelete
        //   onClick={() => {
        //     if (!row.role) {
        //       Swal.fire({
        //         title: "Warning!",
        //         text: "You dont have any role assigned to this user !!",
        //         icon: "warning",
        //         confirmButtonText: "OK",
        //       });
        //     } else {
        //     //   handleDelete(row.userid);
        //     }
        //   }}
          style={{ color: "#f36e6f", fontSize: 20, display: Op_per.get().USERS_MANAGERS.ALLOW_DELETE ? '' : 'none' }}
        />
      </div>
      ),
    },)
  }

  const customStyles = {   head: {     style: {       backgroundColor: 'blue',     },   }, };
  const CustomHeader = ({ children }) => (   <div style={{ backgroundColor: 'blue', padding: '10px', color: 'white' }}>    {children}  </div>);

  async function downloadCSV(managers) {

    let csv;
    try {
      const opts = {
        fields: [
          "managerid",
          "managerfirstname",
          "managerlastname",
          "hq",
          "designation",
          "reportingto",
          "managername",
        ],
        transforms: [
          // unwind({
          //   paths: [
          //     "areas",
          //     "chemists",
          //     "hospitals",
          //     "clincs",
          //     "doctors",
          //     "stockists",
          //   ],
          // }),
          // flatten({ object: false, array: true, separator: "_" }),
        ],
      };
      const parser = new Parser(opts);
      csv = parser.parse(managers);
    } catch (err) {
      console.error(err);
    }

    const link = document.createElement("a");

    if (csv == null) return;

    const filename = "managers.csv";

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute("href", encodeURI(csv));
    link.setAttribute("download", filename);
    link.click();
  }

  const Export = ({ onExport }) => (
    <Button onClick={(e) => onExport(e.target.value)}>Export All</Button>
  );

  const actionsMemo = React.useMemo(
    () => <Export onExport={() => downloadCSV(managers)} />,
    [managers]
  );


  return (
    <Container fluid style={{ minHeight: "80vh" }}>
      <div
        className="glass-body"
        style={{
          margin: 10,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          padding:0,
          backgroundColor:'transparent'
        }}
      >

<div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
<ul id="breadcrumb">
  <li><a href="/"><IoHomeOutline
            style={{ color: "white", fontSize: 20 }}
            
          /></a></li>
  <li><a href="/user-manager"><span class="icon icon-beaker"> </span>Users</a></li>
  <li><a href="/user-manager"><span class="icon icon-beaker"> </span>Managers</a></li>
  {/* <li><a href="#"><span class="icon icon-double-angle-right"></span>Doctor</a></li> */}
  
</ul>
	
</div>
      </div>

      <div className="glass-body" style={{ margin: 10 }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div>
          
          </div>
          <div>
          <div class="dropdown">
                <button
                  class="btn btn-secondary"
                  type="button"
                  style={{ backgroundColor: "#2194f0", display:Op_per.get().USERS_MANAGERS.ALLOW_ADD ? '' : 'none' }}
                  title="Options"
                  id="dropdown-basic"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <HiDotsVertical />
                </button>
                <ul class="dropdown-menu">
                  <li onClick={setShow} style={{display:Op_per.get().USERS_MANAGERS.ALLOW_ADD ? '' : 'none'}}>
                    <a class="dropdown-item">Add</a>
                  </li>
                 
                </ul>
              </div>

            {/* <Export onExport={() => downloadCSV(user)} /> */}
          </div>
        </div>
        <div className="pt-3">
          <DataTable
            columns={columns}
            data={managers}
            // actions={actionsMemo}
            pagination
            customStyles={{headRow: {style: {backgroundColor: '#c0dfff',  padding:10,borderRadius:'10px 10px 0 0' }, }  }}
                        CustomHeader={<CustomHeader/>}
            style={{ marginTop: 10 }}
            dense
          />
        </div>
      </div>
     
      <Modal show={show} backdrop="static" size="md" onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{editOperation ? "Edit" : "Add"} Manager </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Manager <span style={{color:"red"}} >*</span></Form.Label>
              {editOperation ? (
                <Form.Label>{username}</Form.Label>
              ) : (
                <Select
                 className="selectdoctor"
                  options={options}
                  value={selectedManager}
                  onChange={handleManagerSelectChange}
                  styles={{
   
   dropdownIndicator: (provided) => ({
     ...provided,
     color: '#ccc' // Change color of the arrow here
   })
 }}
                />
              )}
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>City <span style={{color:"red"}} >*</span></Form.Label>
              
                <Select
                 className="selectdoctor"
                closeMenuOnSelect={false}
                  options={citiesoptions}
                  value={selectedCities}
                  onChange={handleCitySelectChange}
                  isMulti
                  styles={{
   
    dropdownIndicator: (provided) => ({
      ...provided,
      color: '#ccc' // Change color of the arrow here
    })
  }}
                />
              
            </Form.Group>

            {/* <Form.Group className="mb-3">
              <Form.Label>Territory </Form.Label>
              
                <Select
                 className="selectdoctor"
                closeMenuOnSelect={false}
                  options={areaoptions}
                  value={selectedAreas}
                  onChange={handleAreaSelectChange}
                  isMulti 
                  styles={{
   
   dropdownIndicator: (provided) => ({
     ...provided,
     color: '#ccc' // Change color of the arrow here
   })
 }}
                />
              
            </Form.Group> */}
            {/* <Form.Group className="mb-3">
              <Form.Label>Doctor <span style={{color:"red"}} >*</span></Form.Label>
              
                <Select
                 className="selectdoctor"
                closeMenuOnSelect={false}
                  options={doctorsoption}
                  value={selectedDoctors}
                  onChange={handleDoctorSelectChange}
                  isMulti
                  styles={{
   
   dropdownIndicator: (provided) => ({
     ...provided,
     color: '#ccc' // Change color of the arrow here
   })
 }}
                />
              
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Chemist <span style={{color:"red"}} >*</span></Form.Label>
              
                <Select
                 className="selectdoctor"
                closeMenuOnSelect={false}
                  options={chemistoption}
                  value={selectedChemists}
                  onChange={handleChemistSelectChange}
                  isMulti
                  styles={{
   
   dropdownIndicator: (provided) => ({
     ...provided,
     color: '#ccc' // Change color of the arrow here
   })
 }}
                />
              
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Stockist <span style={{color:"red"}} >*</span></Form.Label>
              
                <Select
                 className="selectdoctor"
                closeMenuOnSelect={false}
                  options={stockistoption}
                  value={selectedStockists}
                  onChange={handleStockistSelectChange}
                  isMulti
                  styles={{
   
   dropdownIndicator: (provided) => ({
     ...provided,
     color: '#ccc' // Change color of the arrow here
   })
 }}
                />
              
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Clinic <span style={{color:"red"}} >*</span></Form.Label>
              
                <Select
                 className="selectdoctor"
                closeMenuOnSelect={false}
                  options={clinicsoption}
                  value={selectedClinics}
                  onChange={handleClinicSelectChange}
                  isMulti
                  styles={{
   
   dropdownIndicator: (provided) => ({
     ...provided,
     color: '#ccc' // Change color of the arrow here
   })
 }}
                />
              
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Hospital <span style={{color:"red"}} >*</span></Form.Label>
              
                <Select
                 className="selectdoctor"
                closeMenuOnSelect={false}
                  options={hospitalsoption}
                  value={selectedHospitals}
                  onChange={handleHospitalsSelectChange}
                  isMulti
                  styles={{
   
   dropdownIndicator: (provided) => ({
     ...provided,
     color: '#ccc' // Change color of the arrow here
   })
 }}
                />
              
            </Form.Group> */}

            <Form.Group className="mb-3">
              <Form.Label>User <span style={{color:"red"}} >*</span></Form.Label>
              
                <Select
                 className="selectdoctor"
                closeMenuOnSelect={false}
                  options={options}
                  value={selectedUser}
                  onChange={handleSelectChange}
                  isMulti
                  styles={{
   
   dropdownIndicator: (provided) => ({
     ...provided,
     color: '#ccc' // Change color of the arrow here
   })
 }}
                />
              
            </Form.Group>
            
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={handleSave}
            // disabled={isclinicname ? true : false}
          >
            {editOperation ? "Edit" : "Add"} Manager
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default Usermanager;
