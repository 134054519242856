import { useEffect, useState } from "react";
import { Button, Container, Form, Image, InputGroup } from "react-bootstrap"
import { IoHomeOutline } from "react-icons/io5"
import { GetAllCurrency } from "../utils/proxy/Currencyproxy";
import Swal from "sweetalert2";
import { GetAllSettings, UpdateSMTPSettings, UpdateSiteSettings } from "../utils/proxy/SettingsProxy";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { CiEdit } from "react-icons/ci";
import { constats } from "../utils/constant";

export const Settingss = () => {

    const [allCurrency, setAllCurrency] = useState([]);
    const [settings, setSettings] = useState([]);
    const [showPassword, setShowPassword] = useState(false);
    const [reload, setReload] = useState(false);

    const [siteDisable, setSiteDisable] = useState(true);
    const [smtpDisable, setSmtpDisable] = useState(true);

    const [siteName, setSiteName] = useState();
    const [siteDescription, setSiteDescription] = useState();
    const [currency, setCurrency] = useState();
    const [company, setCompany] = useState();
    const [logo, setLogo] = useState('');

    const [host, setHost] = useState();
    const [port, setPort] = useState();
    const [security, setSecurity] = useState('none');
    const [username, setUsername] = useState();
    const [password, setPassword] = useState();
    const [fromemail, setFromemail] = useState();

    const [file, setFile] = useState();
    const [filekey, setFileKey] = useState(Date.now());

    useEffect(() => {
        const getcurrencies = async () => {
            let d = await GetAllCurrency();
            if (d) {
                setAllCurrency(d);
            } else {
                alert('Error', 'Issues while fetching Currency')
            }
        }

        getcurrencies();
    }, [])

    useEffect(() => {
        const getSettings = async () => {
            let d = await GetAllSettings();
            setSettings(d);
            setSiteName(d.filter((data) => { return data.setting_name == "SITE_NAME" })[0].setting_value)
            setSiteDescription(d.filter((data) => { return data.setting_name == "SITE_DESCRIPTION" })[0].setting_value)
            setCurrency(d.filter((data) => { return data.setting_name == "CURRENCY_ID" })[0].setting_value)
            setCompany(d.filter((data) => { return data.setting_name == "COMAPNY_NAME" })[0].setting_value)
            setLogo(d.filter((data) => { return data.setting_name == "LOGO_URL" })[0].setting_value)

            setHost(d.filter((data) => { return data.setting_name == "SMTP_HOST" })[0].setting_value)
            setPort(d.filter((data) => { return data.setting_name == "SMTP_PORT" })[0].setting_value)
            setSecurity(d.filter((data) => { return data.setting_name == "SMTP_SECURITY" })[0].setting_value)
            setUsername(d.filter((data) => { return data.setting_name == "SMTP_USERNAME" })[0].setting_value)
            setPassword(d.filter((data) => { return data.setting_name == "SMTP_PASSWORD" })[0].setting_value)
            setFromemail(d.filter((data) => { return data.setting_name == "SMTP_FROM_EMAIL" })[0].setting_value)
        }
        getSettings();
        setReload(false);
        setFileKey(Date.now());
    }, [reload])

    const handlePasswordShow = () => {
        setShowPassword(!showPassword)
    }

    const handleAttachmentChange = (event) =>{
        const file = event.target.files[0];

        if (file) {
            
            setFile(file);
        }
    }

    const handleSiteSetting = async () => {
        if (siteName == null || siteName == '') {
            Swal.fire({
                title: "Warning!",
                text: "Enter Site Name !!",
                icon: "warning",
                confirmButtonText: "OK",
            })
        } else if (siteDescription == null || siteDescription == '') {
            Swal.fire({
                title: "Warning!",
                text: "Enter Site Description !!",
                icon: "warning",
                confirmButtonText: "OK",
            })
        } else if (currency == null || currency == '') {
            Swal.fire({
                title: "Warning!",
                text: "Select Currency !!",
                icon: "warning",
                confirmButtonText: "OK",
            })
        } else if (company == null || company == '') {
            Swal.fire({
                title: "Warning!",
                text: "Enter company Name !!",
                icon: "warning",
                confirmButtonText: "OK",
            })
        } else {
            Swal.showLoading();

            let path = null;
            if (file) {
                const formdata = new FormData();
                formdata.append("file", file);
                formdata.append("filename", "");

                const requestOptions = {
                    method: "POST",
                    body: formdata,
                    redirect: "follow"
                };

                const [AUTH_URL, REPORTING_URL] = await constats();

                try{
                    const response = await fetch(REPORTING_URL+"/apiv1/fileupload/logo", requestOptions)
                    path = await response.text();
                }catch (error) {
                    Swal.fire({
                        title: 'Failed',
                        text: 'Failed To Upload Document',
                        icon: 'error'
                    });
                }
            }

            let d = await UpdateSiteSettings(siteName, siteDescription, currency, company, path ?? logo);
            if (d.status == 'success') {
                Swal.hideLoading();
                setReload(true)
                setSiteDisable(true);
                setFile(null);
                Swal.fire({
                    title: "Success!",
                    text: d.message + " !!",
                    icon: "success",
                    confirmButtonText: "OK",
                });
            } else {
                Swal.hideLoading();
                Swal.fire({
                    title: "Error!",
                    text: "Something Went Wrong !!",
                    icon: "Error",
                    confirmButtonText: "OK",
                });
            }
        }
    }

    const handleSmtpDetails = async () => {
        if (host == null || host == '') {
            Swal.fire({
                title: "Warning!",
                text: "Enter SMTP Host !!",
                icon: "warning",
                confirmButtonText: "OK",
            })
        } else if (port == null || port == '') {
            Swal.fire({
                title: "Warning!",
                text: "Enter SMTP Port !!",
                icon: "warning",
                confirmButtonText: "OK",
            })
        } else if (security == null || security == '') {
            Swal.fire({
                title: "Warning!",
                text: "Select SMTP Security !!",
                icon: "warning",
                confirmButtonText: "OK",
            })
        } else if (username == null || username == '') {
            Swal.fire({
                title: "Warning!",
                text: "Enter SMTP Username !!",
                icon: "warning",
                confirmButtonText: "OK",
            })
        } else if (password == null || password == '') {
            Swal.fire({
                title: "Warning!",
                text: "Enter SMTP Password !!",
                icon: "warning",
                confirmButtonText: "OK",
            })
        } else if (fromemail == null || fromemail == '') {
            Swal.fire({
                title: "Warning!",
                text: "Enter SMTP From Email !!",
                icon: "warning",
                confirmButtonText: "OK",
            })
        } else {
            Swal.showLoading();
            let d = await UpdateSMTPSettings(host, port, security, username, password, fromemail);
            if (d.status == 'success') {
                Swal.hideLoading();
                setReload(true);
                setSmtpDisable(true);
                Swal.fire({
                    title: "Success!",
                    text: d.message + " !!",
                    icon: "success",
                    confirmButtonText: "OK",
                });
            } else if (d.status == 'error') {
                Swal.hideLoading();
                Swal.fire({
                    title: "Error!",
                    text: d.message,
                    icon: "error",
                    confirmButtonText: "OK",
                });
            } else {
                Swal.hideLoading();
                Swal.fire({
                    title: "Error!",
                    text: "Something Went Wrong !!",
                    icon: "Error",
                    confirmButtonText: "OK",
                });
            }
        }
    }

    return (
        <Container fluid style={{ minHeight: "80vh" }}>
            <div
                className="glass-body"
                style={{
                    margin: 10,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    padding: 0,
                    backgroundColor: 'transparent'
                }}
            >

                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <ul id="breadcrumb">
                        <li><a href="/"><IoHomeOutline
                            style={{ color: "white", fontSize: 20 }}
                            
                        /></a></li>
                        <li><a href="/settings"><span class="icon icon-beaker"> </span>Settings</a></li>
                        {/* <li><a href="#"><span class="icon icon-double-angle-right"></span>Doctor</a></li> */}

                    </ul>

                </div>
            </div>

            <div className="glass-body" style={{ margin: 10 }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <h2>Site Settings</h2>
                    <Button onClick={() => { setSiteDisable(!siteDisable) }}><CiEdit /></Button>
                </div>
                <Form>
                    <Form.Group className="mb-3" controlId="formBasicEmail1">
                        <Form.Label>Site Name</Form.Label>
                        <Form.Control type="text" value={siteName} onChange={e => setSiteName(e.target.value)} placeholder="Enter Site Name" disabled={siteDisable} />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicEmail2">
                        <Form.Label>Site Description</Form.Label>
                        <Form.Control type="text" value={siteDescription} onChange={e => setSiteDescription(e.target.value)} placeholder="Enter Site Description" disabled={siteDisable} />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicEmail3">
                        <Form.Label>Currency</Form.Label>
                        <Form.Select value={currency} onChange={e => setCurrency(e.target.value)} disabled={siteDisable}>
                            <option value={''}>--Select Currency--</option>
                            {allCurrency.map(saluation => (
                                <option value={saluation.id}>{saluation.currency}</option>
                            ))}
                        </Form.Select>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicEmail4">
                        <Form.Label>Company Name</Form.Label>
                        <Form.Control type="text" value={company} onChange={e => setCompany(e.target.value)} placeholder="Enter Company Name" disabled={siteDisable} />
                    </Form.Group>

                    <div style={{display:'flex',alignItems:'center',justifyContent:'space-between',gap:5}}>
                    <Form.Group className="mb-3" style={{flexGrow:1}} controlId="formBasicEmail5">
                        <Form.Label>Logo</Form.Label>
                        <Form.Control 
                            type="file" 
                            accept="image/*"
                            onChange={(e) => handleAttachmentChange(e)} 
                            disabled={siteDisable} />
                    </Form.Group>
                    {logo && <div>
                        <Image key={filekey} src={logo + `?date=${filekey}`} height={100} width={100} rounded fluid />
                    </div>}
                    </div>

                    <Button variant="primary" type="button" disabled={siteDisable} onClick={handleSiteSetting}>
                        Save
                    </Button>
                </Form>
            </div>

            <div className="glass-body" style={{ margin: 10 }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <h2>SMTP Settings</h2>
                    <Button onClick={() => { setSmtpDisable(!smtpDisable) }}><CiEdit /></Button>
                </div>

                <Form>
                    <Form.Group className="mb-3" controlId="formBasicEmail6">
                        <Form.Label>SMTP Host</Form.Label>
                        <Form.Control type="text" value={host} onChange={e => setHost(e.target.value)} placeholder="mail.example.com" disabled={smtpDisable} />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicEmail7">
                        <Form.Label>SMTP Port</Form.Label>
                        <Form.Control type="number" value={port} onChange={e => setPort(e.target.value)} placeholder="25" disabled={smtpDisable} />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicEmail8">
                        <Form.Label>SMTP Security</Form.Label>
                        <Form.Select value={security} onChange={e => setSecurity(e.target.value)} disabled={smtpDisable}>
                            {/* <option value={'auto'} selected>Auto</option> */}
                            <option value={'none'} selected>None</option>
                            <option value={'ssl'}>SSL</option>
                            <option value={'tls'}>TLS</option>
                            <option value={'tls-available'}>TLS When Available</option>
                        </Form.Select>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicEmail9">
                        <Form.Label>SMTP Username</Form.Label>
                        <Form.Control type="text" value={username} onChange={e => setUsername(e.target.value)} placeholder="Username" disabled={smtpDisable} />
                    </Form.Group>

                    <Form.Group controlId="formPassword">
                        <Form.Label>SMTP Password</Form.Label>
                        <InputGroup>
                            <Form.Control
                                type={showPassword ? 'text' : 'password'}
                                value={password}
                                onChange={e => setPassword(e.target.value)}
                                placeholder="Password"
                                disabled={smtpDisable}
                            />
                            <Button onClick={handlePasswordShow} variant="outline-secondary">
                                {showPassword ? <FaEye /> : <FaEyeSlash />}
                            </Button>
                        </InputGroup>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label>SMTP From E-Mail</Form.Label>
                        <Form.Control type="email" value={fromemail} onChange={e => setFromemail(e.target.value)} placeholder="john.doe@gmail.com" disabled={smtpDisable} />
                    </Form.Group>
                    <Button variant="primary" type="button" disabled={smtpDisable} onClick={handleSmtpDetails}>
                        Save
                    </Button>
                </Form>
            </div>
        </Container>
    )
}