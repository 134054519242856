import { Container } from "react-bootstrap"
import Chart from 'react-apexcharts';
import { useEffect, useRef } from "react";
import { getCompletedTaskDataByMonth, getPerDayByMonth } from "../../utils/proxy/ReportsProxy";
import { useState } from "react";
import { GrFormPrevious } from "react-icons/gr";
import { MdNavigateNext } from "react-icons/md";
import { Bar } from "recharts";
import { useNavigate } from "react-router-dom";
import Helper from "../../utils/helperFunctions";
import Swal from "sweetalert2";

export const LineBarDemochart = ({setTaskof}) => {

    const months = {
        1:'Jan',
        2:'Feb',
        3:'Mar',
        4:'Apr',
        5:'May',
        6:'Jun',
        7:'Jul',
        8:'Aug',
        9:'Sep',
        10:'Oct',
        11:'Nov',
        12:'Dec',
    }

    const [dates, setDates] = useState([]);
    const [count, setCount] = useState([]);
    const [currdate, setCurrdate] = useState(new Date());

    const navigate = useNavigate();
    const dateRef = useRef(currdate);

    setTaskof(months[currdate.getMonth()+1] +" "+ currdate.getFullYear());

    useEffect(() => {
        const getLeadData = async () => {
            let d = await getPerDayByMonth(currdate.getFullYear(), currdate.getMonth()+1,'');
            let categories = [];
            let series = [];
            if (d) {
                d && d.map(k => {
                    series.push(k.count);
                    categories.push(k.date.split('-')[2])
                })
                setDates(categories);
                setCount(series);
            }
        }

        getLeadData();
    }, [currdate])

    function subtractMonths(date, months) {
        const d = new Date(date);
        d.setMonth(d.getMonth() - months);
    
        // Handle edge case where subtracting months might affect the day
        if (d.getDate() !== date.getDate()) {
            d.setDate(0);
        }
    
        setCurrdate(d);
        dateRef.current = d;
    }
    
    // Function to add months to a given date
    function addMonths(date, months) {
        const d = new Date(date);
        d.setMonth(d.getMonth() + months);
    
        // Handle edge case where adding months might affect the day
        if (d.getDate() !== date.getDate()) {
            d.setDate(0);
        }
    
        setCurrdate(d);
        dateRef.current = d;
    }

    const options = {
        // chart: {
        //     height: "100px",
            
        //     type: 'line',
        // },
        
        // stroke: {
        //     width: [0, 1]
        // },
        chart: {
            id: 'basic-bar',
            events: {
                dataPointSelection: (event, chartContext, config) => {
                    const { seriesIndex, dataPointIndex } = config;
                    const latestSeries = seriesRef.current; // Access latest data
                    const latestDate = dateRef.current;
                    if (latestSeries[seriesIndex] && latestSeries[seriesIndex].data) {
                      const value = latestSeries[seriesIndex].data[dataPointIndex];
                      navigate('/tasks',{ state: { dateProp: `${latestDate.getFullYear()}-${Helper.addLeadingZero(latestDate.getMonth()+1)}-${(Helper.addLeadingZero(dataPointIndex+1))}` } })
                    } else {
                        Swal.fire({
                            title:'Error',
                            text:'Series data is not accessible or undefined',
                            icon:'info'
                        })
                    }
                }
              }
        },
        
        xaxis: {
            categories: dates
        }
        // title: {
        //     text: `Tasks of ${months[currdate.getMonth()+1]}`
        // },
        // noData: {
        //     text: 'Loading...'
        // },
        // dataLabels: {
        //     enabled: true,
        //     enabledOnSeries: [1]
        // },
        // labels: dates,
        // xaxis: {
        //     // type: 'datetime'
        // },
        // yaxis: [{ min: 0 }],
        // legend: {
        //     show: true
        // },

        // tooltip: {
        //     custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        //         return '<div class="apexcharts-tooltip">' +
        //             '<span>' + w.globals.seriesNames[seriesIndex] + ': ' + series[seriesIndex][dataPointIndex] + '</span>' +
        //             '</div>';
        //     }
        // }

    };

    const series = [{
        name: 'Completed Task',
        type: 'column',
        color: '#f5430f',
        data: count
    }]

    const seriesRef = useRef(series); // Ref to always get the latest data

  // Update seriesRef whenever series changes
  useEffect(() => {
    seriesRef.current = series;
  }, [series]);

    return (
        <Container>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>

                {/* <GrFormPrevious size={30} onClick={() => subtractMonths(currdate, 1)} /> */}
                <i class="fa fa-chevron-left navigate__icon" onClick={() => subtractMonths(currdate, 1)} aria-hidden="true"></i>

                <div style={{ flexGrow: 1 }}>
                    <Chart height={300} options={options} series={series} type="bar" />
                </div>

                {/* <MdNavigateNext size={30} onClick={() => addMonths(currdate, 1)} /> */}
                <i class="fa fa-chevron-right navigate__icon" onClick={() => addMonths(currdate, 1)} aria-hidden="true"></i>

            </div>
        </Container>
    )
}