import { Button, Container, Dropdown, Modal } from "react-bootstrap";
import DataTable from "react-data-table-component";
import Form from 'react-bootstrap/Form';
import { useState } from "react";
import { AiFillDelete, AiTwotoneEdit } from "react-icons/ai";
import { useEffect } from "react";
import Swal from "sweetalert2";
import { GetAllHolidayPagination } from "../../utils/proxy/AttendanceProxy";
import Select from "react-select";
import { HiDotsVertical } from "react-icons/hi";
import { Parser } from "@json2csv/plainjs";
import { CreateProjects, DeleteProjects, GetAllProjects, GetAllProjectsByUserid, UpdateProjects } from "../../utils/proxy/ProjectProxy";
import { GetCurrency } from "../../utils/proxy/Expenseproxy";
import { IoHomeOutline } from "react-icons/io5";
import { AllUsers, GetAllManagerUsers } from "../../utils/proxy/Authproxy";
import { GetCurrencyFromSettings } from "../../utils/proxy/SettingsProxy";
import Helper from "../../utils/helperFunctions";
import { Op_per } from "../../utils/Permissions";
import { useNavigate } from "react-router-dom";


function Project() {

    const [show, setShow] = useState(false);
    const [showedit, setShowedit] = useState(false);
    const [reload, SetReload] = useState(false);
    const [data, setData] = useState([]);

    const [users, setusers] = useState([])
    const [id, setId] = useState();
    const [name, setName] = useState();
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [description, setDescription] = useState();
    const [selectedStatus, setSelectedStatus] = useState();
    const [selectedStatusValue, setSelectedStatusValue] = useState();

    const [cost, setCost] = useState();
    const [allCurrency, setAllCurrency] = useState([]);
    const [selectedCurrency, setSelectedCurrency] = useState();
    const [selectedCurrencyId, setSelectedCurrencyId] = useState();
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [selectedUserId, setSelectedUserId] = useState();
    const [utcStartDate, setUtcStartDate] = useState();

    const navigate = useNavigate();

    const handleClose = () => setShow(false);
    const handleShow = () => {setShow(true);setUtcStartDate(Helper.dateToIsoStringWithoutChange(new Date()).substr(0,10))};

    const [searchQuery, setSearchQuery] = useState('');

    useEffect(() => {

        const getAllUsersData = async () => {
          if(localStorage.getItem('role') === 'Admin'){
            let allusers = await AllUsers();
            setusers(allusers);
          }else if(localStorage.getItem('role') === 'Manager'){
            let allusers = await GetAllManagerUsers(localStorage.getItem('uuid'));
            setusers(allusers);
          }
        };
        getAllUsersData();
      }, []);

    const getall = async () => {
        if (localStorage.getItem('role') === 'Admin') {
            let d = await GetAllProjects();
        if (d) {
            setData(d);
        } else {
            alert('Error', 'Issues while fetching data')
        }
        }else{
            let d = await GetAllProjectsByUserid(localStorage.getItem('uuid'));
            if (d) {
                setData(d);
            } else {
                alert('Error', 'Issues while fetching data')
            }
        }
        
    }

    useEffect(() => {

        getall(0)
        SetReload(false);
    }, [reload])

    useEffect(() => {
        const getCurrencyType = async () => {
            let curr = await GetCurrency();
            let setcurr = await GetCurrencyFromSettings();
            if (curr) {
                setAllCurrency(curr);
                if(setcurr){
                    const b = curr.find(cur => cur.id == setcurr)
                    setSelectedCurrency({value:b.id, label:b.currency})
                    setSelectedCurrencyId(b.id);
                    }
            } else {
                alert("Error", "Issue While Fetching Data");
            }
        }
        getCurrencyType()
    }, [reload])

    const filterData = data.filter((row)=>{
        if(row.name.toLowerCase().includes(searchQuery.toLowerCase())){
            return true;
        }

        if(row.users && row.users.length > 0){
            for(let user of row.users){
                if(user.username.toLowerCase().includes(searchQuery.toLowerCase()))
                return true;
            }
        }
        return false;
    })

    const handleCurrencyChange = (selected) => {
        setSelectedCurrency(selected)
        setSelectedCurrencyId(selected.value)
    }

    const handleStatusChange = (selected) => {
        setSelectedStatus(selected)
        setSelectedStatusValue(selected.value)
    }

    const handleSelectChange = (selected) => {
        const values = selected.map((opt)=>opt.value);
        setSelectedUsers(selected);
        setSelectedUserId(values);
      };

    const handleSave = async () => {
        if (name == null || name == '') {
            Swal.fire({
                title: "Warning!",
                text: "Enter Project Name !!",
                icon: "warning",
                confirmButtonText: "OK",
            });
        } else if (selectedUserId == null || selectedUserId == []) {
            Swal.fire({
                title: "Warning!",
                text: "Select At Least 1 User !!",
                icon: "warning",
                confirmButtonText: "OK",
            });
        } else if (startDate == null || startDate == '' || endDate == null || endDate == '') {
            Swal.fire({
                title: "Warning!",
                text: "Select Date !!",
                icon: "warning",
                confirmButtonText: "OK",
            });
        } else if (cost == null || cost == '') {
            Swal.fire({
                title: "Warning!",
                text: "Enter Cost !!",
                icon: "warning",
                confirmButtonText: "OK",
            });
        } else if (cost <= 0) {
            Swal.fire({
                title: "Warning!",
                text: "Cost Should Not Be Negative Or Zero !!",
                icon: "warning",
                confirmButtonText: "OK",
            });
        } else if (selectedCurrencyId == null || selectedCurrencyId == '') {
            Swal.fire({
                title: "Warning!",
                text: "Select Currency !!",
                icon: "warning",
                confirmButtonText: "OK",
            });
        } else if (description == null || description == '') {
            Swal.fire({
                title: "Warning!",
                text: "Enter Description !!",
                icon: "warning",
                confirmButtonText: "OK",
            });
        } else if (selectedStatusValue == null || selectedStatusValue == '') {
            Swal.fire({
                title: "Warning!",
                text: "Select Status !!",
                icon: "warning",
                confirmButtonText: "OK",
            });
        } else if (startDate > endDate) {
            Swal.fire({
                title: "Warning!",
                text: "End Date Should be After Start Date !!",
                icon: "warning",
                confirmButtonText: "OK",
            });
        } else {
            let d = await CreateProjects(name,selectedUserId, startDate, endDate, cost, selectedCurrencyId, description, selectedStatusValue);
            if (d.status === 'success') {
                setShow(false);
                SetReload(true);
                setName(null);
                setStartDate('');
                setEndDate('');
                setCost(null);
                setDescription(null);
                setSelectedStatusValue(null);
                setSelectedUserId(null);
                Swal.fire({
                    title: "Success!",
                    text: "New Project Added !!",
                    icon: "success",
                    confirmButtonText: "OK",
                });
            }
        }
    }

    const handledelete = async (id) => {
        let d = await DeleteProjects(id);
        if (d.status === 'success') {
            SetReload(true);
            Swal.fire({
                title: "Success!",
                text: "Project Deleted !!",
                icon: "success",
                confirmButtonText: "OK",
            });
        }
    }

    const handleEditSave = async () => {
        if (name == null || name == '') {
            Swal.fire({
                title: "Warning!",
                text: "Enter Project Name !!",
                icon: "warning",
                confirmButtonText: "OK",
            });
        } else if (startDate == null || startDate == '' || endDate == null || endDate == '') {
            Swal.fire({
                title: "Warning!",
                text: "Select Date !!",
                icon: "warning",
                confirmButtonText: "OK",
            });
        } else if (cost == null || cost == '') {
            Swal.fire({
                title: "Warning!",
                text: "Enter Cost !!",
                icon: "warning",
                confirmButtonText: "OK",
            });
        } else if (cost < 0) {
            Swal.fire({
                title: "Warning!",
                text: "Cost Should Not Be Negative !!",
                icon: "warning",
                confirmButtonText: "OK",
            });
        } else if (selectedCurrencyId == null || selectedCurrencyId == '') {
            Swal.fire({
                title: "Warning!",
                text: "Select Currency !!",
                icon: "warning",
                confirmButtonText: "OK",
            });
        } else if (description == null || description == '') {
            Swal.fire({
                title: "Warning!",
                text: "Enter Description !!",
                icon: "warning",
                confirmButtonText: "OK",
            });
        } else if (selectedStatusValue == null || selectedStatusValue == '') {
            Swal.fire({
                title: "Warning!",
                text: "Select Status !!",
                icon: "warning",
                confirmButtonText: "OK",
            });
        } else {
            let d = await UpdateProjects(id, name,selectedUserId, startDate, endDate, cost, selectedCurrencyId, description, selectedStatusValue);
            if (d.status === 'success') {
                setShowedit(false);
                SetReload(true);
                setId(null);
                setName(null);
                setDescription(null);
                setSelectedUsers(null);
                setSelectedUserId(null);
                Swal.fire({
                    title: "Success!",
                    text: "Project Edited !!",
                    icon: "success",
                    confirmButtonText: "OK",
                });
            }
        }
    }

    const edithandle = (row) => {
        const p = [];
        const v = row.users.map(opt=>opt.userid);
        row.users.map(opt=>{
            p.push({
                value:opt.userid,
                label:opt.username
            })
        })
        setShowedit(true);
        setId(row.id);
        setName(row.name);
        setStartDate(row.startdate);
        setEndDate(row.enddate);
        setCost(row.cost);
        setSelectedCurrency({ value: row.currencyid, label: row.currency })
        setSelectedCurrencyId(row.currencyid)
        setDescription(row.description)
        setSelectedStatus({ value: row.status, label: row.status });
        setSelectedStatusValue(row.status)
        setSelectedUsers(p);
        setSelectedUserId(v);
    }

    async function downloadCSV(areas) {
        let csv;
        try {
            const opts = {
                fields: [
                    "name",
                    "startdate",
                    "enddate",
                    "cost",
                    "currency",
                    "description",
                    "status",
                ],
            };
            const parser = new Parser(opts);
            csv = parser.parse(areas);
        } catch (err) {
            console.error(err);
        }

        const link = document.createElement("a");

        if (csv == null) return;

        const filename = "export.csv";

        if (!csv.match(/^data:text\/csv/i)) {
            csv = `data:text/csv;charset=utf-8,${csv}`;
        }

        link.setAttribute("href", encodeURI(csv));
        link.setAttribute("download", filename);
        link.click();
    }

    // export excel
    const handleExcelExport = () => {
        Swal.fire({
            title: "Exporting!",
            text: "Exporting Excel !!",
            showConfirmButton: false,
            showCancelButton: false,
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading()
                downloadCSV(data);
                Swal.hideLoading()
                Swal.close()

            }
        });
    }

    const handleRedirectProjectWork = (projectid) =>{
        navigate('/projects-work',{state:{id:projectid}})
    }

    const columns = [
        // {
        //     name: "Id",
        //     selector: (row) => row.id,
        //  
        //     width: "5%",
        // },
        {
            name: "Project Name",
            selector: (row) =>
                <div onClick={()=>handleRedirectProjectWork(row.id)}><u style={{color:'blue',cursor:"pointer",fontSize:10}}>{row.name ? row.name : "N/A"}</u></div>  //style={{ whiteSpace: 'normal', backgroundColor: row.name === "" || row.name === null ? "#f36e6f" : "", borderRadius: "10px", border: row.name === "" || row.name === null ? "2px solid transparent" : "2px solid transparent", padding: "3px", display: "flex", flexDirection: "row", color: row.name === "" || row.name === null ? "#fff" : "#000", margin: "3px", }}
            ,
         
            width: "10%",
        },
        {
            name: "Start Date",
            selector: (row) =>
                <div style={{ whiteSpace: 'normal', backgroundColor: row.startdate.split('T')[0] === "" || row.startdate.split('T')[0] === null ? "#f36e6f" : "", borderRadius: "10px", border: row.startdate.split('T')[0] === "" || row.startdate.split('T')[0] === null ? "2px solid transparent" : "2px solid transparent", padding: "3px", display: "flex", flexDirection: "row", color: row.startdate.split('T')[0] === "" || row.startdate.split('T')[0] === null ? "#fff" : "#000", margin: "3px", }}>{row.startdate.split('T')[0] ? row.startdate.split('T')[0] : "N/A"}</div>
            ,
         
            width: "10%",
        },
        {
            name: "End Date",
            selector: (row) =>
                <div style={{ whiteSpace: 'normal', backgroundColor: row.enddate.split('T')[0] === "" || row.enddate.split('T')[0] === null ? "#f36e6f" : "", borderRadius: "10px", border: row.enddate.split('T')[0] === "" || row.enddate.split('T')[0] === null ? "2px solid transparent" : "2px solid transparent", padding: "3px", display: "flex", flexDirection: "row", color: row.clinicname === "" || row.enddate.split('T')[0] === null ? "#fff" : "#000", margin: "3px", }}>{row.enddate ? row.enddate.split('T')[0] : "N/A"}</div>
            ,
         
            width: "10%",
        },
        {
            name: "Cost",
            selector: (row) =>
                <div style={{ whiteSpace: 'normal', backgroundColor: row.cost === "" || row.cost === null ? "#f36e6f" : "", borderRadius: "10px", border: row.cost === "" || row.cost === null ? "2px solid transparent" : "2px solid transparent", padding: "3px", display: "flex", flexDirection: "row", color: row.cost === "" || row.cost === null ? "#fff" : "#000", margin: "3px", }}>{row.cost ? row.cost : "N/A"}</div>
            ,
         
            width: "10%",
        },
        {
            name: "Currency",
            selector: (row) =>
                <div style={{ whiteSpace: 'normal', backgroundColor: row.currency === "" || row.currency === null ? "#f36e6f" : "", borderRadius: "10px", border: row.currency === "" || row.currency === null ? "2px solid transparent" : "2px solid transparent", padding: "3px", display: "flex", flexDirection: "row", color: row.currency === "" || row.currency === null ? "#fff" : "#000", margin: "3px", }}>{row.currency ? row.currency : "N/A"}</div>
            ,
         
            width: "10%",
        },
        {
            name: "Description",
            selector: (row) =>
                <div style={{ whiteSpace: 'normal', backgroundColor: row.description === "" || row.description === null ? "#f36e6f" : "", borderRadius: "10px", border: row.description === "" || row.description === null ? "2px solid transparent" : "2px solid transparent", padding: "3px", display: "flex", flexDirection: "row", color: row.description === "" || row.description === null ? "#fff" : "#000", margin: "3px", }}>{row.description ? row.description : "N/A"}</div>
            ,
         
            width: "15%",
        },
        {
            name: "Status",
            selector: (row) =>
                <div style={{ whiteSpace: 'normal', backgroundColor: row.status === "" || row.status === null ? "#f36e6f" : "", borderRadius: "10px", border: row.status === "" || row.status === null ? "2px solid transparent" : "2px solid transparent", padding: "3px", display: "flex", flexDirection: "row", color: row.status === "" || row.status === null ? "#fff" : "#000", margin: "3px", }}>{row.status ? row.status : "N/A"}</div>
            ,
         
            width: "10%",
        },
        // {
        //     name: "People Working On Project",
        //     selector: (row) =>
        //     <div style={{ whiteSpace: 'normal' }}>{ row.users.map((hobby) => (
        //       <Button style={{padding:1,fontSize:'11px',fontWeight:'bold',margin:3,padding:2,backgroundColor:"",borderRadius:"10px",border: "2px solid transparent",color:'#000'}} >{hobby.username}
              
        //   </Button>
        //    ))}</div>,
            
           
        //  
        //     width:"13%"
        //   },
    ];

    const customStyles = {   head: {     style: {       backgroundColor: 'blue',     },   }, };
    const CustomHeader = ({ children }) => (   <div style={{ backgroundColor: 'blue', padding: '10px', color: 'white' }}>    {children}  </div>);

    if (Op_per.get().PROJECTS.ALLOW_DELETE || Op_per.get().PROJECTS.ALLOW_EDIT) {
        columns.push({
            name: "Actions",
            selector: (row) => (
                <div key={row.id}>
                    <AiFillDelete
                        onClick={() => handledelete(row.id)}
                        style={{ color: "#f36e6f", fontSize: 20,display:Op_per.get().PROJECTS.ALLOW_DELETE ? '' : 'none' }}
                    />
                    &nbsp;&nbsp;&nbsp;
                    <AiTwotoneEdit
                        onClick={() => edithandle(row)}
                        style={{ color: "#9889e6", fontSize: 20,display:Op_per.get().PROJECTS.ALLOW_EDIT ? '' : 'none' }}
                    />
                </div>
            ),
        })
    }

    const options = [];
    const currencyoptions = [];
    const statusoptions = [];

    users.map((user) => {
        options.push({
          value: user.userid,
          label: user.username + "(" + user.firstname + " " + user.lastname + ")",
        });
      });

    allCurrency.map((curr) => {
        currencyoptions.push({
            value: curr.id,
            label: curr.currency
        });
    });

    statusoptions.push({ value: 'New', label: 'New' })
    statusoptions.push({ value: 'Inprogress', label: 'Inprogress' })
    statusoptions.push({ value: 'Completed', label: 'Completed' })
    statusoptions.push({ value: 'Rejected', label: 'Rejected' })

    return (
        <Container fluid style={{ minHeight: "80vh" }}>
            <div
                className="glass-body"
                style={{
                    margin: 10,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    padding: 0,
                    backgroundColor: 'transparent'
                }}
            >

                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <ul id="breadcrumb">
                        <li><a href="/"><IoHomeOutline
                            style={{ color: "white", fontSize: 20 }}
                            
                        /></a></li>
                        <li><a href="/projects"><span class="icon icon-beaker"> </span>Projects</a></li>
                        {/* <li><a href="/user-doctor-master"><span class="icon icon-beaker"> </span> User Doctor Master</a></li> */}
                        {/* <li><a href="#"><span class="icon icon-double-angle-right"></span>Doctor</a></li> */}

                    </ul>

                </div>
            </div>
            <div className="glass-body" style={{ margin: 10 }}>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                    }}
                    className="flex-column-mobile"
                >
                    <div>
                        <Form.Control
                            style={{
                                height: 40,
                                width: 200,
                                borderColor: '#ccc',
                                borderWidth: 1,
                                paddingHorizontal: 8,
                                marginBottom: 16,
                                borderRadius:10,
                            }}
                            placeholder="Search"
                            type="text"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />
                    </div>
                    <div>
                        {Op_per.get().PROJECTS.ALLOW_ADD || Op_per.get().PROJECTS.ALLOW_EXPORT_XLSX ? (
                            
                  <div class="dropdown">
                 <button
                  class="btn btn-secondary"
                  type="button"
                  style={{ backgroundColor: "#2194f0" }}
                  title="Options"
                  id="dropdown-basic"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <HiDotsVertical />
                </button>
                <ul class="dropdown-menu">
                  <li onClick={handleShow} style={{display:Op_per.get().PROJECTS.ALLOW_ADD ? '' : 'none'}}>
                    <a class="dropdown-item">Add</a>
                  </li>
                  <li onClick={handleExcelExport} style={{display:Op_per.get().PROJECTS.ALLOW_EXPORT_XLSX ? '' : 'none'}}>
                    <a class="dropdown-item"> Export XLSX</a>
                  </li>
                 
                </ul>
              </div>
                        ) : ("")}
                    </div>
                </div>
                <div style={{ marginTop: 10 }}>
                    <DataTable
                        columns={columns}
                        data={filterData}
                        // progressPending={pending}
                        // selectableRows
                        // onSelectedRowsChange={({ selectedRows }) =>
                        // setSelectedRows(selectedRows)
                        //   }
                        pagination
                        customStyles={{headRow: {style: {backgroundColor: '#c0dfff',  padding:10,borderRadius:'10px 10px 0 0' }, }  }}
                        CustomHeader={<CustomHeader/>}
                        // actions={actionsMemo}
                        style={{ marginTop: 10 }}
                        // progressPending={pending}
                        // progressComponent={<LoadingComponent/>}
                        dense
                    />
                </div>
            </div>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Project</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Project Name <span style={{color:'red'}}>*</span></Form.Label>
                            <Form.Control
                                style={{
                                    height: 40,

                                    borderColor: '#ccc',
                                    borderWidth: 1,
                                    paddingHorizontal: 8,
                                    marginBottom: 16,
                                    
                                    borderRadius:10,
                                }}
                                type="text"
                                onChange={(e) => setName(e.target.value)}
                                placeholder="Enter Project Name"
                                required
                            />
                        </Form.Group>
                        <Form.Label >Users <span style={{color:'red'}}>*</span></Form.Label>
                        <Form.Group
                            className="mb-3 d-flex"
                            style={{ gap: "15px" }}
                            controlId="formBasicPassword"
                        >
                            <div className="selectdoctor" >
                                <Select
                                    closeMenuOnSelect={false}
                                    options={options}
                                    value={selectedUsers}
                                    onChange={handleSelectChange}
                                    isMulti
                                    styles={{
   
   dropdownIndicator: (provided) => ({
     ...provided,
     color: '#ccc' // Change color of the arrow here
   })
 }}
                                />
                            </div>
                        </Form.Group>
                        <Form.Label>Start Date <span style={{color:'red'}}>*</span></Form.Label>
                        <Form.Control
                            style={{
                                height: 40,

                                borderColor: '#ccc',
                                borderWidth: 1,
                                paddingHorizontal: 8,
                                marginBottom: 16,
                                
                                borderRadius:10,
                            }}
                            type="date"
                            value={utcStartDate}
                            onChange={(e) => {setStartDate(new Date(e.target.value).toISOString());setUtcStartDate(e.target.value)}}
                            placeholder="Enter Start Date"
                        />
                        <Form.Label>End Date <span style={{color:'red'}}>*</span></Form.Label>
                        <Form.Control
                            style={{
                                height: 40,

                                borderColor: '#ccc',
                                borderWidth: 1,
                                paddingHorizontal: 8,
                                marginBottom: 16,
                                
                                borderRadius:10,
                            }}
                            type="date"
                            onChange={(e) => setEndDate(new Date(e.target.value).toISOString())}
                            placeholder="Enter End Date"
                        />
                        <Form.Label>Project Cost <span style={{color:'red'}}>*</span></Form.Label>
                        <Form.Control
                            style={{
                                height: 40,

                                borderColor: '#ccc',
                                borderWidth: 1,
                                paddingHorizontal: 8,
                                marginBottom: 16,
                                
                                borderRadius:10,
                            }}
                            type="number"
                            onChange={(e) => setCost(e.target.value)}
                            placeholder="Enter Project Cost"
                            required
                        />
                        <Form.Label>Currency <span style={{color:'red'}}>*</span></Form.Label>
                        <Form.Group
                            className="mb-3 d-flex"
                            style={{ gap: "15px" }}
                            controlId="formBasicPassword"
                        >
                            <div className="selectdoctor">
                                <Select

                                    options={currencyoptions}
                                    value={selectedCurrency}
                                    onChange={handleCurrencyChange}
                                    placeholder="Select Currency"
                                    styles={{
   
   dropdownIndicator: (provided) => ({
     ...provided,
     color: '#ccc' // Change color of the arrow here
   })
 }}
                                />
                            </div>
                        </Form.Group>
                        <Form.Label>Description <span style={{color:'red'}}>*</span></Form.Label>
                        <Form.Control
                            style={{
                                height: 80,

                                borderColor: '#ccc',
                                borderWidth: 1,
                                paddingHorizontal: 8,
                                marginBottom: 16,
                                
                                borderRadius:10,
                            }}
                            as="textarea"
                            onChange={(e) => setDescription(e.target.value)}
                            placeholder="Enter Project Description"
                            required
                        />
                        <Form.Label>Status <span style={{color:'red'}}>*</span></Form.Label>
                        <Form.Group
                            className="mb-3 d-flex"
                            style={{ gap: "15px" }}
                            controlId="formBasicPassword"
                        >
                            <div className="selectdoctor">
                                <Select

                                    options={statusoptions}
                                    value={selectedStatus}
                                    onChange={handleStatusChange}
                                    placeholder="Select Status"
                                    styles={{
   
   dropdownIndicator: (provided) => ({
     ...provided,
     color: '#ccc' // Change color of the arrow here
   })
 }}
                                />
                            </div>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button
                        variant="primary"
                        onClick={handleSave}
                    >
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showedit} onHide={() => setShowedit(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Project</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Project Name <span style={{color:"red"}} >*</span></Form.Label>
                            <Form.Control
                                style={{
                                    height: 40,

                                    borderColor: '#ccc',
                                    borderWidth: 1,
                                    paddingHorizontal: 8,
                                    marginBottom: 16,
                                    
                                    borderRadius:10,
                                }}
                                type="text"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                placeholder="Enter Project Name"
                                required
                            />
                        </Form.Group>
                        <Form.Label >Users <span style={{color:"red"}} >*</span></Form.Label>
                        <Form.Group
                            className="mb-3 d-flex"
                            style={{ gap: "15px" }}
                            controlId="formBasicPassword"
                        >
                            <div className="selectdoctor" >
                                <Select
                                    closeMenuOnSelect={false}
                                    options={options}
                                    value={selectedUsers}
                                    onChange={handleSelectChange}
                                    isMulti
                                    styles={{
   
   dropdownIndicator: (provided) => ({
     ...provided,
     color: '#ccc' // Change color of the arrow here
   })
 }}
                                />
                            </div>
                        </Form.Group>
                        <Form.Label>Start Date <span style={{color:"red"}} >*</span></Form.Label>
                        <Form.Control
                            style={{
                                height: 40,

                                borderColor: '#ccc',
                                borderWidth: 1,
                                paddingHorizontal: 8,
                                marginBottom: 16,
                                
                                borderRadius:10,
                            }}
                            type="date"
                            value={startDate.split('T')[0]}
                            onChange={(e) => setStartDate(new Date(e.target.value).toISOString())}
                            placeholder="Enter Start Date"
                        />
                        <Form.Label>End Date <span style={{color:"red"}} >*</span></Form.Label>
                        <Form.Control
                            style={{
                                height: 40,

                                borderColor: '#ccc',
                                borderWidth: 1,
                                paddingHorizontal: 8,
                                marginBottom: 16,
                                
                                borderRadius:10,
                            }}
                            type="date"
                            value={endDate.split('T')[0]}
                            onChange={(e) => setEndDate(new Date(e.target.value).toISOString())}
                            placeholder="Enter End Date"
                        />
                        <Form.Label>Project Cost <span style={{color:"red"}} >*</span></Form.Label>
                        <Form.Control
                            style={{
                                height: 40,

                                borderColor: '#ccc',
                                borderWidth: 1,
                                paddingHorizontal: 8,
                                marginBottom: 16,
                                
                                borderRadius:10,
                            }}
                            type="number"
                            value={cost}
                            onChange={(e) => setCost(e.target.value)}
                            placeholder="Enter Project Cost"
                            required
                        />
                        <Form.Label>Currency <span style={{color:"red"}} >*</span></Form.Label>
                        <Form.Group
                            className="mb-3 d-flex"
                            style={{ gap: "15px" }}
                            controlId="formBasicPassword"
                        >
                            <div className="selectdoctor">
                                <Select

                                    options={currencyoptions}
                                    value={selectedCurrency}
                                    onChange={handleCurrencyChange}
                                    placeholder="Select Currency"
                                    styles={{
   
   dropdownIndicator: (provided) => ({
     ...provided,
     color: '#ccc' // Change color of the arrow here
   })
 }}
                                />
                            </div>
                        </Form.Group>
                        <Form.Label>Description <span style={{color:"red"}} >*</span></Form.Label>
                        <Form.Control
                            style={{
                                height: 80,

                                borderColor: '#ccc',
                                borderWidth: 1,
                                paddingHorizontal: 8,
                                marginBottom: 16,
                                
                                borderRadius:10,
                            }}
                            as="textarea"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            placeholder="Enter Project Description"
                            required
                        />
                        <Form.Label>Status <span style={{color:"red"}} >*</span></Form.Label>
                        <Form.Group
                            className="mb-3 d-flex"
                            style={{ gap: "15px" }}
                            controlId="formBasicPassword"
                        >
                            <div className="selectdoctor">
                                <Select

                                    options={statusoptions}
                                    value={selectedStatus}
                                    onChange={handleStatusChange}
                                    placeholder="Select Status"
                                    styles={{
   
   dropdownIndicator: (provided) => ({
     ...provided,
     color: '#ccc' // Change color of the arrow here
   })
 }}
                                />
                            </div>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowedit(false)}>
                        Close
                    </Button>
                    <Button
                        variant="primary"
                        onClick={handleEditSave}
                    >
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>

        </Container>
    )
}

export default Project;