import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { GetPreviousMonthsDCR, GetPreviousMonthsDCRManager, GetPreviousMonthsDCRUser } from '../../utils/proxy/DashboardProxy';
import { TbReport } from "react-icons/tb";
import { Row } from 'react-bootstrap';

const SubmissionTrendChart = () => {

    const [yearmonth, setYearMonth] = useState([])
    const [doctor, setDoctor] = useState([]);
    const [hospital, setHospital] = useState([]);
    const [clinic, setClinic] = useState([]);
    const [stockist, setStockist] = useState([]);
    const [chemist, setChemist] = useState([]);

    useEffect(() => {
        const getRecord = async () => {
            let d;
            if (localStorage.getItem('role') == 'Admin') {

                d = await GetPreviousMonthsDCR();
            } else if (localStorage.getItem('role') == 'Manager') {
                d = await GetPreviousMonthsDCRManager(localStorage.getItem('uuid'));
            } else if (localStorage.getItem('role') == 'User') {
                d = await GetPreviousMonthsDCRUser(localStorage.getItem('uuid'));
            }
            let yearmonth = [];
            let doctor = [];
            let hospital = [];
            let clinic = [];
            let stockist = [];
            let chemist = [];
            d && d.map((data) => {
                yearmonth.push(data.yearmonth);
                doctor.push(data.doctor);
                hospital.push(data.hospital);
                clinic.push(data.clinic);
                stockist.push(data.stockist);
                chemist.push(data.chemist);
            })
            setYearMonth(yearmonth.reverse());
            setDoctor(doctor.reverse())
            setHospital(hospital.reverse())
            setClinic(clinic.reverse())
            setStockist(stockist.reverse())
            setChemist(chemist.reverse())
        }
        getRecord()
    }, [])

    const options = {
        chart: {
            id: 'line-chart'
        },
        xaxis: {
            categories: yearmonth
        },
        // title:{
        //     text : "DCR Submission Trend Summary",
        //     style:{
        //         fontSize:'13px',
        //     }
        // },
        legend: {
            show: true,
            position: 'bottom',
            markers: {
                width: 12,
                height: 12,
                radius: 2 // To make the legend marker square, set radius to 0
            }
        }
    };

    const series = [
        {
            name: 'Doctor',
            data: doctor,
            color: '#27b8ce'
        },
        {
            name: 'Hospital',
            data: hospital,
            color: '#f66387'
        },
        {
            name: 'Chemist',
            data: chemist,
            color: '#e3bb23'
        },
        {
            name: 'Clinic',
            data: clinic,
            color: '#b0a4f0'
        },
        {
            name: 'Stockist',
            data: stockist,
            color: '#6da169'
        },
    ];

    return (
        <>


            <div style={{ backgroundColor: 'white', height: 300, padding: 10 }}>
                <h6 style={{ textAlign: 'center', marginBottom: 0 }}>DCR Submission Trend Summary</h6>
                <Chart options={options} series={series} type="line" height={300} width='100%' />
            </div>

        </>
    );
};

export default SubmissionTrendChart;
