import React, { useEffect, useState } from "react";
import { Badge, Button, Container, InputGroup } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import DataTable from "react-data-table-component";
import Dropdown from "react-bootstrap/Dropdown";
import { AiFillDelete, AiTwotoneEdit } from "react-icons/ai";
import { TiTick } from "react-icons/ti";
import Select from "react-select";
import {
  AddUser,
  AddUserMappings,
  CreateUserDeduction,
  CreateUserHrDetails,
  DeleteUser,
  EditUser,
  EditUserDeduction,
  EditUserHrDetails,
  EditUserHrDetailsByUserid,
  GetAllUser,
} from "../../utils/proxy/Usermasterproxy";
import { GetAllDoctors } from "../../utils/proxy/Doctorproxy";
import { GetAllChemists } from "../../utils/proxy/Chemistproxy";
import { GetAllStockists } from "../../utils/proxy/Stockistproxy";
import { GetAllClinics } from "../../utils/proxy/Clinicproxy";
import { GetAllHospitalss } from "../../utils/proxy/Hospitalsproxy";
import {
  GetAllCountries,
  GetAllCitiesDetail,
  GetAllAreasDetail,
  GetAllStatesDetail,
} from "../../utils/proxy/Countryproxy";
import Modal from "react-bootstrap/Modal";
import Swal from "sweetalert2";
import { Parser } from "@json2csv/plainjs";
import { unwind, flatten } from "@json2csv/transforms";
import LoadingComponent from "../Includes/LoadingComponent";
import { GetAllUsersDesignation } from "../../utils/proxy/UserDesignationProxy";
import { HiDotsVertical } from "react-icons/hi";
import { IoHomeOutline } from "react-icons/io5";
import { GetAllRoleMaster } from "../../utils/proxy/RolemasterProxy";
import { GetAllDeduction } from "../../utils/proxy/DeductionMasterProxy";
import Helper from "../../utils/helperFunctions";
import { Op_per } from "../../utils/Permissions";
import { MdOutlineVpnKey } from "react-icons/md";
import { PasswordChangeByAdmin } from "../../utils/proxy/Authproxy";

function UserMaster() {
  const [user, setUser] = useState([]);
  const [userid, setUserId] = useState();
  const [reload, setReload] = useState(false);
  const [selectedUser, setSelectedUser] = useState();
  const [searchQuery, setSearchQuery] = useState("");
  const [show, setShow] = useState(false);
  const [editshow, setEditShow] = useState(false);
  const [username, setUsername] = useState(false);
  const [password, setPassword] = useState(false);
  const [citys, setCitys] = useState([]);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [country, setCountry] = useState();
  const [state, setState] = useState();
  const [city, setCity] = useState();
  const [areas, setAreas] = useState([]);
  const [designations, setDesignations] = useState([]);
  const [email, setEmail] = useState("");
  const [pincode, setPincode] = useState("");
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [selectedAreas, setSelectedAreas] = useState([]);
  const [selectedDesignation, setSelectedDesignation] = useState();
  const [areasid, setAreasid] = useState([]);
  const [designationid, setDesignationid] = useState();
  const [selectedDoctors, setSelectedDoctors] = useState([]);
  const [doctors, setDoctors] = useState([]);
  const [doctorIds, setDoctorIds] = useState([]);
  const [selectedChemists, setSelectedChemists] = useState([]);
  const [chemists, setChemists] = useState([]);
  const [chemistIds, setChemistIds] = useState([]);
  const [selectedStockists, setSelectedStockists] = useState([]);
  const [stockists, setStockists] = useState([]);
  const [stockistIds, setStockistIds] = useState([]);
  const [selectedClinics, setSelectedClinics] = useState([]);
  const [clinics, setClinics] = useState([]);
  const [clinicIds, setClinicIds] = useState([]);
  const [selectedHospitals, setSelectedHospitals] = useState([]);
  const [hospitals, setHospitals] = useState([]);
  const [hospitalIds, setHospitalIds] = useState([]);
  const [selectedWorkingType, setSelectedWorkingType] = useState();
  const [workingType, setWorkingType] = useState();
  const [disabled, setDisabled] = useState();
  const [headquator, setHeadquator] = useState('');
  const [salary, setSalary] = useState();
  const [allRoles, setAllRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState();
  const [selectedRoleId, setSelectedRoleId] = useState();
  const [allDeduction, setAllDeduction] = useState([]);
  const [selectedDeductions, setSelectedDeductions] = useState();
  const [deductionids, setDeductionIds] = useState([]);
  const [dateOfJoining, setDateOfJoining] = useState('');

  const [pending, setPending] = useState(true);

  const [showPasswordChange, setShowPasswordChange] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [name, setName] = useState('');
  const [showTerritory, setShowTerritory] = useState(null);

  useEffect(() => {
    // Fetch data from an API when the component mounts
    const getallteritories = async () => {
      let users = await GetAllUser();
      if (users) {
        setUser(users);
        setPending(false);
      } else {
        alert("Error", "Issue While Fetching Users");
      }
    };
    getallteritories();
    setReload(false);
  }, [reload, userid]);

  useEffect(() => {
    // Fetch data from an API when the component mounts
    const getallcitys = async () => {
      let citysdata = await GetAllCitiesDetail();
      if (citysdata) {
        setCitys(citysdata);
      } else {
        alert("Error", "Issue While Fetching City");
      }
    };
    getallcitys();

    const getallareas = async () => {
      let areas = await GetAllAreasDetail();
      if (areas) {
        setAreas(areas);
      } else {
        alert("Error", "Issue While Fetching Territories");
      }
    };
    getallareas();

    const getalldesignations = async () => {
      let areas = await GetAllUsersDesignation();
      if (areas) {
        setDesignations(areas);
      } else {
        alert("Error", "Issue While Fetching Designations");
      }
    };
    getalldesignations();

    const getallroles = async () => {
      let areas = await GetAllRoleMaster();
      if (areas) {
        setAllRoles(areas);
      } else {
        alert("Error", "Issue While Fetching Designations");
      }
    };
    getallroles();

    const getalldeductions = async () => {
      let areas = await GetAllDeduction();
      if (areas) {
        setAllDeduction(areas);
      } else {
        alert("Error", "Issue While Fetching Designations");
      }
    };
    getalldeductions();

    const getallcountries = async () => {
      let countriesdata = await GetAllCountries();
      if (countriesdata) {
        setCountries(countriesdata);
      } else {
        alert("Error", "Issue While Fetching Countries");
      }
    };
    getallcountries();

    const getallstates = async () => {
      let statesdata = await GetAllStatesDetail();
      if (statesdata) {
        setStates(statesdata);
      } else {
        alert("Error", "Issue While Fetching State");
      }
    };
    getallstates();

    // const getallDoctors = async () => {
    //   let doctorsdata = await GetAllDoctors();
    //   if (doctorsdata) {
    //     setDoctors(doctorsdata);
    //   } else {
    //     alert("Error", "Issue While Fetching Doctors");
    //   }
    // };
    // getallDoctors();

    // const getallChemists = async () => {
    //   let chemistsdata = await GetAllChemists();
    //   if (chemistsdata) {
    //     setChemists(chemistsdata);
    //   } else {
    //     alert("Error", "Issue While Fetching Chemists");
    //   }
    // };
    // getallChemists();
    // const getallStockists = async () => {
    //   let stockistsdata = await GetAllStockists();
    //   if (stockistsdata) {
    //     setStockists(stockistsdata);
    //   } else {
    //     alert("Error", "Issue While Fetching Stockists");
    //   }
    // };
    // getallStockists();
    // const getallClinics = async () => {
    //   let clinicsdata = await GetAllClinics();
    //   if (clinicsdata) {
    //     setClinics(clinicsdata);
    //   } else {
    //     alert("Error", "Issue While Fetching Clinics");
    //   }
    // };
    // getallClinics();
    // const getallHospitals = async () => {
    //   let hospitalsdata = await GetAllHospitalss();
    //   if (hospitalsdata) {
    //     setHospitals(hospitalsdata);
    //   } else {
    //     alert("Error", "Issue While Fetching Hospitals");
    //   }
    // };
    // getallHospitals();
  }, [show]);

  const filteredData = user.filter((row) =>
    row.username.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const areaOptions = [];

  areas.map((area) => {
    areaOptions.push({ value: area.id, label: area.areaname });
  });

  const designationOptions = [];

  designations.map((designation) => {
    designationOptions.push({ value: designation.id, label: designation.designation });
  });

  const workingTypeOptions = [];
  workingTypeOptions.push({ value: 'Internal', label: 'Internal' })
  workingTypeOptions.push({ value: 'External', label: 'External' })

  const doctorOptions = [];

  doctors.map((doctor) => {
    doctorOptions.push({ value: doctor.id, label: doctor.doctorname });
  });

  const chemistOptions = [];

  chemists.map((chemist) => {
    chemistOptions.push({ value: chemist.id, label: chemist.chemistname });
  });

  const stockistOptions = [];

  stockists.map((stockist) => {
    stockistOptions.push({ value: stockist.id, label: stockist.stockistname });
  });

  const clinicOptions = [];

  clinics.map((clinic) => {
    clinicOptions.push({ value: clinic.id, label: clinic.clinicname });
  });

  const hospitalOptions = [];

  hospitals.map((hospital) => {
    hospitalOptions.push({ value: hospital.id, label: hospital.hospitalsname });
  });

  const roleOptions = [];

  allRoles.map((role) => {
    roleOptions.push({ value: role.id, label: role.rolename });
  })

  const deductionOptions = [];

  allDeduction.map((role) => {
    deductionOptions.push({ value: role.id, label: role.name });
  })

  const handleClose = () => setShow(false);
  const handleEditClose = () => { setEditShow(false); setSelectedAreas(null); setAreasid(null) }

  const handleShow = () => {
    // setIsClinicName(false);
    setShow(true);
  };

  const handlePasswordModal = () =>{
    setShowPasswordChange(!showPasswordChange);
    setNewPassword('')
    setName('')
    setUserId()
  }

  const handlePasswordChange = (row) => {
    setShowPasswordChange(true);
    setName(row.username);
    setUserId(row.userid);
  }

  const handleNewPasswordChange = async() =>{
    if(newPassword == '' || newPassword == null){
      Swal.fire({
        title:"Warning",
        text:'Password Is Empty',
        icon:'warning'
      })
    }else{
      let d = await PasswordChangeByAdmin(userid,newPassword);
      if (d.status == 'success') {
        Swal.fire({
          title:'Success',
          text:`${d.message} for ${name}`,
          icon:'success'
        })
        setShowPasswordChange(false);
        setNewPassword('');
        setUserId();
        setName('');
      }
    }
  }

  const conditionalRowStyles = [
    {
      when: (row) => row.disabled,
      style: {
        backgroundColor: '#f39c12',
        // color: taskColor.newtext,
      },
    }
  ];

  const columns = [

    {
      name: "Users",
      selector: (row) =>
        <div style={{ whiteSpace: 'normal', backgroundColor: row.firstname === "" || row.firstname === null ? "#f36e6f" : "", borderRadius: "10px", border: row.firstname === "" || row.firstname === null ? "2px solid transparent" : "2px solid transparent", padding: "3px", display: "flex", flexDirection: "row", color: row.firstname === "" || row.firstname === null ? "#fff" : "#000", margin: "3px", }}>{row.firstname ? `${row.firstname} ${row.lastname}` : "N/A"}</div>,
      /* <div><p>{row.firstname} {row.lastname}</p></div>, */

      width: "10%",
    },
    {
      name: "HQ",
      selector: (row) =>
        <div style={{ whiteSpace: 'normal', backgroundColor: row.hq === "" || row.hq === null ? "#f36e6f" : "", borderRadius: "10px", border: row.hq === "" || row.hq === null ? "2px solid transparent" : "2px solid transparent", padding: "3px", display: "flex", flexDirection: "row", color: row.hq === "" || row.hq === null ? "#fff" : "#000", margin: "3px", }}>{row.hq ? row.hq : "N/A"}</div>,


      width: "8%",
    },
    {
      name: "State",
      selector: (row) =>
        <div style={{ whiteSpace: 'normal', backgroundColor: row.state === "" || row.state === null ? "#f36e6f" : "", borderRadius: "10px", border: row.state === "" || row.state === null ? "2px solid transparent" : "2px solid transparent", padding: "3px", display: "flex", flexDirection: "row", color: row.state === "" || row.state === null ? "#fff" : "#000", margin: "3px", }}>{row.state ? row.state : "N/A"}</div>,


      width: "8%",
    },
    {
      name: "Designation",
      selector: (row) =>
        <div style={{ whiteSpace: 'normal', backgroundColor: row.designation === "" || row.designation === null ? "#f36e6f" : "", borderRadius: "10px", border: row.designation === "" || row.designation === null ? "2px solid transparent" : "2px solid transparent", padding: "3px", display: "flex", flexDirection: "row", color: row.designation === "" || row.designation === null ? "#fff" : "#000", margin: "3px", }}>{row.designation ? row.designation : "N/A"}</div>,

      width: "8%",
    },
    {
      name: "Reporting To",

      selector: (row) =>
        <div style={{ whiteSpace: 'normal', backgroundColor: row.reportingto === "" || row.reportingto === null ? "#f36e6f" : "", borderRadius: "10px", border: row.reportingto === "" || row.reportingto === null ? "2px solid transparent" : "2px solid transparent", padding: "3px", display: "flex", flexDirection: "row", color: row.reportingto === "" || row.reportingto === null ? "#fff" : "#000", margin: "3px", }}>{row.reportingto ? row.reportingto : "N/A"}</div>,


      width: "10%",
    },

    {
      name: "Username",
      selector: (row) =>
        <div style={{ whiteSpace: 'normal', backgroundColor: row.username === "" || row.username === null ? "#f36e6f" : "", borderRadius: "10px", border: row.username === "" || row.username === null ? "2px solid transparent" : "2px solid transparent", padding: "3px", display: "flex", flexDirection: "row", color: row.username === "" || row.username === null ? "#fff" : "#000", margin: "3px", }}>{row.username ? row.username : "N/A"}</div>,


      width: "10%",
    },

    {
      name: "User Type",
      selector: (row) =>
        <div style={{ whiteSpace: 'normal', backgroundColor: row.usertype === "" || row.usertype === null ? "#f36e6f" : "", borderRadius: "10px", border: row.usertype === "" || row.usertype === null ? "2px solid transparent" : "2px solid transparent", padding: "3px", display: "flex", flexDirection: "row", color: row.usertype === "" || row.usertype === null ? "#fff" : "#000", margin: "3px", }}>{row.usertype ? row.usertype : "N/A"}</div>,


      width: "8%",
    },

    {
      name: "Territories",
      selector: (row,index) =>
        <div className={showTerritory === row.userid ?  'multi-line-ellipsis expanded' : 'multi-line-ellipsis'} onClick={() => setShowTerritory(row.userid)} style={{ whiteSpace: 'normal' }}>{row.areas.map((hobby) => (
          <Button style={{ padding: 1, fontSize: '11px', fontWeight: 'bold', margin: 3, padding: 2, backgroundColor: "", borderRadius: "10px", border: "2px solid transparent", color: '#000' }} key={hobby.id}>
            <Badge style={{ color: "#fff", borderRadius: "5px", backgroundColor: "#000", padding: "3px", }} >{hobby.id}</Badge> {hobby.area}
          </Button>
        ))}</div>,
      width: "27%",

    },
    // {
    //   name: "Actions",
    //   selector: (row) => (
    //     <div key={row.id}>
    //       <AiFillDelete
    //         onClick={() => handleDelete(row.userid)}
    //         style={{ color: "#f36e6f", fontSize: 20, display:Op_per.get().USERS_USER_MASTER.ALLOW_DELETE ? '' : 'none' }}
    //       />
    //       &nbsp;&nbsp;&nbsp;
    //       <AiTwotoneEdit
    //         style={{ color: "#9889e6", fontSize: 20, display:Op_per.get().USERS_USER_MASTER.ALLOW_EDIT ? '' : 'none' }}
    //         onClick={() => handleEditShow(row)}
    //       />
    //     </div>

    //     // <div key={row.id}>
    //     //   <Button size="sm" onClick={() => handleEditShow(row)}><AiTwotoneEdit
    //     //     style={{ color: "white", fontSize: 20 }}

    //     //   /></Button>
    //     //   &nbsp;&nbsp;&nbsp;
    //     //   <Button size="sm"  onClick={() => handleDelete(row.userid)}><AiFillDelete

    //     //     style={{ color: "white", fontSize: 20 }}
    //     //   /></Button>
    //     // </div>
    //   ),
    //   width: "10%"
    // },
  ];

  if (Op_per.get().USERS_USER_MASTER.ALLOW_DELETE || Op_per.get().USERS_USER_MASTER.ALLOW_EDIT) {
    columns.push({
      name: "Actions",
      selector: (row) => (
        <div key={row.id}>
          <AiFillDelete
            onClick={() => handleDelete(row.userid)}
            style={{ color: "#f36e6f", fontSize: 20, display: Op_per.get().USERS_USER_MASTER.ALLOW_DELETE ? '' : 'none' }}
          />
          &nbsp;&nbsp;&nbsp;
          <AiTwotoneEdit
            style={{ color: "#9889e6", fontSize: 20, display: Op_per.get().USERS_USER_MASTER.ALLOW_EDIT ? '' : 'none' }}
            onClick={() => handleEditShow(row)}
          />
          &nbsp;&nbsp;&nbsp;
          <MdOutlineVpnKey
            style={{ color: "#9889e6", fontSize: 20, display: Op_per.get().USERS_USER_MASTER.ALLOW_EDIT ? '' : 'none' }}
            onClick={() => handlePasswordChange(row)}
          />
        </div>
      ),
      width: "10%"
    })
  }
  const customStyles = { head: { style: { backgroundColor: 'blue', }, }, };
  const CustomHeader = ({ children }) => (<div style={{ backgroundColor: 'blue', padding: '10px', color: 'white' }}>    {children}  </div>);

  const handleEditShow = (row) => {
    setUsername(row.username)
    setEmail(row.email)
    setFirstname(row.firstname)
    setLastname(row.lastname)
    setAddress1(row.address1)
    setAddress2(row.address2)
    setCountry(row.countryid)
    setState(row.stateid)
    setCity(row.cityid)
    setPincode(row.pincode)
    setPhoneNumber(row.phone)
    setUserId(row.userid);
    setDateOfJoining(row.dateofjoining != '' ? Helper.dateToIsoStringWithoutChange(new Date(row.dateofjoining)) : null)
    setSelectedRole({ label: row.rolename, value: row.roleid })
    setSelectedRoleId(row.roleid)
    setSelectedDesignation({ label: row.designation, value: row.designationid })
    setDesignationid(row.designationid)
    setSalary(row.salary)
    setDisabled(row.disabled)
    let ar = [];
    let arid = [];
    row.areas.map((area) => {
      ar.push({ value: area.id, label: area.area });
      arid.push(area.id);
      return true;
    });
    let deduction = [];
    let deductionsid = [];
    row.deductions.map((area) => {
      deduction.push({ value: area.id, label: area.name });
      deductionsid.push(area.id);
      return true;
    });
    setHeadquator(row.hq);
    setSelectedAreas(ar);
    setAreasid(arid);
    setSelectedDeductions(deduction);
    setDeductionIds(deductionsid);

    setSelectedDesignation({ value: row.designationid, label: row.designation });
    setWorkingType(row.usertype)
    setSelectedWorkingType({ value: row.usertype, label: row.usertype })
    setEditShow(true)
  };

  const handleDelete = async (row) => {
    Swal.fire({
      title: "Do you want to delete the User?",

      showCancelButton: true,
      confirmButtonText: "Delete",
    }).then(async(result) =>{
      if (result.isConfirmed) {
        setPending(true);
    let res = await DeleteUser(row);
    if (res) {
      setReload(true);
      setShow(false);

      Swal.fire({
        title: "Success!",
        text: "User Deleted Successfully !!",
        icon: "success",
        confirmButtonText: "OK",
      });
    } else {
      setReload(true);
      setShow(false);

      Swal.fire({
        title: "Error!",
        text: "Issue while Deleting User!!",
        icon: "warning",
        confirmButtonText: "OK",
      });
    }
      }
    })
    
  };



  const handleAreaChange = (selected) => {
    const values = selected.map((option) => option.value);
    
    setSelectedAreas(selected);
    setAreasid(values);
  };

  const handleDesignationChange = (selected) => {
    const values = selected.value;
    
    setSelectedDesignation(selected);
    setDesignationid(values);
  };

  const handleWorkingTypeChange = (selected) => {
    setWorkingType(selected.value);
    setSelectedWorkingType(selected);
  }

  const handleDoctorChange = (selected) => {
    const values = selected.map((option) => option.value);
    
    setSelectedDoctors(selected);
    setDoctorIds(values);
  };

  const handleChemistChange = (selected) => {
    const values = selected.map((option) => option.value);
    
    setSelectedChemists(selected);
    setChemistIds(values);
  };

  const handleStockistChange = (selected) => {
    const values = selected.map((option) => option.value);
    
    setSelectedStockists(selected);
    setStockistIds(values);
  };
  const handleClinicChange = (selected) => {
    const values = selected.map((option) => option.value);
    
    setSelectedClinics(selected);
    setClinicIds(values);
  };
  const handleHospitalChange = (selected) => {
    const values = selected.map((option) => option.value);
    
    setSelectedHospitals(selected);
    setHospitalIds(values);
  };
  const handleRoleChange = (selected) => {
    setSelectedRole(selected);
    setSelectedRoleId(selected.value);
  };
  const handleDeductionChange = (selected) => {
    const values = selected.map((option) => option.value);
    setSelectedDeductions(selected);
    setDeductionIds(values);
  };

  // const handleEditShow = (row) => {
  //   setClinicName(row.clinicname);
  //   let ar = [];
  //   let arid = [];
  //   row.areas.map((area) => {
  //     ar.push({ value: area.id, label: area.areaname });
  //     arid.push(area.id);
  //     return true;
  //   });
  //   setSelectedAreas(ar);
  //   setAreasid(arid);
  //   setClinicAddress(row.address);
  //   setState(row.stateid);
  //   setCity(row.cityid);
  //   setPincode(row.pincode);
  //   setCountry(row.countryid);
  //   setNumber(row.phone);
  //   setEmail(row.email);
  //   setClinicId(row.id);
  //   setShowEdit(true);
  // };
  // const handleEditClose = () => {
  //   setClinicName("");
  //   setSelectedAreas([]);
  //   setAreasid([]);
  //   setClinicAddress("");
  //   setState("");
  //   setCity("");
  //   setPincode("");
  //   setCountry("");
  //   setNumber("");
  //   setEmail("");
  //   setShowEdit(false);
  // };

  let prepareData = () => {
    return {
      creatorId: localStorage.getItem('adminid'),
      email: email,
      username: username,
      password: password,
      firstName: firstname,
      lastName: lastname,
      company: "N/A",
      address_1: address1,
      address_2: address2,
      countryid: country,
      state: state,
      city: city,
      zip: pincode,
      phone: phoneNumber,
      url: "N/A",
      designationid: designationid,
      roleid:selectedRoleId,
      headquator: headquator
    };
  };

  let prepareEditData = () => {
    return {
      uid: userid,
      email: email,
      username: username,
      password: password,
      firstName: firstname,
      lastName: lastname,
      address_1: address1,
      address_2: address2,
      countryid: country,
      state: state,
      city: city,
      zip: pincode,
      phone: phoneNumber,
      designationid: designationid,
      roleid:selectedRoleId,
      headquator: headquator,
      disabled: disabled
    };
  };

  const handleSave = async () => {
    let response = await AddUser(prepareData());
    if(response.status === 'error'){
      Swal.fire({
        title: "Error!",
        text: response.message,
        icon: "error",
        confirmButtonText: "OK",
      });
    }
    else {
      let data = {
        userid: response,
        doctors: doctorIds,
        areas: areasid,
        workingtype: workingType
      };
      let mappingHrDetails = await CreateUserHrDetails(salary, dateOfJoining, response)

      let d = [];
      deductionids.map((e) => {
        d.push({ userid: response, deductionid: e });
      })

      let mappingHrDeduction = await CreateUserDeduction(d);
      let mappingResponse = await AddUserMappings(data);
      if (mappingHrDeduction && mappingHrDetails) {
        setReload(true);
        setShow(false);

        Swal.fire({
          title: "Success!",
          text: "User Added Successfully !!",
          icon: "success",
          confirmButtonText: "OK",
        });
        setDeductionIds(null);
        setSelectedDeductions(null);
        setSelectedRole(null);
        setSelectedRoleId(null);
        setSelectedDesignation(null);
        setDesignationid(null);
        setSelectedAreas(null);
        setAreasid(null);
      } else {
        alert("Error", "Issue While Fetching Data");
      }
    }
  };

  const handleEditSave = async () => {

    setPending(true);
    setEditShow(false);
    let response = await EditUser(prepareEditData());
    if (response) {
      let data = {
        userid: response,
        areas: areasid,
        workingtype: workingType

      };
      let mappingHrDetails = await EditUserHrDetailsByUserid(salary, dateOfJoining, response)

      let d = [];
      deductionids.map((e) => {
        d.push({ userid: response, deductionid: e });
      })

      let mappingHrDeduction = await EditUserDeduction(response, d);
      let mappingResponse = await AddUserMappings(data);
      if (mappingResponse && mappingHrDetails && mappingHrDeduction) {
        setReload(true);


        Swal.fire({
          title: "Success!",
          text: "User Updated Successfully !!",
          icon: "success",
          confirmButtonText: "OK",
        });
        setDeductionIds(null);
        setSelectedDeductions(null);
        setSelectedRole(null);
        setSelectedRoleId(null);
        setSelectedDesignation(null);
        setDesignationid(null);
        setSelectedAreas(null);
        setAreasid(null);
      } else {
        alert("Error", "Issue While Fetching Data");
      }
    }
  };

  async function downloadCSV(user) {

    let csv;
    try {
      const opts = {
        fields: [
          "userid",
          "firstname",
          "lastname",
          "hq",
          "state",
          "designation",
          "reportingto",
          "username",
        ],
        transforms: [
          // unwind({
          //   paths: [
          //     "areas",
          //     "chemists",
          //     "hospitals",
          //     "clincs",
          //     "doctors",
          //     "stockists",
          //   ],
          // }),
          // flatten({ object: false, array: true, separator: "_" }),
        ],
      };
      const parser = new Parser(opts);
      csv = parser.parse(user);
    } catch (err) {
      console.error(err);
    }

    const link = document.createElement("a");

    if (csv == null) return;

    const filename = "users.csv";

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute("href", encodeURI(csv));
    link.setAttribute("download", filename);
    link.click();
  }

  const Export = ({ onExport }) => (
    <Button onClick={(e) => onExport(e.target.value)}>Export All</Button>
  );

  const actionsMemo = React.useMemo(
    () => <Export onExport={() => downloadCSV(user)} />,
    [user]
  );

  return (
    <Container fluid style={{ minHeight: "80vh" }}>
      <div
        className="glass-body"
        style={{
          margin: 10,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          padding: 0,
          backgroundColor: 'transparent'
        }}
      >

        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <ul id="breadcrumb">
            <li><a href="/"><IoHomeOutline
              style={{ color: "white", fontSize: 20 }}
              
            /></a></li>
            <li><a href="/user-master"><span class="icon icon-beaker"> </span>Users</a></li>
            <li><a href="/user-master"><span class="icon icon-beaker"> </span> User Masters</a></li>
            {/* <li><a href="#"><span class="icon icon-double-angle-right"></span>Doctor</a></li> */}

          </ul>

        </div>
      </div>

      <div className="glass-body" style={{ margin: 10 }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
          className="flex-column-mobile"
        >
          <div>
            <Form.Control
              style={{
                height: 40,
                width: 250,
                borderColor: '#ccc',
                borderWidth: 1,
                paddingHorizontal: 8,
                marginBottom: 16,
                borderRadius:10,
              }}
              className="glass"
              placeholder="Search"
              type="text"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
          <div>
            <div class="dropdown">
              <button
                class="btn btn-secondary"
                type="button"
                style={{ backgroundColor: "#2194f0", display: Op_per.get().USERS_USER_MASTER.ALLOW_ADD ? '' : 'none' }}
                title="Options"
                id="dropdown-basic"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <HiDotsVertical />
              </button>
              <ul class="dropdown-menu">
                <li onClick={setShow} style={{ display: Op_per.get().USERS_USER_MASTER.ALLOW_ADD ? '' : 'none' }}>
                  <a class="dropdown-item">Add</a>
                </li>

              </ul>
            </div>


            {/* <Export onExport={() => downloadCSV(user)} /> */}
          </div>
        </div>
        <div className="pt-3 " >
          <DataTable
            columns={columns}
            data={filteredData}
            conditionalRowStyles={conditionalRowStyles}
            progressPending={pending}
            progressComponent={<LoadingComponent />}
            pagination
            customStyles={{ headRow: { style: { backgroundColor: '#c0dfff', padding: 7 }, } }}
            CustomHeader={<CustomHeader />}
            // actions={actionsMemo}
            style={{ marginTop: 10, padding: 20 }}
            dense
          />
        </div>
      </div>
      <Modal show={show} backdrop="static" size="md" onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Username <span style={{ color: "red" }} >*</span></Form.Label>
              <Form.Control
                style={{
                  height: 40,

                  borderColor: '#ccc',
                  borderWidth: 1,
                  paddingHorizontal: 8,
                  marginBottom: 16,
                  borderRadius:10,
                }}
                // style={{
                //   backgroundColor: isclinicname ? "#f36e6f" : "",
                //   color: isclinicname ? "#fff" : "",
                // }}
                type="text"
                onChange={(e) => setUsername(e.target.value)}
                placeholder="Enter Username"
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Password <span style={{ color: "red" }} >*</span></Form.Label>
              <Form.Control
                style={{
                  height: 40,

                  borderColor: '#ccc',
                  borderWidth: 1,
                  paddingHorizontal: 8,
                  marginBottom: 16,
                  borderRadius:10,
                }}
                type="password"
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Enter Password"
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Email <span style={{ color: "red" }} >*</span></Form.Label>
              <Form.Control
                style={{
                  height: 40,

                  borderColor: '#ccc',
                  borderWidth: 1,
                  paddingHorizontal: 8,
                  marginBottom: 16,
                  borderRadius:10,
                }}
                type="text"
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter Email"
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>First Name <span style={{ color: "red" }} >*</span></Form.Label>
              <Form.Control
                style={{
                  height: 40,

                  borderColor: '#ccc',
                  borderWidth: 1,
                  paddingHorizontal: 8,
                  marginBottom: 16,
                  borderRadius:10,
                }}
                type="text"
                onChange={(e) => setFirstname(e.target.value)}
                placeholder="Enter First Name"

                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Last Name <span style={{ color: "red" }} >*</span></Form.Label>
              <Form.Control
                style={{
                  height: 40,

                  borderColor: '#ccc',
                  borderWidth: 1,
                  paddingHorizontal: 8,
                  marginBottom: 16,
                  borderRadius:10,
                }}
                type="text"
                onChange={(e) => setLastname(e.target.value)}
                placeholder="Enter Last Name"

                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Designation</Form.Label>
              <Select
                className="selectdoctor"
                closeMenuOnSelect={true}
                options={designationOptions}
                value={selectedDesignation}
                onChange={handleDesignationChange}
                styles={{

                  dropdownIndicator: (provided) => ({
                    ...provided,
                    color: '#ccc' // Change color of the arrow here
                  })
                }}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Role</Form.Label>
              <Select
                className="selectdoctor"
                closeMenuOnSelect={true}
                options={roleOptions}
                value={selectedRole}
                onChange={handleRoleChange}
                styles={{

                  dropdownIndicator: (provided) => ({
                    ...provided,
                    color: '#ccc' // Change color of the arrow here
                  })
                }}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Salary <span style={{ color: "red" }} >*</span></Form.Label>
              <Form.Control
                style={{
                  height: 40,

                  borderColor: '#ccc',
                  borderWidth: 1,
                  paddingHorizontal: 8,
                  marginBottom: 16,
                  borderRadius:10,
                }}
                type="number"
                onChange={(e) => setSalary(e.target.value)}
                placeholder="Enter Salary"
                required
              />
            </Form.Group>
            <Form.Label>Date of Joining <span style={{ color: "red" }} >*</span></Form.Label>
            <Form.Control
              style={{
                height: 40,

                borderColor: '#ccc',
                borderWidth: 1,
                paddingHorizontal: 8,
                marginBottom: 16,
                borderRadius:10,
              }}
              type='date'
              onChange={(e) => { setDateOfJoining(new Date(e.target.value).toISOString()) }}
            />
            <Form.Group className="mb-3">
              <Form.Label>Working Type <span style={{ color: "red" }} >*</span></Form.Label>
              <Select
                className="selectdoctor"
                options={workingTypeOptions}
                value={selectedWorkingType}
                onChange={handleWorkingTypeChange}
                styles={{

                  dropdownIndicator: (provided) => ({
                    ...provided,
                    color: '#ccc' // Change color of the arrow here
                  })
                }}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Address 1</Form.Label>
              <Form.Control
                style={{
                  height: 40,
                  borderColor: '#ccc',
                  borderWidth: 1,
                  paddingHorizontal: 8,
                  marginBottom: 16,
                  borderRadius:10,
                }}
                type="text"
                onChange={(e) => setAddress1(e.target.value)}
                placeholder="Enter Address 1"

                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Address 2</Form.Label>
              <Form.Control
                style={{
                  height: 40,

                  borderColor: '#ccc',
                  borderWidth: 1,
                  paddingHorizontal: 8,
                  marginBottom: 16,
                  borderRadius:10,
                }}
                type="text"
                onChange={(e) => setAddress2(e.target.value)}
                placeholder="Enter Address 2"

                required
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Country <span style={{ color: "red" }} >*</span></Form.Label>
              <Form.Select
                className="selectdoctor"
                onChange={(e) => setCountry(e.target.value)}
                required
                style={{
                  backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='%232457C5'%3E%3Cpath d='M14.293 7.293a1 1 0 0 0-1.414 1.414L10 11.414l-2.879-2.88a1 1 0 1 0-1.414 1.414l3.5 3.5a1 1 0 0 0 1.414 0l3.5-3.5a1 1 0 0 0 0-1.414z'/%3E%3C/svg%3E")`, // Arrow SVG with blue color (fill='%232457C5')
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'right .75rem center',
                  backgroundSize: '25px 25px' // Adjust as needed
                }}
              >
                <option>---Select Country---</option>
                {countries.map((city) => (
                  <option key={city.id} value={city.id}>
                    {city.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>State <span style={{ color: "red" }} >*</span></Form.Label>
              <Form.Select className="selectdoctor" onChange={(e) => setState(e.target.value)} required
                style={{
                  backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='%232457C5'%3E%3Cpath d='M14.293 7.293a1 1 0 0 0-1.414 1.414L10 11.414l-2.879-2.88a1 1 0 1 0-1.414 1.414l3.5 3.5a1 1 0 0 0 1.414 0l3.5-3.5a1 1 0 0 0 0-1.414z'/%3E%3C/svg%3E")`, // Arrow SVG with blue color (fill='%232457C5')
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'right .75rem center',
                  backgroundSize: '25px 25px' // Adjust as needed
                }}>
                <option>---Select State---</option>
                {states.map((city) => (
                  <option key={city.id} value={city.id}>
                    {city.statename}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>City <span style={{ color: "red" }} >*</span></Form.Label>
              <Form.Select className="selectdoctor" onChange={(e) => setCity(e.target.value)} required
                style={{
                  backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='%232457C5'%3E%3Cpath d='M14.293 7.293a1 1 0 0 0-1.414 1.414L10 11.414l-2.879-2.88a1 1 0 1 0-1.414 1.414l3.5 3.5a1 1 0 0 0 1.414 0l3.5-3.5a1 1 0 0 0 0-1.414z'/%3E%3C/svg%3E")`, // Arrow SVG with blue color (fill='%232457C5')
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'right .75rem center',
                  backgroundSize: '25px 25px' // Adjust as needed
                }}>
                <option>---Select City---</option>
                {citys.map((city) => (
                  <option key={city.id} value={city.id}>
                    {city.cityname}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Pincode <span style={{ color: "red" }} >*</span></Form.Label>
              <Form.Control
                style={{
                  height: 40,

                  borderColor: '#ccc',
                  borderWidth: 1,
                  paddingHorizontal: 8,
                  marginBottom: 16,
                  borderRadius:10,
                }}
                type="text"
                onChange={(e) => setPincode(e.target.value)}
                placeholder="Enter Pincode"
                required
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Phone <span style={{ color: "red" }} >*</span></Form.Label>
              <Form.Control
                style={{
                  height: 40,

                  borderColor: '#ccc',
                  borderWidth: 1,
                  paddingHorizontal: 8,
                  marginBottom: 16,
                  borderRadius:10,
                }}
                type="number"
                onChange={(e) => setPhoneNumber(e.target.value)}
                placeholder="Enter Phone"
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Headquarter</Form.Label>
              <Form.Control
                style={{
                  height: 40,

                  borderColor: '#ccc',
                  borderWidth: 1,
                  paddingHorizontal: 8,
                  marginBottom: 16,
                  borderRadius:10,
                }}
                type="text"
                className="selectdoctor"
                onChange={(e) => setHeadquator(e.target.value)}
                placeholder="Enter Headquarter"
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Areas</Form.Label>
              <Select
                className="selectdoctor"
                closeMenuOnSelect={false}
                options={areaOptions}
                value={selectedAreas}
                onChange={handleAreaChange}
                isMulti
                styles={{

                  dropdownIndicator: (provided) => ({
                    ...provided,
                    color: '#ccc' // Change color of the arrow here
                  })
                }}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Deductions</Form.Label>
              <Select
                className="selectdoctor"
                closeMenuOnSelect={false}
                options={deductionOptions}
                value={selectedDeductions}
                onChange={handleDeductionChange}
                isMulti
                styles={{

                  dropdownIndicator: (provided) => ({
                    ...provided,
                    color: '#ccc' // Change color of the arrow here
                  })
                }}
              />
            </Form.Group>

          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={handleSave}
          // disabled={isclinicname ? true : false}
          >
            Add User
          </Button>
        </Modal.Footer>
      </Modal>


      <Modal show={editshow} backdrop="static" size="md" onHide={handleEditClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Username <span style={{ color: "red" }} >*</span></Form.Label>
              <Form.Control
                style={{
                  height: 40,

                  borderColor: '#ccc',
                  borderWidth: 1,
                  paddingHorizontal: 8,
                  marginBottom: 16,
                  borderRadius:10,
                }}
                type="text"
                onChange={(e) => setUsername(e.target.value)}
                placeholder="Enter Username"
                value={username}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Email <span style={{ color: "red" }} >*</span></Form.Label>
              <Form.Control
                style={{
                  height: 40,

                  borderColor: '#ccc',
                  borderWidth: 1,
                  paddingHorizontal: 8,
                  marginBottom: 16,
                  borderRadius:10,
                }}
                type="text"
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter Email"
                value={email}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>First Name <span style={{ color: "red" }} >*</span></Form.Label>
              <Form.Control
                style={{
                  height: 40,

                  borderColor: '#ccc',
                  borderWidth: 1,
                  paddingHorizontal: 8,
                  marginBottom: 16,
                  borderRadius:10,
                }}
                type="text"
                onChange={(e) => setFirstname(e.target.value)}
                placeholder="Enter First Name"
                value={firstname}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Last Name <span style={{ color: "red" }} >*</span></Form.Label>
              <Form.Control
                style={{
                  height: 40,

                  borderColor: '#ccc',
                  borderWidth: 1,
                  paddingHorizontal: 8,
                  marginBottom: 16,
                  borderRadius:10,
                }}
                type="text"
                onChange={(e) => setLastname(e.target.value)}
                placeholder="Enter Last Name"
                value={lastname}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Designation </Form.Label>
              <Select
                className="selectdoctor"
                closeMenuOnSelect={true}
                options={designationOptions}
                value={selectedDesignation}
                onChange={handleDesignationChange}
                styles={{

                  dropdownIndicator: (provided) => ({
                    ...provided,
                    color: '#ccc' // Change color of the arrow here
                  })
                }}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Role <span style={{ color: "red" }} >*</span></Form.Label>
              <Select

                className="selectdoctor"
                closeMenuOnSelect={true}
                options={roleOptions}
                value={selectedRole}
                onChange={handleRoleChange}
                styles={{

                  dropdownIndicator: (provided) => ({
                    ...provided,
                    color: '#000', marginBottom: 10 // Change color of the arrow here
                  })
                }}
              />
              <Form.Group className="mb-3">
                <Form.Label style={{ marginTop: 15 }} >Salary <span style={{ color: "red" }} >*</span></Form.Label>
                <Form.Control
                  style={{
                    height: 40,

                    borderColor: '#ccc',
                    borderWidth: 1,
                    paddingHorizontal: 8,
                    marginBottom: 16,
                    
                    borderRadius:10,
                  }}
                  type="number"
                  value={salary}
                  onChange={(e) => setSalary(e.target.value)}
                  placeholder="Enter Salary"
                  required
                />
              </Form.Group>
              <Form.Label>Date of Joining <span style={{ color: "red" }} >*</span></Form.Label>
              <Form.Control
                style={{
                  height: 40,
                  borderColor: '#ccc',
                  borderWidth: 1,
                  paddingHorizontal: 8,
                  marginBottom: 16,
                  borderRadius:10,
                }}
                type='date'
                value={dateOfJoining !== null ? dateOfJoining.split('T')[0] : null}
                onChange={(e) => { setDateOfJoining(new Date(e.target.value).toISOString()) }}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Working Type <span style={{ color: "red" }} >*</span></Form.Label>
              <Select
                className="selectdoctor"
                options={workingTypeOptions}
                value={selectedWorkingType}
                onChange={handleWorkingTypeChange}
                styles={{

                  dropdownIndicator: (provided) => ({
                    ...provided,
                    color: '#ccc' // Change color of the arrow here
                  })
                }}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Address 1</Form.Label>
              <Form.Control
                style={{
                  height: 40,

                  borderColor: '#ccc',
                  borderWidth: 1,
                  paddingHorizontal: 8,
                  marginBottom: 16,
                  borderRadius:10,
                }}
                type="text"
                onChange={(e) => setAddress1(e.target.value)}
                placeholder="Enter Address 1"
                value={address1}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Address 2</Form.Label>
              <Form.Control
                style={{
                  height: 40,

                  borderColor: '#ccc',
                  borderWidth: 1,
                  paddingHorizontal: 8,
                  marginBottom: 16,
                  borderRadius:10,
                }}
                type="text"
                onChange={(e) => setAddress2(e.target.value)}
                placeholder="Enter Address 2"
                value={address2}
                required
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Country <span style={{ color: "red" }} >*</span></Form.Label>
              <Form.Select
                className="selectdoctor"
                onChange={(e) => setCountry(e.target.value)}
                required value={country}
                style={{
                  backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='%232457C5'%3E%3Cpath d='M14.293 7.293a1 1 0 0 0-1.414 1.414L10 11.414l-2.879-2.88a1 1 0 1 0-1.414 1.414l3.5 3.5a1 1 0 0 0 1.414 0l3.5-3.5a1 1 0 0 0 0-1.414z'/%3E%3C/svg%3E")`, // Arrow SVG with blue color (fill='%232457C5')
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'right .75rem center',
                  backgroundSize: '25px 25px' // Adjust as needed
                }}
              >
                <option>---Select Country---</option>
                {countries.map((city) => (
                  <option key={city.id} value={city.id}>
                    {city.name}


                  </option>

                ))}
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>State <span style={{ color: "red" }} >*</span></Form.Label>
              <Form.Select className="selectdoctor" onChange={(e) => setState(e.target.value)} value={state} required
                style={{
                  backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='%232457C5'%3E%3Cpath d='M14.293 7.293a1 1 0 0 0-1.414 1.414L10 11.414l-2.879-2.88a1 1 0 1 0-1.414 1.414l3.5 3.5a1 1 0 0 0 1.414 0l3.5-3.5a1 1 0 0 0 0-1.414z'/%3E%3C/svg%3E")`, // Arrow SVG with blue color (fill='%232457C5')
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'right .75rem center',
                  backgroundSize: '25px 25px' // Adjust as needed
                }}>
                <option>---Select State---</option>
                {states.map((city) => (
                  <option key={city.id} value={city.id}>
                    {city.statename}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>City <span style={{ color: "red" }} >*</span></Form.Label>
              <Form.Select className="selectdoctor" onChange={(e) => setCity(e.target.value)} value={city} required
                style={{
                  backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='%232457C5'%3E%3Cpath d='M14.293 7.293a1 1 0 0 0-1.414 1.414L10 11.414l-2.879-2.88a1 1 0 1 0-1.414 1.414l3.5 3.5a1 1 0 0 0 1.414 0l3.5-3.5a1 1 0 0 0 0-1.414z'/%3E%3C/svg%3E")`, // Arrow SVG with blue color (fill='%232457C5')
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'right .75rem center',
                  backgroundSize: '25px 25px' // Adjust as needed
                }}>
                <option>---Select City---</option>
                {citys.map((city) => (
                  <option key={city.id} value={city.id}>
                    {city.cityname}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Pincode <span style={{ color: "red" }} >*</span></Form.Label>
              <Form.Control
                style={{
                  height: 40,

                  borderColor: '#ccc',
                  borderWidth: 1,
                  paddingHorizontal: 8,
                  marginBottom: 16,
                  borderRadius:10,
                }}
                type="text"
                onChange={(e) => setPincode(e.target.value)}
                placeholder="Enter Pincode"
                value={pincode}
                required
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Phone <span style={{ color: "red" }} >*</span></Form.Label>
              <Form.Control
                style={{
                  height: 40,

                  borderColor: '#ccc',
                  borderWidth: 1,
                  paddingHorizontal: 8,
                  marginBottom: 16,
                  borderRadius:10,
                }}
                type="number"
                onChange={(e) => setPhoneNumber(e.target.value)}
                placeholder="Enter Phone"
                value={phoneNumber}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Headquarter</Form.Label>
              <Form.Control
                style={{
                  height: 40,

                  borderColor: '#ccc',
                  borderWidth: 1,
                  paddingHorizontal: 8,
                  marginBottom: 16,
                  borderRadius:10,
                }}
                type="text"
                className="selectdoctor"
                onChange={(e) => setHeadquator(e.target.value)}
                placeholder="Enter Headquarter"
                value={headquator}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Areas</Form.Label>
              <Select
                className="selectdoctor"
                closeMenuOnSelect={false}
                options={areaOptions}
                value={selectedAreas}
                onChange={handleAreaChange}
                isMulti
                styles={{

                  dropdownIndicator: (provided) => ({
                    ...provided,
                    color: '#ccc' // Change color of the arrow here
                  })
                }}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Deductions</Form.Label>
              <Select
                className="selectdoctor"
                closeMenuOnSelect={false}
                options={deductionOptions}
                value={selectedDeductions}
                onChange={handleDeductionChange}
                isMulti
                styles={{

                  dropdownIndicator: (provided) => ({
                    ...provided,
                    color: '#ccc' // Change color of the arrow here
                  })
                }}
              />
            </Form.Group>
            <Form.Check // prettier-ignore
              type="switch"
              id="custom-switch"
              label="Disabled"
              defaultChecked={disabled}
              onChange={(e) => { setDisabled(!disabled) }}
            />
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleEditClose}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={handleEditSave}
          // disabled={isclinicname ? true : false}
          >
            Edit User
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showPasswordChange} backdrop="static" onHide={handlePasswordModal}>
        <Modal.Header closeButton>
          <Modal.Title>Password Change For {name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
              <Form.Group>
                <Form.Label>New Password</Form.Label>
                <InputGroup>
                <Form.Control
                  className="selectdoctor"
                  type="password"
                  placeholder="Enter New Password Here"
                  onChange={e => setNewPassword(e.target.value)}
                  value={newPassword}
                />
                </InputGroup>
              </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handlePasswordModal}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={handleNewPasswordChange}
          // disabled={isclinicname ? true : false}
          >
            Set
          </Button>
        </Modal.Footer>
      </Modal>

    </Container>
  );
}

export default UserMaster;
