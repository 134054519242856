import { Button, Container, Form, OverlayTrigger, Table, Tooltip, InputGroup, Modal } from "react-bootstrap"
import { IoHomeOutline } from "react-icons/io5"
import { Op_per } from "../../../utils/Permissions"
import { FaEye, FaPlus, FaRegFilePdf } from "react-icons/fa"
import DataTable from "react-data-table-component"
import { useEffect } from "react"
import { useState } from "react"
import jsPDF from "jspdf"
import { AiFillDelete } from "react-icons/ai"
import PdfGenerator from "../../Common/PdfGenerator"
import ReactDomServer from 'react-dom/server'
import Swal from "sweetalert2"
import { DeleteInvoice, GetAllInvoice, GetInvoiceByOwnerid } from "../../../utils/proxy/InvoiceProxy"
import { GetAllTaxConfiguration } from "../../../utils/proxy/TaxProxy"
import { useNavigate } from "react-router-dom"
import Helper from "../../../utils/helperFunctions"
import { useCallback } from "react"


export const InvoiceMaster = () => {

    const navigation = useNavigate();

    const [pdfPreviewUrl, setPdfPreviewUrl] = useState(null);
    const [isPreviewOpen, setIsPreviewOpen] = useState(false);
    const [blobUrl, setBlobUrl] = useState();

    function redirect() {
        navigation("");
    }

    const [data, setData] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [borderColor, setBorderColor] = useState(false);
    const [reload, setReload] = useState(false);

    const [companyDetail, setCompanyDetail] = useState({})

    let fullName =  searchQuery.split(" ");
    
    
    const filteredData = data.filter((k) =>
        k.company.toLowerCase().includes(searchQuery.toLowerCase()) || k.invoiceno.toLowerCase().includes(searchQuery.toLowerCase()) ||
        (fullName[0] && k.firstname.toLowerCase().includes(fullName[0].toLowerCase())) ||
        (fullName[1] && k.lastname.toLowerCase().includes(fullName[1].toLowerCase()))
    );

    useEffect(() => {
        const getallinvoices = async () => {
            let d;
            
            if(localStorage.getItem('role') == 'Admin' || localStorage.getItem('role') == 'Manager'){
                d = await GetAllInvoice();
                
            }else{
                d = await GetInvoiceByOwnerid(localStorage.getItem('uuid'));
                console.log(d);
            }
            if (d) {
                setData(d);
            } else {
                alert('Error', 'Issues while fetching data');
            }
            
        }
        getallinvoices();
        setReload(false);
    }, [reload])

    useEffect(()=>{
        const getCompanyDetail = async () =>{
            let d = await GetAllTaxConfiguration(); 

            if(d){
                setCompanyDetail(d[0]);
            }
        }

        getCompanyDetail();
    },[])
    
    const handleSearchQuery = (e) => {
        // let rex = /^([a-zA-Z0-9]-?)+$/;
        // let n = rex.test(e);
        // console.log(n);
        // if(e != ""){
        //     setBorderColor(!n);
        // } else if(n == ""){
        //     setBorderColor(n);
        // }
        setSearchQuery(e);
    }

    const generatePDF = (products) => {
          const pdf = new jsPDF({
            orientation: 'portrait', // or 'landscape'
            unit: 'px', // units: 'mm', 'in', 'px'
            format: 'a4' // or a custom size [width, height]
          });
          
          pdf.html(ReactDomServer.renderToStaticMarkup(<PdfGenerator data={products} company={companyDetail} type={'Invoice'} />), {
            callback: function (pdf) {
              pdf.save(`Invoice_${new Date().toISOString().split('T')[0]}.pdf`);
            },
            margin: [10, 10, 10, 10],
            autoPaging: 'text',
            // x: 0,
            // y: 0,
            width: 450, //target width in the PDF document
            windowWidth: 1024
          });
      };

    function calculateDiscount(originalPrice, discountPercentage, operation) {

        const discountAmount = (originalPrice * discountPercentage) / 100;
        let finalPrice = 0;

        if (operation == 'add') {
            finalPrice = originalPrice + discountAmount;
        } else {
            finalPrice = originalPrice - discountAmount;
        }
        return finalPrice;
    }

    const totalCalculate = useCallback((data) =>{
        const total = data.products.reduce((sum, item) => {
            if (item.taxed) {
                return sum + parseFloat(item.taxprice || 0);
            }

            return sum + parseFloat(item.total || 0);
        }, 0);

        let temp = calculateDiscount(total,((data.tcsid || data.tdsid) ? data.tdsid ? data.tdsrate : data.tcsrate : 0 ),'minus');

        return temp.toFixed(2);
    },[data])

    const generateAndPreviewPDF = (products) => {
        const pdf = new jsPDF({
          orientation: 'portrait',
          unit: 'px',
          format: 'a4'
        });
    
        pdf.html(
          ReactDomServer.renderToStaticMarkup(
            <PdfGenerator 
                data={products} 
                company={companyDetail} 
                type={'Invoice'}
            />
          ),
          {
            callback: function(pdf) {
              // Create data URL from PDF
              const pdfDataUrl = pdf.output('blob');
              setPdfPreviewUrl(pdfDataUrl);
              setBlobUrl(URL.createObjectURL(pdfDataUrl));
              setIsPreviewOpen(true);
            },
            margin: [10, 10, 10, 10],
            autoPaging: 'text',
            width: 450,
            windowWidth: 1024
          }
        );
      };

    const handletaskdelete = async (id) => {
        Swal.fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!"
        }).then(async (result) => {
          if (result.isConfirmed) {
            let d = await DeleteInvoice(id);
            if (d.status === "success") {
              setReload(true)
              Swal.fire({
                title: "Deleted!",
                text: "Invoice Deleted Successfully !!",
                icon: "success",
                confirmButtonText: "OK",
              });
            }
          }
       
        });
        
      }

      

    const CustomHeader = ({ children }) => (<div style={{ backgroundColor: 'blue', padding: '10px', color: 'white' }}>    {children}  </div>);

    
    const columns = [
        
        {
            name: "Invoice",
            selector: (row) => <div style={{ whiteSpace: 'normal', backgroundColor: row.invoiceno === "" || row.invoiceno === null ? "red" : "#D9E1FF", borderRadius: "10px", border: row.invoiceno === "" || row.invoiceno === null ? "2px solid red" : "2px solid #28B3D2", padding: "3px", display: "flex", flexDirection: "row", color: row.invoiceno === "" || row.invoiceno === null ? "#fff" : "#2457C5", margin: "3px", }}>{row.invoiceno ? row.invoiceno : "N/A"}</div>,
            fontSize: 10,
            fontWeight: 'bold',
        },
        {
            name: "Name",
            selector: (row) => <div style={{ whiteSpace: 'normal', backgroundColor: row.firstname === "" || row.firstname === null ? "#f36e6f" : "", borderRadius: "10px", border: row.firstname === "" || row.firstname === null ? "2px solid transparent" : "2px solid transparent", padding: "3px", display: "flex", flexDirection: "row", color: row.firstname === "" || row.firstname === null ? "#fff" : "#000", margin: "3px", }}>{row.firstname ? row.firstname + ' ' + row.lastname : "N/A"}</div>,
            fontSize: 10,
            fontWeight: 'bold',
        },
        {
            name: "Company",
            selector: (row) => <div style={{ whiteSpace: 'normal', backgroundColor: row.company === "" || row.company === null ? "#f36e6f" : "", borderRadius: "10px", border: row.company === "" || row.company === null ? "2px solid transparent" : "2px solid transparent", padding: "3px", display: "flex", flexDirection: "row", color: row.company === "" || row.company === null ? "#fff" : "#000", margin: "3px", }}>{row.company ? row.company : "N/A"}</div>,

            fontSize: 10,
            fontWeight: 'bold',
        },
        {
            name: "Title",
            selector: (row) => <div style={{ whiteSpace: 'normal', backgroundColor: row.title === "" || row.title === null ? "#f36e6f" : "", borderRadius: "10px", border: row.title === "" || row.title === null ? "2px solid transparent" : "2px solid transparent", padding: "3px", display: "flex", flexDirection: "row", color: row.title === "" || row.title === null ? "#fff" : "#000", margin: "3px", }}>{row.title ? row.title : "N/A"}</div>,

            fontSize: 10,
            fontWeight: 'bold',
        },
        {
            name: "Email",
            selector: (row) => <div style={{ whiteSpace: 'normal', backgroundColor: row.email === "" || row.email === null ? "#f36e6f" : "", borderRadius: "10px", border: row.email === "" || row.email === null ? "2px solid transparent" : "2px solid transparent", padding: "3px", display: "flex", flexDirection: "row", color: row.email === "" || row.email === null ? "#fff" : "#000", margin: "3px", }}>{row.email ? row.email : "N/A"}</div>,

            fontSize: 10,
            fontWeight: 'bold',
        },
        {
            name: "Phone",
            selector: (row) => <div style={{ whiteSpace: 'normal', backgroundColor: row.phone === "" || row.phone === null ? "#f36e6f" : "", borderRadius: "10px", border: row.phone === "" || row.phone === null ? "2px solid transparent" : "2px solid transparent", padding: "3px", display: "flex", flexDirection: "row", color: row.phone === "" || row.phone === null ? "#fff" : "#000", margin: "3px", }}>{row.phone ? row.phone : "N/A"}</div>,

            fontSize: 10,
            fontWeight: 'bold',
        },
        {
            name: "Validity",
            selector: (row) => <div style={{ whiteSpace: 'normal', backgroundColor: row.duedate === "" || row.duedate === null ? "#f36e6f" : "", borderRadius: "10px", border: row.duedate === "" || row.duedate === null ? "2px solid transparent" : "2px solid transparent", padding: "3px", display: "flex", flexDirection: "row", color: row.duedate === "" || row.duedate === null ? "#fff" : "#000", margin: "3px", }}>{row.duedate ? row.duedate.split('T')[0] : "N/A"}</div>,

            fontSize: 10,
            fontWeight: 'bold',
        },
        {
            name: "Actions",
            selector: (row) => (
                <div style={{display:'flex',margin:"10px",gap:3}} key={row.id}>
                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Preview</Tooltip>}>
                    <div>
                    <FaEye
                        onClick={() => generateAndPreviewPDF(row)}
                        style={{ color: "#9889e8", fontSize: 20 }}
                    />
                    </div>
                    </OverlayTrigger>
                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Generate PDF</Tooltip>}>
                    <div>
                    <FaRegFilePdf
                        onClick={() => generatePDF(row)}
                        style={{ color: "#9889e8", fontSize: 20 }}
                    />
                    </div>
                    </OverlayTrigger>

                    {Op_per.get().INVOICE_MASTER.ALLOW_DELETE && <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Delete</Tooltip>}>
                    <div>
                    <AiFillDelete 
                        onClick={() => handletaskdelete(row.id)}
                        style={{ color: "#f36e6f", fontSize: 20 }}
                    />
                    </div>
                    </OverlayTrigger>}
                </div>
            )
        }
    ];

    const ExpandedComponent = ({ data }) => (
        <div style={{
            backgroundColor: data.disabled ? "#f36e6f" : "",
            color: "#fff",
            padding: 10,
            fontSize: 12,
            margin: 20,
            borderRadius: 20,
            border: "2px solid transparent",
            width: "95%"

        }}>
            <Table bordered responsive='md' className="mt-3 table table-bordered">
                <thead>
                    <tr>
                        <th>Product Name</th>
                        <th>Qty</th>
                        <th>Price</th>
                        <th>Discount</th>
                        <th>Total</th>
                        <th>Tax Price</th>
                    </tr>
                </thead>
                <tbody>
                    {data.products.length > 0 ? (
                        <>
                            {data.products.map((d, index) => (
                                <tr key={index}>
                                    <td>{d.productname}</td>
                                    <td>{d.quantity}</td>
                                    <td>{d.price}</td>
                                    <td>{d.discount}</td>
                                    <td>{Helper.formatRupees(d.total)}</td>
                                    <td>{Helper.formatRupees(d.taxprice)}</td>
                                </tr>
                            ))}
                            {(data.tdsid || data.tcsid) && <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td colSpan="2">{(data.tdsid || data.tcsid) ? (data.tcsid ? 'TCS -' : 'TDS -') : ''} {(data.tdsid || data.tcsid) ? (data.tcsid ? data.tcsrate +' %' : data.tdsrate+' %') : ''}</td>
                            </tr>}
                            {(data.taxtype && data.taxamount != 0) && data.taxtype?.split(',').map(d => (<tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>{d}</td>
                                <td>{Helper.formatRupees(data.taxamount/data.taxtype?.split(',').length)}</td>
                            </tr>))}
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>Grand Total </td>
                                <td>{Helper.formatRupees(totalCalculate(data))}</td>
                            </tr>
                        </>
                    ) : (
                        <tr>
                            <td style={{paddingLeft:'45%',paddingTop:'3%',fontWeight:'bold' }} colSpan="6">No Products</td>
                        </tr>
                    )}

                </tbody>
            </Table>
        </div>
    )

    return (
        <Container fluid style={{ minHeight: "80vh" }}>
            <div
                className="glass-body"
                style={{
                    margin: 10,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    padding: 0,
                    backgroundColor: 'transparent'
                }}
            >

                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <ul id="breadcrumb">
                        <li><a href="/"><IoHomeOutline
                            style={{ color: "white", fontSize: 20 }}

                        /></a></li>
                        <li><a href="/invoice-master"><span class="icon icon-beaker"> </span>Master</a></li>
                        <li><a href="/invoice-master"><span class="icon icon-beaker"> </span>Invoice</a></li>
                       

                    </ul>

                </div>
            </div>

            <div id="content" className="glass-body" style={{ margin: 10 }}>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                    }}
                >
                    <div>
                    <InputGroup className="">
                        <Form.Control
                            placeholder="Search Invoices"
                            value= {searchQuery}
                            onChange={(e) => {handleSearchQuery(e.target.value)}}
                            
                        />
                    </InputGroup>
                        {borderColor ? <span style={{fontSize:"small", color:"red"}}>Special characters not allowed</span> : ""}
                    </div>
                    
                    
                    {false && <div>
                    <Button style={{ backgroundColor: "#2194f0" }} onClick={redirect}>
                    <FaPlus />
                    </Button>
                    </div>}
                </div>
                <div style={{ marginTop: 10 }}>
                    <DataTable
                        columns={columns}
                        data={filteredData}
                        pagination
                        customStyles={{ headRow: { style: { backgroundColor: '#c0dfff', padding: 7 }, } }}
                        CustomHeader={<CustomHeader />}
                        style={{ marginTop: 10 }}
                        expandableRows
                        expandableRowsComponent={ExpandedComponent}
                        dense
                    />
                </div>
            </div>

            <Modal size="lg" onHide={() =>{setIsPreviewOpen(false);URL.revokeObjectURL(blobUrl);setBlobUrl(null);}} show={isPreviewOpen}>
            <Modal.Header closeButton></Modal.Header>
          <Modal.Body style={{height:'80vh'}}>
          {pdfPreviewUrl && (
            <div style={{width:'100%',height:'100%'}}>
              <iframe
                src={blobUrl}
                width='100%'
                height='100%'
                className=""
                title="PDF Preview"
              />
            </div>
          )}
          </Modal.Body>
        </Modal>
        </Container>
    )
}