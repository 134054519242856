import React, { useEffect, useState } from "react";
import { Button, Container } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import {
  GetAllDoctors,
  GetAllDoctorsDegree,
  CheckDocDegreeAvailable,
  CreateDocDegree,
  UpdateDoctorDegree,
  DeleteDoctorDegree,
} from "../../utils/proxy/Doctorproxy";
import ReactPaginate from "react-paginate";
import Modal from "react-bootstrap/Modal";
import Swal from "sweetalert2";
import DataTable from "react-data-table-component";
import { AiFillDelete, AiTwotoneEdit } from "react-icons/ai";
import { Parser } from "@json2csv/plainjs";
import { HiDotsVertical } from "react-icons/hi";
import { IoHomeOutline } from "react-icons/io5";
import { FaPlus } from "react-icons/fa6";
import { Op_per } from "../../utils/Permissions";

function Doctordegree() {
  const [doctordegree, setDoctorDegree] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [show, setShow] = useState(false);
  const [city_id, setCityId] = useState();
  const [docdegree, setDocDegree] = useState("");
  const [isdocdegree, setIsDocDegree] = useState();
  const [docdesc, setDocDesc] = useState("");
  const [reload, SetReload] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [pending, setPending] = React.useState(true);

  const [currentId, setCurrentId] = useState();
  const [editShow, setEditShow] = useState(false);

  const filteredData = doctordegree.filter((row) =>
    row.degree.toLowerCase().includes(searchQuery.toLowerCase())
  );

  useEffect(() => {
    // Fetch data from an API when the component mounts
    const getalldocdegree = async () => {
      let doctordegreedata = await GetAllDoctorsDegree();
      if (doctordegreedata) {
        setDoctorDegree(doctordegreedata);
        setPending(false);
      } else {
        alert("Error", "Issue While Fetching Doctor Degree");
      }
    };
    getalldocdegree();
    SetReload(false);
  }, [reload]);

  useEffect(() => {
    const getallcountries = async (docdegree) => {
      if (docdegree.length > 0) {
        let statedata = await CheckDocDegreeAvailable(docdegree);
        if (statedata) {
          setIsDocDegree(true);
        } else {
          setIsDocDegree(false);
        }
      }
    };
    getallcountries(docdegree);
  }, [docdegree]);

  // Function to handle page change
  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected);
  };

  // Pagination configuration
  const itemsPerPage = 10; // Number of items per page
  const pageCount = Math.ceil(filteredData.length / itemsPerPage); // Total number of pages

  // Calculate the current page's items
  const startIndex = currentPage * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentPageItems = filteredData.slice(startIndex, endIndex);

  const handleClose = () => {setShow(false);setIsDocDegree(false)}
  const handleShow = () => setShow(true);
  const handleEditShow = (row) => {
    setDocDegree(row.degree);
    setDocDesc(row.description);
    setCurrentId(row.id);
    setEditShow(true);
  };

  const handleEditClose = () => {setEditShow(false);setIsDocDegree(false)}

  const handleDegreeChange = (event) => {
    setDocDegree(event.target.value);
  };

  const handleDescriptionChange = (event) => {
    setDocDesc(event.target.value);
  };

  let createDocDegree = async (docdegree, docdesc) => {
    let statedata = await CreateDocDegree(docdegree, docdesc);
    if (statedata) {
      SetReload(true);
      setShow(false);

      Swal.fire({
        title: "Success!",
        text: "Doctor Degree Added Successfully !!",
        icon: "success",
        confirmButtonText: "OK",
      });
    } else {
      alert("Error", "Issue While Adding Doctor Degree");
    }
  };

  let handleSave = (event) => {
    if (isdocdegree == false && docdesc.length > 0) {
      createDocDegree(docdegree, docdesc);
    }
  };

  let updateDegree = async (id, degree, description) => {
    let degreedata = await UpdateDoctorDegree(id, degree, description);
    if (degreedata) {
      SetReload(true);
      setEditShow(false);

      Swal.fire({
        title: "Success!",
        text: "Doctor Degree Update Successfully !!",
        icon: "success",
        confirmButtonText: "OK",
      });
      setCurrentId(null);
      setDocDegree('');
      setDocDesc('');
      setIsDocDegree(false);
    } else {
      alert("Error", "Issue While Updating Doctor Degree");
    }
  };

  let handleedit = (event) => {
    updateDegree(currentId, docdegree, docdesc);
  };

  let deleteDegree = async (id) => {
    let degreedelete = await DeleteDoctorDegree(id);

    if (degreedelete) {
      SetReload(true);

      Swal.fire({
        title: "Success!",
        text: "Doctor Degree Deleted Successfully !!",
        icon: "success",
        confirmButtonText: "OK",
      });
    } else {
      alert("Error", "Issue While Deleting Doctor Degree");
    }
  };

  let handledelete = (id) => {
    deleteDegree(id);
  };

  const columns = [
    // {
    //   name: "Id",
    //   selector: (row) => row.id,
    //   sortable: true,
    //   width:"5%",
      
    // },
    {
      name: "Degree",
      selector: (row) =><div style={{ whiteSpace: 'normal',backgroundColor: row.degree === "" || row.degree === null ? "#f36e6f":"", borderRadius:"10px", border: row.degree === "" || row.degree === null ? "2px solid transparent": "2px solid transparent",padding:"3px", display:"flex", flexDirection:"row",color:row.degree === "" || row.degree === null ? "#fff": "#000", margin:"3px", }}>{row.degree ? row.degree : "N/A"}</div> ,
      sortable: true,
    },
    {
      name: "Description",
      selector: (row) => <div style={{ whiteSpace: 'normal',backgroundColor: row.description === "" || row.description === null ? "#f36e6f":"", borderRadius:"10px", border: row.description === "" || row.description === null ? "2px solid transparent": "2px solid transparent",padding:"3px", display:"flex", flexDirection:"row",color:row.description === "" || row.description === null ? "#fff": "#000", margin:"3px", }}>{row.description ? row.description : "N/A"}</div> ,
      sortable: true,
    },
    // {
    //   name: "Actions",
    //   selector: (row) => (
    //     <div key={row.id}>
    //       <AiFillDelete
    //         onClick={() => handledelete(row.id)}
    //         style={{ color: "#f36e6f", fontSize: 20 }}
    //       />
    //       &nbsp;&nbsp;&nbsp;
    //       <AiTwotoneEdit
    //         onClick={() => handleEditShow(row)}
    //         style={{ color: "black", fontSize: 20 }}
    //       />
    //     </div>
    //   ),
    // },
  ];

  if(Op_per.get().OPTIONS_QUALIFICATION_MASTER.ALLOW_DELETE || Op_per.get().OPTIONS_QUALIFICATION_MASTER.ALLOW_EDIT){
    columns.push({
      name: "Actions",
      selector: (row) => (
        <div key={row.id}>
          <AiFillDelete
            onClick={() => handledelete(row.id)}
            style={{ color: "#f36e6f", fontSize: 20, display:Op_per.get().OPTIONS_QUALIFICATION_MASTER.ALLOW_EDIT ? '' : 'none' }}
          />
          &nbsp;&nbsp;&nbsp;
          <AiTwotoneEdit
            onClick={() => handleEditShow(row)}
            style={{ color: "#9889e6", fontSize: 20, display:Op_per.get().OPTIONS_QUALIFICATION_MASTER.ALLOW_DELETE ? '' : 'none' }}
          />
        </div>
      ),
    })
  }

  const customStyles = {   head: {     style: {       backgroundColor: 'blue',     },   }, };
  const CustomHeader = ({ children }) => (   <div style={{ backgroundColor: 'blue', padding: '10px', color: 'white' }}>    {children}  </div>);

  async function downloadCSV(doctordegree) {
    let csv;
    try {
      const opts = {
        fields: ["id", "degree", "description"],
      };
      const parser = new Parser(opts);
      csv = parser.parse(doctordegree);
    } catch (err) {
      console.error(err);
    }

    const link = document.createElement("a");

    if (csv == null) return;

    const filename = "export.csv";

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute("href", encodeURI(csv));
    link.setAttribute("download", filename);
    link.click();
  }

  const Export = ({ onExport }) => (
    <Button onClick={(e) => onExport(e.target.value)}>Export All</Button>
  );

  const actionsMemo = React.useMemo(
    () => <Export onExport={() => downloadCSV(doctordegree)} />,
    [doctordegree]
  );

  return (
    <Container fluid style={{ minHeight: "80vh" }}>
      <div
        className="glass-body"
        style={{
          margin: 10,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          padding:0,
          backgroundColor:'transparent'
        }}
      >

<div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
<ul id="breadcrumb">
  <li><a href="/"><IoHomeOutline
            style={{ color: "white", fontSize: 20 }}
            
          /></a></li>
  <li><a href="/doctor-degree"><span class="icon icon-beaker"> </span>Options</a></li>
  <li><a href="/doctor-degree"><span class="icon icon-beaker"> </span>Dr. Qualification Master</a></li>
 
  
</ul>
	
</div>
      
      </div>

      <div className="glass-body" style={{ margin: 10 }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
          className="flex-column-mobile"
        >
          <div>
            <Form.Control
            style={{height: 40,
            width:250,
            borderColor: '#ccc',
            borderWidth: 1,
            paddingHorizontal: 8,
            marginBottom: 16,
            borderRadius:10}}
              className="glass"
              placeholder="Search Degree"
              type="text"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
          <div>
            <Button style={{backgroundColor: "#2194f0", display:Op_per.get().OPTIONS_QUALIFICATION_MASTER.ALLOW_ADD ? '' : 'none' }} onClick={handleShow}>
            <FaPlus />
            </Button>
          </div>
        </div>
        <div style={{ marginTop: 10 }}>
          <DataTable
            columns={columns}
            data={filteredData}
            // progressPending={pending}
            pagination
            customStyles={{headRow: {style: {backgroundColor: '#c0dfff',  padding:10,borderRadius:'10px 10px 0 0' }, }  }}
                        CustomHeader={<CustomHeader/>}
            // actions={actionsMemo}
            style={{ marginTop: 10 }}
            dense
          />
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Doctor Degree</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Degree <span style={{color:"red"}}>*</span></Form.Label>

              <Form.Control
                style={{
                  backgroundColor: isdocdegree ? "#f36e6f" : "",
                  color: isdocdegree ? "#fff" : "",
                  height: 40,
              
            borderColor: '#ccc',
            borderWidth: 1,
            paddingHorizontal: 8,
            marginBottom: 16,
            borderTopLeftRadius:20,
            borderBottomRightRadius:20,
                }}
                type="text"
                onChange={handleDegreeChange}
                placeholder="Enter Degree"
                required
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Description <span style={{color:"red"}}>*</span></Form.Label>
              <Form.Control
               style={{height: 40,
             
            borderColor: '#ccc',
            borderWidth: 1,
            paddingHorizontal: 8,
            marginBottom: 16,
            borderTopLeftRadius:20,
            borderBottomRightRadius:20,}}
                type="text"
                onChange={handleDescriptionChange}
                placeholder="Enter Description"
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={handleSave}
            disabled={isdocdegree ? true : false}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={editShow} onHide={handleEditClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Doctor Degree</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Degree <span style={{color:"red"}}>*</span></Form.Label>

              <Form.Control
               style={{height: 40,
            
            borderColor: '#ccc',
            borderWidth: 1,
            paddingHorizontal: 8,
            marginBottom: 16,
            borderTopLeftRadius:20,
            borderBottomRightRadius:20,}}
                type="text"
                onChange={handleDegreeChange}
                placeholder="Enter Degree"
                value={docdegree}
                required
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Description <span style={{color:"red"}}>*</span></Form.Label>
              <Form.Control
              style={{height: 40,
            
            borderColor: '#ccc',
            borderWidth: 1,
            paddingHorizontal: 8,
            marginBottom: 16,
            borderTopLeftRadius:20,
            borderBottomRightRadius:20,}}
                type="text"
                onChange={handleDescriptionChange}
                value={docdesc}
                placeholder="Enter Description"
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleEditClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleedit}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default Doctordegree;
