import React, { useEffect, useState } from "react";
import { Button, Container } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import DataTable from "react-data-table-component";
import Dropdown from "react-bootstrap/Dropdown";
import { AiFillDelete, AiTwotoneEdit } from "react-icons/ai";
import { TiTick } from "react-icons/ti";
import Select from "react-select";
import { AllUsers, GetAllManagerUsers } from "../../utils/proxy/Authproxy";
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import Helper from "../../utils/helperFunctions";
import { GetAllDayInOut } from "../../utils/proxy/DayInOutProxy";
import { Parser } from "@json2csv/plainjs";
import { IoHomeOutline } from "react-icons/io5";
import { RiFileExcel2Fill } from "react-icons/ri";
import { Op_per } from "../../utils/Permissions";

function Dayinout() {

  const months = [
    { label: "January", value: "01" },
    { label: "February", value: "02" },
    { label: "March", value: "03" },
    { label: "April", value: "04" },
    { label: "May", value: "05" },
    { label: "June", value: "06" },
    { label: "July", value: "07" },
    { label: "August", value: "08" },
    { label: "September", value: "09" },
    { label: "October", value: "10" },
    { label: "November", value: "11" },
    { label: "December", value: "12" },
  ];
  const currentYear = new Date().getFullYear();
  const years = [];

  for (let i = currentYear; i >= currentYear - 100; i--) {
    years.push({ value: i.toString(), label: i.toString() });
  }

  const [dayInOut, setDayInOut] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [userid, setUserId] = useState(localStorage.getItem('role') === 'User' ? localStorage.getItem('uuid') : "");
  const [reload, setReload] = useState(false);
  const [selectedUser, setSelectedUser] = useState();
  const [users, setUsers] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [date, setDate] = useState();

  const [selectedMonth, setselectedMonth] = useState(months[new Date().getMonth()]);
  const [selectedYear, setSelectedYear] = useState(years[0]);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    // Fetch data from an API when the component mounts
    const getAllDayInOut = async (userid) => {
      let response = await GetAllDayInOut(userid,selectedMonth.value,selectedYear.value);
      if (response) {
        setDayInOut(response);
        setFilteredData(response);
      } else {
        alert("Error", "Issue While Fetching Day IN / OUT");
      }
    };
    if (userid) {
      getAllDayInOut(userid);
    }
    setReload(false);
  }, [reload, userid, selectedMonth,selectedYear]);

  useEffect(() => {
    // Fetch data from an API when the component mounts
    const getallusers = async () => {
      if (localStorage.getItem('role') === 'Admin') {
        let allusers = await AllUsers();
        setUsers(allusers);
      } else if (localStorage.getItem('role') === 'Manager') {
        let allusers = await GetAllManagerUsers(localStorage.getItem('uuid'));
        setUsers(allusers);
      }
    };
    getallusers();
  }, []);

  const options = [];

  users.map((user) => {
    options.push({
      value: user.userid,
      label: user.username + "(" + user.firstname + " " + user.lastname + ")",
    });
  });

  const columns = [
    // {
    //   name: "Id",
    //   selector: (row) => row.id,
    //   sortable: true,
    //   width: "5%",
    // },
    {
      name: "Date",
      selector: (row) => <div style={{ whiteSpace: 'normal', backgroundColor: Helper.ConvertUtcToLocalTime(row.startday).split(" ")[0] === "" || Helper.ConvertUtcToLocalTime(row.startday).split(" ")[0] === null ? "#f36e6f" : "", borderRadius: "10px", border: Helper.ConvertUtcToLocalTime(row.startday).split(" ")[0] === "" || Helper.ConvertUtcToLocalTime(row.startday).split(" ")[0] === null ? "2px solid transparent" : "2px solid transparent", padding: "3px", display: "flex", flexDirection: "row", color: Helper.ConvertUtcToLocalTime(row.startday).split(" ")[0] === "" || Helper.ConvertUtcToLocalTime(row.startday).split(" ")[0] === null ? "#fff" : "#000", margin: "3px", }}>{Helper.ConvertUtcToLocalTime(row.startday).split(" ")[0] ? Helper.ConvertUtcToLocalTime(row.startday).split(" ")[0] : "N/A"}</div>,
      width: "15%",
    },
    {
      name: "Start Day",
      selector: (row) => <div style={{ whiteSpace: 'normal', backgroundColor: Helper.ConvertUtcToLocalTime(row.startday).split(" ")[1] + ' ' + Helper.ConvertUtcToLocalTime(row.startday).split(" ")[2] === "" || Helper.ConvertUtcToLocalTime(row.startday).split(" ")[1] + ' ' + Helper.ConvertUtcToLocalTime(row.startday).split(" ")[2] === null ? "#f36e6f" : "", borderRadius: "10px", border: Helper.ConvertUtcToLocalTime(row.startday).split(" ")[1] + ' ' + Helper.ConvertUtcToLocalTime(row.startday).split(" ")[2] === "" || Helper.ConvertUtcToLocalTime(row.startday).split(" ")[1] + ' ' + Helper.ConvertUtcToLocalTime(row.startday).split(" ")[2] === null ? "2px solid transparent" : "2px solid transparent", padding: "3px", display: "flex", flexDirection: "row", color: Helper.ConvertUtcToLocalTime(row.startday).split(" ")[1] + ' ' + Helper.ConvertUtcToLocalTime(row.startday).split(" ")[2] === "" || Helper.ConvertUtcToLocalTime(row.startday).split(" ")[1] + ' ' + Helper.ConvertUtcToLocalTime(row.startday).split(" ")[2] === null ? "#fff" : "#000", margin: "3px", }}>{Helper.ConvertUtcToLocalTime(row.startday).split(" ")[1] + ' ' + Helper.ConvertUtcToLocalTime(row.startday).split(" ")[2] ? Helper.ConvertUtcToLocalTime(row.startday).split(" ")[1] + ' ' + Helper.ConvertUtcToLocalTime(row.startday).split(" ")[2] : "N/A"}</div>,
      width: "15%",
    },
    {
      name: "End Day",
      selector: (row) => <div style={{ whiteSpace: 'normal', backgroundColor: Helper.ConvertUtcToLocalTime(row.endday).split(" ")[1] || Helper.ConvertUtcToLocalTime(row.endday).split(" ")[2] ? "" : "red", borderRadius: "10px", border: Helper.ConvertUtcToLocalTime(row.endday).split(" ")[1] || Helper.ConvertUtcToLocalTime(row.endday).split(" ")[2] === null ? "2px solid transparent" : "2px solid transparent", padding: "3px", display: "flex", flexDirection: "row", color: Helper.ConvertUtcToLocalTime(row.endday).split(" ")[1] || Helper.ConvertUtcToLocalTime(row.endday).split(" ")[2] ? "#000" : "#fff", margin: "3px", }}>{Helper.ConvertUtcToLocalTime(row.endday).split(" ")[1] || Helper.ConvertUtcToLocalTime(row.endday).split(" ")[2] ? Helper.ConvertUtcToLocalTime(row.endday).split(" ")[1] + ' ' + Helper.ConvertUtcToLocalTime(row.endday).split(" ")[2] : "N/A"}</div>,
      width: "15%",
    },
    {
      name: "Remark",
      selector: (row) => <div style={{ whiteSpace: 'normal', backgroundColor: row.remark === "" || row.remark === null ? "#f36e6f" : "", borderRadius: "10px", border: row.remark === "" || row.remark === null ? "2px solid transparent" : "2px solid transparent", padding: "3px", display: "flex", flexDirection: "row", color: row.remark === "" || row.remark === null ? "#fff" : "#000", margin: "3px", }}>{row.remark ? row.remark : "N/A"}</div>,
      width: "10%",
    },
  ];

  const customStyles = { head: { style: { backgroundColor: 'blue', }, }, };
  const CustomHeader = ({ children }) => (<div style={{ backgroundColor: 'blue', padding: '10px', color: 'white' }}>    {children}  </div>);

  const handleEditShow = (row) => { };

  const handleDelete = (row) => { };

  const handleSelectChange = (selected) => {
    setUserId(selected.value);
  };

  const handleSelectChangeMonth=(selected)=>{
    setselectedMonth(selected)
  }
  const handleSelectChangeYear=(selected)=>{
    setSelectedYear(selected)
  }

  const handleDateFilter = (value) => {
    if (!value) {
      setDate(value);
      return;
    }
    setFilteredData(() =>
      dayInOut.filter(
        (iDay) =>
          new Date(iDay.startday).toDateString() ===
          new Date(value).toDateString()
      )
    );
    setDate(value);
  };

  async function downloadCSV(filteredData) {
    let csv;
    try {
      const data = filteredData.map(entry => {
        try {
          // Handle UTC to local time conversion
          const startdayLocal = Helper.ConvertUtcToLocalTime(entry.startday);
          const enddayLocal = Helper.ConvertUtcToLocalTime(entry.endday);
          return {
            date:startdayLocal.split(' ')[0],
            startday: startdayLocal.split(' ')[1]+" "+startdayLocal.split(' ')[2],
            endday: (enddayLocal.split(' ')[1] || enddayLocal.split(' ')[2]) ? enddayLocal.split(' ')[1]+" "+enddayLocal.split(' ')[2] : 'N/A',
            remark: entry.remark || '',
          };
        } catch (err) {
          return {
            startday: 'Invalid Date',
            endday: 'Invalid Date',
            remark: 'Error processing date',
          };
        }
      });
      const opts = {
        fields: [ "date", "startday", "endday", "remark"],
      };

      const parser = new Parser(opts);
      csv = parser.parse(data);


    } catch (err) {
      console.error(err);
    }

    const link = document.createElement("a");

    if (csv == null) return;

    const filename = "export.csv";

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute("href", encodeURI(csv));
    link.setAttribute("download", filename);
    link.click();
  }

  const Export = ({ onExport }) => (
    <Button style={{ position: 'absolute', right: 40, backgroundColor: "#2194f0", display: Op_per.get().REPORTS_DCR_DAYINOUT.ALLOW_EXPORT_XLSX ? '' : 'none' }} onClick={(e) => onExport(e.target.value)}><RiFileExcel2Fill /></Button>
  );

  const actionsMemo = React.useMemo(
    () => <Export onExport={() => downloadCSV(filteredData)} />,
    [filteredData]
  );

  return (
    <div style={{ minHeight: "80vh" }}>
      <div
        className="glass-body"
        style={{
          margin: 10,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          padding: 0,
          backgroundColor: 'transparent'
        }}
      >

        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <ul id="breadcrumb">
            <li><a href="/"><IoHomeOutline
              style={{ color: "white", fontSize: 20 }}

            /></a></li>
            <li><a href="/day-in-out"><span class="icon icon-beaker"> </span>Reports</a></li>

            <li><a href="#"><span class="icon icon-double-angle-right"></span>Day In/Out</a></li>

          </ul>

        </div>

      </div>

      <div className="glass-body" style={{ margin: 10 }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
            className="flex-column-mobile"
          >
            <div style={{ width: "250px", display: Op_per.get().REPORTS_DCR_DAYINOUT.USER_DROPDOWN ? '' : 'none' }}>
              <Form.Group
                className="mb-3 d-flex w-100"
                style={{ gap: "15px" }}
                controlId="formBasicPassword"
              >
                <Form.Label className="mt-2">Users</Form.Label>
                <div className="w-75">
                  <Select
                    className="selectdoctor"
                    options={options}
                    value={selectedUser}
                    onChange={handleSelectChange}
                    styles={{

                      dropdownIndicator: (provided) => ({
                        ...provided,
                        color: '#ccc' // Change color of the arrow here
                      })
                    }}
                  />
                </div>
              </Form.Group>
            </div>
            <div style={{ width: "250px", zIndex: 3 }}>
              <Form.Group
                className="mb-3 d-flex w-100"
                style={{ gap: "15px" }}
                controlId="formBasicPassword"
              >
                <Form.Label className="mt-2">Select Month</Form.Label>
                <div className="w-50">
                  <Select
                    className="selectdoctor"
                    options={months}
                    value={selectedMonth}
                    onChange={handleSelectChangeMonth}
                    styles={{

                      dropdownIndicator: (provided) => ({
                        ...provided,
                        color: '#ccc' // Change color of the arrow here
                      })
                    }}
                  />
                </div>
              </Form.Group>
            </div>
            <div style={{ width: "450px", zIndex: 3 }}>
              <Form.Group
                className="mb-3 d-flex w-100"
                style={{ gap: "15px" }}
                controlId="formBasicPassword"
              >
                <Form.Label className="mt-2">Select Year</Form.Label>
                <div className="w-50">
                  <Select
                    className="selectdoctor"
                    options={years}
                    value={selectedYear}
                    onChange={handleSelectChangeYear}
                    styles={{

                      dropdownIndicator: (provided) => ({
                        ...provided,
                        color: '#ccc' // Change color of the arrow here
                      })
                    }}
                  />
                </div>

              </Form.Group>
            </div>
          </div>
        </div>
        <div className="pt-3">
          <DataTable
            columns={columns}
            data={filteredData}
            pagination
            customStyles={{ headRow: { style: { backgroundColor: '#c0dfff', padding: 7, minHeight: 32 }, } }}
            CustomHeader={<CustomHeader />}
            actions={actionsMemo}
            style={{ marginTop: 10 }}
          />
        </div>
      </div>
    </div>
  );
}

export default Dayinout;
