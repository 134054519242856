import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Form, InputGroup, Row, Table } from 'react-bootstrap'
import { deletetdsSection, getAllTdsSection } from '../../../utils/proxy/TaxProxy';
import DataTable from 'react-data-table-component';
import { AiFillDelete, AiTwotoneEdit } from 'react-icons/ai';
import { Op_per } from '../../../utils/Permissions';
import TdsSectionmodel from './TdsSectionmodel';
import Swal from 'sweetalert2';

function Tdssections() {

  const [sections,setSections] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [action, setSetaction] = useState("");
  const [show, setShow] = useState(false);
  const [reload, setReload] = useState(true);
  const [editData,setEditData] = useState([]);

  const filteredData = sections.filter((row) =>
    row.section.toLowerCase().includes(searchQuery.toLowerCase())
  );

  useEffect(()=>{
    if (reload) {

      getSec();
      setReload(false)
    }
  },[reload]);

  function handleEdit(row) {
    setSetaction("edit");
    setEditData(row);
    setShow(true);
  }

  function handleAdd()
  {
    setSetaction("create");
    setShow(true);
  }

  async function handleDelete(row) {
    Swal.fire({
      title: "Are you sure you want to delete ?",
      // showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: "Yes",
      // denyButtonText: `No`
    }).then((result) => {
      if (result.isConfirmed) {
        deletedata(row);
      }
    });
    
   
  }


  async function deletedata(row) {
    let res = await deletetdsSection(row);
    if (res) {
        setReload(true)
        Swal.fire({
            icon: res.status,
            text: res.message,
          });
        }
  }


  async function getSec() {
   let res = await getAllTdsSection();
   if (res) {
    setSections(res)
   }
  }

  const columns = [
    {
      name: "Section",
      selector: (row) => row.section,
     
      width: "10%",
    },
    {
      name: "Desc",
      selector: (row) => row.desc,
      // width: "10%",

    }]

    if(Op_per.get().VISIBLE_TAX_OPTIONS_TDS.ALLOW_DELETE_TDS_SECTION || Op_per.get().VISIBLE_TAX_OPTIONS_TDS.ALLOW_EDIT_TDS_SECTION){
      columns.push({
        name: "Actions",
        selector: (row) => (
          <div key={row.id}>
            <AiTwotoneEdit
              style={{ color: "#9889e6", fontSize: 20, display:Op_per.get().VISIBLE_TAX_OPTIONS_TDS.ALLOW_EDIT_TDS_SECTION ? '' : 'none' }}
              onClick={() => handleEdit(row)}
            />
            &nbsp;&nbsp;&nbsp;
            <AiFillDelete
              onClick={() => handleDelete(row.id)}
              style={{ color: "#f36e6f", fontSize: 20, display:Op_per.get().VISIBLE_TAX_OPTIONS_TDS.ALLOW_DELETE_TDS_SECTION ? '' : 'none'}}
            />
          </div>
        ),
        width: "150px",
      })
    }


    return (
        <Container>
            <Row>
                <Col>
                    <InputGroup className="mb-3">
                        <Form.Control
                            placeholder="Search Sections"
                            value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
                        />

                    </InputGroup>
                </Col>
                <Col>
                    {Op_per.get().VISIBLE_TAX_OPTIONS_TDS.ALLOW_ADD_TDS_SECTION ? <Button onClick={() => handleAdd()} style={{ float: 'right' }}>Add Section</Button> : ""}
                </Col>
            </Row>

            <Row>
            <DataTable
            columns={columns}
            data={filteredData}
            pagination
            customStyles={{headRow: {style: {backgroundColor: '#c0dfff',  padding:10,borderRadius:'10px 10px 0 0' }, }  }}
                        // CustomHeader={<CustomHeader/>}
            style={{ marginTop: 10 }}
            highlightOnHover
            // actions={actionsMemo}
            dense
          />
            </Row>
            {show && <TdsSectionmodel action={action} data={editData} show setShow={setShow} setReload={setReload} />}
        </Container>
    )
}

export default Tdssections