import { Button, Container, Modal } from "react-bootstrap";
import DataTable from "react-data-table-component";
import Form from 'react-bootstrap/Form';
import { useState } from "react";
import { AiFillDelete, AiTwotoneEdit } from "react-icons/ai";
import { useEffect } from "react";
import Swal from "sweetalert2";
import Select from "react-select";
import { DeleteHolidayType, EditHolidayType } from "../../utils/proxy/AttendanceProxy";
import { FaPlus } from "react-icons/fa6";
import { IoHomeOutline } from "react-icons/io5";
import { CreateTaskCategory, DeleteTaskCategory, GetAllTaskCategory, UpdateTaskCategory } from "../../utils/proxy/Taskproxy";
import { GetCurrency } from "../../utils/proxy/Expenseproxy";
import { GetCurrencyFromSettings } from "../../utils/proxy/SettingsProxy";
import { Op_per } from "../../utils/Permissions";

function TaskCategoryMaster() {

    const [show, setShow] = useState(false);
    const [showedit, setShowedit] = useState(false);
    const [reload, SetReload] = useState(false);
    const [data, setData] = useState();

    const [id, setId] = useState();
    const [name, setName] = useState();
    const [cost, setCost] = useState();
    const [selectedWorth, setSelectedWorth] = useState();
    const [selectedWorthId, setSelectedWorthId] = useState();
    const [selectedStatus, setSelectedStatus] = useState();
    const [selectedStatusId, setSelectedStatusId] = useState();
    const [selectedCurrency, setSelectedCurrency] = useState();
    const [selectedCurrencyId, setSelectedCurrencyId] = useState();
    const [allCurrency, setAllCurrency] = useState([]);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        const getall = async () => {
            let d = await GetAllTaskCategory();
            if (d) {
                setData(d);
            } else {
                alert('Error', 'Issues while fetching data')
            }
        }
        getall()
        SetReload(false);
    }, [reload])

    useEffect(() => {
        const getCurrencyType = async () => {
            let curr = await GetCurrency();
            let setcurr = await GetCurrencyFromSettings();
            if (curr) {
                setAllCurrency(curr);
                if(setcurr){
                const b = curr.find(cur => cur.id == setcurr)
                setSelectedCurrency({value:b.id, label:b.currency})
                setSelectedCurrencyId(b.id);
                }
            } else {
                alert("Error", "Issue While Fetching Data");
            }
        }
        getCurrencyType();
    }, [reload]);

    const handleSave = async () => {
        if (name == null || name == '') {
            Swal.fire({
                title: "Warning!",
                text: "Enter Category Name !!",
                icon: "warning",
                confirmButtonText: "OK",
            });
        } else if (selectedWorthId == null || selectedWorthId == '') {
            Swal.fire({
                title: "Warning!",
                text: "Select Worth !!",
                icon: "warning",
                confirmButtonText: "OK",
            });
        } else if (setSelectedStatusId == null || setSelectedStatusId == '') {
            Swal.fire({
                title: "Warning!",
                text: "Select Status !!",
                icon: "warning",
                confirmButtonText: "OK",
            });
        } else {
            let d = await CreateTaskCategory(name, selectedWorthId, cost,selectedCurrencyId,selectedStatusId);
            if (d.status === 'success') {
                setShow(false);
                SetReload(true);
                setName(null);
                setCost(null);
                setSelectedWorth(null);
                setSelectedWorthId(null);
                setSelectedStatus(null);
                setSelectedStatusId(null);
                Swal.fire({
                    title: "Success!",
                    text: "New Task Category Added !!",
                    icon: "success",
                    confirmButtonText: "OK",
                });
            }
        }
    }

    const handledelete = async (id) => {
        let d = await DeleteTaskCategory(id);
        if (d.status === 'success') {
            SetReload(true);
            Swal.fire({
                title: "Success!",
                text: "Task Category Deleted !!",
                icon: "success",
                confirmButtonText: "OK",
            });
        }
    }

    const handleEditShow = (row) => {
        setShowedit(true);
        setId(row.id);
        setName(row.name);
        setCost(row.cost);
        setSelectedWorth({ value: row.worth, label: row.worth })
        setSelectedWorthId(row.worth)
        setSelectedStatus({ value: row.status, label: row.status })
        setSelectedStatusId(row.status)
        setSelectedCurrency({value:row.currencyid,label:row.currencyname})
        setSelectedCurrencyId(row.currencyid);
    }

    const handleEditSave = async () => {
        if (name == null || name == '') {
            Swal.fire({
                title: "Warning!",
                text: "Enter Task Category !!",
                icon: "warning",
                confirmButtonText: "OK",
            });
        } else if (selectedWorthId == null || selectedWorthId == '') {
            Swal.fire({
                title: "Warning!",
                text: "Select Worth !!",
                icon: "warning",
                confirmButtonText: "OK",
            });
        } else if (setSelectedStatusId == null || setSelectedStatusId == '') {
            Swal.fire({
                title: "Warning!",
                text: "Select Status !!",
                icon: "warning",
                confirmButtonText: "OK",
            });
        } else {
            let d = await UpdateTaskCategory(id, name, selectedWorthId,cost,selectedCurrencyId, selectedStatusId);
            if (d.status === 'success') {
                setShowedit(false);
                SetReload(true);
                setId(null);
                setName(null);
                setSelectedWorth(null);
                setSelectedWorthId(null);
                setSelectedStatus(null);
                setSelectedStatusId(null);
                Swal.fire({
                    title: "Success!",
                    text: "Task Category Edited !!",
                    icon: "success",
                    confirmButtonText: "OK",
                });
            }
        }
    }

    const columns = [
        // {
        //     name: "Id",
        //     selector: (row) => row.id,
        //  
        // },
        {
            name: "Name",
            selector: (row) => <div style={{ whiteSpace: 'normal', backgroundColor: row.name === "" || row.name === null ? "#f36e6f" : "", borderRadius: "10px", border: row.name === "" || row.name === null ? "2px solid transparent" : "2px solid transparent", padding: "3px", display: "flex", flexDirection: "row", color: row.name === "" || row.name === null ? "#fff" : "#000", margin: "3px", }}>{row.name ? row.name : "N/A"}</div>,

            width: '20%',
         
            fontSize: 10,
            fontWeight: 'bold',
        },
        {
            name: "Worth",
            selector: (row) => <div style={{ whiteSpace: 'normal', backgroundColor: row.worth === "" || row.worth === null ? "#f36e6f" : "", borderRadius: "10px", border: row.worth === "" || row.worth === null ? "2px solid transparent" : "2px solid transparent", padding: "3px", display: "flex", flexDirection: "row", color: row.worth === "" || row.worth === null ? "#fff" : "#000", margin: "3px", }}>{row.worth ? row.worth : "N/A"}</div>,

            width: '20%',
         
            fontSize: 10,
            fontWeight: 'bold',
        },
        {
            name: "Cost",
            selector: (row) => <div style={{ whiteSpace: 'normal', backgroundColor: row.cost === "" || row.cost === null ? "#f36e6f" : "", borderRadius: "10px", border: row.cost === "" || row.cost === null ? "2px solid transparent" : "2px solid transparent", padding: "3px", display: "flex", flexDirection: "row", color: row.cost === "" || row.cost === null ? "#fff" : "#000", margin: "3px", }}>{row.cost ? row.symbol +" "+row.cost : "N/A"}</div>,

            width: '20%',
         
            fontSize: 10,
            fontWeight: 'bold',
        },
        // {
        //     name: "Currency",
        //     selector: (row) => <div style={{ whiteSpace: 'normal', backgroundColor: row.currencyname === "" || row.currencyname === null ? "#f36e6f" : "", borderRadius: "10px", border: row.currencyname === "" || row.currencyname === null ? "2px solid transparent" : "2px solid transparent", padding: "3px", display: "flex", flexDirection: "row", color: row.currencyname === "" || row.currencyname === null ? "#fff" : "#000", margin: "3px", }}>{row.currencyname ? row.currencyname : "N/A"}</div>,

        //     width: '20%',
        //  
        //     fontSize: 10,
        //     fontWeight: 'bold',
        // },
        {
            name: "Status",
            selector: (row) => <div style={{ whiteSpace: 'normal', backgroundColor: row.status === "" || row.status === null ? "#f36e6f" : "", borderRadius: "10px", border: row.status === "" || row.status === null ? "2px solid transparent" : "2px solid transparent", padding: "3px", display: "flex", flexDirection: "row", color: row.status === "" || row.status === null ? "#fff" : "#000", margin: "3px", }}>{row.status ? row.status : "N/A"}</div>,

            width: '20%',
         
            fontSize: 10,
            fontWeight: 'bold',
        },
    ];

    
    const customStyles = {   head: {     style: {       backgroundColor: 'blue',     },   }, };
    const CustomHeader = ({ children }) => (   <div style={{ backgroundColor: 'blue', padding: '10px', color: 'white' }}>    {children}  </div>);

    if (Op_per.get().MASTERS_TASK_CATEGORIES.ALLOW_DELETE || Op_per.get().MASTERS_TASK_CATEGORIES.ALLOW_EDIT) {
        columns.push({
            name: "Actions",
            selector: (row) => (
                <div key={row.id}>
                    <AiFillDelete
                        onClick={() => handledelete(row.id)}
                        style={{ color: "#f36e6f", fontSize: 20,display:Op_per.get().MASTERS_TASK_CATEGORIES.ALLOW_DELETE ? '' : 'none' }}
                    />
                    &nbsp;&nbsp;&nbsp;
                    <AiTwotoneEdit
                        onClick={() => handleEditShow(row)}
                        style={{ color: "#9889e6", fontSize: 20,display:Op_per.get().MASTERS_TASK_CATEGORIES.ALLOW_EDIT ? '' : 'none' }}
                    />
                </div>
            ),
        })
    }

    const worthoptions = [];
    const statusoptions = [];
    const currencyoptions = [];

    allCurrency.map((curr) => {
        currencyoptions.push({
            value: curr.id,
            label: curr.currency
        });
    });

    worthoptions.push({ value: 'Direct', label: 'Direct' }, { value: 'Indirect', label: 'Indirect' });
    statusoptions.push({ value: 'Active', label: 'Active' }, { value: 'Pending', label: 'Pending' })

    const handleSelectWorth = (row) => {
        setSelectedWorth(row);
        setSelectedWorthId(row.value);
    }

    const handleSelectStatus = (row) => {
        setSelectedStatus(row);
        setSelectedStatusId(row.value);
    }

    const handleCurrencyChange = (selected) => {
        setSelectedCurrency(selected)
        setSelectedCurrencyId(selected.value)
    }

    return (
        <Container fluid style={{ minHeight: "80vh" }}>
            <div
                className="glass-body"
                style={{
                    margin: 10,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    padding: 0,
                    backgroundColor: 'transparent'
                }}
            >

                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <ul id="breadcrumb">
                        <li><a href="/"><IoHomeOutline
                            style={{ color: "white", fontSize: 20 }}
                            
                        /></a></li>
                        <li><a href="/taskcategory-master"><span class="icon icon-beaker"> </span>Masters</a></li>
                        <li><a href="/taskcategory-master"><span class="icon icon-beaker"> </span>Task Categories </a></li>
                        {/* <li><a href="#"><span class="icon icon-double-angle-right"></span>Doctor</a></li> */}

                    </ul>

                </div>
            </div>
            <div className="glass-body" style={{ margin: 10 }}>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                    }}
                    className="flex-column-mobile"
                >
                    <div>
                        <Form.Control
                            className="glass"
                            placeholder="Search"
                            type="text"
                            style={{ display: 'none' }}
                        //   value={searchQuery}
                        //   onChange={(e) => setSearchQuery(e.target.value)}
                        />
                    </div>
                    <div>
                        {Op_per.get().MASTERS_TASK_CATEGORIES.ALLOW_ADD ? (
                            <Button style={{ backgroundColor: "#2194f0" }} onClick={handleShow}>
                                <FaPlus />
                            </Button>
                        ) : ("")}
                    </div>
                </div>
                <div style={{ marginTop: 10 }}>
                    <DataTable
                        columns={columns}
                        data={data}
                        // progressPending={pending}
                        // selectableRows
                        // onSelectedRowsChange={({ selectedRows }) =>
                        // setSelectedRows(selectedRows)
                        //   }
                        pagination
                        customStyles={{headRow: {style: {backgroundColor: '#c0dfff',  padding:10,borderRadius:'10px 10px 0 0' }, }  }}
                        CustomHeader={<CustomHeader/>}
                        // actions={actionsMemo}
                        style={{ marginTop: 10 }}
                        // progressPending={pending}
                        // progressComponent={<LoadingComponent/>}
                        dense
                    />
                </div>
            </div>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Task Category</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Category Name <span style={{color:"red"}} >*</span></Form.Label>
                            <Form.Control
                                style={{
                                    height: 40,
                                    borderColor: '#ccc',
                                    borderWidth: 1,
                                    paddingHorizontal: 8,
                                    marginBottom: 16,
                                    
                                    borderRadius:10,
                                }}
                                type="text"
                                onChange={(e) => setName(e.target.value)}
                                placeholder="Enter Category Name"
                                required
                            />
                        </Form.Group>
                        <Form.Label>Worth <span style={{color:"red"}} >*</span></Form.Label>
                        <Form.Group
                            className="mb-3 d-flex w-100"
                            style={{ gap: "15px" }}
                            controlId="formBasicPassword"
                        >
                            <div className="w-100">
                                <Select
                                    className="selectdoctor"
                                    options={worthoptions}
                                    value={selectedWorth}
                                    onChange={handleSelectWorth}
                                    styles={{
   
   dropdownIndicator: (provided) => ({
     ...provided,
     color: '#ccc' // Change color of the arrow here
   })
 }}
                                />
                            </div>
                        </Form.Group>
                        <Form.Label>Cost <span style={{color:"red"}} >*</span></Form.Label>
                        <Form.Control
                            style={{
                                height: 40,

                                borderColor: '#ccc',
                                borderWidth: 1,
                                paddingHorizontal: 8,
                                marginBottom: 16,
                                
                                borderRadius:10,
                            }}
                            type="number"
                            placeholder="Enter Cost"
                            onChange={(e) => { setCost(e.target.value) }}
                        />
                        <Form.Label>Currency <span style={{color:"red"}} >*</span></Form.Label>
                        <Form.Group
                            className="mb-3 d-flex w-100"
                            style={{ gap: "15px" }}
                            controlId="formBasicPassword"
                            placeholder="Select Currency"
                            color="black"
                        >
                            <div className="selectdoctor">
                                <Select
                                    options={currencyoptions}
                                    value={selectedCurrency}
                                    onChange={handleCurrencyChange}
                                    styles={{
   
   dropdownIndicator: (provided) => ({
     ...provided,
     color: '#ccc' // Change color of the arrow here
   })
 }}
                                />
                            </div>
                        </Form.Group>
                        <Form.Label>Status <span style={{color:"red"}} >*</span></Form.Label>
                        <Form.Group
                            className="mb-3 d-flex w-100"
                            style={{ gap: "15px" }}
                            controlId="formBasicPassword"
                        >
                            <div className="w-100">
                                <Select
                                    className="selectdoctor"
                                    options={statusoptions}
                                    value={selectedStatus}
                                    onChange={handleSelectStatus}
                                    styles={{
   
   dropdownIndicator: (provided) => ({
     ...provided,
     color: '#ccc' // Change color of the arrow here
   })
 }}
                                />
                            </div>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button
                        variant="primary"
                        onClick={handleSave}
                    >
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showedit} onHide={() => setShowedit(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Task Category</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Category Name <span style={{color:"red"}} >*</span></Form.Label>
                            <Form.Control
                                style={{
                                    height: 40,

                                    borderColor: '#ccc',
                                    borderWidth: 1,
                                    paddingHorizontal: 8,
                                    marginBottom: 16,
                                    
                                    borderRadius:10,
                                }}
                                type="text"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                placeholder="Enter Task Category Name"
                                required
                            />
                        </Form.Group>
                        <Form.Label>Worth <span style={{color:"red"}} >*</span></Form.Label>
                        <Form.Group
                            className="mb-3 d-flex w-100"
                            style={{ gap: "15px" }}
                            controlId="formBasicPassword"
                        >
                            <div className="w-100">
                                <Select
                                    className="selectdoctor"
                                    options={worthoptions}
                                    value={selectedWorth}
                                    onChange={handleSelectWorth}
                                    styles={{
   
   dropdownIndicator: (provided) => ({
     ...provided,
     color: '#ccc' // Change color of the arrow here
   })
 }}
                                />
                            </div>
                        </Form.Group>
                        <Form.Label>Cost <span style={{color:"red"}} >*</span></Form.Label>
                        <Form.Control
                            style={{
                                height: 40,

                                borderColor: '#ccc',
                                borderWidth: 1,
                                paddingHorizontal: 8,
                                marginBottom: 16,
                                
                                borderRadius:10,
                            }}
                            type="number"
                            placeholder="Enter Cost"
                            value={cost}
                            onChange={(e) => { setCost(e.target.value) }}
                        />
                        <Form.Label>Currency <span style={{color:"red"}} >*</span></Form.Label>
                        <Form.Group
                            className="mb-3 d-flex w-100"
                            style={{ gap: "15px" }}
                            controlId="formBasicPassword"
                            placeholder="Select"
                        >
                            <div className="selectdoctor">
                                <Select

                                    options={currencyoptions}
                                    value={selectedCurrency}
                                    onChange={handleCurrencyChange}
                                    styles={{
   
   dropdownIndicator: (provided) => ({
     ...provided,
     color: '#ccc' // Change color of the arrow here
   })
 }}
                                />
                            </div>
                        </Form.Group>
                        <Form.Label>Status <span style={{color:"red"}} >*</span></Form.Label>
                        <Form.Group
                            className="mb-3 d-flex w-100"
                            style={{ gap: "15px" }}
                            controlId="formBasicPassword"
                        >
                            <div className="w-100">
                                <Select
                                    className="selectdoctor"
                                    options={statusoptions}
                                    value={selectedStatus}
                                    onChange={handleSelectStatus}
                                    styles={{
   
   dropdownIndicator: (provided) => ({
     ...provided,
     color: '#ccc' // Change color of the arrow here
   })
 }}
                                />
                            </div>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowedit(false)}>
                        Close
                    </Button>
                    <Button
                        variant="primary"
                        onClick={handleEditSave}
                    >
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>

        </Container>
    )

}

export default TaskCategoryMaster;