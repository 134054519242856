import React, { useState } from 'react';
import { Form, Button, Table, Container, Row, Col } from 'react-bootstrap';
import { FaPlus } from 'react-icons/fa';
import { IoMdClose } from 'react-icons/io';
import { v4 as uuidv4 } from 'uuid';

const CreatePurchaseInvoice = () => {
  const [invoiceItems, setInvoiceItems] = useState([]);
  const [vendorName, setVendorName] = useState('');
  const [billNumber, setBillNumber] = useState('');
  const [orderNumber, setOrderNumber] = useState('');
  const [billDate, setBillDate] = useState('');
  const [dueDate, setDueDate] = useState('');
  const [paymentTerms, setPaymentTerms] = useState('Due on Receipt');
  const [itemName, setItemName] = useState('');
  const [account, setAccount] = useState('');
  const [quantity, setQuantity] = useState(1);
  const [rate, setRate] = useState(0);
  const [tax, setTax] = useState('');
  const [customerDetails, setCustomerDetails] = useState('');
  const [discount, setDiscount] = useState(0);
  const [notes, setNotes] = useState('');
  const [tds, setTds] = useState(false);
  const [tcs, setTcs] = useState(false);
  const [selectedTax, setSelectedTax] = useState('');
  const [adjustment, setAdjustment] = useState(0);

  const addItem = () => {
    const newItem = {
      id: uuidv4(),
      name: itemName,
      account,
      quantity: parseInt(quantity),
      rate: parseFloat(rate),
      tax: parseFloat(tax) || 0,
      customerDetails,
    };
    setInvoiceItems([...invoiceItems, newItem]);
    setItemName('');
    setAccount('');
    setQuantity(1);
    setRate(0);
    setTax('');
    setCustomerDetails('');
  };

  const handleDelete = (id) => {
    setInvoiceItems(invoiceItems.filter(item => item.id !== id));
  };

  const handleEdit = (id, field, value) => {
    const updatedItems = invoiceItems.map(item => {
      if (item.id === id) {
        return { ...item, [field]: value };
      }
      return item;
    });
    setInvoiceItems(updatedItems);
  };

  const calculateAmount = (item) => (item.quantity * item.rate) + (item.quantity * item.rate * item.tax / 100);
  const subTotal = invoiceItems.reduce((total, item) => total + calculateAmount(item), 0);
  const discountAmount = subTotal * (parseFloat(discount) || 0) / 100;
  const tdsTcsAmount = tds ? subTotal * (parseFloat(selectedTax) || 0) / 100 : tcs ? subTotal * (parseFloat(selectedTax) || 0) / 100 : 0;
  const totalAmount = subTotal - discountAmount + parseFloat(adjustment) + tdsTcsAmount;

  return (
    <Container>
      <h1>New Bill</h1>
      <Form>
        <Row>
          <Col md={6}>
            <Form.Group controlId="formVendorName">
              <Form.Label>Vendor Name</Form.Label>
              <Form.Control
                type="text"
                value={vendorName}
                onChange={(e) => setVendorName(e.target.value)}
                placeholder="Select a Vendor"
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Form.Group controlId="formBillNumber">
              <Form.Label>Bill#</Form.Label>
              <Form.Control
                type="text"
                value={billNumber}
                onChange={(e) => setBillNumber(e.target.value)}
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="formOrderNumber">
              <Form.Label>Order Number</Form.Label>
              <Form.Control
                type="text"
                value={orderNumber}
                onChange={(e) => setOrderNumber(e.target.value)}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Form.Group controlId="formBillDate">
              <Form.Label>Bill Date</Form.Label>
              <Form.Control
                type="date"
                value={billDate}
                onChange={(e) => setBillDate(e.target.value)}
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="formDueDate">
              <Form.Label>Due Date</Form.Label>
              <Form.Control
                type="date"
                value={dueDate}
                onChange={(e) => setDueDate(e.target.value)}
              />
            </Form.Group>
          </Col>
        </Row>
        <Form.Group controlId="formPaymentTerms">
          <Form.Label>Payment Terms</Form.Label>
          <Form.Control
            as="select"
            value={paymentTerms}
            onChange={(e) => setPaymentTerms(e.target.value)}
          >
            <option>Due on Receipt</option>
            <option>Net 30</option>
            <option>Net 60</option>
            <option>Net 90</option>
          </Form.Control>
        </Form.Group>
      </Form>

      <Table striped bordered hover className="mt-3 table table-bordered">
        <thead>
          <tr>
            <th>Item/Details</th>
            <th>Account</th>
            <th>Quantity</th>
            <th>Rate</th>
            <th>Tax %</th>
            {/* <th>Customer Details</th> */}
            <th>Amount</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {invoiceItems.map(item => (
            <tr key={item.id}>
              <td>
                <Form.Control
                  type="text"
                  as="textarea"
                  value={item.name}
                  onChange={(e) => handleEdit(item.id, 'name', e.target.value)}
                />
              </td>
              <td>
                <Form.Control
                  type="text"
                  value={item.account}
                  onChange={(e) => handleEdit(item.id, 'account', e.target.value)}
                />
              </td>
              <td>
                <Form.Control
                  type="number"
                  value={item.quantity}
                  onChange={(e) => handleEdit(item.id, 'quantity', parseInt(e.target.value))}
                />
              </td>
              <td>
                <Form.Control
                  type="number"
                  value={item.rate}
                  onChange={(e) => handleEdit(item.id, 'rate', parseFloat(e.target.value))}
                />
              </td>
              <td>
                <Form.Control
                  type="number"
                  value={item.tax}
                  onChange={(e) => handleEdit(item.id, 'tax', parseFloat(e.target.value))}
                />
              </td>
              {/* <td>
                <Form.Control
                  type="text"
                  value={item.customerDetails}
                  onChange={(e) => handleEdit(item.id, 'customerDetails', e.target.value)}
                />
              </td> */}
              <td>{calculateAmount(item).toFixed(2)}</td>
              <td>
    
                <IoMdClose size={20} color='red'  onClick={() => handleDelete(item.id)} />

              </td>
            </tr>
          ))}
          <tr>
            <td>
              <Form.Control
                type="text"
                as="textarea"
                value={itemName}
                onChange={(e) => setItemName(e.target.value)}
                placeholder="Type or click to select an item"
              />
            </td>
            <td>
              <Form.Control
                type="text"
                value={account}
                onChange={(e) => setAccount(e.target.value)}
                placeholder="Select an account"
              />
            </td>
            <td>
              <Form.Control
                type="number"
                value={quantity}
                onChange={(e) => setQuantity(e.target.value)}
              />
            </td>
            <td>
              <Form.Control
                type="number"
                value={rate}
                onChange={(e) => setRate(e.target.value)}
              />
            </td>
            <td>
              <Form.Control
                type="number"
                value={tax}
                onChange={(e) => setTax(e.target.value)}
                placeholder="Select a Tax"
              />
            </td>
            {/* <td>
              <Form.Control
                type="text"
                value={customerDetails}
                onChange={(e) => setCustomerDetails(e.target.value)}
                placeholder="Select Customer"
              />
            </td> */}
            <td colSpan="2">
              <Button variant="primary" size='sm' onClick={addItem}>
              <FaPlus /> Add
              </Button>
            </td>
          </tr>
        </tbody>
      </Table>

      <Row className="mt-3">
        <Col md={8}>
          <Form.Group controlId="formNotes">
            <Form.Label>Notes</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
            />
          </Form.Group>
          <br/>
          <Form.Group controlId="formFile">
            <Form.Label>Attach File(s) to Bill</Form.Label>
            <Form.Control type="file" multiple />
            <Form.Text className="text-muted">
              You can upload a maximum of 5 files, 10MB each
            </Form.Text>
          </Form.Group>
        </Col>
        <Col md={4}>
        <Table bordered className="mt-3 table table-bordered">
            <tbody>
              <tr>
                <td>Sub Total</td>
                <td>{subTotal.toFixed(2)}</td>
              </tr>
              <tr>
                <td>Discount</td>
                <td>
                  <Form.Control
                    type="number"
                    value={discount}
                    onChange={(e) => setDiscount(e.target.value)}
                  />
                </td>
                <td>{discountAmount.toFixed(2)}</td>
              </tr>
              <tr>
                <td>
                  <Form.Check
                    type="radio"
                    label="TDS"
                    name="taxType"
                    checked={tds}
                    onChange={() => { setTds(true); setTcs(false); }}
                  />
                  <Form.Check
                    type="radio"
                    label="TCS"
                    name="taxType"
                    checked={tcs}
                    onChange={() => { setTds(false); setTcs(true); }}
                  />
                </td>
                <td>
                  <Form.Control
                    as="select"
                    value={selectedTax}
                    onChange={(e) => setSelectedTax(e.target.value)}
                  >
                    <option value="">Select a Tax</option>
                    <option value="5">5%</option>
                    <option value="10">10%</option>
                    <option value="18">18%</option>
                  </Form.Control>
                </td>
                <td>{tdsTcsAmount.toFixed(2)}</td>
              </tr>
              <tr>
                <td>Adjustment</td>
                <td>
                  <Form.Control
                    type="number"
                    value={adjustment}
                    onChange={(e) => setAdjustment(e.target.value)}
                  />
                </td>
                <td>{parseFloat(adjustment).toFixed(2)}</td>
              </tr>
              <tr>
                <td>Total</td>
                <td>{totalAmount.toFixed(2)}</td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>

    </Container>
  );
};

export default CreatePurchaseInvoice;
