import { Container } from "react-bootstrap"
import Chart from 'react-apexcharts';
import { getLeadSourcData } from "../../utils/proxy/ReportsProxy";
import { useEffect, useState } from "react";

export const DonutDemo = () => {

    const [names, setNames] = useState([]);
    const [count, setCount] = useState([]);

    useEffect(()=>{
        const getLeadData = async() =>{
            let d = await getLeadSourcData();
            let names = [];
            let series = [];
            if (d) {
              d && d.map(k =>{
                series.push(k.count);
                names.push(k.date)
              })
            setNames(names.reverse());
            setCount(series.reverse());
            }
          }
          getLeadData();
    },[])

    const options = {
        chart: {
            type: 'donut'
        },
        labels: names,
        colors:[
            "#1F77B4",
            "#FF7F0E",
            "#2CA02C",
            "#D62728",
            "#9467BD",
            "#E377C2",
            // "#7F7F7F",
            "#BCBD22",
            "#17BECF",
            "#F5A400",
            "#9B59B6",
            "#E74C3C",
            "#F39C12",
            "#1ABC9C",
            "#3498DB",
            "#2ECC71",
            "#8E44AD",
            "#FF6347",
            "#16A085"
          ]
        ,
        plotOptions: {
            pie: {
                expandOnClick: false,
                donut: {
                    labels: {
                        show: true,
                    }
                }
            }
        },
        // title: {
        //     text: "Leads By Sources",
        //     style: {
        //         fontSize: '13px',
        //     }
        // },
        legend: {
            show: true,
            position: 'bottom',
            markers: {
                width: 12,
                height: 12,
                radius: 2 // To make the legend marker square, set radius to 0
            }
        }
    }

    const series = count;

    return (
        <Container fluid>
            <div className="donut">
                <Chart options={options} width={420} series={series} type="donut" />
            </div>
        </Container>
    )
}