import React, { useEffect, useState } from 'react'
import { Badge, Button, Col, Container, Form, InputGroup, Row } from 'react-bootstrap'
import DataTable from 'react-data-table-component'
import { deleteTaxRules, getAllTaxRules } from '../../utils/proxy/TaxProxy';
import { Op_per } from '../../utils/Permissions';
import { AiFillDelete, AiTwotoneEdit } from 'react-icons/ai';
import TaxRulesModal from './TaxRulesModal';
import Swal from 'sweetalert2';

function Taxrules() {
  const [raxrules,setTaxRules] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [action, setSetaction] = useState("");
  const [show, setShow] = useState(false);
  const [reload, setReload] = useState(false);
  const [editData,setEditData] = useState([]);
  

  const filteredData = raxrules.filter((row) =>
    row.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  useEffect(() => {
    AllTaxRules();
    setReload(false);
  },[reload]);

  async function AllTaxRules() {
    let res = await getAllTaxRules();
    if (res) {
      setTaxRules(res)
    }

  }

  function handleEdit(row) {
    setSetaction("edit");
    setEditData(row);
    setShow(true);
    console.log("prasham");
    
  }

  function handleAdd()
  {
    setSetaction("create");
    setShow(true);
  }

  async function handleDelete(row) {
    Swal.fire({
      title: "Are you sure you want to delete ?",
      showCancelButton: true,
      confirmButtonText: "Yes",
      }).then((result) => {
      if (result.isConfirmed) {
        let d = deleteTaxRules(row);
        setReload(true);
        
      }
      
    });
    
   
  }

  const columns = [
    {
      name: "Name",
      selector: (row) => row.name,
     
    },
    {
      name: "Rate",
      selector: (row) => row.taxrate + "%",
      
    },
    {
      name: "Level",
      selector: (row) => row.level,
      
    },
    {
      name: "Countries",
      selector: (row) => row.countries.map((c) =>(<Badge bg="secondary">{c.name}</Badge>)),
      
    }
    ]


    
    if(Op_per.get().VISIBLE_TAX_OPTIONS_RULES.ALLOW_DELETE_TAX_RULES || Op_per.get().VISIBLE_TAX_OPTIONS_RULES.ALLOW_EDIT_TAX_RULES){
      columns.push({
        name: "Actions",
        selector: (row) => (
          <div key={row.id}>
            <AiTwotoneEdit
              style={{ color: "#9889e6", fontSize: 20, display:Op_per.get().VISIBLE_TAX_OPTIONS_RULES.ALLOW_EDIT_TAX_RULES ? '' : 'none' }}
              onClick={() => handleEdit(row)}
            />
            &nbsp;&nbsp;&nbsp;
            <AiFillDelete
              onClick={() =>  handleDelete(row.id)}
              style={{ color: "#f36e6f", fontSize: 20, display:Op_per.get().VISIBLE_TAX_OPTIONS_RULES.ALLOW_DELETE_TAX_RULES ? '' : 'none'}}
            />
          </div>
        ),
        width: "150px",
      })
    }

  return (
    <Container>

<Row>
                <Col>
                    <InputGroup className="mb-3">
                        <Form.Control
                            placeholder="Search Rules"
                            value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
                        />

                    </InputGroup>
                </Col>
                <Col>
                    {Op_per.get().VISIBLE_TAX_OPTIONS_RULES.ALLOW_ADD_TAX_RULES ? <Button onClick={() => handleAdd()} style={{ float: 'right' }}>Add Rules</Button> : ""}
                </Col>
            </Row>
      <Row>
      <DataTable
            columns={columns}
            data={filteredData}
            pagination
            customStyles={{headRow: {style: {backgroundColor: '#c0dfff',  padding:10,borderRadius:'10px 10px 0 0' }, }}}
                        style={{ marginTop: 10 }}
            highlightOnHover
            dense
          />
      </Row>
      {show && <TaxRulesModal action={action} data={editData} show setShow={setShow} setReload={setReload} />}
    </Container>
  )
}

export default Taxrules;