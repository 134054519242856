import React, { useEffect, useState } from 'react'
import { GetNotesByUseridAndStatus } from '../../utils/proxy/NotesProxy';
import { Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

function UserNotes() {

  const navigate = useNavigate();

  const [notes, setNotes] = useState([]);

  useEffect(() => {
    const getNotes = async () => {
      // Fetch notes from the server
      const notesFromServer = await GetNotesByUseridAndStatus("new,inprogress", localStorage.getItem('uuid'));
      setNotes(notesFromServer)
    }

    getNotes()
  }, []);


  function navigateToNotes() {

    navigate('/notes')
    
  }

  return (
    <>
    {
    notes.map((note) => (
      
      <Card key={note.id} onClick={() => navigateToNotes()}>
        <Card.Header style={{backgroundColor: note.status == 'new' ? 'rgb(0, 134, 255)' :'rgb(255, 189, 0)',color:'#fff'}}><b>{note.title}</b></Card.Header>
        <Card.Body>
          
        <p dangerouslySetInnerHTML={{__html:note.description}}></p>
        </Card.Body>
        
      </Card>
    ))
  }
    </>

  )

}

export default UserNotes