import React from 'react'
import { Container } from 'react-bootstrap'
import { IoHomeOutline } from 'react-icons/io5'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Taxrules from './Taxrules';
import Taxconfiguration from './Taxconfiguration';
import Tds from './Tds';
import Tcs from './Tcs';
import { Permissions } from '../../utils/Permissions';

function Taxoptions() {
    return (
        <Container fluid style={{ minHeight: "80vh" }}>
            <div
                className="glass-body"
                style={{
                    margin: 10,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    padding: 0,
                    backgroundColor: 'transparent'
                }}
            >

                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <ul id="breadcrumb">
                        <li><a href="/"><IoHomeOutline
                            style={{ color: "white", fontSize: 20 }}

                        /></a></li>
                        <li><a href="/tax-options"><span class="icon icon-beaker"> </span>Tax Options</a></li>
                        {/* <li><a href="#"><span class="icon icon-double-angle-right"></span>Doctor</a></li> */}

                    </ul>

                </div>
            </div>

            <div className="glass-body" style={{ margin: 10 }}>
                <Tabs
                    defaultActiveKey="profile"
                    id="uncontrolled-tab-example"
                    className="mb-3"
                    justify
                >
                    {Permissions.get().VISIBLE_TAX_OPTIONS_CONFIG ? <Tab eventKey="home" title="Tax Configuration">
                       <Taxconfiguration/>
                    </Tab> : ""}
                    {Permissions.get().VISIBLE_TAX_OPTIONS_RULES ? <Tab eventKey="profile" title="Tax Rules">
                       <Taxrules/>
                    </Tab> : "" }
                    {Permissions.get().VISIBLE_TAX_OPTIONS_TDS ? <Tab eventKey="tds" title="TDS">
                       <Tds/>
                    </Tab> : ""}
                    {Permissions.get().VISIBLE_TAX_OPTIONS_TDS ? 
                    <Tab eventKey="tcs" title="TCS">
                        <Tcs/>
                    </Tab> : ""}
                </Tabs>
            </div>
        </Container>
    )
}

export default Taxoptions