import React, { Component, useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { Row } from 'react-bootstrap';
import { TbChartInfographic } from 'react-icons/tb';
import { getPerDayByMonth } from '../../utils/proxy/ReportsProxy';

function BarChart(selectedUserId,selectedMonth,selectedYear) {

   
    const [dates,setDates] = useState([]);
    const [counts,setCounts] = useState([]);


    useEffect(() => {
        getTasks();
    }, [selectedUserId,selectedMonth,selectedYear])



    async function getTasks() {
        let data = await getPerDayByMonth(selectedUserId.selectedYear.value,selectedUserId.selectedMonth.value,selectedUserId.selectedUserId);
        if (data) {
            let d = [];
            let c = [];
            data.forEach(element => {
                d.push(element.date.split('-')[2]);
                c.push(element.count);
            });
            setDates(d);
            setCounts(c);
        }
    }

    const options = {

        chart: {
            id: 'basic-bar'
        },
        
        xaxis: {
            categories: dates
        }
    }
    const series = [
        {
            name: 'Tasks',
            data: counts
        }
    ]

        return (
            <>
 
            <Row>
            <h6 style={{textAlign:'center', margin:10}}>User Tasks Completed ({selectedUserId.selectedMonth.value}-{selectedUserId.selectedYear.value})</h6>
                        <Chart
                            options={options}
                            series={series}
                            type="bar"
                            height="300"
                            width="100%"
                        />
                </Row>
            </>
        );
    
}

export default BarChart;
