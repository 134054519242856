import { AiOutlineUserAdd } from "react-icons/ai"
import { FaTasks } from "react-icons/fa"
import { MdOutlineLeaderboard } from "react-icons/md"

export const CardDash2 = ({ count, title }) => {

  const cardArray = [
    { title: 'Total Tasks', icon: <FaTasks color="#b0a4f0" size={20} /> },
    { title: 'Total Leads', icon: <MdOutlineLeaderboard color="#27b8ce" size={20} /> },
    { title: 'Total Users', icon: <AiOutlineUserAdd color="#f66387" size={20} /> },
  ]

  return (
    <div className="col-lg-4">
      {/* <div className="card">
            <div className="card-body">
              <div className="d-flex flex-row">
                <div className="col-3 align-self-center">
                  <div className="round">
                    {cardArray.filter(k => k.title === title)[0].icon}
                  </div>
                </div>
                <div className="col-9 align-self-center text-right">
                  <div className="m-l-10">
                    <h5 className="mt-0">{count}</h5>
                    <p className="mb-0 text-muted">
                      {title}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div> */}

      <div className="counter__block m-0">
        <div className="icon__block">
          {cardArray.filter(k => k.title === title)[0].icon}
        </div>

        <div className='text__block'>
          <h5 className="mt-0">{count}</h5>
          <p className="mb-0 text-muted">
            {title}{" "}
          </p>
        </div>
      </div>
    </div>
  )
}