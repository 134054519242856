import React, { useEffect, useState } from 'react'
import { GetAllAreasByCity, CreateUserTeritory, GetAllCountries, GetAllStatesByCountry, GetAllCitiesByState, AddUserTeritory } from '../../../utils/proxy/Countryproxy'

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { AllUsers } from '../../../utils/proxy/Authproxy';

function Addarea({ showModal, closeModal }) {

  const [areatype, setareatype] = React.useState();
  const [areaname, setareaName] = React.useState();
  const [areaCode, setareaCode] = React.useState();
  const [isFocus, setIsFocus] = useState(false);

  const [countries, setCountries] = useState([]);
  const [countryid, setCountryId] = useState();

  const [states, setStates] = useState([]);
  const [stateid, setStateId] = useState();

  const [cities, setCitys] = useState([]);
  const [cityid, setCityId] = useState();

  const [areas, setAreas] = useState([]);
  const [areaid, setAreaId] = React.useState();
  const [users, setUsers] = useState([]);
  const [user, setUserId] = useState('');


  useEffect(() => {
    // Fetch data from an API when the component mounts
    const getallusers = async () => {
        let allusers = await AllUsers();
        if (allusers) {
            setUsers(allusers);
        } else {
            alert('Error', 'Issue While Fetching Users');
        }
    }
    getallusers();

}, []);

  useEffect(() => {
    // Fetch data from an API when the component mounts
    const getallcountries = async () => {
      let countriesdata = await GetAllCountries();
      if (countriesdata) {
        setCountries(countriesdata);
      } else {
        alert('Error', 'Issue While Fetching Countries');
      }
    }
    getallcountries();

  }, []);

  useEffect(() => {

    if (countryid > 0) {

      // Fetch data from an API when the component mounts
      const getallstates = async (countryid) => {
        let statesdata = await GetAllStatesByCountry(countryid);
        if (statesdata) {
          setStates(statesdata);
        } else {
          alert('Error', 'Issue While Fetching States');
        }
      };
      getallstates(countryid);
    }
  }, [countryid]);

  useEffect(() => {
    if (stateid > 0) {
      // Fetch data from an API when the component mounts
      const getallcitys = async (stateid) => {
        let citysdata = await GetAllCitiesByState(stateid);
        if (citysdata) {
          setCitys(citysdata);
        } else {
          alert('Error', 'Issue While Fetching City');
        }
      }
      getallcitys(stateid);
    }
  }, [stateid]);

  useEffect(() => {
    if (stateid > 0) {
      // Fetch data from an API when the component mounts
      const getallareas = async (cityid) => {
        let areasdata = await GetAllAreasByCity(cityid);
        if (areasdata) {
          setAreas(areasdata);
        } else {
          alert('Error', 'Issue While Fetching Teritories');
        }
      }
      getallareas(cityid);
    }
  }, [cityid]);


  const handleUserChange = (event) => {
    setUserId(event.target.value);
  };

  const handleCountryChange = (event) => {
    setCountryId(event.target.value);
  };

  const handleStateChange = (event) => {
    setStateId(event.target.value);
  };

  const handleCityChange = (event) => {
    setCityId(event.target.value);
  };

  const handleAreaChange = (event) => {
    setAreaId(event.target.value);
  };

  const handleAreatypeChange = (event) => {
    setareatype(event.target.value);
  };

  const handleCodeChange = (event) => {
    setareaCode(event.target.value);
  };

  const createUseTeritory = async (user, areaid, areaCode, areatype) => {
    let countriesdata = await AddUserTeritory(user, areaid, areaCode, areatype);
    if (countriesdata) {
      
      alert('Success', 'Teritory Added For Approval');
      closeModal();
    } else {
      alert('Error', 'Issue While Fetching Data');
    }
  }

  const handlesubmit = () => {
    if (areaname === '' || areaCode === '') {
      alert('Error', 'Please enter Teritory and Teritory Code');
    } else {
      createUseTeritory(user, areaid, areaCode, areatype);

    }
  };

  const areatypeoptions = [{ value: "HQ", label: "Headquater" }, { value: "EX", label: "EX Station" }, { value: "OT", label: "Out Station" }];







  return (
    <Modal show={showModal} onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>Add User Territory</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <div style={{paddingBottom:10}}>
          <label>Select User <span style={{color:"red"}}>*</span></label>
          <Form.Select 
          className="selectdoctor"
          value={user}
            onChange={handleUserChange}  style={{
    backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='%232457C5'%3E%3Cpath d='M14.293 7.293a1 1 0 0 0-1.414 1.414L10 11.414l-2.879-2.88a1 1 0 1 0-1.414 1.414l3.5 3.5a1 1 0 0 0 1.414 0l3.5-3.5a1 1 0 0 0 0-1.414z'/%3E%3C/svg%3E")`, // Arrow SVG with blue color (fill='%232457C5')
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right .75rem center',
    backgroundSize: '25px 25px' // Adjust as needed
  }}>
              <option >Select</option>
            {users.map(user => (
              <option key={user.userid} value={user.userid} >{user.firstname} {user.lastname} ({user.username})</option>
            ))}
          </Form.Select>
        </div>
        <div style={{paddingBottom:10}}>
          <label>Select Country <span style={{color:"red"}}>*</span></label>
          <Form.Select 
          className="selectdoctor"
           value={countryid}
            onChange={handleCountryChange}  style={{
    backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='%232457C5'%3E%3Cpath d='M14.293 7.293a1 1 0 0 0-1.414 1.414L10 11.414l-2.879-2.88a1 1 0 1 0-1.414 1.414l3.5 3.5a1 1 0 0 0 1.414 0l3.5-3.5a1 1 0 0 0 0-1.414z'/%3E%3C/svg%3E")`, // Arrow SVG with blue color (fill='%232457C5')
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right .75rem center',
    backgroundSize: '25px 25px' // Adjust as needed
  }}>
              <option >Select</option>
            {countries.map(country => (
              <option key={country.id} value={country.id} >{country.name}</option>
            ))}
          </Form.Select>
        </div>
        <div style={{paddingBottom:10}}>
          <label>Select State <span style={{color:"red"}}>*</span></label>
          <Form.Select 
          className="selectdoctor"
           value={stateid}
            onChange={handleStateChange}  style={{
    backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='%232457C5'%3E%3Cpath d='M14.293 7.293a1 1 0 0 0-1.414 1.414L10 11.414l-2.879-2.88a1 1 0 1 0-1.414 1.414l3.5 3.5a1 1 0 0 0 1.414 0l3.5-3.5a1 1 0 0 0 0-1.414z'/%3E%3C/svg%3E")`, // Arrow SVG with blue color (fill='%232457C5')
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right .75rem center',
    backgroundSize: '25px 25px' // Adjust as needed
  }}>
              <option >Select</option>
            {states.map(state => (
              <option key={state.id} value={state.id} >{state.name}</option>
            ))}
          </Form.Select>
        </div>

        <div style={{paddingBottom:10}}>
          <label>Select City <span style={{color:"red"}}>*</span></label>
          <Form.Select 
          className="selectdoctor"
          value={cityid}
            onChange={handleCityChange}  style={{
    backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='%232457C5'%3E%3Cpath d='M14.293 7.293a1 1 0 0 0-1.414 1.414L10 11.414l-2.879-2.88a1 1 0 1 0-1.414 1.414l3.5 3.5a1 1 0 0 0 1.414 0l3.5-3.5a1 1 0 0 0 0-1.414z'/%3E%3C/svg%3E")`, // Arrow SVG with blue color (fill='%232457C5')
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right .75rem center',
    backgroundSize: '25px 25px' // Adjust as needed
  }}>
              <option >Select</option>
            {cities.map(cty => (
              <option key={cty.id} value={cty.id} >{cty.city}</option>
            ))}
          </Form.Select>
        </div>

        <div style={{paddingBottom:10}}>
          <label>Select Territory Type <span style={{color:"red"}}>*</span></label>
          <Form.Select 
          className="selectdoctor"
           value={areatype}
            onChange={handleAreatypeChange}  style={{
    backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='%232457C5'%3E%3Cpath d='M14.293 7.293a1 1 0 0 0-1.414 1.414L10 11.414l-2.879-2.88a1 1 0 1 0-1.414 1.414l3.5 3.5a1 1 0 0 0 1.414 0l3.5-3.5a1 1 0 0 0 0-1.414z'/%3E%3C/svg%3E")`, // Arrow SVG with blue color (fill='%232457C5')
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right .75rem center',
    backgroundSize: '25px 25px' // Adjust as needed
  }}>
              <option >Select</option>
            {areatypeoptions.map(cty => (
              <option key={cty.value} value={cty.value} >{cty.label}</option>
            ))}
          </Form.Select>
        </div>

        <div style={{paddingBottom:10}}>
          <label>Select Territory <span style={{color:"red"}}>*</span></label>
          <Form.Select 
         className="selectdoctor" 
           value={areaid}
            onChange={handleAreaChange}  style={{
    backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='%232457C5'%3E%3Cpath d='M14.293 7.293a1 1 0 0 0-1.414 1.414L10 11.414l-2.879-2.88a1 1 0 1 0-1.414 1.414l3.5 3.5a1 1 0 0 0 1.414 0l3.5-3.5a1 1 0 0 0 0-1.414z'/%3E%3C/svg%3E")`, // Arrow SVG with blue color (fill='%232457C5')
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right .75rem center',
    backgroundSize: '25px 25px' // Adjust as needed
  }}>
              <option >Select</option>
            {areas.map(cty => (
              <option key={cty.id} value={cty.id} >{cty.area}</option>
            ))}
          </Form.Select>
        </div>
        <div style={{paddingBottom:10}}>
        <label>Teritory Code <span style={{color:"red"}}>*</span></label>
        <Form.Control style={{height: 40,
           
            borderColor: '#ccc',
            borderWidth: 1,
            paddingHorizontal: 8,
            marginBottom: 16,
            borderTopLeftRadius:20,
            borderBottomRightRadius:20,}} type="text" placeholder="Enter Teritory Pin Code" value={areaCode}
        onChange={handleCodeChange} />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={closeModal}>
          Close
        </Button>
        <Button variant="primary" onClick={handlesubmit}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default Addarea