import React, { useEffect, useState } from 'react'
import { Badge, Card, Col, Row, Table } from 'react-bootstrap';
import { FaChartSimple } from "react-icons/fa6";
import Chart from 'react-apexcharts';
import { GetWorkingUsers } from '../../utils/proxy/DashboardProxy';
import { GetUserDetails, GetUserRoiByTask } from '../../utils/proxy/ReportsProxy';
import { GetUserDeductionByUserid } from '../../utils/proxy/DeductionMasterProxy';
import { GetAttendance } from '../../utils/proxy/AttendanceProxy';
import { GetExpenseByUserByMonth } from '../../utils/proxy/Expenseproxy';
import { GetAllLeaveRequestByMonth, GetLeaveCount } from '../../utils/proxy/LeaveRequestProxy';

function UserRoiGraph(selectedUserId,selectedMonth,selectedYear) {


    const [invested, setInvested] = useState();
    const [profit, setProfit] = useState();

    // Cal
    const [userinfo,setUserInfo] = useState({});
    const [userid,setUserId] = useState(selectedUserId.selectedUserId);

    const [present, setPresent] = useState();
    const [absent, setAbsent] = useState();
    const [holiday, setHoliday] = useState();
    const [days,setDays] = useState();

    const [leaves, setLeaves] = useState([]);
    const [leaveCount, setLeaveCount] = useState([]);
    const [casualLeave, setCasualLeave] = useState();
    const [sickLeave, setSickLeave] = useState()

    const [GetAllDeduction,setAllDeduction] = useState([]);
    const [userDeduction,setUserDeduction] = useState([]);

    const [userDeductionCount,setUserDeductionCount] = useState(0);
    const [userExpensePayabale,setUserExpensepayable] = useState(0);

    useEffect(() => {
        const getUserDetail = async () => {
            
            let res = await GetUserDetails(selectedUserId.selectedUserId);
            if (res) {
              setUserInfo(res);
                setInvested(res.salary ? res.salary:0);
            }
        }


        async function getTasksRoi(userid,month,year,status) {
            let res = await GetUserRoiByTask(userid,month,year,status);
            if (res) {

                let t = 0;
                let ut = 0;

                res.forEach(element => {
                    t = t + element.minutes;
                    ut = ut + element.usercost;
                });
                setProfit(ut)
            }

            
        }

        async function getLeaves() {
          let data = await GetAllLeaveRequestByMonth(selectedUserId.selectedMonth.value,selectedUserId.selectedYear.value,selectedUserId.selectedUserId);
          let data2 = await GetLeaveCount(selectedUserId.selectedMonth.value,selectedUserId.selectedYear.value,selectedUserId.selectedUserId);
          if (data) {
              setLeaves(data);
              setLeaveCount(data2);
              setCasualLeave(0);
              setSickLeave(0);
              data2.map(item=>{
                  if(item.name == 'casual leave'){
                      setCasualLeave(le => le + 1)
                  }else if(item.name == 'sick leave'){
                      setSickLeave(le => le + 1);
                  }
              })

          } else {
              alert("Unable To Fetch");
          }
      }

      async function getExp(){
          let res = await GetExpenseByUserByMonth(selectedUserId.selectedMonth.value,selectedUserId.selectedYear.value,selectedUserId.selectedUserId);

          if (res) {

              let count = 0;
              res.forEach(item => {
                  count = count + item.amount

              })
              setUserExpensepayable(count);

          } else {
              
          }
      }


      const getAttendance = async()=>{
          let start = selectedUserId.selectedYear.value+'-'+selectedUserId.selectedMonth.value+'-01';
          let a,b ;
          if(selectedUserId.selectedMonth.value == 12){
              a = (parseInt(selectedUserId.selectedYear.value) + 1).toString();
              b = '01';
          }else{
              a = selectedUserId.selectedYear.value;
              let resultString = (parseInt(selectedUserId.selectedMonth.value) + 1).toString();
              b = resultString.length === 1 ? '0' + resultString : resultString;
          }
          let end = a + '-'+b+'-01';
          let d = await GetAttendance(selectedUserId.selectedUserId,start, end);

          if(d){
              setPresent(0);
              setAbsent(0);
              setHoliday(0);
              setDays(d.length);
              d.map(item=>{
                  if(item.working == 'Present'){
                      setPresent(present=>present+1);
                  }else if(item.working == 'Absent'){
                      setAbsent(absent=>absent+1);
                  }else if(item.working == 'Holiday'){
                      setHoliday(holiday=>holiday+1);
                  }
              })
          }
      }


      const getDeduction = async () => {
          let res = await GetUserDeductionByUserid(selectedUserId.selectedUserId);
          if (res) {
              setUserDeduction(res);
              let count = 0;
              res.forEach(item => {
                  count = count + item.amount

              })
              setUserDeductionCount(count);
          } else {
              alert('Issue While Fetching Deductions')
          }
      }

     
      if ((selectedUserId.selectedUserId !=null || selectedUserId.selectedUserId != undefined) && selectedUserId.selectedMonth.value && selectedUserId.selectedYear.value) {
          getLeaves();
          getDeduction();
          getAttendance();
          getExp();
          getUserDetail();
          getTasksRoi(selectedUserId.selectedUserId,selectedUserId.selectedMonth.value,selectedUserId.selectedYear.value,"completed");
        }
        
        
        
    }, [selectedUserId,selectedMonth,selectedYear])
  
    const chartOptions = {
      chart: {
        type: 'donut',
        toolbar: {
          show: true,
        }
      },
      labels: ['Invested',  profit > Math.round(((userinfo.salary/days)*(present + holiday) - userDeductionCount)) ? "Gain" : "Loss"],
      dataLabels: {
        enabled: false
      },
      pie: {
        donut: {
          labels: {
            show: true,
          },
        },
      },
      colors: ['#3887BE',  profit > Math.round(((userinfo.salary/days)*(present + holiday) - userDeductionCount)) ? "#0BC56D" : "#FF0000"],
      legend: {
        show: true,
        position: 'bottom'
      },
      // title: {
      //   text: "Today's Visit Summary",
      //   style: {
      //     fontSize: '13px',
      //   }
      // },
      legend: {
        show: true,
        position: 'bottom',
        markers: {
          width: 12,
          height: 12,
          radius: 2 // To make the legend marker square, set radius to 0
        }
      }
    };
  
    const series = [Math.round(((userinfo.salary/days)*(present + holiday) - userDeductionCount)), profit];
  return (
    <>


      

        <Row>
            {/* <Col md={12}>
                <Table className='jmn-table' borderless size='xs' responsive>
                    <tr>
                        <td><b>Salary</b></td>
                        <td> :&nbsp;</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td><b>DOJ</b></td>
                        <td> :&nbsp; </td>
                        <td></td>
                    </tr>
                    <tr>
                        <td><b>Deductions</b></td>
                        <td> :&nbsp; </td>
                        <td></td>
                    </tr>
                    <tr>
                        <td><b>Present Days</b></td>
                        <td> :&nbsp; </td>
                        <td></td>
                    </tr>
                   
                </Table>
             </Col> */}
             <div style={{}}>

 
 
             <div  style={{ backgroundColor: 'white', padding: 10, width:"100%", display:"flex",flexDirection:"column" , justifyContent:"center", alignItems:"center"}}>
          <div>
          <h6 style={{textAlign:'center', marginBottom:10}}>User ROI</h6>
            <Chart options={chartOptions} series={series} type="pie" width='100%' height={238} /></div>
          <div><table className='table jmn-table table-hover'  size='xs'>
       <tr>
          <td className='pr-table-heads'><b>Invested</b></td>
          <td className='pr-table-seprator'>:</td>
          <td>{present!=null && holiday!=null && sickLeave!=null && casualLeave!=null ? Math.round(((userinfo.salary/days)*(present + holiday) - userDeductionCount)): ''}</td>
          <td className='pr-table-heads'><b>{profit > Math.round(((userinfo.salary/days)*(present + holiday) - userDeductionCount)) ? "Gain" : "Loss"}</b></td>
          <td className='pr-table-seprator'>:</td>
          <td>{Math.round(profit- Math.round(((userinfo.salary/days)*(present + holiday) - userDeductionCount)))}</td>
        </tr>
        <tr>
          <td className='pr-table-heads'><b>Return</b></td>
          <td className='pr-table-seprator'>:</td>
          <td> {Math.round(profit)}</td>
          <td className='pr-table-heads'><b>ROI</b></td>
          <td className='pr-table-seprator'>:</td>
          <td>{Math.round(((profit-Math.round(((userinfo.salary/days)*(present + holiday) - userDeductionCount))) / Math.round(((userinfo.salary/days)*(present + holiday) - userDeductionCount)) * 100)) + "%" }</td>
        </tr>
       
      
    </table></div>
        </div>
        </div>
        </Row>
</>
  )
}

export default UserRoiGraph