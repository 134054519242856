import React from 'react'

function CardDash({ count, icon, name, updownpercent, updownpercenticon, progress, progresscolor, progressbar }) {
  return (
    <>

      {/* <div className="d-flex flex-row ">
        <div className="col-3 align-self-center">
          <div className="round">
            <i className={icon} />
          </div>
        </div>
        <div className="col-9 align-self-center text-right">
          <div className="m-l-10">
            <h5 className="mt-0">{count}</h5>
            <p className="mb-0 text-muted">
              {name}{" "}
            </p>
          </div>
        </div>
      </div> */}


      <div className="icon__block">
        
        {
          icon == 'doctors' ? <i className="fa fa-user-md" aria-hidden="true" style={{ color: '#27b8ce' }}></i> : ''
        }

        {
          icon == 'hospitals' ? <i className="fa fa-hospital" aria-hidden="true" style={{ color: '#f66387' }}></i> : ''
        }

        {
          icon == 'clinics' ? <i className="fa fa-heartbeat" aria-hidden="true" style={{ color: '#b0a4f0' }}></i> : ''
        }

        {
          icon == 'stockists' ? <i className="fa fa-medkit" aria-hidden="true" style={{ color: '#6da169' }}></i> : ''
        }

      </div>

      <div className='text__block'>
        <h5 className="mt-0">{count}</h5>
        <p className="mb-0 text-muted">
          {name}{" "}
        </p>
      </div>


      {progressbar && <div className="progress mt-3" style={{ height: 3 }}>
        <div
          className={"progress-bar " + progresscolor}
          role="progressbar"
          style={{ width: `${progress}%` }}
          aria-valuenow={progress}
          aria-valuemin={0}
          aria-valuemax={100}
        />
      </div>}

      {/*end card*/}
    </>

  )
}

export default CardDash