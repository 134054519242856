import { Button, Container, Form, Modal } from "react-bootstrap"
import DataTable from "react-data-table-component"
import { IoHomeOutline } from "react-icons/io5"
import { FaPlus } from "react-icons/fa6"
import { useState } from "react"
import { useEffect } from "react"
import { AiFillDelete, AiTwotoneEdit } from "react-icons/ai"
import Swal from "sweetalert2"
import { CreateProduct, DeleteProduct, GetAllProducts, GetAllProductsCategory, ProductAvailableByName, UpdateProduct } from "../../utils/proxy/ProductsProxy"
import { Op_per } from "../../utils/Permissions"
import Select from "react-select"
import LoadingComponent from "../Includes/LoadingComponent"
import { GetAllCurrency } from "../../utils/proxy/Currencyproxy"

export const ProductMaster = () => {

    const [data, setData] = useState([]);
    const [id, setId] = useState();
    const [name, setName] = useState();
    const [description, setDescription] = useState();
    const [allCategory, setAllCategory] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedCategoryId, setSelectedCategoryId] = useState(null);
    const [allCurrency, setAllCurrency] = useState([]);
    const [selectedCurrency, setSelectedCurrency] = useState();
    const [selectedCurrencyId, setSelectedCurrencyId] = useState();
    const [price, setPrice] = useState();
    const [salePrice, setSalePrice] = useState();
    const [quantity, setQuantity] = useState();
    const [reload, SetReload] = useState(false);
    const [show, setShow] = useState(false);
    const [available, setAvailable] = useState(true);
    const [operation, setOperation] = useState('Add');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const getall = async () => {
            let d = await GetAllProducts();
            if (d) {
                setData(d);
            } else {
                alert('Error', 'Issues while fetching data')
            }
        }
        getall()
        SetReload(false);
    }, [reload])

    useEffect(()=>{
        const getcategories = async () =>{
            let d = await GetAllProductsCategory();
            if(d){
                setAllCategory(d);
            }else{
                alert('Error', 'Issues while fetching Categories')
            }
        }

        const getcurrencies = async () =>{
            let d = await GetAllCurrency();
            if(d){
                setAllCurrency(d);
            }else{
                alert('Error', 'Issues while fetching Currency')
            }
        }

        getcategories();
        getcurrencies();
    },[])

    useEffect(() => {
        let debounceTimeout;
      
        if (name !== "" && name !== null && name !== undefined) {
          debounceTimeout = setTimeout(async () => {
            // Make API call with name
            const a = await ProductAvailableByName(name);
            setAvailable(a);
          }, 500); // Adjust debounce delay as needed
        }else{
            setAvailable(true);
        }
      
        // Clear timeout when name changes or becomes empty
        return () => clearTimeout(debounceTimeout);
      }, [name]);
    

    const handleShow = () => {
        setShow(true);
    }

    const handledelete = async (id) => {
        let d = await DeleteProduct(id);
        if (d.status === 'success') {
            SetReload(true);
            Swal.fire({
                title: "Success!",
                text: "Product Deleted !!",
                icon: "success",
                confirmButtonText: "OK",
            });
        }
    }

    const handleEditShow = (row) => {
        setShow(true);
        setOperation('Edit')
        setId(row.id);
        setName(row.name);
        setDescription(row.description);
        setSelectedCategory({ value: row.categoryid, label: row.categoryname })
        setSelectedCategoryId(row.categoryid);
        setSelectedCurrency({value: row.currencyid, label: row.currencyname})
        setSelectedCurrencyId(row.currencyid);
        setPrice(row.price);
        setSalePrice(row.saleprice);
        setQuantity(row.quantity)
    }

    const handleClose = () => {
        setShow(false)
        setAvailable(true);
        setId(null);
        setName(null);
        setDescription(null);
        setSelectedCategory(null);
        setSelectedCategoryId(null);
        setSelectedCurrency();
        setSelectedCurrencyId();
        setPrice();
        setSalePrice();
        setQuantity();
        setOperation('Add')
    }

    const handleNameChange = (e) => {
        const { value } = e.target
        setName(value);
    }

    const category = [];
    const currency = [];

    allCategory.map((cat) => {
        category.push({
            value: cat.id,
            label: cat.name
        })
    })

    allCurrency.map((curr)=>{
        currency.push({
            value: curr.id,
            label: curr.currency
        })
    })

    const handleCategoryChange = (selected) => {
        if (selected === null) {
            setSelectedCategory();
            setSelectedCategoryId();
        } else {
            setSelectedCategory(selected);
            setSelectedCategoryId(selected.value);
        }

    }

    const handleCurrencyChange = (selected) => {
        if (selected === null) {
            setSelectedCurrency();
            setSelectedCurrencyId();
        } else {
            setSelectedCurrency(selected);
            setSelectedCurrencyId(selected.value);
        }

    }

    const handleSave = async () => {
        if (name == null || name == '') {
            Swal.fire({
                title: "Warning!",
                text: "Enter Product Name !!",
                icon: "warning",
                confirmButtonText: "OK",
            });
        } else if(selectedCategoryId == null || selectedCategoryId == ''){
            Swal.fire({
                title: "Warning!",
                text: "Select Product Category !!",
                icon: "warning",
                confirmButtonText: "OK",
            });
        } else if(selectedCurrencyId == null || selectedCurrencyId == ''){
            Swal.fire({
                title: "Warning!",
                text: "Select Currency !!",
                icon: "warning",
                confirmButtonText: "OK",
            });
        } else if(price == null || price <= 0){
            Swal.fire({
                title: "Warning!",
                text: "Enter Appropriate Price !!",
                icon: "warning",
                confirmButtonText: "OK",
            });
        } else if(salePrice == null || salePrice <= 0){
            Swal.fire({
                title: "Warning!",
                text: "Enter Appropriate Sale Price !!",
                icon: "warning",
                confirmButtonText: "OK",
            });
        } else if(quantity == null || quantity < 0){
            Swal.fire({
                title: "Warning!",
                text: "Enter Appropriate Quantity !!",
                icon: "warning",
                confirmButtonText: "OK",
            });
        } else {
            setLoading(false)
            let d = await CreateProduct(name, description,selectedCategoryId,selectedCurrencyId,price,salePrice,quantity,"");
            if (d.status === 'success') {
                setShow(false);
                SetReload(true);
                setName(null);
                setDescription(null);
                setSelectedCategory();
                setSelectedCategoryId();
                setSelectedCurrency();
                setSelectedCurrencyId();
                setPrice();
                setSalePrice();
                setQuantity();
                setLoading(true)
                Swal.fire({
                    title: "Success!",
                    text: "New Product Added !!",
                    icon: "success",
                    confirmButtonText: "OK",
                });
            } else if (d.status === 'error') {
                setLoading(true)
                Swal.fire({
                    title: "Warning!",
                    text: d.message,
                    icon: "warning",
                    confirmButtonText: "OK",
                });
            } else{
                setLoading(true)
                Swal.fire({
                    title: "Warning!",
                    text: "Something Went Wrong!",
                    icon: "warning",
                    confirmButtonText: "OK",
                });
            }
        }
    }

    const handleEditSave = async () => {
        if (name == null || name == '') {
            Swal.fire({
                title: "Warning!",
                text: "Enter Product Name !!",
                icon: "warning",
                confirmButtonText: "OK",
            });
        } else if(selectedCategoryId == null || selectedCategoryId == ''){
            Swal.fire({
                title: "Warning!",
                text: "Select Product Category !!",
                icon: "warning",
                confirmButtonText: "OK",
            });
        } else if(selectedCurrencyId == null || selectedCurrencyId == ''){
            Swal.fire({
                title: "Warning!",
                text: "Select Currency !!",
                icon: "warning",
                confirmButtonText: "OK",
            });
        } else if(price == null || price <= 0){
            Swal.fire({
                title: "Warning!",
                text: "Enter Appropriate Price !!",
                icon: "warning",
                confirmButtonText: "OK",
            });
        } else if(salePrice == null || salePrice <= 0){
            Swal.fire({
                title: "Warning!",
                text: "Enter Appropriate Sale Price !!",
                icon: "warning",
                confirmButtonText: "OK",
            });
        } else if(quantity == null || quantity < 0){
            Swal.fire({
                title: "Warning!",
                text: "Enter Appropriate Quantity !!",
                icon: "warning",
                confirmButtonText: "OK",
            });
        } else {
            setLoading(false)
            let d = await UpdateProduct(id, name, description, selectedCategoryId,selectedCurrencyId,price,salePrice,quantity,"");
            if (d && d.status === 'success') {
                setShow(false);
                setOperation('Add')
                SetReload(true);
                setId(null);
                setName(null);
                setDescription(null);
                setSelectedCategory();
                setSelectedCategoryId();
                setSelectedCurrency();
                setSelectedCurrencyId();
                setPrice();
                setSalePrice();
                setQuantity();
                setLoading(true)
                Swal.fire({
                    title: "Success!",
                    text: "Product Detail Edited !!",
                    icon: "success",
                    confirmButtonText: "OK",
                });
            } else{
                setLoading(true)
                Swal.fire({
                    title: "Warning!",
                    text: "Something Went Wrong!",
                    icon: "warning",
                    confirmButtonText: "OK",
                });
            }
        }
    }

    const columns = [
        // {
        //     name: "Id",
        //     selector: (row) => row.id,
        //   
        //     width:"5%",
        // },
        {
            name: "Product Name",
            selector: (row) => <div style={{ whiteSpace: 'normal', backgroundColor: row.name === "" || row.name === null ? "#f36e6f" : "", borderRadius: "10px", border: row.name === "" || row.name === null ? "2px solid transparent" : "2px solid transparent", padding: "3px", display: "flex", flexDirection: "row", color: row.name === "" || row.name === null ? "#fff" : "#000", margin: "3px", }}>{row.name ? row.name : "N/A"}</div>,
            fontSize: 10,
            fontWeight: 'bold',
        },
        {
            name: "Description",
            selector: (row) => <div style={{ whiteSpace: 'normal', backgroundColor: row.description === "" || row.description === null ? "#f36e6f" : "", borderRadius: "10px", border: row.description === "" || row.description === null ? "2px solid transparent" : "2px solid transparent", padding: "3px", display: "flex", flexDirection: "row", color: row.description === "" || row.description === null ? "#fff" : "#000", margin: "3px", }}>{row.description ? row.description : "N/A"}</div>,
            fontSize: 10,
            fontWeight: 'bold',
        },
        {
            name: "Price",
            selector: (row) => <div style={{ whiteSpace: 'normal', backgroundColor: row.price === "" || row.price === null ? "#f36e6f" : "", borderRadius: "10px", border: row.price === "" || row.price === null ? "2px solid transparent" : "2px solid transparent", padding: "3px", display: "flex", flexDirection: "row", color: row.price === "" || row.price === null ? "#fff" : "#000", margin: "3px", }}>{row.price ? <span>{row.currency} {row.price}</span> : "N/A"}</div>,
            fontSize: 10,
            fontWeight: 'bold',
        },
        {
            name: "Sale Price",
            selector: (row) => <div style={{ whiteSpace: 'normal', backgroundColor: row.saleprice === "" || row.saleprice === null ? "#f36e6f" : "", borderRadius: "10px", border: row.saleprice === "" || row.saleprice === null ? "2px solid transparent" : "2px solid transparent", padding: "3px", display: "flex", flexDirection: "row", color: row.saleprice === "" || row.saleprice === null ? "#fff" : "#000", margin: "3px", }}>{row.saleprice ? <span>{row.currency} {row.saleprice}</span> : "N/A"}</div>,
            fontSize: 10,
            fontWeight: 'bold',
        },
        {
            name: "Quantity",
            selector: (row) => <div style={{ whiteSpace: 'normal', backgroundColor: row.quantity === "" || row.quantity === null ? "#f36e6f" : "", borderRadius: "10px", border: row.quantity === "" || row.quantity === null ? "2px solid transparent" : "2px solid transparent", padding: "3px", display: "flex", flexDirection: "row", color: row.quantity === "" || row.quantity === null ? "#fff" : "#000", margin: "3px", }}>{row.quantity ? row.quantity : "N/A"}</div>,
            fontSize: 10,
            fontWeight: 'bold',
        },
        {
            name: "Category",
            selector: (row) => <div style={{ whiteSpace: 'normal', backgroundColor: row.categoryname === "" || row.categoryname === null ? "#f36e6f" : "", borderRadius: "10px", border: row.categoryname === "" || row.categoryname === null ? "2px solid transparent" : "2px solid transparent", padding: "3px", display: "flex", flexDirection: "row", color: row.categoryname === "" || row.categoryname === null ? "#fff" : "#000", margin: "3px", }}>{row.categoryname ? row.categoryname : "N/A"}</div>,
            fontSize: 10,
            fontWeight: 'bold',
        },
    ];

    const CustomHeader = ({ children }) => (<div style={{ backgroundColor: 'blue', padding: '10px', color: 'white' }}>    {children}  </div>);

    if (Op_per.get().MASTERS_HOLIDAYTYPES.ALLOW_DELETE || Op_per.get().MASTERS_HOLIDAYTYPES.ALLOW_EDIT) {
        columns.push({
            name: "Actions",
            selector: (row) => (
                <div key={row.id}>
                    <AiFillDelete
                        onClick={() => handledelete(row.id)}
                        style={{ color: "#f36e6f", fontSize: 20, display: Op_per.get().MASTERS_HOLIDAYTYPES.ALLOW_DELETE ? '' : 'none' }}
                    />
                    &nbsp;&nbsp;&nbsp;
                    <AiTwotoneEdit
                        onClick={() => handleEditShow(row)}
                        style={{ color: "#9889e6", fontSize: 20, display: Op_per.get().MASTERS_HOLIDAYTYPES.ALLOW_EDIT ? '' : 'none' }}
                    />
                </div>
            ),
        })
    }

    return (
        <Container fluid style={{ minHeight: "80vh" }}>
            <div
                className="glass-body"
                style={{
                    margin: 10,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    padding: 0,
                    backgroundColor: 'transparent'
                }}
            >

                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <ul id="breadcrumb">
                        <li><a href="/"><IoHomeOutline
                            style={{ color: "white", fontSize: 20 }}
                            
                        /></a></li>
                        <li><a href="/product-master"><span class="icon icon-beaker"> </span>Products</a></li>
                        <li><a href="/product-master"><span class="icon icon-beaker"> </span>Master</a></li>
                        {/* <li><a href="#"><span class="icon icon-double-angle-right"></span>Doctor</a></li> */}

                    </ul>

                </div>
            </div>

            <div className="glass-body" style={{ margin: 10 }}>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                    }}
                    className="flex-column-mobile"
                >
                    <div>
                        <Form.Control
                            className="glass"
                            placeholder="Search"
                            type="text"
                            style={{ display: 'none' }}
                        //   value={searchQuery}
                        //   onChange={(e) => setSearchQuery(e.target.value)}
                        />
                    </div>
                    <div>
                        {Op_per.get().MASTERS_HOLIDAYTYPES.ALLOW_ADD ? (
                            <Button style={{ backgroundColor: "#2194f0" }} onClick={handleShow}>
                                <FaPlus />
                            </Button>
                        ) : ("")}
                    </div>
                </div>
                <div style={{ marginTop: 10 }}>
                    <DataTable
                        columns={columns}
                        data={data}
                        // progressPending={pending}
                        // selectableRows
                        // onSelectedRowsChange={({ selectedRows }) =>
                        // setSelectedRows(selectedRows)
                        //   }
                        pagination
                        customStyles={{ headRow: { style: { backgroundColor: '#c0dfff', padding: 7 }, } }}
                        CustomHeader={<CustomHeader />}
                        // actions={actionsMemo}
                        style={{ marginTop: 10 }}
                        // progressPending={pending}
                        // progressComponent={<LoadingComponent/>}
                        dense
                    />
                </div>
            </div>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{operation} Product</Modal.Title>
                </Modal.Header>
                {loading ? <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Product Name <span style={{ color: "red" }} >*</span></Form.Label>
                            <Form.Control
                                style={{
                                    height: 40,

                                    borderColor: available ? '#28B3D2' : 'red',
                                    borderWidth: 1,
                                    paddingHorizontal: 8,
                                    marginBottom: 16,
                                    
                                    borderRadius:10,
                                }}
                                type="text"
                                value={name}
                                onChange={handleNameChange}
                                placeholder="Enter Product Name"
                                required
                            />
                        </Form.Group>
                        <Form.Label>Description </Form.Label>
                        <Form.Control
                            style={{
                                height: 40,

                                borderColor: '#ccc',
                                borderWidth: 1,
                                paddingHorizontal: 8,
                                marginBottom: 16,
                                
                                borderRadius:10,
                            }}
                            type="text"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            placeholder="Enter Description"
                        />
                        <Form.Label>Category <span style={{ color: 'red' }}>*</span></Form.Label>
                        <Form.Group
                            className="mb-3 d-flex w-100"
                            style={{ gap: "15px" }}
                            controlId="formBasicPassword"
                        >
                            <div className="selectdoctor">
                                <Select
                                    options={category}
                                    value={selectedCategory}
                                    onChange={handleCategoryChange}
                                    isClearable={true}
                                    styles={{

                                        dropdownIndicator: (provided) => ({
                                            ...provided,
                                            color: '#ccc' // Change color of the arrow here
                                        })
                                    }}
                                    placeholder="Select Category"
                                />
                            </div>
                        </Form.Group>
                        <Form.Label>Currency <span style={{ color: 'red' }}>*</span></Form.Label>
                        <Form.Group
                            className="mb-3 d-flex w-100"
                            style={{ gap: "15px" }}
                            controlId="formBasicPassword"
                        >
                            <div className="selectdoctor">
                                <Select
                                    options={currency}
                                    value={selectedCurrency}
                                    onChange={handleCurrencyChange}
                                    isClearable={true}
                                    styles={{

                                        dropdownIndicator: (provided) => ({
                                            ...provided,
                                            color: '#ccc' // Change color of the arrow here
                                        })
                                    }}
                                    placeholder="Select Currency"
                                />
                            </div>
                        </Form.Group>
                        <Form.Label>Price </Form.Label>
                        <Form.Control
                            style={{
                                height: 40,

                                borderColor: '#ccc',
                                borderWidth: 1,
                                paddingHorizontal: 8,
                                marginBottom: 16,
                                
                                borderRadius:10,
                            }}
                            type="number"
                            value={price}
                            onChange={(e) => setPrice(e.target.value)}
                            placeholder="Enter Price"
                        />
                        <Form.Label>Sale Price </Form.Label>
                        <Form.Control
                            style={{
                                height: 40,

                                borderColor: '#ccc',
                                borderWidth: 1,
                                paddingHorizontal: 8,
                                marginBottom: 16,
                                
                                borderRadius:10,
                            }}
                            type="number"
                            value={salePrice}
                            onChange={(e) => setSalePrice(e.target.value)}
                            placeholder="Enter Sale Price"
                        />
                        <Form.Label>Quantity </Form.Label>
                        <Form.Control
                            style={{
                                height: 40,

                                borderColor: '#ccc',
                                borderWidth: 1,
                                paddingHorizontal: 8,
                                marginBottom: 16,
                                
                                borderRadius:10,
                            }}
                            type="number"
                            value={quantity}
                            onChange={(e) => setQuantity(e.target.value)}
                            placeholder="Enter Quantity Of Product"
                        />
                    </Form>
                </Modal.Body> : <div style={{display:'grid',placeContent:'center'}}><LoadingComponent /></div>}
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button
                        variant="primary"
                        onClick={operation == 'Add' ? handleSave : handleEditSave}
                    >
                        {operation}
                    </Button>
                </Modal.Footer>
            </Modal>

        </Container>
    )
}